import React, { useState } from "react";
import { TextField, IconButton, InputAdornment, Button, Box, Typography } from "@mui/material";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { Mic, Pause, Send } from "@mui/icons-material";
import PreviewModal from "./PreviewModal";
import { useTranslation } from "react-i18next";
import Lottie from 'lottie-react';
import TypingAnimation from '../../../assests/images/Animations/typing.json';
import RecordingAnimation from '../../../assests/images/Animations/recording.json';
function ChatInputBar({
    handleSendMessage,
    handleAttachFiles,
    handleRecordingStart,
    handleRecordingStop,
    handleCancelRecording,
    setIsRecording,
    isRecording,
    isTyping,
    isTargetRecording,
    chatService,
    targetUserId,
    targetUserType,
}) {
    const [text, setText] = useState("");
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [showPreviewModal, setShowPreviewModal] = useState(false);
    const { i18n, t } = useTranslation();

    const toggleRecording = () => {
        if (isRecording) {
            handleRecordingStop();
        } else {
            handleRecordingStart();
        }
        setIsRecording(!isRecording);
    };

    const cancelRecording = () => {
        setIsRecording(false);
        handleCancelRecording();
    };

    const handleTextChange = (e) => {
        setText(e.target.value);
        if (chatService && chatService.isConnected && targetUserId && targetUserType) {
            chatService.typing(targetUserId, targetUserType);
        }
    };

    const handleFileAttach = (e) => {
        const files = Array.from(e.target.files);
        setSelectedFiles((prev) => [...prev, ...files]);
        setShowPreviewModal(true);
    };

    const handleSend = () => {
        if (text.trim()) {
            handleSendMessage({ text, type: "text" });
            setText("");
        } else if (selectedFiles.length > 0) {
            handleSendFiles();
        }
    };

    const handleSendFiles = () => {
        handleAttachFiles(selectedFiles);
        setSelectedFiles([]);
        setShowPreviewModal(false);
    };

    const removeFile = (index) => {
        const updatedFiles = [...selectedFiles];
        updatedFiles.splice(index, 1);
        setSelectedFiles(updatedFiles);
    };

    const closePreviewModal = () => {
        setSelectedFiles([]);
        setShowPreviewModal(false);
    };

    const handleClick = () => {
        if (text.trim()) {
            handleSend();
        } else {
            toggleRecording();
        }
    };

    const handleKeyPress = (e) => {
        if (e.key === "Enter" && !e.shiftKey) {
            e.preventDefault(); // Prevent new line in the text field
            handleSend();
        }
    };

    const getIcon = () => {
        if (text.trim()) {
            return <Send style={{ fontSize: 30, color: "white" }} />;
        }
        return isRecording ? (
            <Pause style={{ fontSize: 30, color: "white" }} />
        ) : (
            <Mic style={{ fontSize: 30, color: "white" }} />
        );
    };
    const getAnimation = () => {

        if (isTyping) {
            return TypingAnimation;
        }

        if (isTargetRecording) {
            return RecordingAnimation;
        }
    };

    return (
        <div className="action-bar-container">
            <div className="action-bar-container2">
                {(isTyping || isTargetRecording) && (
                    <div
                        className="typingAnimationContainer"
                    >
                        <Lottie
                            animationData={getAnimation()}
                            loop={true}
                            style={{ height: 90, width: 90 }}
                        />
                    </div>
                )}

                {isRecording ? (
                    <div className="recording-container">
                        <button className="cancel-recording-button" onClick={cancelRecording}>
                            <svg width="40" height="40" viewBox="0 0 40 40" fill="none">
                                <circle cx="20" cy="20" r="20" fill="#FF3B30" />
                                <path d="M14 14H26V28H14V14Z" fill="white" />
                                <path d="M12 12H28V14H12V12Z" fill="white" />
                                <path d="M16 10H24V12H16V10Z" fill="white" />
                            </svg>
                        </button>
                        <span className="recording-text">{t('RecordingInProgress')}</span>
                    </div>
                ) : (
                    <div className="message-input-section">
                        <TextField
                            variant="outlined"
                            placeholder={t('TypeYourMessage')}
                            value={text}
                            onChange={handleTextChange}
                            onKeyDown={handleKeyPress}
                            fullWidth
                            multiline
                            maxRows={4}
                            sx={{
                                "& .MuiOutlinedInput-root": {
                                    backgroundColor: "rgba(234, 237, 255, 1)",
                                    borderRadius: "32px",
                                    padding: 0,
                                },
                                "& .MuiOutlinedInput-input": {
                                    padding: "12px 14px", // Adjust padding for better alignment
                                },
                            }}
                            InputProps={{
                                disableUnderline: true,
                                endAdornment: (
                                    <InputAdornment>
                                        <IconButton component="label">
                                            <AttachFileIcon style={{ fontSize: 30 }} />
                                            <input
                                                type="file"
                                                style={{ display: "none" }}
                                                multiple
                                                onChange={handleFileAttach}
                                            />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </div>
                )}
                <button
                    className="action-button"
                    onClick={handleClick}
                    title={text.trim() ? "Send" : isRecording ? "Pause Recording" : "Start Recording"}
                >
                    <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 64 64" className="button-circle">
                        <circle cx="32" cy="32" r="30" fill={text.trim() ? "#304FFF" : "#007BFF"} />
                    </svg>
                    <div className="icon-container">{getIcon()}</div>
                </button>
            </div>

            <PreviewModal
                open={showPreviewModal}
                files={selectedFiles}
                onClose={closePreviewModal}
                onSend={handleSendFiles}
                onRemove={removeFile}
            />
        </div>
    );
}

export default ChatInputBar;