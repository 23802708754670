import React from 'react';
import { FaEdit, FaTrash } from 'react-icons/fa';
import { IoDocumentTextOutline } from 'react-icons/io5'; // For file icons
import './ManageLabelUsersModal.css'; // Your CSS file
import { useTranslation } from 'react-i18next';
import { MdOutlineAccessTime, MdCheckCircleOutline } from 'react-icons/md';
import moment from 'moment-timezone';
function getFileExtension(fileName = '') {
  return fileName.split('.').pop().toLowerCase();
}

/**
 * Extract file details from a blob-based URL or normal URL.
 * Allows you to store the actual file name after the "|" if needed.
 */
function extractFileDetails(fileUrl) {
  let fileName = '';
  if (fileUrl.startsWith('blob:') && fileUrl.includes('|')) {
    const parts = fileUrl.split('|');
    fileUrl = parts[0];   // e.g. "blob:http://..."
    fileName = parts[1];  // e.g. "myfile.jpg"
  } else {
    const parts = fileUrl.split('/');
    fileName = parts[parts.length - 1].split('?')[0] || 'Unknown file';
  }
  return { fileUrl, fileName };
}

/* ---------------------------
   The FilePreview component
   --------------------------- */
function FilePreview({ fileUrl: initialFileUrl, t, openPreview }) {
  const { fileUrl, fileName } = extractFileDetails(initialFileUrl);
  const fileExt = getFileExtension(fileName).toLowerCase();

  const isImage = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'svg', 'webp'].includes(fileExt);
  const isPdf = fileExt === 'pdf';
  const isVideo = ['mp4', 'webm'].includes(fileExt);
  const isAudio = ['mp3', 'wav', 'ogg', 'm4a'].includes(fileExt);
  const isText = ['txt', 'csv'].includes(fileExt);

  const handleClickImage = () => {
    // If you want to open your custom preview modal:
    openPreview([fileUrl], 'auto');
  };

  const handleClickPdf = () => {
    // Use the custom preview or open in new tab, up to you
    openPreview([fileUrl], 'pdf');
  };

  const handleClickGeneric = () => {
    // Fallback method
    openPreview([fileUrl], 'auto');
  };

  return (
    <div className="file-item">
      {/* ---- IMAGE ---- */}
      {isImage && (
        <img
          src={fileUrl}
          alt={fileName}
          className="file-preview image-preview"
          onClick={handleClickImage}
        />
      )}

      {/* ---- PDF ---- */}
      {isPdf && (
        <div className="file-preview pdf-preview">
          <iframe
            title={`pdf-preview-${fileName}`}
            src={fileUrl}
            className="iframe-preview"
          />
          <div
            className="chat-iframe-overlay"
            onClick={handleClickPdf}
            style={{ cursor: 'pointer' }}
            title={t ? t('ClickToOpenPDF') : 'Click to open PDF'}
          />
        </div>
      )}

      {/* ---- VIDEO ---- */}
      {isVideo && (
        <video
          controls
          src={fileUrl}
          className="file-preview video-preview"
          onClick={handleClickGeneric}
        >
          {t ? t('BrowserDoesNotSupportVideo') : 'Your browser does not support the video element.'}
        </video>
      )}

      {/* ---- AUDIO ---- */}
      {isAudio && (
        <audio
          controls
          src={fileUrl}
          className="file-preview audio-preview"
        >
          {t ? t('BrowserDoesNotSupportAudio') : 'Your browser does not support the audio element.'}
        </audio>
      )}

      {/* ---- TEXT ---- */}
      {isText && (
        <div
          className="file-preview text-preview"
          onClick={handleClickGeneric}
          title={fileName}
        >
          <IoDocumentTextOutline size={30} color="#304FFF" />
          <span className="file-name">{fileName}</span>
        </div>
      )}

      {/* ---- FALLBACK (unsupported file types) ---- */}
      {!isImage && !isPdf && !isVideo && !isAudio && !isText && (
        <div
          className="file-preview unsupported-preview"
          onClick={handleClickGeneric}
        >
          <IoDocumentTextOutline size={30} color="#304FFF" />
          <span className="file-name">{fileName}</span>
        </div>
      )}
    </div>
  );
}

/* ---------------------------------
   The main ScheduledItem component
   --------------------------------- */
function ScheduledItem({ item, onDelete, onEdit, openPreview }) {
  const formatToIsraelTime = (utcDate) => {
    if (!utcDate) return '';

    try {
      // Convert UTC date to Israel time
      const date = moment.utc(utcDate).tz('Asia/Jerusalem').format('YYYY-MM-DD HH:mm');
      return date;
    } catch (error) {
      console.error("Error converting to Israel time:", error);
      return '';
    }
  };

  const { t } = useTranslation()
  // Replaced old logic with the new "FilePreview" approach
  const renderFilePreview = (fileUrl, idx) => {
    return (
      <FilePreview
        key={idx}
        fileUrl={fileUrl}
        t={t}       // or pass any translation function you use
        openPreview={openPreview}
      />
    );
  };

  return (
    <div className="scheduled-item-container fancy-bg">
      <div className="scheduled-item-content">
        {/* Show file previews if any */}
        {item.fileKeys && item.fileKeys.length > 0 && (
          <div className="file-carousel-container">
            {item.fileKeys.map((fileUrl, idx) => renderFilePreview(fileUrl, idx))}
          </div>
        )}

        <div className="text-info">
          <h4 className="title">{item.messageContent}</h4>
          <span className="text-Container">
            <p
              className="subtitle"
              style={{ color: item.isActive ? '#F9AA2A' : '#FF5F6D' }} // Dynamically set color
            >
              {t(item.isActive ? 'NextRun' : 'Runed')}:{' '}
            </p>
            <p className="sender-value">
              {formatToIsraelTime(item.nextRunTime)}
            </p>
            {item.isActive ? (
              <MdCheckCircleOutline size={28} color="#36D1DC" style={{ marginRight: '8px' }} />
            ) : (
              <MdOutlineAccessTime size={28} color="#FF5F6D" style={{ marginRight: '8px' }} />
            )}
          </span>
          <span className="text-Container">
            <p className="sender">{t('Sender')}:</p>
            <p className="sender-value">{item.senderName}</p>
          </span>
        </div>
      </div>

      {/* Right side: Edit / Delete icons */}
      <div className="scheduled-item-actions">
        <button className="icon-btn edit" onClick={() => onEdit(item)}>
          <FaEdit />
        </button>
        <button className="icon-btn delete" onClick={() => onDelete(item)}>
          <FaTrash />
        </button>
      </div>
    </div>
  );
}

export default ScheduledItem;
