// ImportExportService.js

import config from '../config';
import selectedEnvironment from '../env';

// Define the API URL based on the selected environment
const API_URL = `${config[selectedEnvironment].API_URL}`;


const ExportTransactionReportMovinDat = async (caseId, startDate, endDate, auth) => {
    try {
        // Prepare the payload from the parameters
        const payload = {
            CaseId: caseId,
            StartDate: startDate,
            EndDate: endDate
        };

        // Make the HTTP request to get the file data
        const response = await fetch(`${API_URL}/ImportExportTransactions/ExportTransactionReportMovinDat`, {
            method: 'POST',
            headers: {
                "Authorization": `Bearer ${auth}`,
                "Content-Type": "application/json"
            },
            body: JSON.stringify(payload)
        });

        if (!response.ok) {
            const errorText = await response.text();
            throw new Error(errorText || `HTTP error! status: ${response.status}`);
        }

        // Extract the Content-Disposition header to determine the file name
        const contentDisposition = response.headers.get('Content-Disposition');
        const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        const matches = filenameRegex.exec(contentDisposition);
        let filename = 'transaction_report_movin.dat'; // Default filename

        if (matches && matches[1]) {
            filename = matches[1].replace(/['"]/g, ''); // Remove quotes if any
        }

        // Get the file content from the response
        const fileContent = await response.blob();

        // Create a URL for the file
        const fileUrl = window.URL.createObjectURL(fileContent);

        // Create a temporary link and trigger the download
        const link = document.createElement('a');
        link.href = fileUrl;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);

        // Clean up the object URL
        window.URL.revokeObjectURL(fileUrl);

    } catch (err) {
        console.error(err);
        alert("Error downloading the report. Please try again.");
    }
};

  

/**
 * Import Transaction Report Movin.DAT
 * @param {string} caseId - The Case ID
 * @param {File} file - The Movin.DAT file
 * @param {string} auth - The Bearer token for authentication
 * @returns {Promise<Object>} - Returns the response JSON
 */
const ImportTransactionReportMovinDat = async (caseId, file, auth) => {
    const formData = new FormData();
    formData.append('caseId', caseId);
    formData.append('file', file);

    const response = await fetch(`${API_URL}/ImportExportTransactions/ImportTransactionReportMovinDat?caseId=${encodeURIComponent(caseId)}`, {
        method: 'POST',
        headers: {
            "Authorization": `Bearer ${auth}`
            // Note: Do not set "Content-Type" when sending FormData
        },
        body: formData
    });

    if (!response.ok) {
        const errorText = await response.text();
        throw new Error(errorText || 'Failed to import transaction report Movin.DAT');
    }

    return await response;
};

/**
 * Import Account Cards Heshin.Dat
 * @param {string} caseId - The Case ID
 * @param {File} prmFile - The heshin.prm file
 * @param {File} datFile - The heshin.dat file
 * @param {string} auth - The Bearer token for authentication
 * @returns {Promise<Object>} - Returns the response JSON with processing details
 */
const ImportAccountCardsHeshinDat = async (caseId, prmFile, datFile, auth) => {
    console.log(caseId, prmFile, datFile, auth);
    
    const formData = new FormData();
    formData.append('caseId', caseId);
    formData.append('prmFile', prmFile);
    formData.append('datFile', datFile);

    const response = await fetch(`${API_URL}/ImportExportTransactions/ImportAccountCardsHeshinDat?caseId=${encodeURIComponent(caseId)}`, {
        method: 'POST',
        headers: {
            "Authorization": `Bearer ${auth}`
            // Do not set "Content-Type" for FormData
        },
        body: formData
    });

    if (!response.ok) {
        const errorText = await response.text();
        throw new Error(errorText || 'Failed to import Account Cards Heshin.Dat');
    }

    return await response.json();
};

/**
 * Export Account Cards Heshin.Dat
 * @param {string} caseId - The Case ID
 * @param {string} auth - The Bearer token for authentication
 * @returns {Promise<{ blob: Blob, fileName: string }>} - Returns the ZIP file blob and name
 */
const ExportAccountCardsHeshinDat = async (caseId, auth) => {
    try {
        // Make the HTTP request to get the file data
        const response = await fetch(`${API_URL}/ImportExportTransactions/ExportAccountCardsHeshinDat?caseId=${encodeURIComponent(caseId)}`, {
            method: 'GET',
            headers: {
                "Authorization": `Bearer ${auth}`
            }
        });

        if (!response.ok) {
            const errorText = await response.text();
            throw new Error(errorText || `HTTP error! status: ${response.status}`);
        }

        // Extract the Content-Disposition header to determine the file name
        const contentDisposition = response.headers.get('Content-Disposition');
        const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        const matches = filenameRegex.exec(contentDisposition);
        let filename = 'account_cards_heshin.zip'; // Default filename

        if (matches && matches[1]) {
            filename = matches[1].replace(/['"]/g, ''); // Remove quotes if any
        }

        // Get the file content from the response
        const fileContent = await response.blob();

        // Create a URL for the file
        const fileUrl = window.URL.createObjectURL(fileContent);

        // Create a temporary link and trigger the download
        const link = document.createElement('a');
        link.href = fileUrl;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);

        // Clean up the object URL
        window.URL.revokeObjectURL(fileUrl);
    } catch (err) {
        console.error(err);
        alert("Error downloading the file. Please try again.");
    }
};


/**
 * Import Problematic Account Cards
 * @param {string} caseId - The Case ID
 * @param {Array<Object>} accountCardActions - List of account card actions
 * @param {string} auth - The Bearer token for authentication
 * @returns {Promise<void>} - Returns nothing on success
 */
const ImportProblematicAccountCards = async (caseId, accountCardActions, auth) => {
    const response = await fetch(`${API_URL}/ImportExportTransactions/ImportProblomaticAccountCards?caseId=${encodeURIComponent(caseId)}`, {
        method: 'POST',
        headers: {
            "Authorization": `Bearer ${auth}`,
            "Content-Type": "application/json"
        },
        body: JSON.stringify(accountCardActions)
    });

    if (!response.ok) {
        const errorText = await response.text();
        throw new Error(errorText || 'Failed to import problematic account cards');
    }

    return;
};

// Consolidate all functions into the service object
const ImportExportService = {
    ExportTransactionReportMovinDat,
    ImportTransactionReportMovinDat,
    ImportAccountCardsHeshinDat,
    ExportAccountCardsHeshinDat,
    ImportProblematicAccountCards
};

export default ImportExportService;
