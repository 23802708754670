import axios from 'axios';
import config from '../config';
import selectedEnvironment from '../env';
import AuthService from './auth.service';
const BASEAPI = config[selectedEnvironment].CHAT_URL;
const API_URL = `${BASEAPI}`;

const getByLabel = async (labelId) => {
    const token = await AuthService.getCurrentToken();
    const response = await axios.get(`${API_URL}/api/ScheduledLabelMessages/GetByLabel`, {
        headers: {
            "Authorization": `Bearer ${token}`,
        },
        params: {
            labelId,
        },
    });
    return response.data; // This should be List<ScheduledLabelMessageInfoDto>
};

const createScheduledMessage = async (payload) => {
    const token = await AuthService.getCurrentToken();
    
    const response = await axios.post(`${API_URL}/api/ScheduledLabelMessages/Create`, payload, {
        headers: {
            "Authorization": `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
        },
    });
    return response.data;
};

const updateScheduledMessage = async (payload, auth) => {
    const token = await AuthService.getCurrentToken();

    const response = await axios.put(`${API_URL}/api/ScheduledLabelMessages/Update`, payload, {
        headers: {
            "Authorization": `Bearer ${token}`,
            "Content-Type": "multipart/form-data",

        },
    });
    return response.data;
};

const deleteScheduledMessage = async (messageId, auth) => {
    const token = await AuthService.getCurrentToken();

    const response = await axios.delete(`${API_URL}/api/ScheduledLabelMessages/Delete/${messageId}`, {
        headers: {
            "Authorization": `Bearer ${token}`,
        },
    });
    return response.data;
};

const ScheduledMessagesService = {
    getByLabel,
    createScheduledMessage,
    updateScheduledMessage,
    deleteScheduledMessage,
};

export default ScheduledMessagesService;
