import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import config from '../config';
import selectedEnvironment from '../env';
const BASEAPI = config[selectedEnvironment].CHAT_URL;
const HUB_URL = `${BASEAPI}/chathub`;

class ChatService {
  constructor() {
    this.connection = null;
    this.isConnected = false;
    this.subscriptions = {};
    this.startConnection = this.startConnection.bind(this);
  }

  async getAccessToken() {
    const token =  localStorage.getItem('token');
    return token ? JSON.parse(token) : null;
  }

  async startConnection() {
    const token = await this.getAccessToken();

    this.connection = new HubConnectionBuilder()
      .withUrl(HUB_URL, { accessTokenFactory: () => token })
      .withAutomaticReconnect([0, 2000, 5000, 10000]) // retry intervals
      .configureLogging(LogLevel.Debug)
      .build();

    // Register handlers
    this.registerHandlers();

    try {
      await this.connection.start();
      console.log('ChatService SignalR Connected.');
      this.isConnected = true;
    } catch (err) {
      console.error('ChatService SignalR Connection Error:', err);
      // If unable to connect, a reconnect attempt will be triggered by withAutomaticReconnect
    }
  }

  registerHandlers() {
    if (!this.connection) return;

    this.connection.onreconnecting(error => {
      this.isConnected = false;
      console.warn('ChatService SignalR reconnecting...', error);
    });

    this.connection.onreconnected(connectionId => {
      console.log('ChatService SignalR reconnected. Connection ID:', connectionId);
      this.isConnected = true;
    });

    this.connection.onclose(error => {
      this.isConnected = false;
      console.log('ChatService SignalR connection closed.', error);
      // Automatic reconnect will attempt to reconnect if configured.
    });

    // Incoming Messages
    this.connection.on('UserSend', async (senderId, senderType) => {
      console.log(senderId, senderType);
      
      this.emit('UserSend', senderId, senderType);
    });

    // Typing notifications
    this.connection.on('Typing', (senderId, senderType) => {
      console.log(`${senderId} is typing...`);
      this.emit('Typing', senderId, senderType);
    }); 

    this.connection.on('IsRecording', (senderId, senderType, isRecording) => {
      console.log(`User ${senderId} is ${isRecording ? 'recording' : 'not recording'}`);
      this.emit('IsRecording', senderId, senderType, isRecording);
    });


    this.connection.on('MessageRead', (readerId, readerType, readTimestamp) => {
      console.log(`User ${readerId} read the message at ${readTimestamp}.`);
      this.emit('MessageRead', readerId, readerType, readTimestamp);
    });

    // User activity
    this.connection.on('UserActive', (userId, active) => {
      console.log(`User ${userId} active status: ${active}`);
      this.emit('UserActive', userId, active);
  });
  }

  async stopConnection() {
    if (this.connection && this.isConnected) {
      await this.connection.stop();
      this.isConnected = false;
    }
  }

  // Publish/Subscribe pattern for custom events
  subscribe(eventName, callback) {
    if (!this.subscriptions[eventName]) {
      this.subscriptions[eventName] = [];
    }
    this.subscriptions[eventName].push(callback);
  }

  unsubscribe(eventName, callback) {
    if (!this.subscriptions[eventName]) return;
    this.subscriptions[eventName] = this.subscriptions[eventName].filter(cb => cb !== callback);
  }

  emit(eventName, ...args) {
    if (!this.subscriptions[eventName]) return;
    this.subscriptions[eventName].forEach(callback => callback(...args));
  }

  // Invoke hub methods
  async sendTyping(targetUserId, targetUserType) {
    if (this.connection && this.isConnected) {
      try {
        await this.connection.invoke('Typing', targetUserId, targetUserType);
      } catch (error) {
        console.error('Error sending typing notification:', error);
      }
    }
  }

  async sendMessageRead(targetUserId, targetUserType) {
    if (this.connection && this.isConnected) {
      try {
        await this.connection.invoke('MessageRead', targetUserId, targetUserType);
      } catch (error) {
        console.error('Error sending read receipt:', error);
      }
    }
  }

  async notifyUserSend(targetUserId, targetUserType) {

    if (this.connection && this.isConnected) {
      try {

        await this.connection.invoke('UserSend', targetUserId.toString(), targetUserType.toString());
      } catch (error) {
        console.error('Error notifying user send:', error);
      }
    }
  }

  async typing(targetUserId, targetUserType) {
    if (this.connection && this.isConnected) {
      try {
        await this.connection.invoke('Typing', targetUserId.toString(), targetUserType.toString());
      } catch (error) {
        console.error('Error sending typing notification:', error);
      }
    }
  }

  async isRecording(targetUserId, targetUserType, isRecording) {
    if (this.connection && this.isConnected) {
      try {
        await this.connection.invoke('IsRecording', targetUserId.toString(), targetUserType.toString(), isRecording);
      } catch (error) {
        console.error('Error sending recording status:', error);
      }
    }
  }

  async sendUserActive() {
    if (this.connection && this.isConnected) {
      try {
        await this.connection.invoke('UserActive');
      } catch (error) {
        console.error('Error sending user active status:', error);
      }
    }
  }
}

export default ChatService;
