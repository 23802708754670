import React, { useState } from 'react';
import { useTranslation } from 'react-i18next'; // Importing t from i18n
import LabelService from '../../../Services/labels.service';
import '../Chat.css';
import { toast } from 'react-toastify';

const CreateLabelModal = ({ visible, onClose, onLabelCreated, setLoading, loading }) => {
    const [labelName, setLabelName] = useState('');
    const { t } = useTranslation(); // Using t for translations

    const handleCreate = async () => {
        try {
            setLoading(true)
            // Replace this with your label creation API call
            const result = await LabelService.createLabel(labelName);
            onLabelCreated();
            setLabelName('');
            toast.success(t('NewListCreatedSuccessfully'));
        } catch (error) {
            toast.error(t('NewListCreatedFailed'));

            console.error('Error creating label:', error);
        }
        onClose();

        setLoading(false)

    };

    if (!visible) return null;

    return (
        <div className="create-label-modal-overlay">
            <div className="create-label-modal-container">
                <h2>{t('CreateNewList')}</h2>
                <input
                    type="text"
                    className="create-label-modal-input"
                    placeholder={t('ListName')}
                    value={labelName}
                    onChange={(e) => setLabelName(e.target.value)}
                />
                <div className="create-label-modal-buttons">
                    <button
                        onClick={handleCreate}
                        className="create-label-modal-create-button"
                    >
                        {t('create')}
                    </button>
                    <button
                        onClick={onClose}
                        className="create-label-modal-cancel-button"
                    >
                        {t('Cancel')}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default CreateLabelModal;
