// JournalEntryDraggableDialog.jsx
import React, { useState, useEffect, useRef } from 'react';
import {
  Button,
  TextField,
  Autocomplete,
  Portal,
  Popper,
  FormControlLabel,
  Checkbox,
  Typography,
} from '@mui/material';
import Draggable from 'react-draggable';
import { useTranslation } from 'react-i18next';
import CustomDatePicker from '../../CustomDatePicker/CustomDatePicker';
import { getVatByCaseType } from '../../../Utils/GetVatValue';
import { ToastContainer, toast } from 'react-toastify';
import AddAccountCardModal from '../../Widgets/AddAccountCardModal/AddAccountCardModal';
import Constants from '../../../assests/Constants/constants';

function JournalEntryDraggableDialog(props) {
  const { t, i18n } = useTranslation();

  // ----------------------
  // Destructure all props from parent
  // ----------------------
  const {
    open,
    onClose,
    data,
    onSave,
    // Arrays with default to empty to avoid "undefined" issues:
    accountCardOptions = [],
    creditAccountOptions = [],
    debitAccountOptions = [],
    sortCodeIdOptions = [],
    // Additional props
    direction = 'rtl',
    isAddMode = false,
    isClosedReportingMonth = false,
    client = {},
    token = '',
    setLoading = () => {},
    // The ones you specifically requested:
    isRange = false,
    palCodes = [],
    palCode = '',
    setPalCode = () => {},
    showAccountCardInputsEdit = false,
    fetchEnums = () => {},
  } = props;

  const [formData, setFormData] = useState(data || {});

  // We create a container for the Portal (for Draggable usage)
  const [container] = useState(() => document.createElement('div'));
  const nodeRef = useRef(null);

  // For VAT
  const [selectedVatValue, setSelectedVatValue] = useState(formData?.vat || '');

  // For controlling AddAccountCard logic
  const [searchTermCredit, setSearchTermCredit] = useState('');
  const [searchTermDebit, setSearchTermDebit] = useState('');
  const [editedAddAccountCard, setEditedAddAccountCard] = useState(false);
  const [isEditAccountCard, setIsEditAccountCard] = useState(false);

  // The new account card data that will be passed to AddAccountCardModal
  const [newAccountCard, setNewAccountCard] = useState({
    accountCodeName: '',
    accountCodeId: '',
    accountCaseId: '',
  });
  // Whether we’re adding a 'creditAccount' or 'debitAccount'
  const [addAccountCardType, setAddAccountCardType] = useState('creditAccount');

  // If you need an auto-increment cardId
  const [cardIdOption, setCardIdOption] = useState(0);

  // If parent also wants to handle close differently, define a local helper
  const handleCloseItem = () => {
    setEditedAddAccountCard(false);
  };

  // On mount/prop changes, set up default formData
  useEffect(() => {
    setFormData(data || {});
    setSelectedVatValue(data?.vat || '');
  }, [data]);

  // Set up the Draggable center logic
  useEffect(() => {
    if (open) {
      document.body.appendChild(container);
      const updateModalPosition = () => {
        const modalWidth = 700;
        const modalHeight = nodeRef.current ? nodeRef.current.offsetHeight : 0;
        const screenWidth = window.innerWidth;
        const screenHeight = window.innerHeight;
        const x = (screenWidth - modalWidth) / 2;
        const y = (screenHeight - modalHeight) / 2;
        if (nodeRef.current) {
          nodeRef.current.style.left = `${x}px`;
          nodeRef.current.style.top = `${y}px`;
        }
      };
      setTimeout(updateModalPosition, 0);
      window.addEventListener('resize', updateModalPosition);
      return () => {
        try {
          document.body.removeChild(container);
        } catch (e) {
          // Ignore
        }
        window.removeEventListener('resize', updateModalPosition);
      };
    } else {
      try {
        document.body.removeChild(container);
      } catch (e) {
        // Ignore
      }
    }
  }, [open, container]);

  // Keep selectedVatValue in sync
  useEffect(() => {
    setSelectedVatValue(formData.vat);
  }, [formData.vat]);

  // If accountCardOptions changes, recalc max cardId
  useEffect(() => {
    if (accountCardOptions.length > 0) {
      const maxCardId = Math.max(
        ...accountCardOptions.map((opt) => parseInt(opt.cardId, 10) || 0)
      );
      setCardIdOption(maxCardId + 1);
    } else {
      setCardIdOption(1);
    }
  }, [accountCardOptions]);

  // Called when user types in an unknown account & clicks "AddAccountCard"
  const handleNoOptionCreditDebitClick = (searchTerm, fieldName) => {
    setEditedAddAccountCard(true);
    setIsEditAccountCard(false);
    setAddAccountCardType(fieldName);
    setNewAccountCard({
      accountCodeName: searchTerm || '',
      accountCodeId: cardIdOption.toString(),
      accountCaseId: '',
    });
  };

  // When the user **successfully adds** a new card in the modal
  // we set that new card ID to the correct field (credit/debit) in our form
  const handleChangeAfterAdd = (newlyAddedCardId, fieldName) => {
    setFormData((prev) => ({
      ...prev,
      [fieldName]: newlyAddedCardId,
    }));
  };

  // Options for VAT checkboxes
  const vatOptions = [
    { value: 100, label: '100%' },
    { value: 66, label: '66%' },
    { value: 0, label: '0%' },
    { value: 16, label: t('AccountingTax') },
  ];

  // Called whenever user types in a field
  const handleChange = (field, value) => {
    const updatedData = { ...formData, [field]: value };

    // If they change the sortCodeId, apply logic for default vat, credit, or debit
    if (field === 'sortCodeId') {
      const selectedOption = sortCodeIdOptions.find(
        (option) => option.sortCodeId === value
      );
      if (selectedOption) {
        const nameWithoutParentheses = selectedOption.sortCodeName
          .replace(/\s*\(.*?\)\s*/, '')
          .trim();
        const match = selectedOption.sortCodeName.match(/\((\d+)%?/);
        const customVat = match ? parseInt(match[1], 10) : null;
        const sortCode = selectedOption;

        // Match your constants logic
        if (Constants.SortCodesWith66Vat.includes(nameWithoutParentheses)) {
          updatedData.vat = 66;
        } else if (
          Constants.SortCodesWithZeroVat.includes(nameWithoutParentheses) ||
          sortCode.sortCodeVatType === 0
        ) {
          updatedData.vat = 0;
        } else if (Constants.SortCodesWith16Vat.includes(nameWithoutParentheses)) {
          updatedData.vat = 16;
        } else if (customVat !== null) {
          updatedData.vat = customVat === 66 ? 66 : customVat;
        } else {
          updatedData.vat = 100;
        }
        // Also set default credit/debit if provided
        updatedData.creditAccount = sortCode.creditAccount
          ? sortCode.creditAccount
          : updatedData.creditAccount;
        updatedData.debitAccount = sortCode.debitAccount
          ? sortCode.debitAccount
          : updatedData.debitAccount;
      }
    }
    setFormData(updatedData);
  };

  // Called when user changes the VAT checkbox group
  const handleVatChange = (value) => {
    setSelectedVatValue(value);
    handleChange('vat', value);
  };

  // Called when user hits Save
  const handleSave = () => {
    onSave(formData);
    onClose();
  };

  // MUI Input styling
  const inputPropsStyle = {
    disableUnderline: true,
    className: `custom-input-box-sizing ${
      direction === 'ltr' ? 'ltr-input' : 'rtl-input'
    }`,
    sx: {
      height: '48px',
      padding: '0 10px',
      borderRadius: '8px',
      background: '#F5F5F6',
      border: '1px solid transparent',
      '&:focus-within': {
        border: '2px solid var(--Foundation-Blue-Normal, #304FFF)',
      },
    },
  };

  // For text alignment inside TextFields
  const inputStyle = {
    style: {
      textAlign: direction === 'ltr' ? 'left' : 'right',
    },
  };

  if (!open) return null;

  return (
    <Portal container={container}>
      <Draggable handle=".handle" nodeRef={nodeRef}>
        <div
          ref={nodeRef}
          style={{
            position: 'absolute',
            top: '20%',
            left: '30%',
            transform: 'translate(-50%, -50%)',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: 'white',
            borderRadius: '8px',
            padding: '16px',
            maxHeight: '90vh',
            overflowY: 'auto',
            minWidth: '700px',
            zIndex: 1300,
          }}
        >
          {/* Draggable Title */}
          <div
            className="handle"
            style={{
              cursor: 'move',
              fontSize: '20px',
              fontWeight: 'bold',
              marginBottom: '16px',
              width: '100%',
            }}
          >
            <div className="edit-title">
              {isAddMode ? t('AddJournalEntry') : t('EditJournalEntry')}
            </div>
          </div>

          {/* Main Content */}
          <div className={`form-container ${direction}`}>
            {/* Row 1 */}
            <div className="form-row">
              {/* ConfirmationNumber */}
              <div className="edit-form-row-item">
                <div className="edit-form-row-item-title">
                  {t('ConfirmationNumber')}
                  <span className="required-asterisk"></span>
                </div>
                <TextField
                  fullWidth
                  placeholder={t('ConfirmationNumber')}
                  value={formData.confirmationNumber || ''}
                  onChange={(e) => handleChange('confirmationNumber', e.target.value)}
                  variant="standard"
                  InputProps={inputPropsStyle}
                  inputProps={inputStyle}
                  required
                  disabled={isClosedReportingMonth}
                />
              </div>

              {/* CompanyId */}
              <div className="edit-form-row-item">
                <div className="edit-form-row-item-title">
                  {t('CompanyId')}
                  {isAddMode && <span className="required-asterisk"></span>}
                </div>
                <TextField
                  fullWidth
                  placeholder={t('CompanyId')}
                  value={formData.accountCaseId || ''}
                  onChange={(e) => handleChange('accountCaseId', e.target.value)}
                  variant="standard"
                  InputProps={inputPropsStyle}
                  inputProps={inputStyle}
                  disabled={isClosedReportingMonth}
                />
              </div>
            </div>

            {/* Row 2 */}
            <div className="form-row">
              {/* Reference */}
              <div className="edit-form-row-item">
                <div className="edit-form-row-item-title">
                  {t('Reference')}
                  <span className="required-asterisk">*</span>
                </div>
                <TextField
                  fullWidth
                  placeholder={t('Reference')}
                  value={formData.reference || ''}
                  onChange={(e) => handleChange('reference', e.target.value)}
                  variant="standard"
                  InputProps={inputPropsStyle}
                  inputProps={inputStyle}
                  required
                  disabled={isClosedReportingMonth}
                />
              </div>
              {/* Description */}
              <div className="edit-form-row-item">
                <div className="edit-form-row-item-title">
                  {t('Description')}
                  <span className="required-asterisk">*</span>
                </div>
                <TextField
                  fullWidth
                  placeholder={t('Description')}
                  value={formData.description || ''}
                  onChange={(e) => handleChange('description', e.target.value)}
                  variant="standard"
                  InputProps={inputPropsStyle}
                  inputProps={inputStyle}
                  required
                  disabled={isClosedReportingMonth}
                />
              </div>
            </div>

            {/* Row 3 */}
            <div className="form-row">
              {/* SortCodeId */}
              <div className="edit-form-row-item">
                <div className="edit-form-row-item-title">
                  {t('Code ID')}
                  <span className="required-asterisk">*</span>
                </div>
                <Autocomplete
                  options={sortCodeIdOptions}
                  getOptionLabel={(option) =>
                    `${option.sortCodeId} - ${option.sortCodeName}`
                  }
                  value={
                    sortCodeIdOptions.find(
                      (option) => option.sortCodeId === formData.sortCodeId
                    ) || null
                  }
                  onChange={(event, newValue) => {
                    const newSortCodeId = newValue ? newValue.sortCodeId : '';
                    handleChange('sortCodeId', newSortCodeId);
                  }}
                  disabled={isClosedReportingMonth}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder={t('Code ID')}
                      variant="standard"
                      InputProps={{
                        ...params.InputProps,
                        ...inputPropsStyle,
                      }}
                      inputProps={{
                        ...params.inputProps,
                        ...inputStyle,
                      }}
                      required
                    />
                  )}
                  PopperComponent={(props) => (
                    <Popper {...props} style={{ ...props.style, direction }} />
                  )}
                />
              </div>
              {/* Amount */}
              <div className="edit-form-row-item">
                <div className="edit-form-row-item-title">
                  {t('Amount')}
                  <span className="required-asterisk">*</span>
                </div>
                <TextField
                  fullWidth
                  placeholder={t('Amount')}
                  value={formData.amount || ''}
                  onChange={(e) => handleChange('amount', e.target.value)}
                  variant="standard"
                  type="number"
                  InputProps={inputPropsStyle}
                  inputProps={inputStyle}
                  required
                  disabled={isClosedReportingMonth}
                />
              </div>
            </div>

            {/* Row 4 */}
            <div className="form-row">
              {/* Credit Account */}
              <div className="edit-form-row-item">
                <div className="edit-form-row-item-title">
                  {t('Credit Account')}
                  <span className="required-asterisk">*</span>
                </div>
                <Autocomplete
                  options={creditAccountOptions}
                  getOptionLabel={(option) => `${option.cardId} - ${option.name}`}
                  value={
                    creditAccountOptions.find(
                      (option) => option.cardId === formData.creditAccount
                    ) || null
                  }
                  onChange={(event, newValue) => {
                    const newCreditAccount = newValue ? newValue.cardId : null;
                    handleChange('creditAccount', newCreditAccount);
                  }}
                  onInputChange={(event, inputValue) => setSearchTermCredit(inputValue)}
                  noOptionsText={
                    <Button
                      onClick={() =>
                        handleNoOptionCreditDebitClick(searchTermCredit, 'creditAccount')
                      }
                      variant="contained"
                      color="primary"
                    >
                      {t('AddAccountCard')} "{searchTermCredit}"
                    </Button>
                  }
                  disabled={isClosedReportingMonth}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder={t('Credit Account')}
                      variant="standard"
                      InputProps={{
                        ...params.InputProps,
                        ...inputPropsStyle,
                      }}
                      inputProps={{
                        ...params.inputProps,
                        ...inputStyle,
                      }}
                      required
                    />
                  )}
                  PopperComponent={(props) => (
                    <Popper {...props} style={{ ...props.style, direction }} />
                  )}
                />
              </div>

              {/* Document Date */}
              <div className="edit-form-row-item">
                <div className="edit-form-row-item-title">
                  {t('Document Date')}
                  <span className="required-asterisk">*</span>
                </div>
                <CustomDatePicker
                  date={formData.documentDate ? new Date(formData.documentDate) : null}
                  onDateChange={(date) => {
                    if (date) {
                      handleChange('documentDate', date.toISOString());
                    }
                  }}
                  height="48px"
                />
              </div>
            </div>

            {/* Row 5 */}
            <div className="form-row">
              {/* Debit Account */}
              <div className="edit-form-row-item">
                <div className="edit-form-row-item-title">
                  {t('Debit Account')}
                  <span className="required-asterisk">*</span>
                </div>
                <Autocomplete
                  options={debitAccountOptions}
                  getOptionLabel={(option) => `${option.cardId} - ${option.name}`}
                  value={
                    debitAccountOptions.find(
                      (option) => option.cardId === formData.debitAccount
                    ) || null
                  }
                  onChange={(event, newValue) => {
                    const newDebitAccount = newValue ? newValue.cardId : null;
                    handleChange('debitAccount', newDebitAccount);
                  }}
                  onInputChange={(event, inputValue) => setSearchTermDebit(inputValue)}
                  noOptionsText={
                    <Button
                      onClick={() =>
                        handleNoOptionCreditDebitClick(searchTermDebit, 'debitAccount')
                      }
                      variant="contained"
                      color="primary"
                    >
                      {t('AddAccountCard')} "{searchTermDebit}"
                    </Button>
                  }
                  disabled={isClosedReportingMonth}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder={t('Debit Account')}
                      variant="standard"
                      InputProps={{
                        ...params.InputProps,
                        ...inputPropsStyle,
                      }}
                      inputProps={{
                        ...params.inputProps,
                        ...inputStyle,
                      }}
                      required
                    />
                  )}
                  PopperComponent={(props) => (
                    <Popper {...props} style={{ ...props.style, direction }} />
                  )}
                />
              </div>

              {/* VAT */}
              <div className="edit-form-row-item">
                <div className="edit-form-row-item-title">
                  {t('VATPercentage')}
                  <span className="required-asterisk">*</span>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  {vatOptions.map((option) => (
                    <FormControlLabel
                      key={option.value}
                      control={
                        <Checkbox
                          checked={selectedVatValue == option.value}
                          onChange={() => {
                            if (
                              client?.caseType &&
                              getVatByCaseType(client.caseType) !== undefined
                            ) {
                              toast.error(`${t('VAT')} ${client.caseType}`, {
                                autoClose: 3000,
                              });
                            } else {
                              handleVatChange(option.value);
                            }
                          }}
                          name={`vat-${option.value}`}
                        />
                      }
                      label={
                        <Typography sx={{ fontSize: 14 }}>{option.label}</Typography>
                      }
                      disabled={isClosedReportingMonth}
                    />
                  ))}
                </div>
              </div>
            </div>

            {/* Buttons: Cancel / Save */}
            <div className="button-container">
              <div onClick={onClose} className="edit-cancel-button">
                {t('Cancel')}
              </div>
              <div
                onClick={isClosedReportingMonth ? null : handleSave}
                className="edit-save-button"
                style={{ opacity: isClosedReportingMonth ? 0.5 : 1 }}
              >
                {t('Save')}
              </div>
            </div>
          </div>
        </div>
      </Draggable>

      {/* AddAccountCardModal with all the extra props */}
      <AddAccountCardModal
        accountCardOptions={accountCardOptions}
        open={editedAddAccountCard}
        onClose={handleCloseItem}
        editedItem={newAccountCard}
        isEdit={isEditAccountCard}
        isRange={isRange}
        isClosedReportingMonth={isClosedReportingMonth}
        palCodes={palCodes}
        propPalCode={palCode}
        setPalCode={setPalCode}
        direction={direction}
        isAdd={!showAccountCardInputsEdit}
        client={client}
        token={token}
        setLoading={setLoading}
        handleChangeAfterAdd={(newlyAddedCardId) =>
          handleChangeAfterAdd(newlyAddedCardId, addAccountCardType)
        }
        fetchEnums={fetchEnums}
        type={addAccountCardType}
      />
    </Portal>
  );
}

export default JournalEntryDraggableDialog;
