import Constants from "../assests/Constants/constants";

/**
 * Returns the VAT value as a float based on the given rules.
 *
 * @param {Date|string} date - A Date object or date string to compare with 01/01/2025
 * @param {boolean} isOneVat - If true, add 1 to the VAT rate (e.g., base 0.17 => 1.17)
 * @param {boolean} isPal - If true, use the Palestinian VAT rate
 * @returns {number} - The VAT value as a float (e.g. 0.17, 1.17, 0.18, 1.18, etc.)
 */
export function getVatValue(date, isOneVat = false, isPal = false) {
  if (!date) {
    return 0;
  }
  // Ensure we have a Date object
  const inputDate = date instanceof Date ? date : new Date(date);

  // Reference date: January 1st, 2025
  const pivotDate = new Date('2025-01-01');

  let baseVat;

  if (inputDate >= pivotDate) {
    // Date is on or after 01/01/2025
    baseVat = isPal ? 0.17 : 0.18;
  } else {
    // Date is before 01/01/2025
    baseVat = isPal ? 0.16 : 0.17;
  }

  // If isOneVat is true, we add 1 (e.g. 0.17 becomes 1.17)
  const result = isOneVat
    ? 1 + baseVat
    : baseVat;

  // Return as a float with two decimals
  return parseFloat(result.toFixed(2));
}


/**
* Calculates the VAT amount for a given total amount, VAT rate, and date.
*
* @param {number} amount - The total amount.
* @param {number|null} vatRate - The VAT rate (e.g., 16, 17, etc.).
* @param {Date|string|null} date - A Date object or date string to compare with 01/01/2025.
* @returns {number} - The calculated VAT amount or 0 if inputs are invalid.
*/
export function calculateVatAmount(amount, vatRate, date) {
  if (!date || vatRate == null) {
    return 0;
  }

  // Ensure we have a Date object
  const inputDate = date instanceof Date ? date : new Date(date);

  // Use getVatValue to calculate the base VAT rate
  if (vatRate === 16 || vatRate === 17) {
    return (amount / getVatValue(inputDate, true, true)) * getVatValue(inputDate, false, true);
  } else {
    return (amount / getVatValue(inputDate, true, false)) * getVatValue(inputDate, false, false) * (vatRate / 100);
  }
}

export const getVatByCaseType = (caseType) => {

  if (Constants.TaxExemptTypes.includes(caseType)) {
    return 0; // VAT is 0 for exempt types
  }
  return undefined; // Return undefined if not in exempt types
};
