// WithholdingTaxModal.jsx
import React, { useState, useEffect, useRef } from 'react';
import { Modal, Box, RadioGroup, FormControlLabel, Radio } from '@mui/material';
import CustomDeleteConfirmationModal from '../../CustomDeleteConfirmationModal/CustomDeleteConfirmationModal';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import TaxAuthorityService from '../../../Services/taxAuthority.service';
import ClientService from '../../../Services/client.service';
import ReportsService from '../../../Services/reports.service';
import Constants from '../../../assests/Constants/constants';
import TaxAdvancesReportImage from '../../../assests/images/TaxAdvancesReport.jpg';

const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    height: '100%',
    borderRadius: '16px',
    background: '#FFF',
    boxShadow: 24,
    maxHeight: '90%',
    overflowY: 'auto',
};

export default function WithholdingTaxModal({
    open,
    onClose,
    item,
    user,
    token,
    onDataUpdate = () => { },
}) {
    const { t, i18n } = useTranslation();
    const direction = i18n.language === 'he' || i18n.language === 'ar' ? 'rtl' : 'ltr';

    // Main local state for WithholdingTax
    const [withholdingTax, setWithholdingTax] = useState({});

    // ReportingWay => "automatic", "manual", or "unreported"
    const [reportingWay, setReportingWay] = useState('unreported');

    // Confirmation modal logic
    const [actionType, setActionType] = useState('');
    const [isConfirmationVisible, setIsConfirmationVisible] = useState(false);

    // Loading state for async operations
    const [loading, setLoading] = useState(false);

    // Data we might pass to the Tax Authority flow
    const [taxAuthorityData, setTaxAuthorityData] = useState({
        authUrl: '',
        resumeTaxAuthorityAdvancesOperation: false,
        UpdatedAdd: {},
    });

    // For rendering HTML that becomes PDF
    const withholdingContentRef = useRef(null);
    const [withholdingHtmlContent, setWithholdingHtmlContent] = useState('');

    // ----------------------------------
    // 1) Build initial data from `item`
    // ----------------------------------
    useEffect(() => {
        if (!item) return;

        /* Example item structure you might receive:
           {
             id: '00000000-0000-0000-0000-000000000000',
             clientName: 'payment test ',
             clientLocation: '',
             clientCaseId: '456456412',
             AcceptedWithholdingTax: 0,
             AcceptedWithholdingTaxMaximum: 0,
             incomeTaxAdvancePercentage: 1,
             IncomeTaxAdvances: 827.5593200000001,
             total: 827.5593200000001,
             witholdingIncomesNoVat: 82755.932,
             StartDate: new Date('2025-01-09T22:00:00.000Z'),
             EndDate: new Date('2025-01-09T22:00:00.000Z'),
             useIncomeTaxAdvancePercentage: true,
             incomeTaxAdvanceAmount: 827.55927
           }
        */

        const {
            id,
            clientName,
            clientLocation,
            clientCaseId,
            AcceptedWithholdingTax,
            AcceptedWithholdingTaxMaximum,
            incomeTaxAdvancePercentage,
            IncomeTaxAdvances,
            total,
            witholdingIncomesNoVat,
            StartDate,
            EndDate,
            startDate, // Some APIs might use lowercased
            endDate,   // Some APIs might use lowercased
            useIncomeTaxAdvancePercentage,
            incomeTaxAdvanceAmount,
        } = item;

        // Use either the capitalized or lowercased Start/End date, whichever is valid
        const start = StartDate || startDate || new Date();
        const end = EndDate || endDate || new Date();

        // Build the local "withholdingTax" object
        const builtTax = {
            id: id || null,
            clientName: clientName || '',
            clientLocation: clientLocation || '',
            clientCaseId: clientCaseId || '',
            AcceptedWithholdingTax: parseInt(AcceptedWithholdingTax) ?? 0,
            AcceptedWithholdingTaxMaximum: parseInt(AcceptedWithholdingTaxMaximum) ?? 0, // might recalc anyway
            incomeTaxAdvancePercentage: parseInt(incomeTaxAdvancePercentage) ?? 0,
            IncomeTaxAdvances: parseInt(IncomeTaxAdvances) ?? 0, // Payment
            total: parseInt(total) ?? 0,
            witholdingIncomesNoVat: parseInt(witholdingIncomesNoVat) ?? 0,
            StartDate: start,
            EndDate: end,
            useIncomeTaxAdvancePercentage: !!useIncomeTaxAdvancePercentage,
            incomeTaxAdvanceAmount: parseInt(incomeTaxAdvanceAmount) ?? 0,
        };

        // Initialize state
        setWithholdingTax(builtTax);
    }, [item]);

    // ----------------------------------------
    // 2) Listen to custom events from the IFRAME
    //    (withholdingTaxUpdate)
    // ----------------------------------------
    useEffect(() => {
        const handleWithholdingTaxMessage = (event) => {
            try {
                const message = JSON.parse(event.detail);
                if (message.source === 'withholdingTaxReport') {
                    switch (message.eventName) {
                        case 'updateAcceptedWithholdingTax':
                            updateAcceptedWithholdingTax(message.value);
                            break;
                        case 'updateIncomesNoVats':
                            updateIncomesNoVats(message.value);
                            break;
                        case 'updateIncomeTaxAdvancePercentage':
                            updateIncomeTaxAdvancePercentage(message.value);
                            break;
                        case 'updateIncomeTaxAdvanceAmount':
                            updateIncomeTaxAdvanceAmount(message.value);
                            break;
                        default:
                            break;
                    }
                }
            } catch (err) {
                console.error('Failed to parse withholdingTaxUpdate event:', event.detail);
            }
        };

        window.addEventListener('withholdingTaxUpdate', handleWithholdingTaxMessage);
        return () => {
            window.removeEventListener('withholdingTaxUpdate', handleWithholdingTaxMessage);
        };
    }, []);

    // ----------------------------------------------------
    // 3) Helper update functions, replicating the logic:
    //    total = (withholdingTaxAdvance % or fixed),
    //    PaymentWithholdingTax = total - AcceptedWithholdingTax,
    //    AcceptedWithholdingTax <= total,
    //    PaymentWithholdingTax >= 0
    // ----------------------------------------------------
    // Example function that merges partial updates & forces integer math
    const recalcAll = (partial) => {
        return (prev) => {
            // Merge old state + partial updates
            const merged = { ...prev, ...partial };

            // Convert to integers just to be sure
            let witholdingIncomesNoVat = parseInt(merged.witholdingIncomesNoVat) || 0;
            let percentage = parseInt(merged.incomeTaxAdvancePercentage) || 0;
            let fixedAmount = parseInt(merged.incomeTaxAdvanceAmount) || 0;
            let accepted = parseInt(merged.AcceptedWithholdingTax) || 0;

            // Compute total
            let newTotal = 0;
            if (merged.useIncomeTaxAdvancePercentage) {
                // (percentage / 100) might cause decimals,
                // so let's do integer math by rounding or flooring:
                newTotal = Math.floor((witholdingIncomesNoVat * percentage) / 100);
            } else {
                newTotal = fixedAmount;
            }

            // Ensure accepted <= total
            if (accepted > newTotal) {
                accepted = newTotal;
            }

            // Payment = total - accepted
            let payment = newTotal - accepted;
            if (payment < 0) {
                payment = 0;
            }

            return {
                ...merged,
                // Overwrite with integer versions
                total: parseInt(newTotal),
                witholdingIncomesNoVat,
                incomeTaxAdvancePercentage: percentage,
                incomeTaxAdvanceAmount: fixedAmount,
                AcceptedWithholdingTax: accepted,
                AcceptedWithholdingTaxMaximum: accepted,
                IncomeTaxAdvances: payment,
            };
        };
    };


    const updateAcceptedWithholdingTax = (val) => {
        const intVal = parseInt(val) || 0;
        setWithholdingTax(recalcAll({ AcceptedWithholdingTax: intVal }));
    };

    const updateIncomesNoVats = (val) => {
        const intVal = parseInt(val) || 0;
        setWithholdingTax(recalcAll({ witholdingIncomesNoVat: intVal }));
    };

    const updateIncomeTaxAdvancePercentage = (val) => {
        setWithholdingTax(
            recalcAll({ incomeTaxAdvancePercentage: parseFloat(val) || 0, useIncomeTaxAdvancePercentage: true }),
        );
    };

    const updateIncomeTaxAdvanceAmount = (val) => {
        setWithholdingTax(
            recalcAll({ incomeTaxAdvanceAmount: parseFloat(val) || 0, useIncomeTaxAdvancePercentage: false }),
        );
    };

    // ----------------------------------
    // 4) Generate HTML for the PDF
    // ----------------------------------
    const isEmptyObject = (obj) => {
        return Object.keys(obj).length === 0 && obj.constructor === Object;
    };

    const generateWithholdingTaxHtml = () => {
        if (isEmptyObject(withholdingTax)) {
            return '';
        }
        const israelTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const formattedDate = new Date().toLocaleString('he-IL', {
            timeZone: israelTimeZone,
            dateStyle: 'short',
            timeStyle: 'medium',
        });

        const startDateFormatted = new Date(withholdingTax.StartDate).toLocaleString('he-IL', {
            month: 'long',
        });
        const endDateFormatted = new Date(withholdingTax.EndDate).toLocaleString('he-IL', {
            month: 'long',
        });
        const endDate = new Date(withholdingTax.EndDate);

        // The snippet below is the HTML (including the embedded input fields)
        // that is displayed in an iframe-like structure
        return `
      <!DOCTYPE html>
      <html lang='en'>
      <head>
          <meta charset='UTF-8' />
          <meta http-equiv='X-UA-Compatible' content='IE=edge' />
          <meta name='viewport' content='width=device-width, initial-scale=1.0' />
          <style>
              a, button, input, select, h1, h2, h3, h4, h5, * {
                  box-sizing: border-box;
                  margin: 0;
                  padding: 0;
                  border: none;
                  text-decoration: none;
                  background: none;
                  -webkit-font-smoothing: antialiased;
              }
              menu, ol, ul {
                  list-style-type: none;
                  margin: 0;
                  padding: 0;
              }
              .body {
                  width: 100%;
                  display: flex;
                  justify-content: center;
                  align-items: center;
              }
              .container {
                  display: flex;
                  flex-direction: column;
                  align-items: center;
              }
              .header-WithholdingTax {
                  width: 100%;
                  text-align: center;
                  padding: 10px 0;
                  background-color: #f3f3f3;
              }
              .header_container {
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  margin-bottom: 20px;
              }
              .date, .title, .placeholder {
                  flex: 1;
                  text-align: center;
              }
              .title {
                  font-size: 26px;
                  font-weight: bold;
              }
              .vat_percentage, .date {
                  font-size: 22px;
                  padding: 5px 0;
                  color: black;
                  font-weight: bold;
              }
              .frame-3 {
                  box-sizing: border-box;
                  position: relative;
                  background: white;
                  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
              }
              .tax-advances-report-1-fotor-20240716175158-1 {
                  left: 0px;
                  top: 0px;
                  object-fit: cover;
                  width: 100%;
              }
              .client-name {
                  color: #000;
                  text-align: right;
                  font-family: 'Inter-Regular', sans-serif;
                  font-size: 22px;
                  font-weight: 400;
                  position: absolute;
                  left: 583px;
                  top: 78px;
                  width: 449px;
                  height: 34px;
              }
              .incomeswithoutvat {
                  color: #000;
                  text-align: right;
                  font-family: 'Inter-Regular', sans-serif;
                  font-size: 22px;
                  font-weight: 400;
                  position: absolute;
                  left: 382px;
                  top: 295px;
                  width: 354px;
                  height: 34px;
              }
              .income-tax-advances {
                  color: #000;
                  text-align: right;
                  font-family: 'Inter-Regular', sans-serif;
                  font-size: 22px;
                  font-weight: 400;
                  position: absolute;
                  left: 382px;
                  top: 390px;
                  width: 354px;
                  height: 34px;
              }
              .total {
                  color: #000;
                  text-align: right;
                  font-family: 'Inter-Regular', sans-serif;
                  font-size: 22px;
                  font-weight: 400;
                  position: absolute;
                  left: 912px;
                  top: 295px;
                  width: 324px;
                  height: 34px;
              }
              .percent {
                  color: #000;
                  text-align: right;
                  font-family: 'Inter-Regular', sans-serif;
                  font-size: 18px;
                  font-weight: bold;
                  position: absolute;
                  left: 771px;
                  top: 305px;
                  width: 66px;
                  height: 24px;
              }
              .income-tax-advances2 {
                  color: #000;
                  text-align: right;
                  font-family: 'Inter-Regular', sans-serif;
                  font-size: 22px;
                  font-weight: 400;
                  position: absolute;
                  left: 912px;
                  top: 390px;
                  width: 324px;
                  height: 34px;
              }
              .total-income-tax-advances {
                  color: #000;
                  text-align: right;
                  font-family: 'Inter-Regular', sans-serif;
                  font-size: 22px;
                  font-weight: 400;
                  position: absolute;
                  left: 826px;
                  top: 535px;
                  width: 404px;
                  height: 34px;
              }
              .client-case {
                  color: #000;
                  text-align: right;
                  font-family: 'Inter-Regular', sans-serif;
                  font-size: 22px;
                  font-weight: 400;
                  position: absolute;
                  left: 85px;
                  top: 205px;
                  width: 204px;
                  height: 34px;
              }
              .year {
                  color: #000;
                  text-align: right;
                  font-family: 'Inter-Regular', sans-serif;
                  font-size: 22px;
                  font-weight: 400;
                  position: absolute;
                  left: 482px;
                  top: 142px;
                  width: 97px;
                  height: 34px;
              }
              .client-location {
                  color: #000;
                  text-align: right;
                  font-family: 'Inter-Regular', sans-serif;
                  font-size: 22px;
                  font-weight: 400;
                  position: absolute;
                  left: 12px;
                  top: 78px;
                  width: 522px;
                  height: 34px;
              }
          </style>
          <title>Document</title>
      </head>
      <body>
          <div class='container'>
              <div class='header-WithholdingTax'>
                  <div class='header_container'>
                      <div class='title'>${withholdingTax.clientLocation}</div>
                      <div class='date'>עוסק מורשה: ${withholdingTax.clientCaseId}</div>
                      <div class='date'>${withholdingTax.clientName}</div>
                  </div>
                  <div class='header_container'>
                      <div class='date'>${formattedDate}</div>
                      <div class='title'>דוח מקדמות ע״פ מחזור</div>
                      <div class='placeholder'></div>
                  </div>
                  <div class='vat_percentage'>
                      ${withholdingTax.incomeTaxAdvancePercentage}% = אחוז מקדמות מהמחזור
                  </div>
              </div>
              <div class='frame-3'>
                  <img class='tax-advances-report-1-fotor-20240716175158-1' src='${TaxAdvancesReportImage}' />
                  <div class='client-name'>${withholdingTax.clientName}</div>
                  <input
                      class='incomeswithoutvat input-field_Container_required-asterisk'
                      value="${withholdingTax.witholdingIncomesNoVat}"
                      onchange="window.parent.dispatchEvent(new CustomEvent('withholdingTaxUpdate', {
                        detail: JSON.stringify({
                          source: 'withholdingTaxReport',
                          eventName: 'updateIncomesNoVats',
                          value: this.value
                        })
                      }))"
                  />
                  <input
                      class='income-tax-advances input-field_Container_required-asterisk'
                      value="${withholdingTax.AcceptedWithholdingTax}"
                      onchange="window.parent.dispatchEvent(new CustomEvent('withholdingTaxUpdate', {
                        detail: JSON.stringify({
                          source: 'withholdingTaxReport',
                          eventName: 'updateAcceptedWithholdingTax',
                          value: this.value
                        })
                      }))"
                  />
                  ${withholdingTax.useIncomeTaxAdvancePercentage
                ? `
                        <input
                          class='percent input-field_Container_required-asterisk'
                          value="${withholdingTax.incomeTaxAdvancePercentage}"
                          onchange="window.parent.dispatchEvent(new CustomEvent('withholdingTaxUpdate', {
                            detail: JSON.stringify({
                              source: 'withholdingTaxReport',
                              eventName: 'updateIncomeTaxAdvancePercentage',
                              value: this.value
                            })
                          }))"
                        />
                      `
                : `<div class='percent'></div>`
            }
                  ${withholdingTax.useIncomeTaxAdvancePercentage
                ? `<div class='total'>${withholdingTax.total}</div>`
                : `
                        <input
                          class='total input-field_Container_required-asterisk'
                          value="${withholdingTax.incomeTaxAdvanceAmount}"
                          onchange="window.parent.dispatchEvent(new CustomEvent('withholdingTaxUpdate', {
                            detail: JSON.stringify({
                              source: 'withholdingTaxReport',
                              eventName: 'updateIncomeTaxAdvanceAmount',
                              value: this.value
                            })
                          }))"
                        />
                      `
            }
                  <div class='income-tax-advances2'>
                      ${withholdingTax.AcceptedWithholdingTaxMaximum}
                  </div>
                  <div class='total-income-tax-advances'>
                      ${withholdingTax.IncomeTaxAdvances}
                  </div>
                  <div class='client-case'>${withholdingTax.clientCaseId}</div>
                  <div class='year'>${endDate?.getFullYear()}</div>
                  <div class='client-location'>${withholdingTax.clientLocation}</div>
              </div>
          </div>
      </body>
      </html>
    `;
    };

    // Create or update the HTML content each time `withholdingTax` changes
    useEffect(() => {
        setWithholdingHtmlContent(generateWithholdingTaxHtml());
    }, [withholdingTax]);

    // ----------------------------------
    // 5) Download as PDF
    // ----------------------------------
    const handleDownloadPdf = (ref) => {
        if (!ref?.current) return;
        html2canvas(ref.current).then((canvas) => {
            const data = canvas.toDataURL('image/png');
            const doc = new jsPDF({ orientation: 'l', unit: 'px', format: 'a4' });
            const imgProps = doc.getImageProperties(data);
            const pdfWidth = doc.internal.pageSize.getWidth();
            const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
            doc.addImage(data, 'JPEG', 0, 0, pdfWidth, pdfHeight);
            doc.save('withholdingTaxReport.pdf');
        });
    };

    // ----------------------------------
    // 6) Save Manually => your logic
    // ----------------------------------
    const saveReportTaxManually = async (reportTax) => {
        try {
            // If user sets "unreported", we store e.g. 2; if not => 1
            const statusValue = reportingWay === 'unreported' ? 2 : 1;
            reportTax.WithholdingTaxStatus = statusValue;

            // PaymentWithholdingTax can be the same as IncomeTaxAdvances
            // from local state
            const paymentWithholdingTax = withholdingTax.IncomeTaxAdvances;

            const payload = {
                caseId: reportTax.caseId,
                AcceptedWithholdingTax: withholdingTax.AcceptedWithholdingTax,
                PaymentWithholdingTax: paymentWithholdingTax,
                IncomeTaxAdvancePercentage: withholdingTax.useIncomeTaxAdvancePercentage
                    ? withholdingTax.incomeTaxAdvancePercentage
                    : 0,
                IncomeTaxAdvanceAmount: !withholdingTax.useIncomeTaxAdvancePercentage
                    ? withholdingTax.incomeTaxAdvanceAmount
                    : 0,
                WitholdingIncomesNoVat: withholdingTax.witholdingIncomesNoVat,
                StartDate: withholdingTax.StartDate,
                EndDate: withholdingTax.EndDate,
                WithholdingTaxStatus: reportTax.WithholdingTaxStatus,
            };

            let response;
            // if (reportTax.id) {
            //     // Update existing
            //     response = await ReportsService.updateReportedAdvance(reportTax.id, payload, token);
            // } else {
            //     // Create new
            //     response = await ReportsService.createReportedAdvance(payload, token);
            // }
            response = await ReportsService.createReportedAdvance(payload, token);

            if (response && response.ok) {
                const responseData = await response.json();
                toast.success(t('ReportedTaxUpdatedSuccess'));
                onDataUpdate(responseData);
            } else {
                toast.error(t('ReportedTaxUpdateFailed'));
            }
        } catch (error) {
            console.error(t('Error saving reported tax:'), error);
            toast.error(t('ReportedTaxSaveFailed'));
        }
    };

    // ----------------------------------
    // 7) Automatic => flow to the Tax Authority
    // ----------------------------------
    const startAuthCompletionCheck = async (authWindow, scope) => {
        // Similar logic to VatModal
        let attempts = 0;
        const maxAttempts = 25;
        const checkAuthCompletion = async () => {
            try {
                const result = await TaxAuthorityService.checkAuthCompletionAccountant(
                    user.accountantId,
                    scope,
                    token,
                );
                if (result.completed) {
                    setTaxAuthorityData((prev) => ({
                        ...prev,
                        resumeTaxAuthorityAdvancesOperation: true,
                    }));
                    authWindow.close();
                } else {
                    attempts += 1;
                    if (attempts < maxAttempts) {
                        setTimeout(checkAuthCompletion, 3000);
                    } else {
                        setLoading(false);
                        console.error('Token retrieval failed after maximum attempts.');
                        toast.error('Token retrieval failed after maximum attempts.');
                        authWindow.close();
                    }
                }
            } catch (err) {
                console.error('Error checking auth completion:', err);
                toast.error(t('Error checking auth completion'));
                authWindow.close();
                setLoading(false);
            }
        };
        checkAuthCompletion();
    };

    useEffect(() => {
        if (taxAuthorityData.resumeTaxAuthorityAdvancesOperation) {
            TaxAuthoritySendAdvancesReport(taxAuthorityData.UpdatedAdd, token);
        }
    }, [taxAuthorityData.resumeTaxAuthorityAdvancesOperation]);

    const TaxAuthoritySendAdvancesReport = async (data, tokenParam) => {
        try {
            setLoading(true);
            // Build the DTO
            // {
            //     id: 'be380790-5a04-4252-8f24-e1148caaae6b',
            //     caseId: '456456412',
            //     startDate: new Date('2025-01-13T11:56:22.000Z'),
            //     endDate: new Date('2025-01-13T11:56:22.000Z'),
            //     acceptedWithholdingTax: 200,
            //     witholdingIncomesNoVat: 82755,
            //     status: 1,
            //     WithholdingTaxStatus: 1,
            //     incomeTaxAdvanceAmount: 827,
            //     incomeTaxAdvancePercentage: 1
            //   }
            const sendAdvancesReportingDto = {
                CaseId: data.caseId,
                AccountantId: user.accountantId,
                Period: data.endDate, // Set the date to the current date or as required
                Income: data.witholdingIncomesNoVat,
                TaxWithHolding: data.acceptedWithholdingTax,
                PaymentAmount: data.paymentAmount,
                IncomeTaxAdvancePercentage: data.incomeTaxAdvancePercentage,
                IncomeTaxAdvanceAmount: data.incomeTaxAdvanceAmount,
            };

            // Call the service
            const response = await ReportsService.sendAdvancesReport(sendAdvancesReportingDto, tokenParam);
            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(`Failed: ${errorData.message}`);
            }
            const responseData = await response.json();
            if (responseData.Code === 0) {
                throw new Error(`Error Sending ReportedTax for caseId ${data.caseId}`);
            }
            const formattedDate = new Date(sendAdvancesReportingDto.Period).toLocaleDateString('en-GB', {
                month: '2-digit',
                year: 'numeric'
            });
            toast.success(t('AdvancesReportSuccess', { caseId: data.caseId, date: formattedDate }));

            // After sending to Tax Authority, also save locally
            await saveReportTaxManually(data);
        } catch (error) {
            console.error(error);
            const formattedDate = new Date(data.endDate).toLocaleDateString('en-GB', {
                month: '2-digit',
                year: 'numeric'
            });
            toast.error(t('AdvancesReportError', { caseId: data.caseId, date: formattedDate }));
        }
        onClose();
        setLoading(false);
    };

    // ----------------------------------
    // 8) Confirmation Modal flow
    // ----------------------------------
    const handleConfirmTaxAdvancesReport = async () => {

        const { UpdatedAdd } = taxAuthorityData;

        setIsConfirmationVisible(false);

        try {
            const tokenData = await TaxAuthorityService.requestTokenAccountant(
                user.accountantId,
                Constants.TaxAuthorityScopes.AdvancesPayment,
                token,
            );
            if (tokenData?.authUrl) {
                setTaxAuthorityData((prev) => ({
                    ...prev,
                    authUrl: tokenData.authUrl,
                    resumeTaxAuthorityAdvancesOperation: false,
                }));
                const authWindow = window.open(tokenData.authUrl, '_blank');
                onClose();
                startAuthCompletionCheck(authWindow, Constants.TaxAuthorityScopes.AdvancesPayment);
            } else if (tokenData?.accessToken) {
                // If we got the token directly
                await TaxAuthoritySendAdvancesReport(UpdatedAdd, token);
            }
        } catch (err) {
            console.error('Error fetching token:', err);
            toast.error(t('ErrorFetchingToken'));
            setLoading(false);
        }
    };

    const handleConfirm = async () => {
        if (actionType === 'TaxAdvancesReport') {
            await handleConfirmTaxAdvancesReport();
        }
    };

    const getConfirmMessage = () => t('AreYouSureSendTaxAdvancesReport');
    const getTitleMessage = () => t('ConfirmTaxAdvancesReport');

    const deleteAdvanceReport = async (reportData, token) => {
        try {
            const response = await ReportsService.deleteReportedAdvanceByPeriod({
                caseId: reportData.caseId,
                StartDate: reportData.startDate,
                EndDate: reportData.endDate,
            }, token);
    
            if (response && response.ok) {
                toast.success(t('ReportedAdvanceDeletedSuccess'));
                const responseData = {
                    id: null,
                    caseId: withholdingTax.clientCaseId,
                    acceptedWithholdingTax: 0,
                    paymentWithholdingTax: 0,
                    witholdingIncomesNoVat: 0,
                    incomeTaxAdvancePercentage: 0,
                    incomeTaxAdvanceAmount: 0,
                    startDate: withholdingTax.StartDate,
                    endDate:  withholdingTax.EndDate,
                    withholdingTaxStatus: 0,
                }
                onDataUpdate(responseData);
            } else {
                toast.error(t('ReportedAdvanceDeleteFailed'));
            }
            onClose();
        } catch (error) {
            console.error(t('Error deleting Advance report:'), error);
            toast.error(t('ReportedAdvanceDeleteFailed'));
        }
    };
    // ----------------------------------
    // 9) Final "Save" button
    // ----------------------------------
    const handleSaveWithholdingTaxReport = async () => {
        // Build a minimal object to pass around
        const UpdatedAdd = {
            id: withholdingTax.id,
            caseId: withholdingTax.clientCaseId,
            startDate: withholdingTax.StartDate,
            endDate: withholdingTax.EndDate,
            acceptedWithholdingTax: parseFloat(withholdingTax.AcceptedWithholdingTaxMaximum),
            witholdingIncomesNoVat: parseFloat(withholdingTax.witholdingIncomesNoVat),
            status: 1,
            WithholdingTaxStatus: 1,
            paymentAmount: withholdingTax.IncomeTaxAdvances,
            incomeTaxAdvanceAmount: parseFloat(withholdingTax.incomeTaxAdvanceAmount),
            incomeTaxAdvancePercentage: parseFloat(withholdingTax.incomeTaxAdvancePercentage)
        }

        setTaxAuthorityData((prev) => ({ ...prev, UpdatedAdd }));
        if (reportingWay === 'unreported') {
            await deleteAdvanceReport(UpdatedAdd, token);
        }
        else if (reportingWay === 'automatic') {
            // Show confirmation modal to do the Tax Authority flow
            setActionType('TaxAdvancesReport');
            setIsConfirmationVisible(true);
        } else {
            // Just do local save
            await saveReportTaxManually(UpdatedAdd);
            onClose();
        }
    };

    // ----------------------------------
    // RENDER
    // ----------------------------------
    return (
        <Modal open={open} onClose={onClose}>
            <>
                <Box sx={modalStyle}>
                    <div className="advanceVatTaxContainer" style={{ direction }}>
                        {/* HEADER */}
                        <div className="advanceVatTaxTitleCloseContainer">
                            <div className="advanceVatTaxTitle">
                                {t('AdvancesReport')}
                            </div>
                            <div className="advanceVatTaxClose" onClick={onClose}>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="20"
                                    height="20"
                                    viewBox="0 0 20 20"
                                    fill="none"
                                >
                                    <path
                                        d="M15.2501 4.75831C14.9251 4.43331 14.4001 4.43331 14.0751 4.75831L10.0001 8.82498L5.9251 4.74998C5.6001 4.42498 5.0751 4.42498 4.7501 4.74998C4.4251 5.07498 4.4251 5.59998 4.7501 5.92498L8.8251 9.99998L4.7501 14.075C4.4251 14.4 4.4251 14.925 4.7501 15.25C5.0751 15.575 5.6001 15.575 5.9251 15.25L10.0001 11.175L14.0751 15.25C14.4001 15.575 14.9251 15.575 15.2501 15.25C15.5751 14.925 15.5751 14.4 15.2501 14.075L11.1751 9.99998L15.2501 5.92498C15.5668 5.60831 15.5668 5.07498 15.2501 4.75831Z"
                                        fill="black"
                                        fillOpacity="0.8"
                                    />
                                </svg>
                            </div>
                        </div>

                        {/* CONTENT => our "embedded HTML" plus PDF download */}
                        <div style={{ margin: '0 auto' }} ref={withholdingContentRef}
                            dangerouslySetInnerHTML={{ __html: withholdingHtmlContent }}
                        />
                        <div style={{ marginTop: '20px', textAlign: 'center' }}>
                            <button
                                onClick={() => handleDownloadPdf(withholdingContentRef)}
                                className="advanceVatTax-download-button"
                            >
                                {t('DownloadPDFFile')}
                            </button>
                            <div className={`report-Type-radio-container ${direction}`}>
                                <RadioGroup
                                    row
                                    value={reportingWay}
                                    onChange={(e) => setReportingWay(e.target.value)}
                                >
                                    <FormControlLabel
                                        value="automatic"
                                        control={<Radio />}
                                        label={t('AutomaticTaxAuthorityReporting')}
                                        labelPlacement="end"
                                    />
                                    <FormControlLabel
                                        value="manual"
                                        control={<Radio />}
                                        label={t('ManualReporting')}
                                        labelPlacement="end"
                                    />
                                    <FormControlLabel
                                        value="unreported"
                                        control={<Radio />}
                                        label={t('Unreported')}
                                        labelPlacement="end"
                                    />
                                </RadioGroup>
                            </div>
                        </div>

                        {/* FOOTER BUTTONS */}
                        <div className="save-buttons-container-vat-withholding">
                            <button
                                className="sub-close-subAcc-content-button"
                                onClick={onClose}
                            >
                                {t('close')}
                            </button>
                            <button
                                className="sub-add-subAcc-content-button"
                                onClick={handleSaveWithholdingTaxReport}
                            >
                                {t('save')}
                            </button>
                        </div>
                    </div>
                </Box>

                {/* CONFIRMATION MODAL */}
                <CustomDeleteConfirmationModal
                    isVisible={isConfirmationVisible}
                    onClose={() => setIsConfirmationVisible(false)}
                    onDelete={handleConfirm}
                    confirmMessage={getConfirmMessage()}
                    titleMessage={getTitleMessage()}
                    type="sendReport"
                />
            </>
        </Modal>
    );
}
