// import ChatService from './chat.service';
//http://192.168.1.32
//http://localhost
//https://accounting-manager.com
import { PermissionsContext } from '../Contexts/Permissions';
import axios from 'axios';
import config from '../config';
import selectedEnvironment from '../env';
import Constants from '../assests/Constants/constants';
const API_URL = `${config[selectedEnvironment].API_URL}/Auth`;



// Log in a user
const login = async (accountantId, password,userType) => {
  return axios.post(API_URL + '/login', {
    username: accountantId,
    password,
    userType: userType,
    IsFromApplication:false
  }).then( async (response) => {
    const userType = JSON.stringify(response.data.userType);
    const user = JSON.stringify(response.data.user);
    const token = JSON.stringify(response.data.token);
    localStorage.setItem('user', user);
    localStorage.setItem('token', token);
    localStorage.setItem('userType', userType);

    return response.data;
  })
    .catch(error => {
      const errorMessage = error.response && error.response.data ? error.response.data : 'Login failed';
      throw errorMessage;
    });
};

// Log out the current user
const logout = () => {
  try {
    localStorage.clear();
  } catch (error) {
    // Error clearing the localStorage
  }
};

const sendOtp = async (Username, UserType) => {
  const response = await fetch(`${API_URL}/forgot-password`, {
      method: 'POST',
      headers: {
          "Content-Type": "application/json"
      },
      body: JSON.stringify({ Username, UserType })
  });

  return response;
};

const verifyOtp = async (Username, otp,UserType) => {
  const response = await fetch(`${API_URL}/verify-otp`, {
      method: 'POST',
      headers: {
          "Content-Type": "application/json"
      },
      body: JSON.stringify({ Username, UserType,otp })
  });

  return response;
};

const resetPassword = async (UserId, NewPassword,UserType) => {
  const response = await fetch(`${API_URL}/ResetPassword`, {
      method: 'PUT',
      headers: {
          "Content-Type": "application/json"
      },
      body: JSON.stringify({ UserId, UserType,NewPassword })
  });

  return response;
};

const CheckTokenValidation = async (token) => {
  const response = await fetch(`${API_URL}/CheckTokenValidation`, {
      method: 'POST',
      headers: {
          "Content-Type": "application/json"
      },
      body: JSON.stringify({ Token: token })
  });

  if (response.ok) {
      const data = await response.json();
      return data;
  } else {
      throw new Error('Failed to validate token');
  }
};


const getCurrentUser = async () => {
  try {
    const userData =  localStorage.getItem('user');
    return userData ? JSON.parse(userData) : null;
  } catch (e) {
    console.error('Failed to retrieve user data', e);
    return null;
  }
};
const getCurrentUserTypeId = async () => {
  const userType = await getCurrentUserType(); // Get the parsed user type

  if (!userType) {
    // throw new Error("User type is not set in localStorage.");
    return null
  }

  switch (userType.toString()) {
    case '1': {
      const user = await getCurrentUser();
      return user?.caseId;
    }
    case '2': {
      const accountant =  localStorage.getItem('user');
      const parsedAccountant = accountant ? JSON.parse(accountant) : null;
      return parsedAccountant?.accountantId;
    }
    case '8': {
      const accountant =  localStorage.getItem('user');
      const parsedAccountant = accountant ? JSON.parse(accountant) : null;
      return parsedAccountant?.subAccountantId;
    }
    default:
      throw new Error("Invalid user type.");
  }
};

const getCurrentUserTypeAccountantId = async () => {
  const userType = await getCurrentUserType(); // Get the parsed user type

  if (!userType) {
    // throw new Error("User type is not set in localStorage.");
    return null
  }

  switch (userType.toString()) {
    case '1': {
      const user = await getCurrentUser();
      return user?.accountantId;
    }
    case '2': {
      const accountant =  localStorage.getItem('user');
      const parsedAccountant = accountant ? JSON.parse(accountant) : null;
      return parsedAccountant?.accountantId;
    }
    case '8': {
      const accountant =  localStorage.getItem('user');
      const parsedAccountant = accountant ? JSON.parse(accountant) : null;
      return parsedAccountant?.accountantId;
    }
    default:
      throw new Error("Invalid user type.");
  }
};

const getCurrentUserByType = async () => {
  const userType = await getCurrentUserType(); // Get the parsed user type

  if (!userType) {
    // throw new Error("User type is not set in localStorage.");
    return null
  }

  switch (userType.toString()) {
    case '1': {
      const user = await getCurrentUser();
      return user; // Return the full user object for type '1'
    }
    case '2': {
      const accountant =  localStorage.getItem('user');
      return accountant ? JSON.parse(accountant) : null; // Return the accountant object for type '2'
    }
    case '8': {
      const accountant =  localStorage.getItem('user');
      const parsedAccountant = accountant ? JSON.parse(accountant) : null;
      return parsedAccountant; // Return the accountant object for type '8'
    }
    default:
      throw new Error("Invalid user type.");
  }
};


const getCurrentUserPermissonType = async () => {
  const userType = await getCurrentUserType(); // Get the parsed user type

  if (!userType) {
    // throw new Error("User type is not set in localStorage.");
    return null
  }

  switch (userType.toString()) {
    case '1': {
      return Constants.PermissionUserType.Client; // Return the full user object for type '1'
    }
    case '2': {
      return Constants.PermissionUserType.Accountant; // Return the accountant object for type '2'
    }
    case '8': {
      return Constants.PermissionUserType.SubAccountant; // Return the accountant object for type '8'
    }
    default:
      throw new Error("Invalid user type.");
  }
};
const getCurrentUserType = async () => {
  const userType =  localStorage.getItem('userType');
  return userType != null ? JSON.parse(userType) : null;
};

const getCurrentUserTypeName = async () => {
  const userType = await getCurrentUserType(); // Get the parsed user type

  if (!userType) {
    // throw new Error("User type is not set in localStorage.");
    return null
  }

  switch (userType.toString()) {
    case '1': {
      const user = await getCurrentUser();
      return user?.name;
    }
    case '2': {
      const accountant =  localStorage.getItem('user');
      const parsedAccountant = accountant ? JSON.parse(accountant) : null;
      return parsedAccountant?.name;
    }
    case '8': {
      const accountant =  localStorage.getItem('user');
      const parsedAccountant = accountant ? JSON.parse(accountant) : null;
      return parsedAccountant?.name;
    }
    default:
      throw new Error("Invalid user type.");
  }
};

const getCurrentToken = async () => {
  const token =  localStorage.getItem('token');
  return token != null ? JSON.parse(token) : null;
};

const EnableDisable = async (userId, userType, isActive, token) => {
  try {
      const response = await axios.put(
          `${API_URL}/EnableDisable`,
          { userId, userType, isActive },
          {
              headers: {
                  Authorization: `Bearer ${token}`,
                  'Content-Type': 'application/json',
              },
          }
      );

      if (response.status === 200) {
          return response.data;
      } else {
          throw new Error('EnableDisable action failed');
      }
  } catch (error) {
      console.error('Error in EnableDisable:', error);
      throw error;
  }
};

const AuthService = {
  login,
  logout,
  sendOtp,
  verifyOtp,
  resetPassword,
  CheckTokenValidation,
  getCurrentUserTypeName,
  getCurrentUserByType,
  getCurrentUserTypeAccountantId,
  getCurrentUserTypeId,
  getCurrentToken,
  getCurrentUserType,
  getCurrentUserPermissonType,
  EnableDisable
};

export default AuthService;
