import React, { useState, useMemo } from "react";
import {
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
    TablePagination, IconButton, Tooltip, Paper
} from "@mui/material";
import {
    ExpandMore, ExpandLess, CancelRounded
} from "@mui/icons-material";

// Example header styling
const headerStyle = {
    backgroundColor: 'var(--foundation-blue-light-active, #BFC8FF)',
    color: "var(--Foundation-Blue-Normal, #304FFF)",
    textAlign: "center",
    leadingTrim: "both",
    textEdge: "cap",
    fontFamily: "Montserrat",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 'bold',
    lineHeight: "normal",
    height: '56px',
    minHeight: '50px',
    padding: "0 5px",

}

// 5) Decide row styling based on whether it's a sub-row, a sub-header, or a parent row
const getRowSx = (row) => {
    if (row.isHeader) {
        // Sub-row header (like "BankCommands" / "BookCommands")
        return {
            backgroundColor: "#EBF2FF", // a subtle highlight color
            fontWeight: "bold",
        };
    } else if (row.isSubRow) {
        // Actual sub-row data
        return {
            backgroundColor: "#F7F9FC", // a very light background
        };
    }
    // Parent row
    return {};
};

function MatchedTable({
    matchedDisplayedRows,   // Flattened data: parents + sub-rows 
    expandedMatchedRows,    // Tracks which parents are expanded
    handleMatchedExpandClick,
    handleRemoveMatchClick,
    formatDate,
    beautifyNumber,
    t,
}) {
    // 1) Build groups from the flattened rows
    const groups = useMemo(
        () => groupMatchedRows(matchedDisplayedRows),
        [matchedDisplayedRows]
    );

    // 2) Pagination state
    const [page, setPage] = useState(0);
    const [groupsPerPage, setGroupsPerPage] = useState(25);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setGroupsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    // 3) Slice the "groups" array for the current page
    const pageGroups = useMemo(() => {
        const start = page * groupsPerPage;
        const end = start + groupsPerPage;
        return groups.slice(start, end);
    }, [groups, page, groupsPerPage]);

    // 4) Flatten the groups on this page into actual rows for rendering
    const currentRows = useMemo(() => {
        return pageGroups.flat(); // merges each group array into one array
    }, [pageGroups]);

    // 5) Render columns (same as your original code). 
    //    We just read from currentRows instead of slicing matchedDisplayedRows directly.
    const renderMatchNumber = (row) => (!row.isSubRow ? row.matchNumber : "");
    const renderTransactionDate = (row) => {
        if (row.isSubRow && !row.isHeader) return formatDate(row.documentDate);
        if (!row.isSubRow) return formatDate(row.transactionDate);
        return "";
    };
    const renderMatchDate = (row) =>
        !row.isSubRow ? formatDate(row.matchDate) : "";
    const renderDescription = (row) => {
        const indentation = row.level * 20;
        if (row.isHeader) {
            return (
                <div style={{ paddingLeft: indentation, fontWeight: "bold" }}>
                    {t(row.transactionType)}
                </div>
            );
        } else if (row.isSubRow) {
            return (
                <div style={{ paddingLeft: indentation }}>
                    {row.description}
                </div>
            );
        }
        return "";
    };
    const renderReference = (row) => {
        if (row.isSubRow && !row.isHeader) return row.reference || "";
        return "";
    };
    const renderDocumentDate = (row) => {
        if (row.isSubRow && !row.isHeader) return formatDate(row.documentDate);
        return "";
    };
    const renderAmount = (row) => {
        if (row.isSubRow && !row.isHeader) {
            return beautifyNumber(row.amount);
        } else {
            // sum external or internal
            const calculateTotalAmount = (transactions) =>
                transactions.reduce((sum, tx) => sum + (tx.amount || 0), 0);

            if (row.externalTransactions && row.externalTransactions.length > 0) {
                return beautifyNumber(calculateTotalAmount(row.externalTransactions));
            } else if (row.internalTransactions && row.internalTransactions.length > 0) {
                return beautifyNumber(calculateTotalAmount(row.internalTransactions));
            }
            return '';
        }
    };
    const renderType = (row) => {
        if (row.isSubRow && !row.isHeader && row.type) {
            const typeParts = row.type.split("-");
            if (typeParts.length > 1) {
                return t(typeParts[1]);
            } else {
                return t(row.type);
            }
        }
        return "";
    };
    const renderUploadNumber = (row) => {
        if (row.isSubRow && !row.isHeader) return row.uploadNumber;
        return "";
    };
    const renderActions = (row) => {
        if (!row.isSubRow) {
            return (
                <Tooltip title={t("RemoveMatch")}>
                    <IconButton size="small" onClick={() => handleRemoveMatchClick(row)}>
                        <CancelRounded style={{ color: "#304FFF" }} />
                    </IconButton>
                </Tooltip>
            );
        }
        return null;
    };
    const renderExpand = (row) => {
        if (!row.isSubRow) {
            const hasSubRows =
                (row.externalTransactions && row.externalTransactions.length > 0) ||
                (row.internalTransactions && row.internalTransactions.length > 0);
            if (!hasSubRows) return null;
            return (
                <IconButton size="small" onClick={() => handleMatchedExpandClick(row.id)}>
                    {expandedMatchedRows[row.id] ? <ExpandLess /> : <ExpandMore />}
                </IconButton>
            );
        }
        return null;
    };

    // 6) Return the table with the flattened rows of the current page
    return (
        <>
            <TableContainer component={Paper} style={{ height: "100%" }}>
                <Table stickyHeader size="small" aria-label="matched-table">
                    <TableHead>
                        <TableRow>
                            <TableCell sx={headerStyle}>{t("MatchNumber")}</TableCell>
                            <TableCell sx={headerStyle}>{t("TransactionDate")}</TableCell>
                            <TableCell sx={headerStyle}>{t("MatchDate")}</TableCell>
                            <TableCell sx={headerStyle} >
                                {t("Description")}
                            </TableCell>
                            <TableCell sx={headerStyle}>{t("Reference")}</TableCell>
                            <TableCell sx={headerStyle}>{t("Document Date")}</TableCell>
                            <TableCell sx={headerStyle}>{t("Amount")}</TableCell>
                            <TableCell sx={headerStyle}>{t("Type")}</TableCell>
                            <TableCell sx={headerStyle}>{t("UploadNumber")}</TableCell>
                            <TableCell sx={headerStyle}>{t("Actions")}</TableCell>
                            <TableCell sx={headerStyle} />
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {currentRows.map((row) => (
                            <TableRow
                                key={row.id || row.matchNumber}
                                sx={getRowSx(row)} // Apply background color, bold text, etc.
                            >
                                <TableCell align="center">{renderMatchNumber(row)}</TableCell>
                                <TableCell align="center">{renderTransactionDate(row)}</TableCell>
                                <TableCell align="center">{renderMatchDate(row)}</TableCell>
                                <TableCell align="center">{renderDescription(row)}</TableCell>
                                <TableCell align="center">{renderReference(row)}</TableCell>
                                <TableCell align="center">{renderDocumentDate(row)}</TableCell>
                                <TableCell align="center">{renderAmount(row)}</TableCell>
                                <TableCell align="center">{renderType(row)}</TableCell>
                                <TableCell align="center">{renderUploadNumber(row)}</TableCell>
                                <TableCell align="center">{renderActions(row)}</TableCell>
                                <TableCell align="center">{renderExpand(row)}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>



            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[25, 50, 100, 200]}
                component="div"
                count={groups.length}           // total "groups"
                rowsPerPage={groupsPerPage}     // how many groups per page
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                labelRowsPerPage={t("RowsPerPage")}
                labelDisplayedRows={({ from, to, count }) =>
                    `${from}-${to} ${t("of")} ${count}`
                }
            />
        </>

    );
}

/** 
 * Helper: Group parent rows + their sub-rows so they stay on the same page.
 * Each group = [parentRow, ...subRows...].
 */
function groupMatchedRows(flatRows) {
    const groups = [];
    let currentGroup = [];

    flatRows.forEach((row) => {
        if (!row.isSubRow) {
            // Found a new parent row => push the old group, start a new one
            if (currentGroup.length > 0) {
                groups.push(currentGroup);
            }
            currentGroup = [row];
        } else {
            // sub-row or header => belongs to the current parent
            currentGroup.push(row);
        }
    });

    // push the final group, if any
    if (currentGroup.length > 0) {
        groups.push(currentGroup);
    }

    return groups;
}

export default MatchedTable;
