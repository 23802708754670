import React, { useEffect } from 'react';
import Modal from '@mui/material/Modal';
import Select from 'react-select';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import FormControlLabel from '@mui/material/FormControlLabel';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import cancelIcon from '../../assests/images/cancel.png';
import addIcon from '../../assests/images/plusO.png';
import nextIcon from '../../assests/images/left-arrow.png';
import previousIcon from '../../assests/images/left-arrow.png';
import '../PaymentAcceptance/PaymentAcceptance.css'

const EditPaymentAcceptanceModalSpecificItem = ({
    open,
    onClose,
    t,
    itemEditing,
    addNewAccountCardDocument,
    companyId,
    accountCodeName,
    accountCodeId,
    setAccount,
    Constants,
    palCode,
    setPalCode,
    isRange,
    editedItem,
    isClosedReportingMonth,
    handleSaveEditAccountCard,
    handleAddAccountCard,
    handleCloseAddNewAccountCardDocument,
    accountCardOptions,
    selectedOption,
    handleMuiChange,
    handleEdit,
    handleDelete,
    handleChangeAccountType,
    customStyles,
    setPaymentDate,
    setReportingMonthDate,
    handleDescriptionChange,
    handleChangeOriginalTaxClearance,
    handleAddEditableItemChange,
    handleItemChange,
    handleRemoveItem,
    showAccountCardInputsEdit,
    statusCheck,
    handleRadioChange,
    handleSave,
    buttonDisabled,
    paymentBeingEdited,
    CustomDropdown,  // Passed as prop
    type,  // Passed as prop
    isClosedReportingMonthAndIsRange,  // Passed as prop
    handleAddItem,  // Passed as prop
    CustomPalCode,
    handleAccountEdit,
    specificType,
    specificId,


    handlePrevious = null,
    currentIndex = null,
    filteredAndSortedData = null,
    handleNext = null,


}) => {
   
    const showSpecificItem = true;
    let itemToRender = [];
    let index = -1;

    if (showSpecificItem && specificType && specificId && editedItem) {
        const itemsList = editedItem[specificType]; // e.g., editedItem['checks']
        if (Array.isArray(itemsList)) {
            index = itemsList.findIndex((item) => item.id === specificId);
            if (index !== -1) {
                itemToRender = [itemsList[index]];
            }
        }
    }
    
    
    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="pdf-modal-title"
            aria-describedby="pdf-modal-description"
            sx={{ zIndex: 1000 }}
        >
            <div
                className='create-modal-overlay'
                onClick={onClose}
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    flexDirection: 'column',
                    overflow: 'hidden'
                }}
            >
                <div
                    className="modal-background"
                    onClick={(e) => e.stopPropagation()}
                    style={{
                        width: '100%',
                        height: '80%',
                        maxWidth: '800px',
                        backgroundColor: 'white',
                        borderRadius: '8px',
                        position: 'relative',
                        overflowY: 'hidden',
                        display: 'flex',
                        flexDirection: 'column'
                    }}
                >
                    <div className="input-field_Container" style={{ display: 'flex', flexDirection: 'column', height: '100%', padding: '0', overflow: 'hidden' }}>

                        {/* checks ----------------------------------------------------------*/}
                        <div className='PAitems_Container'>

                            {/* Item inputs */}
                            {
                                itemToRender?.map((item) => (
                                    <div className='item_Container' key={index} style={{ textAlign: 'right', direction: 'rtl' }}>
                                        <h2 style={{ marginBottom: '20px', textAlign: 'right' }}>{t('Checks')}</h2>
                                        <div className="input-field_Container" style={{ textAlign: 'right', direction: 'rtl' }}>
                                            <div className="input-field-title">{t('CheckNumber')}</div>
                                            <input
                                                type="text"
                                                placeholder={t('CheckNumber')}
                                                value={item.description}
                                                onChange={(e) => handleItemChange('checks', index, 'description', e.target.value)}
                                                className="input-field"
                                                style={{ textAlign: 'right', direction: 'rtl' }}
                                                disabled={!isRange ? isClosedReportingMonth : editedItem?.isClosedReportingMonth}
                                            />
                                        </div>
                                        <div className="input-field_Container" style={{ textAlign: 'right', direction: 'rtl' }}>
                                            <div className="input-field-title">{t('Amount')}</div>
                                            <input
                                                type="number"
                                                placeholder={t('Amount')}
                                                value={item.amount}
                                                onChange={(e) => handleItemChange('checks', index, 'amount', e.target.value)}
                                                className="input-field input-field_Container_required-asterisk"
                                                style={{ textAlign: 'right', direction: 'rtl' }}
                                                disabled={!isRange ? isClosedReportingMonth : editedItem?.isClosedReportingMonth}
                                            />
                                        </div>
                                        <div className="input-field_Container" style={{ textAlign: 'right', direction: 'rtl' }}>
                                            <div className="input-field-title ">{t('date')}</div>
                                            <ReactDatePicker
                                                selected={item.paymentDate ? new Date(item.paymentDate) : new Date()}
                                                onChange={(date) => handleItemChange('checks', index, 'paymentDate', date)}
                                                dateFormat="dd-MM-yyyy"
                                                className="input-field"
                                                style={{ textAlign: 'right', direction: 'rtl' }}
                                                disabled={!isRange ? isClosedReportingMonth : editedItem?.isClosedReportingMonth}
                                            />
                                        </div>
                                        <div className="input-field_Container" style={{ textAlign: 'right', direction: 'rtl' }}>
                                            <div className="input-field-title">{t('BankName')}</div>
                                            <input
                                                type="text"
                                                placeholder={t('BankName')}
                                                value={item.bankName}
                                                onChange={(e) => handleItemChange('checks', index, 'bankName', e.target.value)}
                                                className="input-field"
                                                style={{ textAlign: 'right', direction: 'rtl' }}
                                                disabled={!isRange ? isClosedReportingMonth : editedItem?.isClosedReportingMonth}
                                            />
                                        </div>
                                        <div className="input-field_Container" style={{ textAlign: 'right', direction: 'rtl' }}>
                                            <div className="input-field-title">{t('BankCode')}</div>
                                            <input
                                                type="number"
                                                placeholder={t('BankCode')}
                                                value={item.bankCode}
                                                onChange={(e) => handleItemChange('checks', index, 'bankCode', e.target.value)}
                                                className="input-field"
                                                style={{ textAlign: 'right', direction: 'rtl' }}
                                                disabled={!isRange ? isClosedReportingMonth : editedItem?.isClosedReportingMonth}
                                            />
                                        </div>
                                        <div className="input-field_Container" style={{ textAlign: 'right', direction: 'rtl' }}>
                                            <div className="input-field-title">{t('BankBranchCode')}</div>
                                            <input
                                                type="number"
                                                placeholder={t('BankBranchCode')}
                                                value={item.bankBranchCode}
                                                onChange={(e) => handleItemChange('checks', index, 'bankBranchCode', e.target.value)}
                                                className="input-field"
                                                style={{ textAlign: 'right', direction: 'rtl' }}
                                                disabled={!isRange ? isClosedReportingMonth : editedItem?.isClosedReportingMonth}
                                            />
                                        </div>
                                        <div className="input-field_Container" style={{ textAlign: 'right', direction: 'rtl' }}>
                                            <div className="input-field-title">{t('SupplierAccountNumber')}</div>
                                            <input
                                                type="number"
                                                placeholder={t('SupplierAccountNumber')}
                                                value={item.supplierAccountNumber}
                                                onChange={(e) => handleItemChange('checks', index, 'supplierAccountNumber', e.target.value)}
                                                className="input-field"
                                                style={{ textAlign: 'right', direction: 'rtl' }}
                                                disabled={!isRange ? isClosedReportingMonth : editedItem?.isClosedReportingMonth}
                                            />
                                        </div>
                                        {
                                            !isClosedReportingMonthAndIsRange &&
                                            <button className='clientItemCancelButton' style={{ textAlign: 'right' }} onClick={() => handleRemoveItem('checks', index)}>
                                                <img src={cancelIcon} alt="Income" className="clientItem-icon" />
                                                {t('RemoveItem')}
                                            </button>
                                        }
                                    </div>
                                ))}
                        </div>

                        {/* CreditCards ----------------------------------------------------------*/}

                        <div className='PAitems_Container'>
                            {/* Item inputs */}
                            {itemToRender.map((item) => (
                                <div className='item_Container' key={index} style={{ textAlign: 'right', direction: 'rtl' }}>
                                    <h2 style={{ marginBottom: '20px', textAlign: 'right' }}>{t('CreditCards')}</h2>

                                    <div className="input-field_Container" style={{ textAlign: 'right', direction: 'rtl' }}>
                                        <div className="input-field-title">{t('Description')}</div>
                                        <input
                                            type="text"
                                            placeholder={t('Description')}
                                            value={item.description}
                                            onChange={(e) => handleItemChange('creditCards', index, 'description', e.target.value)}
                                            className="input-field"
                                            style={{ textAlign: 'right', direction: 'rtl' }}
                                            disabled={!isRange ? isClosedReportingMonth : editedItem?.isClosedReportingMonth}
                                        />
                                    </div>
                                    <div className="input-field_Container" style={{ textAlign: 'right', direction: 'rtl' }}>
                                        <div className="input-field-title">{t('Amount')}</div>
                                        <input
                                            type="number"
                                            placeholder={t('Amount')}
                                            value={item.amount}
                                            onChange={(e) => handleItemChange('creditCards', index, 'amount', e.target.value)}
                                            className="input-field input-field_Container_required-asterisk"
                                            style={{ textAlign: 'right', direction: 'rtl' }}
                                            disabled={!isRange ? isClosedReportingMonth : editedItem?.isClosedReportingMonth}
                                        />
                                    </div>
                                    <div className="input-field_Container" style={{ textAlign: 'right', direction: 'rtl' }}>
                                        <div className="input-field-title ">{t('date')}</div>
                                        <ReactDatePicker
                                            selected={item.paymentDate ? new Date(item.paymentDate) : new Date()}
                                            onChange={(date) => handleItemChange('creditCards', index, 'paymentDate', date)}
                                            dateFormat="dd-MM-yyyy"
                                            className="input-field"
                                            style={{ textAlign: 'right', direction: 'rtl' }}
                                            disabled={!isRange ? isClosedReportingMonth : editedItem?.isClosedReportingMonth}
                                        />
                                    </div>
                                    {
                                        !isClosedReportingMonthAndIsRange &&
                                        <button className='clientItemCancelButton' style={{ textAlign: 'right' }} onClick={() => handleRemoveItem('creditCards', index)}>
                                            <img src={cancelIcon} alt="Income" className="clientItem-icon" />
                                            {t('RemoveItem')}
                                        </button>
                                    }
                                </div>
                            ))}
                        </div>





                        {/* cash ----------------------------------------------------------*/}

                        <div className='PAitems_Container'>
                            {/* Item inputs */}
                            {itemToRender.map((item) => (
                                <div className='item_Container' key={index} style={{ textAlign: 'right', direction: 'rtl' }}>
                                    <h2 style={{ marginBottom: '20px', textAlign: 'right' }}>{t('cash')}</h2>

                                    <div className="input-field_Container" style={{ textAlign: 'right', direction: 'rtl' }}>
                                        <div className="input-field-title">{t('Description')}</div>
                                        <input
                                            type="text"
                                            placeholder={t('Description')}
                                            value={item.description}
                                            onChange={(e) => handleItemChange('cash', index, 'description', e.target.value)}
                                            className="input-field"
                                            style={{ textAlign: 'right', direction: 'rtl' }}
                                            disabled={!isRange ? isClosedReportingMonth : editedItem?.isClosedReportingMonth}
                                        />
                                    </div>
                                    <div className="input-field_Container" style={{ textAlign: 'right', direction: 'rtl' }}>
                                        <div className="input-field-title">{t('Amount')}</div>
                                        <input
                                            type="number"
                                            placeholder={t('Amount')}
                                            value={item.amount}
                                            onChange={(e) => handleItemChange('cash', index, 'amount', e.target.value)}
                                            className="input-field input-field_Container_required-asterisk"
                                            style={{ textAlign: 'right', direction: 'rtl' }}
                                            disabled={!isRange ? isClosedReportingMonth : editedItem?.isClosedReportingMonth}
                                        />
                                    </div>
                                    <div className="input-field_Container" style={{ textAlign: 'right', direction: 'rtl' }}>
                                        <div className="input-field-title ">{t('date')}</div>
                                        <ReactDatePicker
                                            selected={item.paymentDate ? new Date(item.paymentDate) : new Date()}
                                            onChange={(date) => handleItemChange('cash', index, 'paymentDate', date)}
                                            dateFormat="dd-MM-yyyy"
                                            className="input-field"
                                            style={{ textAlign: 'right', direction: 'rtl' }}
                                            disabled={!isRange ? isClosedReportingMonth : editedItem?.isClosedReportingMonth}
                                        />
                                    </div>
                                    {
                                        !isClosedReportingMonthAndIsRange &&
                                        <button className='clientItemCancelButton' style={{ textAlign: 'right' }} onClick={() => handleRemoveItem('cash', index)}>
                                            <img src={cancelIcon} alt="Income" className="clientItem-icon" />
                                            {t('RemoveItem')}
                                        </button>
                                    }
                                </div>
                            ))}
                        </div>

                        <div style={{ borderTop: '1px solid black', margin: '20px 0', width: '100%' }}></div>

                        {/* BankTransfers ----------------------------------------------------------*/}

                        <div className='PAitems_Container'>
                            {/* Item inputs */}
                            {itemToRender.map((item) => (
                                <div className='item_Container' key={index} style={{ textAlign: 'right', direction: 'rtl' }}>
                                    <h2 style={{ marginBottom: '20px', textAlign: 'right' }}>{t('BankTransfers')}</h2>
                                    <div className="input-field_Container" style={{ textAlign: 'right', direction: 'rtl' }}>
                                        <div className="input-field-title">{t('Description')}</div>
                                        <input
                                            type="text"
                                            placeholder={t('Description')}
                                            value={item.description}
                                            onChange={(e) => handleItemChange('bankTransactions', index, 'description', e.target.value)}
                                            className="input-field"
                                            style={{ textAlign: 'right', direction: 'rtl' }}
                                            disabled={!isRange ? isClosedReportingMonth : editedItem?.isClosedReportingMonth}
                                        />
                                    </div>
                                    <div className="input-field_Container" style={{ textAlign: 'right', direction: 'rtl' }}>
                                        <div className="input-field-title">{t('Amount')}</div>
                                        <input
                                            type="number"
                                            placeholder={t('Amount')}
                                            value={item.amount}
                                            onChange={(e) => handleItemChange('bankTransactions', index, 'amount', e.target.value)}
                                            className="input-field input-field_Container_required-asterisk"
                                            style={{ textAlign: 'right', direction: 'rtl' }}
                                            disabled={!isRange ? isClosedReportingMonth : editedItem?.isClosedReportingMonth}
                                        />
                                    </div>
                                    <div className="input-field_Container" style={{ textAlign: 'right', direction: 'rtl' }}>
                                        <div className="input-field-title ">{t('date')}</div>
                                        <ReactDatePicker
                                            selected={item.paymentDate ? new Date(item.paymentDate) : new Date()}
                                            onChange={(date) => handleItemChange('bankTransactions', index, 'paymentDate', date)}
                                            dateFormat="dd-MM-yyyy"
                                            className="input-field"
                                            style={{ textAlign: 'right', direction: 'rtl' }}
                                            disabled={!isRange ? isClosedReportingMonth : editedItem?.isClosedReportingMonth}
                                        />
                                    </div>
                                    <div className="input-field_Container" dir="rtl" style={{ textAlign: 'right', alignItems: 'center' }}>
                                        <div className="input-field-title">
                                            {editedItem.paymentAcceptanceType === 'תשלום' ? t("Credit Account") : t("Debit Account")}
                                        </div>
                                        <Select
                                            className="input-field-dropdown input-field_Container_required"
                                            options={accountCardOptions.map(option => ({ value: option.cardId, label: option.name }))}
                                            value={accountCardOptions
                                                .filter(option => option.cardId === (editedItem.paymentAcceptanceType === 'תשלום' ? item.creditAccount : item.debitAccount))
                                                .map(option => ({ value: option.cardId, label: option.name }))[0]}
                                            onChange={(selectedOption) => {
                                                // Check paymentAcceptanceType to determine whether to update the credit or debit account
                                                if (editedItem.paymentAcceptanceType === 'תשלום') {
                                                    // Handle updating the creditAccount
                                                    handleItemChange('bankTransactions', index, 'creditAccount', selectedOption.value);
                                                } else {
                                                    // Handle updating the debitAccount
                                                    handleItemChange('bankTransactions', index, 'debitAccount', selectedOption.value);
                                                }

                                                // Update the BankTransactionsCode with the selected value
                                                handleItemChange('bankTransactions', index, 'bankTransactionsCode', selectedOption.value);
                                            }}
                                            placeholder={editedItem.paymentAcceptanceType === 'תשלום' ? t("Credit Account") : t("Debit Account")}
                                        />
                                    </div>
                                    {
                                        !isClosedReportingMonthAndIsRange &&
                                        <button className='clientItemCancelButton' style={{ textAlign: 'right' }} onClick={() => handleRemoveItem('bankTransactions', index)}>
                                            <img src={cancelIcon} alt="Income" className="clientItem-icon" />
                                            {t('RemoveItem')}
                                        </button>
                                    }
                                </div>
                            ))}
                        </div>
                        <div style={{ borderTop: '1px solid transparent', margin: '20px 0', width: '100%' }}></div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', width: '15%', textAlign: 'center', backgroundColor: 'white', borderRadius: '20px', marginTop: '10px' }}>
                        {/* buttons */}
                        <button className="create-buttons" onClick={onClose}>
                            {t('close')}
                        </button>
                        {!(itemEditing && !paymentBeingEdited) && !isClosedReportingMonth && (
                            <button className="create-buttons" onClick={() => handleSave()} >
                                {t('save')}
                            </button>
                        )}
                    </div>
                </div>
            </div>

        </Modal >
    );
};

export default EditPaymentAcceptanceModalSpecificItem;
