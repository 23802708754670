const config = {
  development: {
    API_URL: 'http://51.16.8.59/api',
    CHAT_URL: 'http://localhost:5036',
  },
  production: {
    API_URL: 'https://server.am.xtram.co.il/api',
    // API_URL: 'https://accounting-manager.com:5163/api',
    CHAT_URL: 'https://chat.xtram.co.il',
    // CHAT_URL: 'http://localhost:5036',
  },
  staging: {
    API_URL: 'https://staging-server.am.xtram.co.il:8080/api',
    CHAT_URL: 'https://chat.xtram.co.il',
    // CHAT_URL: 'http://localhost:5036',
  },
};

export default config;