export const getInitials = (name) => {
    if (!name) return "N/A";
    return name
        .split(" ")
        .map((word) => word[0])
        .join("");
};


// export const getInitials = (name) => {
//     if (!name) return "N/A";
//     return name
//         .split(" ")
//         .map((word) => word[0].toUpperCase())
//         .join("");
// };