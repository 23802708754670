// JournalEntryDataGrid.jsx
import React, { useState, useEffect, useImperativeHandle, forwardRef } from 'react';
import {
    DataGrid,
    GridToolbarContainer,
    GridRowModes,
    GridActionsCellItem,
} from '@mui/x-data-grid';
import { styled } from '@mui/material/styles';
import {
    IconButton,
    Checkbox,
    Button,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { enUS, arSA, he } from 'date-fns/locale';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import NoDocumentsPdf from '../../../assests/pdf/NoDocument.pdf';
import editIcon from '../../../assests/images/Icons/editIcon.svg';
import deleteIcon from '../../../assests/images/Icons/deleteIcon.svg';
import saveIcon from '../../../assests/images/Icons/saveIcon.svg';
import cancelIcon from '../../../assests/images/Icons/cancelIcon.svg';
import AddIcon from '@mui/icons-material/Add';

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
    width: '100%',
    '& .MuiAutocomplete-root': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: "center"
    },
    '& *': {
        boxSizing: 'border-box !important',
    },
    '& .MuiDataGrid-columnHeaders': {
        opacity: 0.7,
        backgroundColor: '#BFC8FF',
    },
    '& .MuiDataGrid-columnHeader': {
        backgroundColor: '#BFC8FF',
    },
    '& .MuiDataGrid-columnHeaderTitle': {
        color: '#304FFF',
        fontFamily: 'Montserrat',
        fontSize: '14px',
        fontWeight: 600,
        lineHeight: 'normal',
    },
    '& .MuiDataGrid-cell': {
        borderTop: '1px solid rgba(191, 191, 193, 0.56)',
        backgroundColor: 'rgba(234, 237, 255, 0.32)',
        whiteSpace: 'normal',
        wordWrap: 'break-word',
    },
    '& .MuiDataGrid-row:hover': {
        backgroundColor: 'rgba(191, 200, 255, 0.3)',
    },
    '& .MuiDataGrid-cell:focus': {
        outline: 'none',
    },
    '& .MuiDataGrid-cell': {
        display: 'flex',
        alignItems: "center"
    },
    '& .disableUser': {
        pointerEvents: 'none',
        opacity: 0.5,
    },
}));

const JournalEntryDataGrid = forwardRef((props, ref) => {
    const {
        t,
        client,
        data,
        isRange,
        isClosedReportingMonth,
        accountCardOptions,
        creditAccountOptions,
        debitAccountOptions,
        sortCodeIdOptions,
        formatDateNew,
        formatMonthYearDate,
        beautifyNumber,
        Constants,
        handleDeleteClick,
        handleSave,
        handleCancel,
        direction,
        setEditedItem,
        editedItem,
        setEditedPopupItem,
        handleEdit,
        setCurrentRowData,
        setOpenInlineEditModal,
        handleSelectRows,
        addNewJournalEntry,
        openPdfInNewTab,
        setAddNewJournalEntry,
        handleOpenDialog,
        handleRightClickAction,
        complexDepositsChecked,
        setDebitCreditSum,
    } = props;

    const { i18n } = useTranslation();
    const [selectedRows, setSelectedRows] = useState([]);
    const [rows, setRows] = useState(data);
    const [rowModesModel, setRowModesModel] = useState({});

    const deselectRows = (ids) => {

        setSelectedRows((prevSelectedRows) => prevSelectedRows.filter(rowId => !ids.includes(rowId)));
    };
    useImperativeHandle(ref, () => ({
        deselectRows
    }));

    const getLocale = () => {
        switch (i18n.language) {
            case 'ar':
                return arSA;
            case 'he':
                return he;
            default:
                return enUS;
        }
    };

    const handleCellDoubleClick = (params) => {
        const row = params?.row || {};
        const rowIndex = data.findIndex((r) => r.id === row.id); // Find the index in the original data array
        handleEdit(row, rowIndex)
    };

    const handleSelectionModelChange = (newSelection) => {
        setSelectedRows(newSelection);
        handleSelectRows(newSelection);

        if (complexDepositsChecked) {
            const selectedRowsData = data.filter((row) =>
                newSelection.includes(row.id)
            );

            let sum = 0;
            selectedRowsData.forEach((row) => {
                const amount = parseFloat(row.amount) || 0;

                if (row.debitAccount) {
                    // If 'debitAccount' exists, treat the 'amount' as a debit
                    sum += amount;
                } else if (row.creditAccount) {
                    // If 'creditAccount' exists, treat the 'amount' as a credit
                    sum -= amount;
                }
            });

            setDebitCreditSum(sum);
        } else {
            setDebitCreditSum(0);
        }
    };

    const isRowSelectable = (params) => {
        const { row } = params;
        if (complexDepositsChecked) {
            // Only allow selection of rows where debit or credit is present, but not both
            const hasDebit = !!row.debitAccount;
            const hasCredit = !!row.creditAccount;
            return (hasDebit && !hasCredit) || (!hasDebit && hasCredit);
        } else {
            return client.accountManagement === Constants.AccountManagementOptions[0].name
                ? row.sortCodeId && row.creditAccount && row.debitAccount
                : row.sortCodeId;
        }
    };

    useEffect(() => {
        if (data) {
            setRows(data);
        }
    }, [data]);

    const getAlignment = () => {
        return i18n.language === 'en' ? 'left' : 'left';
    };
    const columns = [
        {
            field: 'processed',
            headerName: t('DocumentProcessed'),
            headerAlign: getAlignment(),
            align: getAlignment(),
            flex: 1,
            renderCell: (params) => {
                const isIdInChecks =
                    client.documentIds.journalEntries.includes(params.row.id);

                const handleRightClick = (event) => {
                    event.preventDefault(); // Prevent the default context menu
                    const status = isIdInChecks ? 'done' : 'notDone'; // Determine the opposite status
                    const id = params.row.id; // Get the ID of the row

                    // Call your function with Id, status, and full row details
                    handleRightClickAction(id, status, params.row);
                };

                return (
                    <p
                        onContextMenu={handleRightClick} // Attach the right-click handler
                        className={isIdInChecks ? 'unprocessed' : 'processed'}
                        style={{
                            cursor: 'context-menu', // Indicate a context menu is available
                        }}
                    >
                        {isIdInChecks ? t('unprocessed') : t('processed')}
                    </p>
                );
            },
            sortComparator: (v1, v2, params1, params2) => {
                // Determine if each row is unprocessed based on the type
                const isIdInChecks1 = client.documentIds.journalEntries.includes(params1.id)


                const isIdInChecks2 = client.documentIds.journalEntries.includes(params2.id)

                if (isIdInChecks1 === isIdInChecks2) {
                    return 0; // Both are either processed or unprocessed
                }
                return isIdInChecks1 ? 1 : -1; // Sort 'unprocessed' after 'processed'
            },
        },
        {
            field: 'accountCaseId',
            headerName: t('CompanyId'),
            headerAlign: getAlignment(),
            align: getAlignment(),
            editable: false,
            flex: 1,
        },
        {
            field: 'reference',
            headerName: t('Reference'),
            headerAlign: getAlignment(),
            align: getAlignment(),
            editable: false,
            flex: 1,
            sortComparator: (v1, v2) => {
                const num1 = parseFloat(v1) || 0; // Convert to number, fallback to 0 if NaN
                const num2 = parseFloat(v2) || 0;
                return num1 - num2;
            },
        },
        {
            field: 'confirmationNumber',
            headerName: t('ConfirmationNumber'),
            headerAlign: getAlignment(),
            align: getAlignment(),
            editable: false,
            flex: 1,
        },
        {
            field: 'description',
            headerName: t('Description'),
            headerAlign: getAlignment(),
            align: getAlignment(),
            editable: false,
            flex: 1,
        },
        ...(isRange
            ? [
                {
                    field: 'reportingMonthDate',
                    headerName: t('MonthReportDate'),
                    headerAlign: getAlignment(),
                    align: getAlignment(),
                    width: 120,
                    sortComparator: (v1, v2) => {
                        const date1 = new Date(v1);
                        const date2 = new Date(v2);
                        return date1 - date2;
                    },
                    valueFormatter: (params) => formatMonthYearDate(params),
                },
            ]
            : []),
        {
            field: 'uploadDate',
            headerName: t('Upload Date'),
            headerAlign: getAlignment(),
            align: getAlignment(),
            flex: 1,
            valueFormatter: (params) => formatDateNew(params),
        },
        {
            field: 'documentDate',
            headerName: t('Document Date'),
            headerAlign: getAlignment(),
            align: getAlignment(),
            flex: 1,
            editable: true,
            type: 'date',
            valueFormatter: (params) => formatDateNew(params),
        },
        {
            field: 'amount',
            headerName: t('Amount'),
            headerAlign: getAlignment(),
            align: getAlignment(),
            editable: true,
            flex: 1,
            renderCell: (params) => (
                <p
                    style={{
                        color: 'green',
                        fontSize: '16px',
                        fontWeight: 'bold',
                    }}
                >
                    {params.value !== null ? beautifyNumber(params.value) : '0.00'}
                </p>
            ),
        },
        {
            field: 'vat',
            headerName: t('VAT'),
            headerAlign: getAlignment(),
            align: getAlignment(),
            editable: true,
            flex: 1,
        },
        {
            field: 'creditAccount',
            headerName: t('Credit Account'),
            headerAlign: getAlignment(),
            align: getAlignment(),
            flex: 1,
            editable: false,
            renderCell: (params) => {
                const option = creditAccountOptions.find(
                    (option) => option.cardId === params.value
                );
                return (
                    <span>{option ? `${option.cardId} - ${option.name}` : ''}</span>
                );
            },
        },
        {
            field: 'debitAccount',
            headerName: t('Debit Account'),
            headerAlign: getAlignment(),
            align: getAlignment(),
            flex: 1,
            editable: false,

            renderCell: (params) => {
                const option = debitAccountOptions.find(
                    (option) => option.cardId === params.value
                );
                return (
                    <span>{option ? `${option.cardId} - ${option.name}` : ''}</span>
                );
            },
        },
        {
            field: 'sortCodeId',
            headerName: t('Code ID'),
            headerAlign: getAlignment(),
            align: getAlignment(),
            flex: 1,
            renderCell: (params) => {
                const value = params.value || '';
                const option = sortCodeIdOptions.find(
                    (option) => option.sortCodeId === value
                );
                const name = option ? option.sortCodeName : '';
                return (
                    <span>
                        {value} {name && `- ${name}`}
                    </span>
                );
            },
        },
        {
            field: 'uploadNumber',
            headerName: t('UploadNumber'),
            headerAlign: getAlignment(),
            align: getAlignment(),
            flex: 1,
            renderCell: (params) => (
                <span>{params.value !== undefined ? params.value : 'N/A'}</span>
            ),
        },
        // Actions Column
        // {
        //     field: 'actions',
        //     type: 'actions',
        //     headerName: t('Actions'),
        //     headerAlign: getAlignment(),
        //     align: getAlignment(),
        //     flex: 1,
        //     getActions: (params) => {
        //         const row = params?.row || {};
        //         const rowIndex = data.findIndex((r) => r.id === row.id); // Find the index in the original data array

        //         return [
        //             <GridActionsCellItem
        //                 icon={<img src={editIcon} alt="Edit" />}
        //                 label="Edit"
        //                 onClick={() => handleEdit(row,rowIndex)}
        //                 showInMenu={false} // Optional
        //             />,
        //             (isRange ? !row.isClosedReportingMonth : !isClosedReportingMonth) && (
        //                 <GridActionsCellItem
        //                     icon={<img src={deleteIcon} alt="Delete" />}
        //                     label="Delete"
        //                     onClick={() => handleDeleteClick(row.id)}
        //                     showInMenu={false} // Optional
        //                 />
        //             ),
        //         ].filter(Boolean); // Filter out falsey values in case the condition is false
        //     },
        // },
    ].reverse();

    return (
        <div className={`${direction}`} style={{ width: '100%', height: '60vh' }}>
            <LocalizationProvider
                dateAdapter={AdapterDateFns}
                adapterLocale={getLocale()}
            >
                <StyledDataGrid
                    rows={rows}
                    columns={columns}
                    checkboxSelection
                    disableRowSelectionOnClick
                    rowSelectionModel={selectedRows}
                    onRowSelectionModelChange={(ids) => handleSelectionModelChange(ids)}
                    getRowId={(row) => row.id}
                    components={{
                        Toolbar: GridToolbarContainer,
                    }}

                    pagination
                    sortingOrder={['asc', 'desc']}
                    isRowSelectable={isRowSelectable}
                    onCellDoubleClick={handleCellDoubleClick}
                    editMode="row"
                    rowModesModel={rowModesModel}

                />
            </LocalizationProvider>
        </div>
    );
});

export default JournalEntryDataGrid;
