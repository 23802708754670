import React, { useMemo, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import UserAvatar from '../../Widgets/UserAvatar/UserAvatar';
import '../Chat.css';

const ContactItem = React.memo(({ item, onView, t }) => {
    const navigate = useNavigate();

    const handleView = () => {
        onView(item);
    };

    const unreadMessagesStyle = useMemo(() => {
        return item.unreadMessagesCount > 0
            ? 'unreadMessages'
            : 'readMessages';
    }, [item.unreadMessagesCount]);

    // Format the lastMessageDate
    const formattedDate = useMemo(() => {
        if (!item.lastMessageDate) return '';

        const messageDate = dayjs(item.lastMessageDate);
        const now = dayjs();

        if (messageDate.isSame(now, 'day')) {
            return messageDate.format('HH:mm'); // Same day: show time
        } else {
            return messageDate.format('DD/MM HH:mm'); // Other days: show dd/mm hh:mm
        }
    }, [item.lastMessageDate]);

    return (
        <div className="chat-item-container" onClick={handleView}>
            {/* Avatar and Info */}
            <div className="avatar-info-container">
                <div className="avatar-info-container-item">
                    {/* Avatar on the right */}
                    <UserAvatar name={item.userName || 'Unknown'} size={30} />

                </div>

                {/* Name and caseType beside avatar */}
                <div className="info-container">
                    {item.caseType && (
                        <div className="caseType">{t(item.caseType)}</div>
                    )}
                    <div className="last-message">
                        {['image', 'file', 'audio'].includes(item.lastMessage?.toLowerCase())
                            ? t(item.lastMessage.toLowerCase()) // Translate specific types
                            : item.lastMessage || t('NoMessageYet') // Translate the message or fallback
                        }
                        
                    </div>
                </div>
            </div>

            {/* Date and unread bubble */}
            <div className="date-container">
                <div className="date">{formattedDate}</div>
                {item.unreadMessagesCount > 0 && (
                    <div className="unread-bubble">
                        <div className={unreadMessagesStyle}>{item.unreadMessagesCount}</div>
                    </div>
                )}

            </div>
        </div>
    );
}, (prevProps, nextProps) => prevProps.item === nextProps.item);

ContactItem.propTypes = {
    item: PropTypes.object.isRequired,
    onView: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
};

export default ContactItem;