import React, { useRef, useState, useMemo, useEffect } from 'react';
import { DataGrid, GridToolbar, useGridApiRef } from '@mui/x-data-grid';
import { Box, Button } from '@mui/material';
import { styled } from '@mui/material/styles';

import { useTranslation } from 'react-i18next';
import { beautifyNumber } from '../../../Utils/FormatNumber';
import { enUS, arSA, he } from 'date-fns/locale'; // Import desired locales
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';


// Styled DataGrid component
const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
    width: '100%',
    '& .MuiDataGrid-columnHeaders': {
        opacity: 0.7,
        backgroundColor: '#BFC8FF !important',
    },
    '& *': {
        boxSizing: 'border-box !important',

    },
    '& .MuiDataGrid-columnHeader': {
        backgroundColor: '#BFC8FF !important',
    },
    '& .MuiDataGrid-columnHeaderTitle': {
        color: 'var(--Foundation-Blue-Normal, #304FFF)',
        textAlign: 'center',
        fontFamily: 'Montserrat',
        fontSize: '14px',
        fontWeight: 600,
        lineHeight: 'normal',
    },
    '& .MuiDataGrid-row': {
        cursor: 'pointer',
        borderTop: '1px solid rgba(191, 191, 193, 0.56)',
        backgroundColor: 'rgba(234, 237, 255, 0.32)',
    },
    '& .MuiDataGrid-cell': {
        whiteSpace: 'normal',
        wordWrap: 'break-word',
    },
    '& .MuiDataGrid-cell:focus': {
        outline: 'none',
    },
    '& .disableUser': {
        pointerEvents: 'none',
        opacity: 0.5,
    },
}));

const ClientsDataGrid = ({
    filteredClients,
    viewClientDetails,
    subAccountants,
    activeUsers,
    ViewSubAccountantClients
}) => {

    const { i18n, t } = useTranslation();
    const [selectedLanguage, setSelectedLanguage] = useState(localStorage.getItem('language') || 'he');
    const direction = i18n.dir();
    // const initChatConf = async () => {
    //   await ChatService.InitChat();

    // }


    useEffect(() => {
        // Check for saved language in localStorage
        const currentLanguage = localStorage.getItem('language') || 'he'; // Default to 'en' if no language is stored

        i18n.changeLanguage(currentLanguage);
        // initChatConf();
    }, []);

    useEffect(() => {
        const currentLanguage = localStorage.getItem('language') || 'he';
        setSelectedLanguage(currentLanguage);
    }, [i18n.language]);


    const columns = useMemo(() => {
        // Base columns, each with flex: 1 and a minWidth
        const baseColumns = [
            {
                field: 'name',
                headerName: t('clientName'),
                headerAlign: 'center',
                align: 'center',
                flex: 1,
            },
            {
                field: 'caseId',
                headerName: t('clientCase'),
                headerAlign: 'center',
                align: 'center',
                flex: 1,
            },
            {
                field: 'subAccountantId',
                headerName: t('SubAccountantName'),
                headerAlign: 'center',
                align: 'center',
                flex: 1,
                renderCell: (params) => {
                    const subAccountant = subAccountants.find(
                        (accountant) => accountant.value === params.value
                    );
                    return <span>{subAccountant ? subAccountant.label : ''}</span>;
                },
            },
            {
                field: 'reportingType',
                headerName: t('MonthlyBiMonthly'),
                headerAlign: 'center',
                align: 'center',
                flex: 1,
            },
            {
                field: 'vatReportStatus',
                headerName: t('VATReport'),
                headerAlign: 'center',
                align: 'center',
                flex: 1,
                minWidth: 180,

                renderCell: (params) => {
                    const status = params.value || 'notReported';
                    let className = '';

                    switch (status) {
                        case 'reported':
                            className = 'main-unProcessed'; // Green
                            break;
                        case 'notReported':
                            className = 'main-processed'; // Red
                            break;
                        case 'notReportedperiod':
                            className = 'main-warning'; // Yellow
                            break;
                        default:
                            className = 'main-unProcessed';
                    }

                    return <div className={className}>{t(status)}</div>;
                },
            },
            {
                field: 'advanceReportStatus',
                headerName: t('AdvanceTaxReport'),
                headerAlign: 'center',
                align: 'center',
                flex: 1,
                minWidth: 180,
                renderCell: (params) => {
                    const status = params.value || 'notReported';
                    let className = '';

                    switch (status) {
                        case 'reported':
                            className = 'main-unProcessed'; // Red
                            break;
                        case 'notReported':
                            className = 'main-processed'; // Green
                            break;
                        case 'notReportedperiod':
                            className = 'main-warning'; // Yellow
                            break;
                        default:
                            className = 'main-unProcessed';
                    }

                    return <div className={className}>{t(status)}</div>;
                },
            },
            {
                field: 'totalCounts',
                headerName: t('unprocessed'),
                headerAlign: 'center',
                align: 'center',
                flex: 1,
                renderCell: (params) => (
                    <div className={`${params.value > 0 ? 'main-processed' : 'main-unProcessed'}`}>
                        {params.value}
                    </div>
                ),
            },
            {
                field: 'unfinishedAssignments',
                headerName: t('UnfinishedAssignments'),
                headerAlign: 'center',
                align: 'center',
                flex: 1,
                renderCell: (params) => (
                    <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <div className={`${params.value > 0 ? 'main-assignments' : 'main-assignments-notfinished'}`}>
                            {params.value || 0}
                        </div>
                    </div>
                ),
            },
            {
                field: 'accountManagement',
                headerName: t('AccountManagement'),
                headerAlign: 'center',
                align: 'center',
                flex: 1,
            },

        ];

        // Add the balance column conditionally
        if (!ViewSubAccountantClients) {

            baseColumns.push({
                field: 'balance',
                headerName: t('Balance'),
                headerAlign: 'center',
                align: 'center',
                flex: 1,
                sortComparator: (v1, v2) => {
                    // Convert beautified numbers back to raw numbers for comparison
                    const parseNumber = (value) => {
                        if (typeof value === 'string') {
                            return parseFloat(
                                value.replace(/[()]/g, '').replace(/,/g, '')
                            ) * (value.includes('(') ? -1 : 1);
                        }
                        return value || 0;
                    };
                    const n1 = beautifyNumber(v1);
                    const n2 = beautifyNumber(v2);
                    const num1 = parseNumber(n1);
                    const num2 = parseNumber(n2);
                    return num1 - num2; // Standard ascending sort
                },
                renderCell: (params) => {
                    const value = beautifyNumber(params.value);
                    return <div>{value}</div>;
                },
            });
        }

        return baseColumns;
    }, [t, subAccountants, ViewSubAccountantClients]);


    const apiRef = useGridApiRef();
    const [selectedRows, setSelectedRows] = useState([]);

    // Handlers for sorting and filtering
    const handleSortModelChange = (model) => {
        // Implement your sort logic here
    };

    const handleFilterModelChange = (model) => {
        // Implement your filter logic here
    };

    const handleEdit = (params) => {
        // Implement your edit logic here
    };

    const getLocale = () => {
        switch (i18n.language) {
            case 'ar':
                return arSA; // Arabic locale
            case 'he':
                return he; // Hebrew locale
            default:
                return enUS; // Default to English locale
        }
    };

    return (
        // <Box
        //     sx={{
        //         height: '50vh',
        //         width: '100%',
        //         boxSizing: 'border-box !important',
        //         backgroundColor: '#fff',
        //         direction: 'ltr',
        //     }}
        // >
        <div className={`${direction}`} style={{ width: '100%', height: '60vh' }}>
            <LocalizationProvider
                dateAdapter={AdapterDateFns}
                adapterLocale={getLocale()}
            >
                <StyledDataGrid
                    apiRef={apiRef}
                    rows={filteredClients}
                    columns={columns}
                    pageSize={25} // Max rows set to 25
                    rowsPerPageOptions={[10, 25, 50]}
                    disableRowSelectionOnClick
                    onSelectionModelChange={(ids) => setSelectedRows(ids)}
                    getRowClassName={(params) => params.row.rowClassName || ''}
                    getRowId={(row) => row.caseId}
                    onCellEditCommit={handleEdit}
                    selectionModel={selectedRows}
                    components={{ Toolbar: GridToolbar }}
                    componentsProps={{
                        toolbar: {
                            showQuickFilter: true,
                        },
                        row: {
                            className: !activeUsers ? 'disableUser' : '',
                        },
                    }}

                    pagination
                    onSortModelChange={handleSortModelChange}
                    onFilterModelChange={handleFilterModelChange}
                    onRowClick={(params) => viewClientDetails(params.row)}

                />
            </LocalizationProvider>
        </div>
        // </Box>
    );
};

export default ClientsDataGrid;
