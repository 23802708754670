import React, { useState, useEffect, useRef } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { registerLocale, setDefaultLocale } from "react-datepicker";
import enGB from 'date-fns/locale/en-GB'; // the locale you want
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import Select, { components } from 'react-select';
import HamburgerMenu from '../HamburgerMenu/HamburgerMenu.jsx';
import UserService from '../../Services/user.service.js';
import ClientService from '../../Services/client.service.js';
import SubAccountantService from '../../Services/subAccountant.service.js';
import PuffLoader from "react-spinners/PuffLoader";
import EnumsService from '../../Services/enums.service.js';
import CustomDeleteConfirmationModal from '../CustomDeleteConfirmationModal/CustomDeleteConfirmationModal.js';
import ProfileDocumentIcon from "../../assests/images/details.png"; // Ensure you have the correct path
import accountantIcon from "../../assests/images/accountantGroup.png"; // Ensure you have the correct path
// import clientsIcon from "../../assests/images/clientsGroups.png"; // Ensure you have the correct path
import { useBack } from "use-back";
import PropertiesIcon from "../../assests/images/properties.png";
import { useTranslation } from 'react-i18next';
import './AccountantDetails.css'; // make sure the path to your CSS file is correct
import backIcon from '../../assests/images/left-arrow.png';
import { CiEdit } from "react-icons/ci";
import loadingAnimation from "../../assests/images/Animation.gif";
import { MdDelete } from "react-icons/md";
import { ToastContainer, toast } from "react-toastify";
import { HashLoader } from "react-spinners";
import Constants from '../../assests/Constants/constants.js';
import 'react-toastify/dist/ReactToastify.css';
import Header from '../Header/Header.js';
import editIcon from "../../assests/images/edit.png";
import saveIcon from "../../assests/images/save.png";
import cancelIcon from "../../assests/images/cancel.png";
import addIcon from "../../assests/images/plusO.png";
import importIcon from "../../assests/images/importO.png";
import deleteIcon from "../../assests/images/deleteicon.png";
import { KeyboardArrowDown, KeyboardArrowUp, Edit, Save, Cancel, Add } from '@mui/icons-material';
import MuiSelect from '@mui/material/Select';
import OutlinedInput from '@mui/material/OutlinedInput';
import AdvancedDropdown from '../AdvancedDropdown/AdvancedDropdown.js';
import ListItemText from '@mui/material/ListItemText';
//table imports
import {
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton,
    Collapse, Box, Typography, TextField, Checkbox, Button, makeStyles, InputAdornment
} from '@mui/material';
import { Card, CardContent, CardHeader, MenuItem, InputLabel, FormControl } from '@mui/material';
import CircularProgress, {
    circularProgressClasses,
} from '@mui/material/CircularProgress';
import { beautifyNumber } from '../../Utils/FormatNumber.js';
import SearchIcon from '@mui/icons-material/Search';
import AutocompleteSelect from '../Widgets/AutocompleteSelect/AutocompleteSelect.jsx';
import UpperPanelDetails from '../Widgets/UpperPanelDetails/UpperPanelDetails.jsx';
import clientsIcon from "../../assests/images/Main/clients.svg";
import subAccountantsIcon from "../../assests/images/Main/subAccountants.svg";
import editIconImage from "../../assests/images/Icons/editIcon.svg";
import addIconImage from "../../assests/images/Icons/addIcon.svg";
import importIconImage from "../../assests/images/Icons/importIcon.svg";
import AutocompleteCardSelect from '../Widgets/AutocompleteCardSelect/AutocompleteCardSelect.jsx';
import AccountantDetailsModals from './Modals/AccountantDetailsModals.jsx';
import ProfilePictureDialog from '../Widgets/ProfilePictureDialog/ProfilePictureDialog.jsx';
registerLocale('en-GB', {
    ...enGB,
    localize: {
        ...enGB.localize,
        month: n => n + 1, // Display months as numbers starting from 1
    },
    formatLong: {
        ...enGB.formatLong,
        date: () => 'MM/yyyy', // Date format when the picker is not showing
    },
});

setDefaultLocale('en-GB'); // Set the default locale to use it

const headerStyle = {
    backgroundColor: '#f7f7f7',
    color: 'black',
    fontWeight: 'bold',
    height: '140px'
}
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
            zIndex: 1000
        },
    },
};

const customStyles = {
    control: (provided) => ({
        ...provided,
        minHeight: '48px',
        borderRadius: '8px',
        background: '#BFC8FF',
        border: 'none',
        boxShadow: 'none',
        padding: '0 10px',
        textAlign: 'left',
        border: '1px solid transparent',
        '&:focus-within': {
            border: '2px solid var(--Foundation-Blue-Normal, #304FFF)',
        },
    }),
    placeholder: (provided) => ({
        ...provided,
        textAlign: 'left',
        color: 'black'
    }),
    singleValue: (provided) => ({
        ...provided,
        textAlign: 'left',
        color: 'black',
    }),
    menu: (provided) => ({
        ...provided,
        textAlign: 'left',
        color: 'black',
    }),
    option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isSelected
            ? '#F9AA2A'
            : state.isFocused
                ? '#F9AA2A'
                : null,
        color: 'black',
    }),
    dropdownIndicator: (provided, state) => ({
        ...provided,
        color: state.isFocused ? 'darkgray' : 'black',
        '&:hover': {
            color: 'darkgray',
        },
    }),
};

const AccountantDetails = () => {

    const navigate = useNavigate();
    const { i18n, t } = useTranslation();
    const [user, setUser] = useState({});
    const [token, setToken] = useState({});
    const [clientsCount, setClientsCount] = useState(0);
    const [employeCount, setEmployeCount] = useState(0);
    const [isAccountant, setIsAccountant] = useState(false);

    const [isEditing, setIsEditing] = useState(false);

    const [phoneNumber, setPhoneNumber] = useState('');
    const [name, setName] = useState('');
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [itemIdToDelete, setItemIdToDelete] = useState(null);
    const [direction, setDirection] = useState('rtl'); // Default to 'rtl'

    const [email, setEmail] = useState('');
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    // const [caseTypeOptions, setCaseTypeOptions] = useState([]);
    // const [checksTypeOptions, setChecksTypeOptions] = useState([]);
    const [otherDocumentsTypeOptions, setOtherDocumentsTypeOptions] = useState([]);

    const [caseType, setCaseType] = useState('');
    const [checkType, setCheckType] = useState('');
    const [otherDocumentsType, setOtherDocumentsType] = useState('');
    const [newSortCodeGroup, setNewSortCodeGroup] = useState('');
    const [newSortCodeGroupListNumber, setNewSortCodeGroupListNumber] = useState('');
    const [sortCodeGroupProfitName, setsortCodeGroupProfitName] = useState('');
    const [selectedValues, setSelectedValues] = useState([]);

    const [isPropCaseTypeAdding, setIsPropCaseTypeAdding] = useState(false);
    const [isPropCaseTypeEditing, setIsPropCaseTypeEditing] = useState(false);

    const [isPropChecksTypeAdding, setIsPropChecksTypeAdding] = useState(false);
    const [isPropChecksTypeEditing, setIsPropChecksTypeEditing] = useState(false);

    const [isPropOtherDocumentsTypeAdding, setIsPropOtherDocumentsTypeAdding] = useState(false);
    const [isPropOtherDocumentsTypeEditing, setIsPropOtherDocumentsTypeEditing] = useState(false);

    const [isPropSortCodeGroupAdding, setIsPropSortCodeGroupAdding] = useState(false);
    const [isPropSortCodeGroupEditing, setIsPropSortCodeGroupEditing] = useState(false);

    const [loading, setLoading] = useState(false);
    const [editedItem, setEditedItem] = useState(null);

    const [isPropSortCodeAdding, setIsPropSortCodeAdding] = useState(false);
    const [sortCode, setSortCode] = useState({
        sortCodeId: '',
        sortCodeName: '',
        sortCodeCredit: '',
        sortCodeDebit: '',
        sortCodeGroup: null,
        sortCodeVatType: null,
    });
    const [isPropSortCodeEditing, setIsPropSortCodeEditing] = useState(false);
    const [sortCodesOptions, setSortCodesOptions] = useState([]);
    const [sortCodesGroupOptions, setSortCodesGroupOptions] = useState([]);
    const [sortCodeGroup, setSortCodeGroup] = useState({
        sortCodeGroupName: '',
        sortCodeGroupListNumber: '',
        profitName: '',
    });

    const fileInputRef2 = useRef(null);
    const [actionType, setActionType] = useState('delete');
    const [selectedCaseType, setSelectedCaseType] = useState(null);
    const [selectedCheckType, setSelectedCheckType] = useState(null);
    const [selectedAccountManagement, setSelectedAccountManagement] = useState(null);
    const [selectedOtherDocumentsType, setSelectedOtherDocumentsType] = useState([]);
    const [selectedSortCode, setSelectedSortCode] = useState(null);
    const [selectedSortCodeGroup, setSelectedSortCodeGroup] = useState(null);

    // State variables for PalCode
    const [isPropPalCodeAdding, setIsPropPalCodeAdding] = useState(false);
    const [isPropPalCodeEditing, setIsPropPalCodeEditing] = useState(false);
    const [palCode, setPalCode] = useState({
        palCodeType: '',
        palCodeId: '',
        palCodeCategory: null,
    });
    const [palCodeType, setPalCodeType] = useState('');
    const [palCodeId, setPalCodeId] = useState('');
    const [palCodeCategory, setPalCodeCategory] = useState({
        palCodeCategoryId: '',
        orderNumber: '',
        accountingGroup: null,
    });
    const [palCodeCategoryAccountingGroup, setPalCodeCategoryAccountingGroup] = useState('');
    const [editAddSelectedPalCodeCategory, setEditAddSelectedPalCodeCategory] = useState('');
    const [palCodeClassification, setPalCodeClassification] = useState('');
    const [selectedPalCode, setSelectedPalCode] = useState(null);
    const [palCodeOptions, setPalCodeOptions] = useState([]);
    const [palCodeCategoryOptions, setPalCodeCategoryOptions] = useState([]);
    const [palCodeAccountingGroupOptions, setPalCodeAccountingGroupOptions] = useState([]);
    const [editAddSelectedPalCodeAccountingGroupCategory, setEditAddSelectedPalCodeAccountingGroupCategory] = useState('');

    // State variables for PalCodeCategory
    const [isPropPalCodeCategoryAdding, setIsPropPalCodeCategoryAdding] = useState(false);
    const [isPropPalCodeCategoryEditing, setIsPropPalCodeCategoryEditing] = useState(false);
    const [newPalCodeCategory, setNewPalCodeCategory] = useState('');
    const [selectedPalCodeCategory, setSelectedPalCodeCategory] = useState(null);
    const [selectedPalCodeAccountingGroup, setSelectedPalCodeAccountingGroup] = useState(null);
    const [accountingGroup, setAccountingGroup] = useState({
        accountingGroupName: '',
        accountingGroupClassifaction: '',
        orderNumber: '',
    });
    const [isPropPalCodeAccountingGroupAdding, setIsPropPalCodeAccountingGroupAdding] = useState(false);
    const [isPropPalCodeAccountingGroupEditing, setIsPropPalCodeAccountingGroupEditing] = useState(false);
    const [accountingGroupClassifaction, setAccountingGroupClassifaction] = useState('');
    const [accountingGroupName, setAccountingGroupName] = useState('');
    const [orderNumber, setOrderNumber] = useState('');
    const [orderNumberCategory, setOrderNumberCategory] = useState('');

    const handleDropdownChange = (setter) => (e) => {
        setter(e.value);
    };

    useEffect(() => {
        // Update direction based on the current language
        if (i18n.language === 'he' || i18n.language === 'ar') {
            setDirection('rtl');
        } else {
            setDirection('ltr');
        }
    }, [i18n.language]);

    const saveSortCodeEdit = async () => {
        if (editedItem) {
            await handleSortCodeEditOptions(
                editedItem.sortCodeId,
                editedItem.sortCodeName,
                editedItem.originalSortCodeId,
                editedItem.sortCodeGroup,
                editedItem.sortCodeCredit,
                editedItem.sortCodeDebit,
                parseInt(editedItem.sortCodeVatType),

            );
            setEditedItem({
                ...editedItem,
                sortCodeId: '',
                sortCodeName: '',
                originalSortCodeId: '',
                sortCodeGroup: '',
                sortCodeCredit: '',
                sortCodeDebit: '',
                sortCodeVatType: ''
            });
        }
        setSelectedSortCode(null);
        setIsPropSortCodeEditing(false);
    };

    const handleSortCodeEditOptions = async (sortCodeId, sortCodeName, originalSortCodeId, sortCodeGroup, CreditAccount, DebitAccount, sortCodeVatType) => {
        try {
            setLoading(true);
            let SortCodeResponseOk = true;
            const response = await EnumsService.UpdateSortCode(user.accountantId, originalSortCodeId, sortCodeId, sortCodeName, sortCodeGroup, CreditAccount, DebitAccount, sortCodeVatType, token);
            SortCodeResponseOk = response.ok;
            if (!response.ok) {
                const errorText = await response.text();
                console.error(`Error on save result account category data:`, errorText);
                toast.error(t('Error on save result account category data', { errorText }));
            } else {
                toast.success(t('Result account data has been saved successfully'));
            }

            if (SortCodeResponseOk) {
                await fetchEnums(token, user);
            }
        } catch (error) {
            console.error(error);
            toast.error(t('Error on save result account category data'));
        }
        setLoading(false);
    };
    // {
    //     sortCodeId: '123',
    //     sortCodeName: 'sad',
    //     sortCodeCredit: '32',
    //     sortCodeDebit: '123',
    //     sortCodeGroup: {
    //       name: 'הוצאות הפעלה ושיווק',
    //       value: 'הוצאות הפעלה ושיווק',
    //       label: 'הוצאות הפעלה ושיווק'
    //     },
    //     sortCodeVatType: { name: 'מע"מ עיסקאות', value: '1', label: 'מע"מ עיסקאות' }
    //   }
    const handlePropSortCodeSave = async () => {

        try {

            let providerResponseOk = true;
            const areSortCodeFieldsValid = areFieldsValid([sortCode.sortCodeId, sortCode.sortCodeName, sortCode.sortCodeGroup]);
            if (!areSortCodeFieldsValid) {
                toast.info(t('InvalidInput'));
                return
            }


            const sortCodeExists = formattedSortCodesOptions.some(option => option.sortCodeId === sortCode.sortCodeId);
            if (sortCodeExists) {
                toast.info(t('SortCodeIdAlreadyExists'));
                return;
            }


            setLoading(true);
            // Make the sortCode API call if fields are valid
            if (areSortCodeFieldsValid) {
                const response = await EnumsService.AddSortCode(user.accountantId, sortCode.sortCodeName, sortCode.sortCodeId, sortCode.sortCodeGroup, sortCode.sortCodeCredit, sortCode.sortCodeDebit, parseInt(sortCode.sortCodeVatType), token);
                providerResponseOk = response.ok;
                if (!response.ok) {
                    const errorText = await response.text();
                    console.error("Error adding sortCode:", errorText);
                    toast.error(t('Error on save result account category data'));
                } else {
                    toast.success(t('Result account data has been saved successfully'));
                }
            } else {
                toast.info(t('InvalidInput'));
            }

            // If the API call was successful, fetch enums
            if (providerResponseOk) {
                await fetchEnums(token, user);
            }

        } catch (error) {
            console.error(error);
            toast.error(t('Error on save result account category data'));
        }
        setIsPropSortCodeAdding(false);
        setSortCode({
            sortCodeId: '',
            sortCodeName: '',
            sortCodeCredit: '',
            sortCodeDebit: '',
            sortCodeGroup: null,
            sortCodeVatType: null,
        });
        setLoading(false);
    };

    const handleSortCodeDeleteOptions = async (sortCodeId) => {
        try {
            setLoading(true);
            let SortCodeResponseOk = true;

            // Make the account card API call if fields are valid
            const response1 = await EnumsService.DeleteSortCodebyId(user.accountantId, sortCodeId, token);
            SortCodeResponseOk = response1.ok;
            if (!response1.ok) {
                const errorText = await response1.text();
                console.error(`Error deleting sortCode:`, errorText);
                toast.error(t('providerDeleteFailure', { errorText }));
            } else {
                toast.success(t('providerDeleteSuccess'));
            }

            // If the API call was successful, fetch enums
            if (SortCodeResponseOk) {
                await fetchEnums(token, user);
            }
        } catch (error) {
            console.error(error);
        }
        setItemIdToDelete(null);
        setSelectedSortCode(null);
        setLoading(false);
    };

    // Function to handle file selection
    const handleFileChange = (event, uploadFunction) => {
        const file = event.target.files[0];
        if (file) {
            uploadFunction(file); // Call the passed upload function with the selected file
        }
    };

    // Function to trigger the file input and specify the upload function
    const handleFileButtonClick = (fileInputRef, uploadFunction) => {
        // Reset the file input value
        fileInputRef.current.value = '';

        // Set up the onchange event handler
        fileInputRef.current.onchange = (event) => handleFileChange(event, uploadFunction);

        // Trigger the file input click
        fileInputRef.current.click();
    };

    // Function to upload to the second API
    const uploadFileToSortCode = async (file) => {
        setLoading(true);
        try {
            // Call the upload service method, passing the caseId, file, and auth token
            const response = await EnumsService.UploadFileData("SortCodes", user.accountantId, file, token);

            if (response.ok) {
                toast.success(t("FileUploadedSuccessfullyToSortCode"));
                // Handle success - perhaps update state or notify the user
                await fetchEnums(token, user);
            } else {
                // The server responded with an error status
                console.error("File upload failed with response status:", response.status);
                const errorText = await response.text();
                console.error("Error details:", errorText);
                // Handle failure - show error message to the user
                toast.error(t("ErrorUploadingFileToSortCode"));
            }
        } catch (error) {
            // An error occurred during the fetch operation
            console.error("Error uploading file to AccountCards API:", error.message);
            toast.error(t("ErrorUploadingFileToSortCode"));
        }
        setLoading(false);
    };

    // Function to upload to the second API
    const uploadFileToPalCode = async (file) => {
        setLoading(true);
        try {
            // Call the upload service method, passing the caseId, file, and auth token
            const response = await EnumsService.UploadFileData("PalCodes", user.accountantId, file, token);

            if (response.ok) {
                toast.success(t("FileUploadedSuccessfullyToPalCode"));
                // Handle success - perhaps update state or notify the user
                await fetchEnums(token, user);
            } else {
                // The server responded with an error status
                console.error("File upload failed with response status:", response.status);
                const errorText = await response.text();
                console.error("Error details:", errorText);
                // Handle failure - show error message to the user
                toast.error(t("ErrorUploadingFileToPalCode"));
            }
        } catch (error) {
            // An error occurred during the fetch operation
            console.error("Error uploading file to Palcode API:", error.message);
            toast.error(t("ErrorUploadingFileToPalCode"));
        }
        setLoading(false);
    };

    const uploadFileToPalCodeCategory = async (file) => {
        setLoading(true);
        try {
            // Call the upload service method, passing the caseId, file, and auth token
            const response = await EnumsService.UploadFileData("PalCodeCategories", user.accountantId, file, token);

            if (response.ok) {
                toast.success(t("FileUploadedSuccessfullyToPalCodeCategory"));
                // Handle success - perhaps update state or notify the user
                await fetchEnums(token, user);
            } else {
                // The server responded with an error status
                console.error("File upload failed with response status:", response.status);
                const errorText = await response.text();
                console.error("Error details:", errorText);
                // Handle failure - show error message to the user
                toast.error(t("ErrorUploadingFileToPalCodeCategory"));
            }
        } catch (error) {
            // An error occurred during the fetch operation
            console.error("Error uploading file to PalCodeCategory API:", error.message);
            toast.error(t("ErrorUploadingFileToPalCodeCategory"));
        }
        setLoading(false);
    };

    const uploadFileToAccountingGroup = async (file) => {
        setLoading(true);
        try {
            // Call the upload service method, passing the caseId, file, and auth token
            const response = await EnumsService.UploadFileData("PalCodeAccountingGroups", user.accountantId, file, token);

            if (response.ok) {
                toast.success(t("FileUploadedSuccessfullyToAccountingGroup"));
                // Handle success - perhaps update state or notify the user
                await fetchEnums(token, user);
            } else {
                // The server responded with an error status
                console.error("File upload failed with response status:", response.status);
                const errorText = await response.text();
                console.error("Error details:", errorText);
                // Handle failure - show error message to the user
                toast.error(t("ErrorUploadingFileToAccountingGroup"));
            }
        } catch (error) {
            // An error occurred during the fetch operation
            console.error("Error uploading file to AccountingGroup API:", error.message);
            toast.error(t("ErrorUploadingFileToAccountingGroup"));
        }
        setLoading(false);
    };
    const areFieldsValid = (fields) => {
        return fields.every(field => field && field.trim() !== '');
    };

    const fetchEnums = async (token, user) => {
        setLoading(true);
        try {
            // Initiate all fetch requests in parallel
            const [
                sortCodeResponse,
                sortCodeGroupResponse,
                otherDocumentsTypeResponse,
                palCodeResponse,
                palCodeCategoryResponse,
                palCodeCategoryAccountingGroupResponse
            ] = await Promise.all([
                EnumsService.GetSortCodesByAccountantId(user.accountantId, token),
                EnumsService.GetSortCodesGroupByAccountantId(user.accountantId, token),
                EnumsService.GetOtherDocumentsTypesByAccountantId(user.accountantId, token),
                EnumsService.GetPalCodesByAccountantId(user.accountantId, token),
                EnumsService.GetPalCodeCategoriesByAccountantId(user.accountantId, token),
                EnumsService.GetPalCodeAccountingGroupsByAccountantId(user.accountantId, token)
            ]);

            // Parse all JSON responses in parallel
            const [
                sortCodeData,
                sortCodeGroupData,
                otherDocumentsData,
                palCodeData,
                palCodeCategoryData,
                palCodeCategoryAccountingGroupData
            ] = await Promise.all([
                sortCodeResponse.json(),
                sortCodeGroupResponse.json(),
                otherDocumentsTypeResponse.json(),
                palCodeResponse.json(),
                palCodeCategoryResponse.json(),
                palCodeCategoryAccountingGroupResponse.json()
            ]);


            // Set state with the fetched data
            setSortCodesOptions(sortCodeData);
            setSortCodesGroupOptions(sortCodeGroupData);
            setOtherDocumentsTypeOptions(otherDocumentsData);
            setPalCodeOptions(palCodeData);
            setPalCodeCategoryOptions(palCodeCategoryData);
            setPalCodeAccountingGroupOptions(palCodeCategoryAccountingGroupData);

            // toast.success(t("Data has been fetched"));
        } catch (error) {
            console.error("Error fetching enum data:", error);
            toast.error(`${t("Error fetching enum data")}: ${error}`);
        }
        setLoading(false);
    };


    const fetchClientsEmployeeCount = async (token, user) => {
        setLoading(true);
        try {
            // Fetching caseTypeOptions
            const ClientCountResponse = await ClientService.GetClientCounts(token, user.accountantId);
            setClientsCount(ClientCountResponse); // Transform this data if needed

            const SubAccountantCountResponse = await SubAccountantService.GetSubAccountantCounts(token, user.accountantId);
            setEmployeCount(SubAccountantCountResponse); // Transform this data if needed

        } catch (error) {
            console.error("Error fetching enum data:", error);
            toast.error(`${t("Error fetching clients and accountants data")}: ${error}`)
        }
        setLoading(false);
    };

    const handleDeleteClick = (itemId, type) => {
        setItemIdToDelete(itemId);
        setActionType(type);
        setIsModalVisible(true);
    };

    const handleConfirm = async () => {
        setIsModalVisible(false);

        if (actionType === 'SortCodeDelete') {
            await handleSortCodeDeleteOptions(itemIdToDelete);
        }
        else if (actionType === 'OtherDocumentDelete') {
            handleOtherDocumentDeleteOptions(itemIdToDelete);
        } else if (actionType === 'SortCodeGroupDelete') {
            handleSortCodeGroupDeleteOptions(itemIdToDelete);
        }
        else if (actionType === 'PalCodeDelete') {
            handlePalCodeDelete(itemIdToDelete);
        } else if (actionType === 'PalCodeCategoryDelete') {
            handlePalCodeCategoryDelete(itemIdToDelete);
        } else if (actionType === 'AccountingGroupDelete') {
            handlePalCodeAccountingGroupDelete(itemIdToDelete);
        }
    };


    const CustomInput = React.forwardRef(({ value, onClick }, ref) => (
        <button className="ac-custom-input" onClick={onClick} ref={ref}>
            {value}
        </button>
    ));

    const init = async () => {
        setLoading(true);
        try {
            const currentUser = UserService.getCurrentUser();
            const currentToken = UserService.getCurrentToken();
            const userType = await UserService.getCurrentUserType();
            if (currentUser && currentToken) {
                setUser(currentUser);
                setToken(currentToken);
                setName(currentUser.name);
                setPhoneNumber(currentUser.phoneNumber);
                setEmail(currentUser.email);
                setNewPassword('');
                setOldPassword('');
                await fetchEnums(currentToken, currentUser);
                await fetchClientsEmployeeCount(currentToken, currentUser);
                if (userType == 2) {
                    setIsAccountant(true)
                }
                // toast.success(t("Data has been fetched"))
            } else {
                navigate("/login"); // Redirect to login if no user or token
            }
        } catch (error) {
            console.error("Error initializing user data", error);
            toast.error(`${t("Error fetching data")}: ${error}`)
        }
        setLoading(false);
    };

    useEffect(() => {
        init();
    }, []);




    const { hasBack, handleBack } = useBack();


    const handleGoBack = () => {
        // Navigate back to the previous page using React Router
        handleBack();
    };

    const handleEditProfile = () => {
        setIsEditing(true);
    };



    const handleSave = async (editedProfile=false) => {
        setLoading(true);
        try {
            // Validation check for password fields
            if ((!newPassword && oldPassword) || (newPassword && !oldPassword)) {
                toast.error("Please fill in either new password or old password, but not both.")
                return; // Exits the function if validation fails
            }

            const editedItem = {
                AccountantId: user.accountantId, // Always include AccountantId
                ...(isAccountant ? {} : { SubAccountantId: user.subAccountantId }), // Conditionally include SubAccountantId
                PhoneNumber: phoneNumber,
                Name: name,
                Password: newPassword,
                OldPassword: oldPassword,
                Email: email,
            };
            const response = isAccountant ? await UserService.EditUser(token, editedItem) : await UserService.EditSubAccountant(token, editedItem);
            if (response.ok) {
                const response1 = isAccountant ? await UserService.UpdateUser(user.accountantId, token) : await UserService.GetSubAccountant(user.subAccountantId, token);
                if (response1) {
                    await init();
                }
                toast.success(t("Accounting data has been updated successfully"))

            } else {
                const errorText = await response.text();
                console.error(errorText);
                toast.error(`${t("Error on save new accountant data")}: ${errorText}`)
            }
        } catch (error) {
            console.error(error);
            toast.error(`${t("Error on save new accountant data")}: ${error}`)
        }
        setIsEditing(false);
        setLoading(false);

        if (editedProfile) {
            window.location.reload();
        }
    };


    const savePropOtherDocumentsTypeEdit = async () => {
        if (editedItem) {
            await handlePropOtherDocumentsTypeEditSave(
                editedItem.oldOtherDocumentTypeName,
                editedItem.otherDocumentTypeName
            );
            setEditedItem({
                ...editedItem,
                oldOtherDocumentTypeName: '',
                otherDocumentTypeName: ''
            });
        }
        setIsPropOtherDocumentsTypeEditing(false);
    };

    const handlePropOtherDocumentsTypeEditSave = async (oldOtherDocumentsTypeName, otherDocumentsTypeName) => {
        setLoading(true);
        try {
            let ResponseOk = true;
            const areOkValid = areFieldsValid([user.accountantId, otherDocumentsTypeName]);

            if (areOkValid) {
                // Replace with the appropriate API call for OtherDocumentsType
                const response = await EnumsService.UpdateOtherDocumentsType(user.accountantId, otherDocumentsTypeName, oldOtherDocumentsTypeName, token);
                ResponseOk = response.ok;
                if (!response.ok) {
                    const errorText = await response.text();
                    console.error("Error updating OtherDocumentsType:", errorText);
                    toast.error(`${t("Error on save OtherDocumentsType data")}: ${errorText}`);
                }
            }

            if (ResponseOk) {
                toast.success(t("OtherDocumentsType data has been saved successfully"));
                // Fetch updated enums or perform other necessary updates
                await fetchEnums(token, user);
                setSelectedOtherDocumentsType([]);
            }

        } catch (error) {
            console.error(error);
            toast.error(t("Error on save OtherDocumentsType data"));
        }
        setLoading(false);
    };

    const savePropSortCodeGroupEdit = async () => {
        if (editedItem) {
            await handlePropSortCodeGroupEditSave(
                editedItem.editSortCodeGroupId,
                editedItem.sortCodeGroupName,
                editedItem.sortCodeGroupListNumber,
                editedItem.profitName
            );
            setEditedItem({
                ...editedItem,
                editSortCodeGroupId: '',
                sortCodeGroupName: '',
                sortCodeGroupListNumber: '',
                profitName: ''
            });
            setSelectedSortCodeGroup(null);
        }
        setIsPropSortCodeGroupEditing(false);
    };

    const handlePropSortCodeGroupEditSave = async (Id, SortCodeGroup, SortCodeGroupListNumber, sortCodeGroupProfitName) => {
        setLoading(true);
        try {
            let ResponseOk = true;
            const areOkValid = areFieldsValid([user.accountantId, SortCodeGroup]);

            if (areOkValid) {
                // Replace with the appropriate API call for OtherDocumentsType
                const response = await EnumsService.UpdateSortCodeGroup(user.accountantId, SortCodeGroup, parseInt(SortCodeGroupListNumber), sortCodeGroupProfitName, Id, token);
                ResponseOk = response.ok;
                if (!response.ok) {
                    const errorText = await response.text();
                    console.error("Error updating SortCodeGroupEdit:", errorText);
                    toast.error(`${t("Error on save result account category data")}: ${errorText}`);
                }
            }

            if (ResponseOk) {
                toast.success(t("Result account data has been saved successfully"));
                // Fetch updated enums or perform other necessary updates
                await fetchEnums(token, user);
            }

        } catch (error) {
            console.error(error);
            toast.error(t("Error on save result account category data"));
        }
        setLoading(false);
    };


    const handlePropOtherDocumentsTypeSave = async () => {
        setLoading(true);
        try {
            let ResponseOk = true;
            const areOkValid = areFieldsValid([user.accountantId, otherDocumentsType]);

            // Make the account card API call if fields are valid
            if (areOkValid) {
                setIsPropOtherDocumentsTypeAdding(false);

                const response1 = await EnumsService.AddOtherDocumentsType(user.accountantId, otherDocumentsType, token);
                ResponseOk = response1.ok;
                if (!response1.ok) {
                    const errorText = await response1.text();
                    console.error("Error adding OtherDocuments:", errorText);
                    toast.error(`${t("Error on save OtherDocumentsType data")}: ${errorText}`);
                }
            }

            // If any API call was successful, fetch enums
            if (ResponseOk) {
                toast.success(t("OtherDocumentsType data has been saved successfully"));
                await fetchEnums(token, user);
            }

        } catch (error) {
            toast.error(t("Error on save OtherDocumentsType data"));
            console.error(error);
        }
        setOtherDocumentsType('');
        setLoading(false);
    };

    // {
    //     sortCodeGroupName: 'asd',
    //     sortCodeGroupListNumber: '132',
    //     profitName: 'asd',
    //     editSortCodeGroup: 'asd',
    //     sortCodeListNumber: '312',
    //     sortCodeGroupProfitName: 'asdsssss'
    //   }
    const handlePropSortCodeGroupSave = async () => {

        setLoading(true);

        try {
            let ResponseOk = true;
            const areOkValid = areFieldsValid([user.accountantId, sortCodeGroup.sortCodeGroupName, sortCodeGroup.sortCodeGroupListNumber]);

            // Make the account card API call if fields are valid
            if (areOkValid) {
                setIsPropSortCodeGroupAdding(false);

                const response1 = await EnumsService.AddSortCodeGroup(user.accountantId, sortCodeGroup.sortCodeGroupName, parseInt(sortCodeGroup.sortCodeGroupListNumber), sortCodeGroup.profitName, token);
                ResponseOk = response1.ok;
                if (!response1.ok) {
                    const errorText = await response1.text();
                    console.error("Error adding SortCodeGroup:", errorText);
                    toast.error(`${t("Error on save result account category data")}: ${errorText}`);
                }
            }

            // If any API call was successful, fetch enums
            if (ResponseOk) {
                toast.success(t("Result account data has been saved successfully"));
                await fetchEnums(token, user);
            }

        } catch (error) {
            toast.error(t("Error on save result account category data"));
            console.error(error);
        }
        setSortCodeGroup({
            sortCodeGroupName: '',
            sortCodeGroupListNumber: '',
            profitName: '',
        })
        setLoading(false);
    };


    const handleCancel = () => {
        // Optionally reset changes
        setIsEditing(false);
        setName(user.name);
        setPhoneNumber(user.phoneNumber);
        setEmail(user.email);
        setNewPassword('');
        setOldPassword('');
    };

    const formattedSortCodesOptions = sortCodesOptions?.map(option => ({

        SortingCode: option.sortCodeId,
        'SortCode Name': option.sortCodeName,
        sortCodeGroup: option.sortCodeGroup,
        'Credit Account': option.creditAccount,
        'Debit Account': option.debitAccount,
        sortCodeVatType: t(Constants.SortCodeVatType[option.sortCodeVatType]),
        onEdit: () => handleEdit(option, "SortCodeEdit"),
        onDelete: () => handleDelete(option, "SortCodeDelete"),
    }));


    const formattedCaseTypeOptions = Constants.CaseTypeOptions?.map(option => ({
        value: option.name,
        label: `${option.name}`,
        name: option.name,
    }));

    const formattedChecksTypeOptions = Constants.CheckTypeOptions?.map(option => ({
        value: option.name,
        label: `${option.name}`,
        name: option.name,
    }));

    const formattedAccountManagementOptions = Constants.AccountManagementOptions?.map(option => ({
        value: option.name,
        label: `${option.name}`,
        name: option.name,
    }));

    const formattedPalCodeOptions = palCodeOptions?.map(option => ({

        value: option.id,
        BalanceClassificationName: option.palCodeId,
        BalanceClassificationType: option.palCodeType,
        BalanceClassificationCategory: option.palCodeCategory,
        onEdit: () => handleEdit(option, "PalCodeEdit"),
        onDelete: () => handleDelete(option, "PalCodeDelete"),
    }));


    const formattedOtherDocumentsTypeOptions = otherDocumentsTypeOptions?.map(option => ({
        value: option.id, // Assuming each option has a unique 'id' property
        OtherDocumentsType: option.name,
        onEdit: () => handleEdit(option, "OtherDocumentEdit"), // Pass the option to your edit handler
        onDelete: () => handleDelete(option, "OtherDocumentDelete"), // Pass the option to your delete handler
    }));

    const formattedSortCodesGroupOptions = sortCodesGroupOptions?.map(option => ({
        value: option.id,
        'sortCodeGroup': option.sortCodeGroupName || option.sortCodeGroup,
        'SortCodeGroupListNumber': option.sortCodeGroupListNumber || option.sortCodeListNumber,
        'ProfitName': option.profitName || option.sortCodeGroupProfitName,
        onEdit: () => handleEdit(option, "SortCodeGroupEdit"),
        onDelete: () => handleDelete(option, "SortCodeGroupDelete"),
    }));

    const formattedPalCodeAccountingGroupOptions = palCodeAccountingGroupOptions?.map(option => ({
        value: option.accountingGroupName,
        BalanceClassificationCategory: option.accountingGroupName,
        SerialNumber: option.orderNumber,
        Attribute: option.accountingGroupClassifaction,
        onEdit: () => handleEdit(option, "AccountingGroupEdit"),
        onDelete: () => handleDelete(option, "AccountingGroupDelete"),
    }));


    const formattedSortCodesGroupNamesOptions = sortCodesGroupOptions?.map(option => ({
        name: option.sortCodeGroup,
        value: option.sortCodeGroup,
        label: `${option.sortCodeGroup}`,

    }));
    const formattedSortCodeVatType = Object.entries(Constants.SortCodeVatType).map(([key, value]) => ({
        name: t(value),   // Using the string value (e.g., 'NotParticipating') as the name
        value: key,    // Using the number (e.g., '0') as the value
        label: t(value)   // Using the string value (e.g., 'NotParticipating') as the label
    }));

    const formattedPalCodeCategoryOptions = palCodeCategoryOptions?.map(option => ({
        value: option.palCodeCategoryId,
        BalanceClassificationCategory: option.palCodeCategoryId,
        SerialNumber: option.orderNumber,
        AccountingGroup: option.accountingGroup,
        onEdit: () => handleEdit(option, "PalCodeCategoryEdit"),
        onDelete: () => handleDelete(option, "PalCodeCategoryDelete"),
    }));


    const CustomSortCodeOption = (props) => {
        return (
            <components.Option {...props}>
                <div style={{ display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'space-between', overflowX: 'auto' }}>
                    <div style={{ display: 'flex', alignItems: 'center', width: '80%', justifyContent: 'space-between', overflowX: 'auto' }}>
                        <div style={{ textAlign: 'center', width: '16%' }}>{props.data.sortCodeDebit}</div>
                        <div style={{ textAlign: 'center', width: '16%' }}>{props.data.sortCodeCredit}</div>
                        <div style={{ textAlign: 'center', width: '16%' }}>{props.data.sortCodeId}</div>
                        <div style={{ textAlign: 'center', width: '16%' }}>{props.data.sortCodeName}</div>
                        <div style={{ textAlign: 'center', width: '16%' }}>{props.data.sortCodeGroup}</div>
                    </div>
                    <div >
                        <button
                            onClick={(e) => {
                                e.stopPropagation(); // Prevents the select dropdown from closing
                                // Handle delete logic here
                                // handleSortCodeDeleteOptions(props.data.sortCodeId);
                                handleDeleteClick(props.data.sortCodeId, 'SortCodeDelete')
                            }}
                            style={{ marginLeft: 'auto', border: 'none', background: 'none' }}
                        >
                            <img src={deleteIcon} alt="Income" className="props-details-icon" />
                        </button>
                        <button
                            onClick={(e) => {
                                e.stopPropagation(); // Prevents the select dropdown from closing
                                // Handle delete logic here
                                setEditedItem({
                                    ...editedItem, originalSortCodeId: props.data.sortCodeId, sortCodeId: props.data.sortCodeId,
                                    sortCodeName: props.data.sortCodeName, sortCodeGroup: props.data.sortCodeGroup
                                    , sortCodeCredit: props.data.sortCodeCredit,
                                    sortCodeDebit: props.data.sortCodeDebit
                                })
                                setIsPropSortCodeEditing(true);
                            }}
                            style={{ marginLeft: 'auto', border: 'none', background: 'none' }}
                        >
                            <img src={editIcon} alt="Income" className="props-details-icon" />
                        </button>
                    </div>
                </div>
            </components.Option>
        );
    };

    const CustomSortCodesMenuList = (props) => {
        return (
            <components.MenuList {...props}>
                <div style={{ display: 'flex', width: '100%', backgroundColor: '#f7f7f7', justifyContent: 'space-between', alignItems: 'center' }}>
                    <div style={{ display: 'flex', alignItems: 'start', width: '100%', justifyContent: 'space-between', overflowX: 'auto' }}>
                        <div style={{ textAlign: 'left' }}>{t("Debit Account")}</div>
                        <div style={{ textAlign: 'left' }}>{t("Credit Account")}</div>
                        <div style={{ textAlign: 'left' }}>{t("SortingCode")}</div>
                        <div style={{ textAlign: 'left' }}>{t("SortCode Name")}</div>
                        <div style={{ textAlign: 'left' }}>{t("sortCodeGroupName")}</div>

                    </div>
                    <div style={{ textAlign: 'left' }}></div>
                </div>
                {props.children}
            </components.MenuList>
        );
    };

    const handleOtherDocumentDeleteOptions = async (otherDocumentId) => {
        setLoading(true);
        try {
            let ResponseOk = true;
            // Make the account card API call if fields are valid
            const response1 = await EnumsService.DeleteOtherDocumentbyId(otherDocumentId, token);
            ResponseOk = response1.ok;
            if (!response1.ok) {
                const errorText = await response1.text();
                console.error(`Error delete otherDocumentId`, errorText);
                toast.error(`${t("Error on delete OtherDocument data")}: ${errorText}`);
            }

            // If any API call was successful, fetch enums
            if (ResponseOk) {
                await fetchEnums(token, user);
                setSelectedOtherDocumentsType([]);
                toast.success(t("OtherDocument data has been deleted successfully"));
            }

        } catch (error) {
            toast.error(t("Error on delete OtherDocument data"));
            console.error(error);
        }
        setItemIdToDelete(null);
        setLoading(false);
    };

    const handleSortCodeGroupDeleteOptions = async (sortCodeGroupId) => {
        setLoading(true);
        try {
            let ResponseOk = true;
            // Make the account card API call if fields are valid
            const response1 = await EnumsService.DeleteSortCodeGroupById(user.accountantId, sortCodeGroupId, token);
            ResponseOk = response1.ok;
            if (!response1.ok) {
                const errorText = await response1.text();
                console.error(`Error delete result account`, errorText);
                toast.error(`${t("Error delete result account")}: ${errorText}`);
            }

            // If any API call was successful, fetch enums
            if (ResponseOk) {
                await fetchEnums(token, user);
                toast.success(t("Result account data has been deleted successfully"));
            }

        } catch (error) {
            toast.error(t("Error delete result account"));
            console.error(error);
        }
        setItemIdToDelete(null);
        setSelectedSortCodeGroup(null);
        setLoading(false);
    };

    const handlePalCodeDelete = async (palCodeId) => {
        setLoading(true);
        try {
            let ResponseOk = true;
            // Make the account card API call if fields are valid
            const response1 = await EnumsService.DeletePalCodeDelete(user.accountantId, palCodeId, token);
            ResponseOk = response1.ok;
            if (!response1.ok) {
                const errorText = await response1.text();
                console.error(`BalanceClassificationNotDeletedSuccessfully`, errorText);
                toast.error(`${t("BalanceClassificationNotDeletedSuccessfully")}: ${errorText}`);
            }

            // If any API call was successful, fetch enums
            if (ResponseOk) {
                await fetchEnums(token, user);
                toast.success(t("BalanceClassificationDeletedSuccessfully"));
            }

        } catch (error) {
            toast.error(t("BalanceClassificationNotDeletedSuccessfully"));
            console.error(error);
        }
        setItemIdToDelete(null);
        setSelectedPalCode(null);

        setLoading(false);
    };

    const handlePalCodeCategoryDelete = async (PalCodeCategoryId) => {
        setLoading(true);
        try {
            let ResponseOk = true;
            // Make the account card API call if fields are valid
            const response1 = await EnumsService.DeletePalCodeCategoriesDelete(user.accountantId, PalCodeCategoryId, token);
            ResponseOk = response1.ok;
            if (!response1.ok) {
                const errorText = await response1.text();
                console.error(`BalanceClassificationCategoryNotDeletedSuccessfully`, errorText);
                toast.error(`${t("BalanceClassificationCategoryNotDeletedSuccessfully")}: ${errorText}`);
            }

            // If any API call was successful, fetch enums
            if (ResponseOk) {

                toast.success(t("BalanceClassificationCategoryDeletedSuccessfully"));
            }

        } catch (error) {
            toast.error(t("BalanceClassificationCategoryNotDeletedSuccessfully"));
            console.error(error);
        }
        await fetchEnums(token, user);
        setItemIdToDelete(null);
        setSelectedPalCodeCategory(null);
        setLoading(false);
    };





    const handleSubAccountantsClick = () => {
        if (isAccountant) {
            navigate("/SubAccountants");
        }
        // You can add an else block if you want to handle the false case
    };



    const handleEdit = (item, editType) => {


        switch (editType) {
            case 'SortCodeEdit':
                setIsPropSortCodeEditing(true);
                setEditedItem({
                    ...editedItem,
                    originalSortCodeId: item.sortCodeId,
                    sortCodeId: item.sortCodeId,
                    sortCodeName: item.sortCodeName,
                    sortCodeGroup: item.sortCodeGroup,
                    sortCodeCredit: item.creditAccount,
                    sortCodeDebit: item.debitAccount,
                    sortCodeVatType: item.sortCodeVatType?.toString()
                });
                break;
            case 'OtherDocumentEdit':
                setEditedItem({
                    ...editedItem,
                    oldOtherDocumentTypeName: item.name,
                    otherDocumentTypeName: item.name
                });
                setIsPropOtherDocumentsTypeEditing(true);
                break;
            case 'SortCodeGroupEdit':
                setEditedItem({
                    ...editedItem,
                    sortCodeGroupName: item.sortCodeGroup,
                    editSortCodeGroupId: item.id,
                    sortCodeGroupListNumber: item.sortCodeListNumber,
                    profitName: item.sortCodeGroupProfitName
                });
                setIsPropSortCodeGroupEditing(true);
                break;
            case 'PalCodeEdit':
                setEditedItem({
                    ...editedItem,
                    palCodeId: item.palCodeId,
                    oldPalCodeId: item.palCodeId,
                    palCodeType: item.palCodeType,
                    palCodeCategory: item.palCodeCategory,
                });
                setIsPropPalCodeEditing(true);
                break;
            case 'PalCodeCategoryEdit':
                setEditedItem({
                    ...editedItem,
                    palCodeCategoryId: item.palCodeCategoryId,
                    oldPalCodeCategoryId: item.palCodeCategoryId,
                    accountingGroup: item.accountingGroup || "",
                    orderNumber: item.orderNumber || "",
                });
                setIsPropPalCodeCategoryEditing(true);
                break;
            case 'AccountingGroupEdit':
                setEditedItem({
                    ...editedItem,
                    accountingGroupName: item.accountingGroupName,
                    oldAccountingGroupName: item.accountingGroupName,
                    accountingGroupClassifaction: item.accountingGroupClassifaction || "",
                    orderNumber: item.orderNumber || "",

                });
                setIsPropPalCodeAccountingGroupEditing(true);
                break;
            default:
                break;
        }
    };

    const handleDelete = (item, deleteType) => {

        switch (deleteType) {
            case 'SortCodeDelete':
                handleDeleteClick(item.sortCodeId, 'SortCodeDelete')
                break;
            case 'OtherDocumentDelete':
                handleDeleteClick(item.id, "OtherDocumentDelete")
                break;
            case 'SortCodeGroupDelete':
                handleDeleteClick(item.id, "SortCodeGroupDelete");
                break;
            case 'PalCodeDelete':
                handleDeleteClick(item.palCodeId, "PalCodeDelete");
                break;
            case 'PalCodeCategoryDelete':
                handleDeleteClick(item.palCodeCategoryId, "PalCodeCategoryDelete");
                break;
            case 'AccountingGroupDelete':
                handleDeleteClick(item.accountingGroupName, "AccountingGroupDelete");
                break;
            default:
                break;
        }
    };



    const handlePropPalCodeSave = async () => {
        setLoading(true);


        try {
            let ResponseOk = true;
            const newPalCode = {
                palCodeId: palCode.palCodeId,
                palCodeType: palCode.palCodeType,
                palCodeCategory: palCode.palCodeCategory,
                accountantId: user.accountantId, // Replace with your actual accountant ID
            };
            setIsPropPalCodeAdding(false);
            // Make the account card API call if fields are valid


            const response1 = await EnumsService.AddPalCode(newPalCode, token);
            ResponseOk = response1.ok;
            if (!response1.ok) {
                const errorText = await response1.text();
                console.error("BalanceClassificationNotAddedSuccessfully:", errorText);
                toast.error(`${t("BalanceClassificationNotAddedSuccessfully")}: ${errorText}`);
            }

            // If any API call was successful, fetch enums
            if (ResponseOk) {
                toast.success(t("BalanceClassificationAddedSuccessfully"));

            }

        } catch (error) {
            toast.error(t("BalanceClassificationNotAddedSuccessfully"));
            console.error(error);
        }
        setPalCode({
            palCodeType: '',
            palCodeId: '',
            palCodeCategory: null,
        });
        await fetchEnums(token, user);
        setLoading(false);
    };



    const savePalCodeEdit = async () => {
        setLoading(true);
        try {
            let ResponseOk = true;
            const updatedPalCode = {
                ...editedItem,
                accountantId: user.accountantId, // Replace with your actual accountant ID
            };
            setIsPropPalCodeEditing(false);
            // Make the account card API call if fields are valid


            const response1 = await EnumsService.UpdatePalCode(updatedPalCode, token);
            ResponseOk = response1.ok;
            if (!response1.ok) {
                const errorText = await response1.text();
                console.error("BalanceClassificationCategoryNotUpdatedSuccessfully:", errorText);
                toast.error(`${t("BalanceClassificationCategoryNotUpdatedSuccessfully")}: ${errorText}`);
            }

            // If any API call was successful, fetch enums
            if (ResponseOk) {
                toast.success(t("BalanceClassificationUpdatedSuccessfully"));
                await fetchEnums(token, user);
            }

        } catch (error) {
            toast.error(t("BalanceClassificationNotUpdatedSuccessfully"));
            console.error(error);
        }
        setEditedItem({
            ...editedItem,
            palCodeId: '',
            oldPalCodeId: '',
            palCodeType: '',
            originalSortCodeId: '',
            palCodeClassification: '',
        });
        setSelectedPalCode(null);
        setLoading(false);
    };


    const handlePropPalCodeCategorySave = async () => {
        setLoading(true);

        try {
            let ResponseOk = true;
            const newPalCode = {
                PalCodeCategoryId: palCodeCategory.palCodeCategoryId,
                AccountingGroup: palCodeCategory.accountingGroup,
                accountantId: user.accountantId, // Replace with your actual accountant ID
                orderNumber: palCodeCategory.orderNumber
            };
            setIsPropPalCodeCategoryAdding(false);
            // Make the account card API call if fields are valid


            const response1 = await EnumsService.AddPalCodeCategory(newPalCode, token);
            ResponseOk = response1.ok;
            if (!response1.ok) {
                const errorText = await response1.text();
                console.error("BalanceClassificationCategoryNotAddedSuccessfully:", errorText);
                toast.error(`${t("BalanceClassificationCategoryNotAddedSuccessfully")}: ${errorText}`);
            }

            // If any API call was successful, fetch enums
            if (ResponseOk) {
                toast.success(t("BalanceClassificationCategoryAddedSuccessfully"));

            }

        } catch (error) {
            toast.error(t("BalanceClassificationCategoryNotAddedSuccessfully"));
            console.error(error);
        }
        setNewPalCodeCategory('');
        setOrderNumberCategory('');
        await fetchEnums(token, user);
        setPalCodeCategory({
            palCodeCategoryId: '',
            orderNumber: '',
            accountingGroup: null,
        })
        setLoading(false);
    };



    const savePalCodeCategoryEdit = async () => {
        setLoading(true);
        try {
            let ResponseOk = true;
            const updatedPalCodeCategory = {
                ...editedItem,
                accountantId: user.accountantId, // Replace with your actual accountant ID
            };
            setIsPropPalCodeCategoryEditing(false);
            // Make the account card API call if fields are valid


            const response1 = await EnumsService.UpdatePalCodeCategory(updatedPalCodeCategory, token);
            ResponseOk = response1.ok;
            if (!response1.ok) {
                const errorText = await response1.text();
                console.error("BalanceClassificationCategoryNotUpdatedSuccessfully:", errorText);
                toast.error(`${t("BalanceClassificationCategoryNotUpdatedSuccessfully")}: ${errorText}`);
            }

            // If any API call was successful, fetch enums
            if (ResponseOk) {
                toast.success(t("BalanceClassificationCategoryUpdatedSuccessfully"));
                await fetchEnums(token, user);
            }

        } catch (error) {
            toast.error(t("BalanceClassificationCategoryNotUpdatedSuccessfully"));
            console.error(error);
        }
        setEditedItem({
            ...editedItem,
            palCodeCategoryName: '',
        });
        setSelectedPalCodeCategory(null);

        setLoading(false);
    };


    const handlePropPalCodeAccountingGroupSave = async () => {
        setLoading(true);


        try {
            let ResponseOk = true;
            const newPalCodeGroupClassifaction = {
                AccountingGroupName: accountingGroup.accountingGroupName,
                AccountingGroupClassifaction: accountingGroup.accountingGroupClassifaction,
                OrderNumber: accountingGroup.orderNumber,
                accountantId: user.accountantId, // Replace with your actual accountant ID
            };
            setIsPropPalCodeAccountingGroupAdding(false);
            // Make the account card API call if fields are valid


            const response1 = await EnumsService.AddPalCodeAccountingGroups(newPalCodeGroupClassifaction, token);
            ResponseOk = response1.ok;
            if (!response1.ok) {
                const errorText = await response1.text();
                console.error("AccountingGroupNotAddedSuccessfully:", errorText);
                toast.error(`${t("AccountingGroupNotAddedSuccessfully")}: ${errorText}`);
            }

            // If any API call was successful, fetch enums
            if (ResponseOk) {
                toast.success(t("AccountingGroupAddedSuccessfully"));

            }

        } catch (error) {
            toast.error(t("AccountingGroupNotAddedSuccessfully"));
            console.error(error);
        }
        setAccountingGroupName('');
        setOrderNumber('');
        setAccountingGroupClassifaction('');
        await fetchEnums(token, user);
        setLoading(false);
    };



    const savePalCodeAccountingGroupEdit = async () => {
        setLoading(true);
        try {
            let ResponseOk = true;
            const updatedPalCodeAccountingGroups = {
                ...editedItem,
                accountantId: user.accountantId, // Replace with your actual accountant ID
            };
            setIsPropPalCodeAccountingGroupEditing(false);
            // Make the account card API call if fields are valid


            const response1 = await EnumsService.UpdatePalCodeAccountingGroups(updatedPalCodeAccountingGroups, token);
            ResponseOk = response1.ok;
            if (!response1.ok) {
                const errorText = await response1.text();
                console.error("AccountingGroupNotUpdatedSuccessfully:", errorText);
                toast.error(`${t("AccountingGroupNotUpdatedSuccessfully")}: ${errorText}`);
            }

            // If any API call was successful, fetch enums
            if (ResponseOk) {
                toast.success(t("AccountingGroupUpdatedSuccessfully"));
                await fetchEnums(token, user);
            }

        } catch (error) {
            toast.error(t("AccountingGroupNotUpdatedSuccessfully"));
            console.error(error);
        }
        setEditedItem({
            ...editedItem,
            accountingGroupName: "",
            oldAccountingGroupName: "",
            accountingGroupClassifaction: "",
            orderNumber: "",

        });
        setSelectedPalCodeAccountingGroup(null);
        setLoading(false);
    };

    const handlePalCodeAccountingGroupDelete = async (AccountingGroupName) => {
        setLoading(true);
        try {
            let ResponseOk = true;
            // Make the account card API call if fields are valid
            const response1 = await EnumsService.DeletePalCodeAccountingGroups(user.accountantId, AccountingGroupName, token);
            ResponseOk = response1.ok;
            if (!response1.ok) {
                const errorText = await response1.text();
                console.error(`AccountingGroupNotDeletedSuccessfully`, errorText);
                toast.error(`${t("AccountingGroupNotDeletedSuccessfully")}: ${errorText}`);
            }

            // If any API call was successful, fetch enums
            if (ResponseOk) {

                toast.success(t("AccountingGroupDeletedSuccessfully"));
            }

        } catch (error) {
            toast.error(t("AccountingGroupNotDeletedSuccessfully"));
            console.error(error);
        }
        await fetchEnums(token, user);
        setItemIdToDelete(null);
        setSelectedPalCodeAccountingGroup(null);
        setLoading(false);
    };




    const [openProfileModal, setOpenProfileModal] = useState(false);

  
    return (
        <>
            <HamburgerMenu />

            <ProfilePictureDialog
                open={openProfileModal}
                onClose={() => setOpenProfileModal(false)}
                existingProfilePicture={user?.profilePicture}   // if available
                token={token}
                accountantId={user?.accountantId}
                saveFunc={handleSave}
            />

            <div className="template-page">
                <div className="template-container">
                    <UpperPanelDetails />

                    <div className={`ac-header`} style={{ flexDirection: direction === 'rtl' ? 'row-reverse' : 'row' }}>
                        <div className={`main-title ${direction}`}>{t('AccountantDetails')}</div>

                        <div className='backIconBackground' style={{ alignSelf: 'center' }} onClick={handleGoBack}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                                <path d="M20 21.1667L14.8267 15.9933L20 10.82C20.52 10.3 20.52 9.45999 20 8.93999C19.48 8.41999 18.64 8.41999 18.12 8.93999L12 15.06C11.48 15.58 11.48 16.42 12 16.94L18.12 23.06C18.64 23.58 19.48 23.58 20 23.06C20.5067 22.54 20.52 21.6867 20 21.1667Z" fill="black" fill-opacity="0.9" />
                            </svg>
                        </div>

                    </div>
                    <div style={{ display: 'flex', gap: "20px", justifyContent: direction === 'rtl' ? 'flex-end' : 'flex-start' }}>
                        <div className={`main-card-container ${direction}`}>
                            <div className={`main-card-icon ${direction}`}>
                                <img
                                    src={clientsIcon}
                                    alt="home"
                                />
                            </div>
                            <div className={`main-card-details ${direction}`}>
                                <div className={`main-card-details1 ${direction}`}>
                                    {t('clients')}
                                </div>
                                <div className={`main-card-details2 ${direction}`}>
                                    {beautifyNumber(clientsCount)}
                                </div>
                            </div>
                        </div>

                        <div className={`main-card-container ${direction}`}>
                            <div className={`main-card-icon ${direction}`}>
                                <img
                                    src={subAccountantsIcon}
                                    alt="home"
                                />
                            </div>
                            <div className={`main-card-details ${direction}`}>
                                <div className={`main-card-details1 ${direction}`}>
                                    {t('SubAccountants')}
                                </div>
                                <div className={`main-card-details2 ${direction}`}>
                                    {beautifyNumber(employeCount)}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={`profile-accountant-container ${direction}`}>
                        {/* Row 1: Title and Edit Icon */}
                        <div className="profile-title-row">
                            <div style={{display: 'flex', flexDirection: 'row', width: '100%', gap:'50px'}}>
                                <div className="profile-title">{t('profile')}</div>
                                <button className={'addClient-button'} style={{ width: '250px' }} onClick={() => setOpenProfileModal(true)}>
                                    <span>{t('AddOrUpdateProfilePicture')}</span>
                                </button>
                            </div>
                            <div className="profile-actions">
                                {!isEditing ? (
                                    <IconButton sx={{ width: '30px', height: '30px' }} onClick={handleEditProfile}>
                                        <img src={editIconImage} alt="Edit" />
                                    </IconButton>
                                ) : (
                                    <>
                                        {/* <button className="addClient-button" onClick={handleSave}>
                                            {t('save')}
                                        </button> */}
                                        <IconButton sx={{ width: '30px', height: '30px' }} onClick={handleCancel}>
                                            <Cancel sx={{ color: '#FC2807', width: '30px', height: '30px' }} />
                                        </IconButton>
                                    </>
                                )}
                            </div>
                        </div>

                        {/* Row 2: Client Name and AccountantId */}
                        <div className="form-row profile-full-width">
                            <div className="form-row-item">
                                <div className="form-row-item-title">
                                    {t('Client Name')}
                                </div>

                                <TextField
                                    fullWidth
                                    placeholder={t('Client Name')}
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                    className="add-client-input-style"
                                    variant="standard"
                                    InputProps={{
                                        disableUnderline: true,
                                        className: `custom-input-box-sizing ${direction === 'ltr' ? 'ltr-input' : 'rtl-input'}`,
                                        sx: {
                                            height: '48px',
                                            padding: '0 10px',
                                            borderRadius: '8px',
                                            background: '#F5F5F6',
                                            border: '1px solid transparent',
                                            '&:focus-within': {
                                                border: '2px solid var(--Foundation-Blue-Normal, #304FFF)',
                                            },
                                        },
                                    }}
                                    inputProps={{
                                        style: {
                                            textAlign: direction === 'ltr' ? 'left' : 'right',
                                        },
                                    }}
                                    disabled={!isEditing}
                                />
                            </div>
                            <div className="form-row-item">
                                <div className="form-row-item-title">
                                    {t('AccountantId')}
                                </div>

                                <TextField
                                    fullWidth
                                    placeholder={t('AccountantId')}
                                    value={isAccountant ? user.accountantId : user.subAccountantId}
                                    onChange={(e) => setName(e.target.value)}
                                    className="add-client-input-style"
                                    variant="standard"
                                    InputProps={{
                                        disableUnderline: true,
                                        className: `custom-input-box-sizing ${direction === 'ltr' ? 'ltr-input' : 'rtl-input'}`,
                                        sx: {
                                            height: '48px',
                                            padding: '0 10px',
                                            borderRadius: '8px',
                                            background: '#F5F5F6',
                                            border: '1px solid transparent',
                                            '&:focus-within': {
                                                border: '2px solid var(--Foundation-Blue-Normal, #304FFF)',
                                            },
                                        },
                                    }}
                                    inputProps={{
                                        style: {
                                            textAlign: direction === 'ltr' ? 'left' : 'right',
                                        },
                                    }}
                                    disabled={!isEditing}
                                />
                            </div>

                        </div>

                        {/* Row 3: phoneNumber and email */}
                        <div className="form-row profile-full-width">
                            <div className="form-row-item">
                                <div className="form-row-item-title">
                                    {t('phoneNumber')}
                                </div>

                                <TextField
                                    fullWidth
                                    placeholder={t('phoneNumber')}
                                    value={phoneNumber}
                                    onChange={(e) => setPhoneNumber(e.target.value)}
                                    className="add-client-input-style"
                                    variant="standard"
                                    InputProps={{
                                        disableUnderline: true,
                                        className: `custom-input-box-sizing ${direction === 'ltr' ? 'ltr-input' : 'rtl-input'}`,
                                        sx: {
                                            height: '48px',
                                            padding: '0 10px',
                                            borderRadius: '8px',
                                            background: '#F5F5F6',
                                            border: '1px solid transparent',
                                            '&:focus-within': {
                                                border: '2px solid var(--Foundation-Blue-Normal, #304FFF)',
                                            },
                                        },
                                    }}
                                    inputProps={{
                                        style: {
                                            textAlign: direction === 'ltr' ? 'left' : 'right',
                                        },
                                    }}
                                    disabled={!isEditing}

                                />
                            </div>
                            <div className="form-row-item">
                                <div className="form-row-item-title">
                                    {t('email')}
                                </div>

                                <TextField
                                    fullWidth
                                    placeholder={t('email')}
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    className="add-client-input-style"
                                    variant="standard"
                                    InputProps={{
                                        disableUnderline: true,
                                        className: `custom-input-box-sizing ${direction === 'ltr' ? 'ltr-input' : 'rtl-input'}`,
                                        sx: {
                                            height: '48px',
                                            padding: '0 10px',
                                            borderRadius: '8px',
                                            background: '#F5F5F6',
                                            border: '1px solid transparent',
                                            '&:focus-within': {
                                                border: '2px solid var(--Foundation-Blue-Normal, #304FFF)',
                                            },
                                        },
                                    }}
                                    inputProps={{
                                        style: {
                                            textAlign: direction === 'ltr' ? 'left' : 'right',
                                        },
                                    }}
                                    disabled={!isEditing}

                                />

                            </div>

                        </div>

                        {/* Row 4: enterOldPassword and enterPassword */}
                        {isEditing && (
                            <div className="form-row profile-full-width">
                                <div className="form-row-item">
                                    <div className="form-row-item-title">
                                        {t('enterOldPassword')}
                                    </div>
                                    <TextField
                                        fullWidth
                                        placeholder={t('enterOldPassword')}
                                        type="password"
                                        value={oldPassword}
                                        onChange={(e) => setOldPassword(e.target.value)}
                                        className="add-client-input-style"
                                        variant="standard"
                                        InputProps={{
                                            disableUnderline: true,
                                            className: `custom-input-box-sizing ${direction === 'ltr' ? 'ltr-input' : 'rtl-input'}`,
                                            sx: {
                                                height: '48px',
                                                padding: '0 10px',
                                                borderRadius: '8px',
                                                background: '#F5F5F6',
                                                border: '1px solid transparent',
                                                '&:focus-within': {
                                                    border: '2px solid var(--Foundation-Blue-Normal, #304FFF)',
                                                },
                                            },
                                        }}
                                        inputProps={{
                                            style: {
                                                textAlign: direction === 'ltr' ? 'left' : 'right',
                                            },
                                        }}
                                        disabled={!isEditing}

                                    />
                                </div>
                                <div className="form-row-item">
                                    <div className="form-row-item-title">
                                        {t('enterPassword')}
                                    </div>
                                    <TextField
                                        fullWidth
                                        placeholder={t('enterPassword')}
                                        type="password"
                                        value={newPassword}
                                        onChange={(e) => setNewPassword(e.target.value)}
                                        className="add-client-input-style"
                                        variant="standard"
                                        InputProps={{
                                            disableUnderline: true,
                                            className: `custom-input-box-sizing ${direction === 'ltr' ? 'ltr-input' : 'rtl-input'}`,
                                            sx: {
                                                height: '48px',
                                                padding: '0 10px',
                                                borderRadius: '8px',
                                                background: '#F5F5F6',
                                                border: '1px solid transparent',
                                                '&:focus-within': {
                                                    border: '2px solid var(--Foundation-Blue-Normal, #304FFF)',
                                                },
                                            },
                                        }}
                                        inputProps={{
                                            style: {
                                                textAlign: direction === 'ltr' ? 'left' : 'right',
                                            },
                                        }}
                                        disabled={!isEditing}

                                    />
                                </div>
                            </div>
                        )}

                        {/* Save Button */}
                        {isEditing && (
                            <div className="form-row">
                                <button className="addClient-button" onClick={handleSave}>
                                    <span>{t('save')}</span>
                                </button>
                            </div>
                        )}
                    </div>


                    <div className={`${direction}`} style={{ width: '100%', backgroundColor: "#EAEDFF", borderRadius: "8px", display: 'flex', justifyContent: 'space-around', padding: '16px', gap: '20px' }}>
                        <Select
                            className="add-client-input-style"
                            placeholder={t('Select CaseType')}
                            options={formattedCaseTypeOptions}
                            isSearchable={true}
                            onChange={(selectedOption) => {
                                setSelectedCaseType(selectedOption);
                            }}
                            value={selectedCaseType}
                            styles={customStyles}
                        />
                        <Select
                            className="add-client-input-style"
                            placeholder={t('Select CheckType')}
                            options={formattedChecksTypeOptions}
                            isSearchable={true}
                            onChange={(selectedOption) => {
                                setSelectedCheckType(selectedOption);
                            }}
                            value={selectedCheckType}
                            styles={customStyles}
                        />
                        <Select
                            className="add-client-input-style"
                            placeholder={t('SelectAccountManagement')}
                            options={formattedAccountManagementOptions}
                            isSearchable={true}
                            onChange={(selectedOption) => {
                                setSelectedAccountManagement(selectedOption);
                            }}
                            value={selectedAccountManagement}
                            styles={customStyles}
                        />
                    </div>
                    <div className='ac-properties-card-container'>

                        <div className={`ac-properties-card ${direction}`}>
                            <div className="ac-properties-card-title-button-container">
                                <div className="ac-properties-card-title">
                                    {t('OtherDocumentsType')}
                                </div>
                                <div className="ac-properties-card-add-button">
                                    <IconButton sx={{ width: '30px', height: '30px' }} onClick={() => setIsPropOtherDocumentsTypeAdding(true)}>
                                        <img src={addIconImage} alt="Add" />
                                    </IconButton>
                                </div>

                            </div>
                            <div className="form-row-item-title">
                                {t('Select OtherDocumentsType')}
                            </div>
                            {!isPropOtherDocumentsTypeAdding && !isPropOtherDocumentsTypeEditing ? (

                                <AutocompleteCardSelect
                                    options={formattedOtherDocumentsTypeOptions}
                                    selectedValues={selectedOtherDocumentsType}
                                    onChange={(event, newValue) => setSelectedOtherDocumentsType(newValue)}
                                    label={t("OtherDocumentsType")}
                                    placeholder={t('Select OtherDocumentsType')}
                                    isMultiple={false}
                                />
                            ) : (

                                isPropOtherDocumentsTypeAdding || isPropOtherDocumentsTypeEditing ? (
                                    <AccountantDetailsModals
                                        isPopupOpen={isPropOtherDocumentsTypeAdding || isPropOtherDocumentsTypeEditing}
                                        value={isPropOtherDocumentsTypeAdding ? otherDocumentsType : editedItem.otherDocumentTypeName}
                                        handleValueChange={isPropOtherDocumentsTypeAdding
                                            ? setOtherDocumentsType
                                            : (newValue) => setEditedItem({ ...editedItem, otherDocumentTypeName: newValue })
                                        }
                                        isEditMode={isPropOtherDocumentsTypeEditing}
                                        handleSaveChange={isPropOtherDocumentsTypeAdding
                                            ? handlePropOtherDocumentsTypeSave
                                            : savePropOtherDocumentsTypeEdit
                                        }
                                        handleClose={() => {
                                            setIsPropOtherDocumentsTypeAdding(false);
                                            setIsPropOtherDocumentsTypeEditing(false);
                                        }}
                                        contentKey={"OtherDocumentsType"}

                                    />
                                ) : null

                            )
                            }

                        </div>
                        <div className={`ac-properties-card ${direction}`}>
                            <div className="ac-properties-card-title-button-container">
                                <div className="ac-properties-card-title">
                                    {t('SortingCode')}
                                </div>
                                <div className="ac-properties-card-add-button">
                                    <input
                                        type="file"
                                        ref={fileInputRef2}
                                        style={{ display: 'none' }} // Hide the file input
                                    />
                                    <IconButton sx={{ width: '30px', height: '30px' }} onClick={() => setIsPropSortCodeAdding(true)}>
                                        <img src={addIconImage} alt="Add" />
                                    </IconButton>
                                    <IconButton sx={{ width: '30px', height: '30px' }} onClick={() => handleFileButtonClick(fileInputRef2, uploadFileToSortCode)}>
                                        <img src={importIconImage} alt="Import" />
                                    </IconButton>
                                </div>
                            </div>
                            <div className="form-row-item-title">
                                {t('Select SortCode')}
                            </div>
                            {!isPropSortCodeAdding && !isPropSortCodeEditing ? (
                                <AutocompleteCardSelect
                                    options={formattedSortCodesOptions}
                                    selectedValues={selectedSortCode}
                                    onChange={(event, newValue) => setSelectedSortCode(newValue)}
                                    label={t("SortingCode")}
                                    placeholder={t('Select SortCode')}
                                    isMultiple={false}
                                />
                            ) : (
                                <AccountantDetailsModals
                                    isPopupOpen={isPropSortCodeAdding || isPropSortCodeEditing}
                                    value={isPropSortCodeAdding ? sortCode : editedItem}
                                    handleValueChange={isPropSortCodeAdding ? setSortCode : setEditedItem}
                                    isEditMode={isPropSortCodeEditing}
                                    handleSaveChange={isPropSortCodeAdding ? handlePropSortCodeSave : saveSortCodeEdit}
                                    handleClose={() => {
                                        setIsPropSortCodeAdding(false);
                                        setIsPropSortCodeEditing(false);
                                    }}
                                    contentKey={"SortingCode"}
                                    formattedSortCodesGroupNamesOptions={formattedSortCodesGroupNamesOptions}
                                    formattedSortCodeVatType={formattedSortCodeVatType}
                                    direction={direction}
                                    t={t}
                                    handleEdit={handleEdit}
                                    handleDelete={handleDelete}
                                />
                            )}
                        </div>

                    </div>

                    <div className='ac-properties-card-container'>
                        <div className={`ac-properties-card ${direction}`}>
                            <div className="ac-properties-card-title-button-container">
                                <div className="ac-properties-card-title">
                                    {t('sortCodeGroup')}
                                </div>
                                <div className="ac-properties-card-add-button">
                                    <IconButton sx={{ width: '30px', height: '30px' }} onClick={() => setIsPropSortCodeGroupAdding(true)}>
                                        <img src={addIconImage} alt="Add" />
                                    </IconButton>
                                </div>
                            </div>
                            <div className="form-row-item-title">
                                {t('SelectSortCodeGroupName')}
                            </div>
                            {!isPropSortCodeGroupAdding && !isPropSortCodeGroupEditing ? (
                                <AutocompleteCardSelect
                                    options={formattedSortCodesGroupOptions}
                                    selectedValues={selectedSortCodeGroup}
                                    onChange={(event, newValue) => setSelectedSortCodeGroup(newValue)}
                                    label={t("sortCodeGroup")}
                                    placeholder={t('SelectSortCodeGroupName')}
                                    isMultiple={false}
                                />
                            ) : (
                                <AccountantDetailsModals
                                    isPopupOpen={isPropSortCodeGroupAdding || isPropSortCodeGroupEditing}
                                    value={isPropSortCodeGroupAdding ? sortCodeGroup : editedItem}
                                    handleValueChange={isPropSortCodeGroupAdding ? setSortCodeGroup : setEditedItem}
                                    isEditMode={isPropSortCodeGroupEditing}
                                    handleSaveChange={isPropSortCodeGroupAdding ? handlePropSortCodeGroupSave : savePropSortCodeGroupEdit}
                                    handleClose={() => {
                                        setIsPropSortCodeGroupAdding(false);
                                        setIsPropSortCodeGroupEditing(false);
                                    }}
                                    contentKey={"SortingCodeGroup"}
                                />
                            )}
                        </div>
                        <div className={`ac-properties-card ${direction}`}>
                            <div className="ac-properties-card-title-button-container">
                                <div className="ac-properties-card-title">
                                    {t('PalCode')}
                                </div>
                                <div className="ac-properties-card-add-button">
                                    <input
                                        type="file"
                                        ref={fileInputRef2}
                                        style={{ display: 'none' }} // Hide the file input
                                    />
                                    <IconButton sx={{ width: '30px', height: '30px' }} onClick={() => setIsPropPalCodeAdding(true)}>
                                        <img src={addIconImage} alt="Add" />
                                    </IconButton>
                                    <IconButton sx={{ width: '30px', height: '30px' }} onClick={() => handleFileButtonClick(fileInputRef2, uploadFileToPalCode)}>
                                        <img src={importIconImage} alt="Import" />
                                    </IconButton>
                                </div>
                            </div>
                            <div className="form-row-item-title">
                                {t('SelectBalanceClassification')}
                            </div>
                            {!isPropPalCodeAdding && !isPropPalCodeEditing ? (
                                <AutocompleteCardSelect
                                    options={formattedPalCodeOptions}
                                    selectedValues={selectedPalCode}
                                    onChange={(event, newValue) => setSelectedPalCode(newValue)}
                                    label={t("PalCode")}
                                    placeholder={t('SelectBalanceClassification')}
                                    isMultiple={false}
                                />
                            ) : (
                                <AccountantDetailsModals
                                    isPopupOpen={isPropPalCodeAdding || isPropPalCodeEditing}
                                    value={isPropPalCodeAdding ? palCode : editedItem}
                                    handleValueChange={isPropPalCodeAdding ? setPalCode : setEditedItem}
                                    isEditMode={isPropPalCodeEditing}
                                    handleSaveChange={isPropPalCodeAdding ? handlePropPalCodeSave : savePalCodeEdit}
                                    handleClose={() => {
                                        setIsPropPalCodeAdding(false);
                                        setIsPropPalCodeEditing(false);
                                    }}
                                    contentKey={"PalCode"}
                                    formattedPalCodeCategoryOptions={palCodeCategoryOptions}
                                    Constants={Constants}
                                    customStyles={customStyles}
                                    t={t}
                                    direction={direction}
                                />
                            )}
                        </div>

                    </div>

                    <div className='ac-properties-card-container'>
                        <div className={`ac-properties-card ${direction}`}>
                            <div className="ac-properties-card-title-button-container">
                                <div className="ac-properties-card-title">
                                    {t('BalanceClassificationCategory')}
                                </div>
                                <div className="ac-properties-card-add-button">
                                    <input
                                        type="file"
                                        ref={fileInputRef2}
                                        style={{ display: 'none' }} // Hide the file input
                                    />
                                    <IconButton sx={{ width: '30px', height: '30px' }} onClick={() => setIsPropPalCodeCategoryAdding(true)}>
                                        <img src={addIconImage} alt="Add" />
                                    </IconButton>
                                    <IconButton sx={{ width: '30px', height: '30px' }} onClick={() => handleFileButtonClick(fileInputRef2, uploadFileToPalCodeCategory)}>
                                        <img src={importIconImage} alt="Import" />
                                    </IconButton>
                                </div>
                            </div>
                            <div className="form-row-item-title">
                                {t('SelectBalanceClassificationCategory')}
                            </div>
                            {!isPropPalCodeCategoryAdding && !isPropPalCodeCategoryEditing ? (
                                <AutocompleteCardSelect
                                    options={formattedPalCodeCategoryOptions}
                                    selectedValues={selectedPalCodeCategory}
                                    onChange={(event, newValue) => setSelectedPalCodeCategory(newValue)}
                                    label={t("BalanceClassificationCategory")}
                                    placeholder={t('SelectBalanceClassificationCategory')}
                                    isMultiple={false}
                                />
                            ) : (
                                <AccountantDetailsModals
                                    isPopupOpen={isPropPalCodeCategoryAdding || isPropPalCodeCategoryEditing}
                                    value={isPropPalCodeCategoryAdding ? palCodeCategory : editedItem}
                                    handleValueChange={isPropPalCodeCategoryAdding ? setPalCodeCategory : setEditedItem}
                                    isEditMode={isPropPalCodeCategoryEditing}
                                    handleSaveChange={isPropPalCodeCategoryAdding ? handlePropPalCodeCategorySave : savePalCodeCategoryEdit}
                                    handleClose={() => {
                                        setIsPropPalCodeCategoryAdding(false);
                                        setIsPropPalCodeCategoryEditing(false);
                                    }}
                                    contentKey={"PalCodeCategory"}
                                    formattedPalCodeAccountingGroupOptions={palCodeAccountingGroupOptions}
                                    customStyles={customStyles}
                                    t={t}
                                    direction={direction}
                                />
                            )}
                        </div>

                        <div className={`ac-properties-card ${direction}`}>
                            <div className="ac-properties-card-title-button-container">
                                <div className="ac-properties-card-title">
                                    {t('AccountingGroup')}
                                </div>
                                <div className="ac-properties-card-add-button">
                                    <input
                                        type="file"
                                        ref={fileInputRef2}
                                        style={{ display: 'none' }} // Hide the file input
                                    />
                                    <IconButton sx={{ width: '30px', height: '30px' }} onClick={() => setIsPropPalCodeAccountingGroupAdding(true)}>
                                        <img src={addIconImage} alt="Add" />
                                    </IconButton>
                                    <IconButton sx={{ width: '30px', height: '30px' }} onClick={() => handleFileButtonClick(fileInputRef2, uploadFileToAccountingGroup)}>
                                        <img src={importIconImage} alt="Import" />
                                    </IconButton>
                                </div>
                            </div>
                            <div className="form-row-item-title">
                                {t('SelectAccountingGroup')}
                            </div>
                            {!isPropPalCodeAccountingGroupAdding && !isPropPalCodeAccountingGroupEditing ? (
                                <AutocompleteCardSelect
                                    options={formattedPalCodeAccountingGroupOptions}
                                    selectedValues={selectedPalCodeAccountingGroup}
                                    onChange={(event, newValue) => setSelectedPalCodeAccountingGroup(newValue)}
                                    label={t("AccountingGroup")}
                                    placeholder={t('SelectAccountingGroup')}
                                    isMultiple={false}
                                />
                            ) : (
                                <AccountantDetailsModals
                                    isPopupOpen={isPropPalCodeAccountingGroupAdding || isPropPalCodeAccountingGroupEditing}
                                    value={isPropPalCodeAccountingGroupAdding ? accountingGroup : editedItem}
                                    handleValueChange={isPropPalCodeAccountingGroupAdding ? setAccountingGroup : setEditedItem}
                                    isEditMode={isPropPalCodeAccountingGroupEditing}
                                    handleSaveChange={isPropPalCodeAccountingGroupAdding ? handlePropPalCodeAccountingGroupSave : savePalCodeAccountingGroupEdit}
                                    handleClose={() => {
                                        setIsPropPalCodeAccountingGroupAdding(false);
                                        setIsPropPalCodeAccountingGroupEditing(false);
                                    }}
                                    contentKey={"AccountingGroup"}
                                    Constants={Constants}
                                    customStyles={customStyles}
                                    t={t}
                                    direction={direction}
                                />
                            )}
                        </div>


                    </div>

                </div>
                <CustomDeleteConfirmationModal
                    isVisible={isModalVisible}
                    onClose={() => { setIsModalVisible(false); setItemIdToDelete(null); }}
                    onDelete={handleConfirm}
                />
                <ToastContainer />
                {loading && (
                    <div className="loader-container">
                        <PuffLoader
                            size={100}        // Larger size for better visibility
                            loading={loading}
                        />
                    </div>
                )}
            </div>
        </>
    );
};

export default AccountantDetails;
