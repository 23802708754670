import React from 'react';
import { useTranslation } from 'react-i18next';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { beautifyNumber } from '../../../Utils/FormatNumber';

const BalanceControlDialog = ({ open, onClose, balanceControlData, client }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    // Helper function to calculate start and end dates of the month
    const getMonthStartAndEnd = (monthYear) => {
        const [month, year] = monthYear.split('/').map(Number);
        const fullYear = year < 100 ? 2000 + year : year; // Handle two-digit year as 2024
        const start = new Date(fullYear, month - 1, 1); // First day of the month
        const end = new Date(fullYear, month, 0); // Last day of the month
        return { start, end };
    };

    const viewDocumentDetails = (type, start, end) => {
        navigate('/Document', { state: { type, client, start, end } });
    };

    const viewJournalEntryDetails = (type, start, end) => {
        navigate('/JournalEntry', { state: { type, client, start, end } });
    };

    const viewPaymentAcceptanceDetails = (type, start, end) => {
        navigate('/PaymentAcceptance', { state: { type, client, start, end } });
    };

    return (
        <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth dir="rtl">
            <DialogTitle style={{ textAlign: 'right' }}>{t('BalanceControl')}</DialogTitle>
            <DialogContent>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow sx={{ height: '40px', backgroundColor: '#e0e0e0' }}>
                                <TableCell align="left" sx={{ padding: '6px', lineHeight: '1.2' }}>
                                    <strong>{t('MonthYear')}</strong>
                                </TableCell>
                                <TableCell align="left" sx={{ padding: '6px', lineHeight: '1.2' }}>
                                    <strong>{t('Incomes')}</strong>
                                </TableCell>
                                <TableCell align="left" sx={{ padding: '6px', lineHeight: '1.2' }}>
                                    <strong>{t('Expenses')}</strong>
                                </TableCell>
                                <TableCell align="left" sx={{ padding: '6px', lineHeight: '1.2' }}>
                                    <strong>{t('JournalEntries')}</strong>
                                </TableCell>
                                <TableCell align="left" sx={{ padding: '6px', lineHeight: '1.2' }}>
                                    <strong>{t('PaymentAcceptance')}</strong>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {balanceControlData.map((row, index) => {
                                const { start, end } = getMonthStartAndEnd(row.monthYear);
                                return (
                                    <TableRow key={index} sx={{ height: '30px' }}>
                                        <TableCell align="left" sx={{ padding: '6px', lineHeight: '1.2' }}>
                                            {row.monthYear}
                                        </TableCell>
                                        <TableCell
                                            align="left"
                                            sx={{
                                                padding: '6px',
                                                lineHeight: '1.2',
                                                cursor: 'pointer',
                                                '&:hover': { backgroundColor: '#f0f0f0' },
                                            }}
                                            onClick={() => viewDocumentDetails('Incomes', start, end)}
                                        >
                                            {beautifyNumber(row.income)}
                                        </TableCell>
                                        <TableCell
                                            align="left"
                                            sx={{
                                                padding: '6px',
                                                lineHeight: '1.2',
                                                cursor: 'pointer',
                                                '&:hover': { backgroundColor: '#f0f0f0' },
                                            }}
                                            onClick={() => viewDocumentDetails('Expenses', start, end)}
                                        >
                                            {beautifyNumber(row.expense)}
                                        </TableCell>
                                        <TableCell
                                            align="left"
                                            sx={{
                                                padding: '6px',
                                                lineHeight: '1.2',
                                                cursor: 'pointer',
                                                '&:hover': { backgroundColor: '#f0f0f0' },
                                            }}
                                            onClick={() => viewJournalEntryDetails('JournalEntries', start, end)}
                                        >
                                            {beautifyNumber(row.journalEntries)}
                                        </TableCell>
                                        <TableCell
                                            align="left"
                                            sx={{
                                                padding: '6px',
                                                lineHeight: '1.2',
                                                cursor: 'pointer',
                                                '&:hover': { backgroundColor: '#f0f0f0' },
                                            }}
                                            onClick={() => viewPaymentAcceptanceDetails("PaymentAcceptances", start, end)}
                                        >
                                            {beautifyNumber(row.paymentAcceptance)}
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                            {/* Sum Row */}
                            <TableRow sx={{ height: '30px' }}>
                                <TableCell align="left" sx={{ padding: '6px', lineHeight: '1.2' }}>
                                    <strong>{t('Total')}</strong>
                                </TableCell>
                                <TableCell align="left" sx={{ padding: '6px', lineHeight: '1.2' }}>
                                    <strong>
                                        {beautifyNumber(balanceControlData.reduce((sum, row) => sum + row.income, 0))}
                                    </strong>
                                </TableCell>
                                <TableCell align="left" sx={{ padding: '6px', lineHeight: '1.2' }}>
                                    <strong>
                                        {beautifyNumber(balanceControlData.reduce((sum, row) => sum + row.expense, 0))}
                                    </strong>
                                </TableCell>
                                <TableCell align="left" sx={{ padding: '6px', lineHeight: '1.2' }}>
                                    <strong>
                                        {beautifyNumber(balanceControlData.reduce((sum, row) => sum + row.journalEntries, 0))}
                                    </strong>
                                </TableCell>
                                <TableCell align="left" sx={{ padding: '6px', lineHeight: '1.2' }}>
                                    <strong>
                                        {beautifyNumber(balanceControlData.reduce((sum, row) => sum + row.paymentAcceptance, 0))}
                                    </strong>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </DialogContent>
            <DialogActions style={{ justifyContent: 'flex-start' }}>
                <Button onClick={onClose} color="primary">
                    {t('Close')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default BalanceControlDialog;
