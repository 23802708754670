import React, { useState, useEffect, useRef, useMemo, memo } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import HamburgerMenu from '../HamburgerMenu/HamburgerMenu';
import Select from 'react-select';
import { FixedSizeList as List, FixedSizeListProps } from 'react-window';
import AutoSizer, { Size } from 'react-virtualized-auto-sizer';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { registerLocale, setDefaultLocale } from "react-datepicker";
import enGB from 'date-fns/locale/en-GB';
import { useTranslation } from 'react-i18next';
import DocumentService from '../../Services/documents.service';
import UserService from '../../Services/user.service';
import backIcon from '../../assests/images/left-arrow.png';
import vatImage from '../../assests/images/VATReport.jpg';
import TaxAdvancesReportImage from '../../assests/images/TaxAdvancesReport.jpg';
import calendarIcon from '../../assests/images/calendar.png';
import EnumsService from '../../Services/enums.service';
import CustomDeleteConfirmationModal from '../CustomDeleteConfirmationModal/CustomDeleteConfirmationModal'
import { ToastContainer, toast } from "react-toastify";
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import FormControlLabel from '@mui/material/FormControlLabel';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import PuffLoader from "react-spinners/PuffLoader";
import ClientService from '../../Services/client.service';

//mui imports
import IconButton from '@mui/material/IconButton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Collapse from '@mui/material/Collapse';
import Typography from '@mui/material/Typography';
import { Edit as EditIcon } from '@mui/icons-material';
import { Button, Modal, Box, DialogTitle, ListItem, TextField } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';
import dayjs, { Dayjs } from 'dayjs';
import DownloadIconMui from '@mui/icons-material/Download';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import CustomMonthDatePicker from '../Widgets/CustomDates/CustomMonthDatePicker/CustomMonthDatePicker';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Switch from '@mui/material/Switch';
import { beautifyNumber } from '../../Utils/FormatNumber';
import DateInput from '../DateInput/DateInput';
import CircularProgress, {
    circularProgressClasses,
} from '@mui/material/CircularProgress';
import Constants from '../../assests/Constants/constants';
import '../PaymentAcceptance/PaymentAcceptance.css';
import './ReportTracking.css';
import SubAccountantService from '../../Services/subAccountant.service';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import MuiSelect from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import zIndex from '@mui/material/styles/zIndex';
import TaxAuthorityService from '../../Services/taxAuthority.service';
import UpperPanelDetails from '../Widgets/UpperPanelDetails/UpperPanelDetails';
import AutocompleteSelect from '../Widgets/AutocompleteSelect/AutocompleteSelect';
import FilterOptionMenu from '../Widgets/FilterOptionMenu/FilterOptionMenu';
import editIconImage from "../../assests/images/Icons/editIcon.svg";
import { getVatValue } from '../../Utils/GetVatValue';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
            zIndex: 1000
        },
    },
};

const formatDate = (date) => {
    const day = ('0' + date.getDate()).slice(-2);
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const year = date.getFullYear();
    return `${month}/${year}`;
};

const formatTableDate = (startDateString, endDateString) => {
    const startDate = new Date(startDateString);
    const endDate = new Date(endDateString);



    const formattedStartDate = formatDate(startDate);
    const formattedEndDate = formatDate(endDate);

    if (startDate.getMonth() === endDate.getMonth()) {
        return formattedStartDate;
    } else {
        return `${formattedStartDate} - ${formattedEndDate}`;
    }
};

const sampleVatReport = {
    clientName: 'Client Name',
    clientLocation: 'Client Location',
    clientCaseId: '12345',
    VATEquipment: '1000',
    VATExpenses: '2000',
    VATTransactions: '3000',
    VATNet: '4000',
    IncomesNoVats: '5000',
    IncomesNoZeroVats: '6000',
    StartDate: new Date('2024-01-01'),
    EndDate: new Date('2024-06-30')
};

const sampleWithholdingTax = {
    clientName: 'Client Name',
    clientLocation: 'Client Location',
    clientCaseId: '12345',
    AcceptedWithholdingTax: '1000',
    AcceptedWithholdingTaxMaximum: '1500',
    incomeTaxAdvancePercentage: '5',
    IncomeTaxAdvances: '2000',
    total: '3000',
    IncomesNoVats: '4000',
    StartDate: new Date('2024-01-01'),
    EndDate: new Date('2024-06-30')
};

function ReportTracking() {
    const navigate = useNavigate();
    const { i18n, t } = useTranslation();
    const [user, setUser] = useState(null);
    const [token, setToken] = useState('');
    const [data, setData] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [focused, setFocused] = useState(false);
    const [sortDate, setSortDate] = useState(() => {
        const storedDateString = localStorage.getItem('Document' + 'ReportTracking' + '_Date');
        return storedDateString ? new Date(storedDateString) : new Date();
    });
    const [loading, setLoading] = useState(false);
    const [startDate, setStartDate] = useState(() => {
        const storedDateString = localStorage.getItem('Document' + 'ReportTracking' + '_StartDate');
        return storedDateString ? new Date(storedDateString) : new Date();
    });; // Start date for range
    const [endDate, setEndDate] = useState(() => {
        const storedDateString = localStorage.getItem('Document' + 'ReportTracking' + '_EndDate');
        return storedDateString ? new Date(storedDateString) : new Date();
    });; // End date for range
    const [isRange, setIsRange] = useState(() => {
        const storedIsRange = localStorage.getItem('Document' + 'ReportTracking' + '_IsRange');
        return storedIsRange === "true";
    });;

    const [reportingWay, setReportingWay] = useState('unreported');
    const [isFirstUserFetched, setIsFirstUserFetched] = useState(false);
    const [actionType, setActionType] = useState('delete');
    const [sortConfig, setSortConfig] = useState({ key: 'uploadNumber', direction: 'descending', type: 'number' });
    const [subAccountants, setSubAccountants] = useState([]);
    const [optionSelected, setOptionSelected] = useState(() => {
        const savedOptions = localStorage.getItem('optionSelected');
        return savedOptions ? JSON.parse(savedOptions) : [];
    });

    const [subAccountantMap, setSubAccountantMap] = useState({});
    const [subAccountantName, setSubAccountantName] = React.useState([]);
    const [selectedStatus, setSelectedStatus] = useState([]);
    const [selectedReportingType, setSelectedReportingType] = useState([]);
    const [openVatModal, setOpenVatModal] = useState(false);
    const [openWithholdingTaxModal, setOpenWithholdingTaxModal] = useState(false);
    const [selectedReportTrackingType, setSelectedReportTrackingType] = useState('VATReport');

    const [vatReport, setVatReport] = useState({});
    const [currentItem, setCurrentItem] = useState({});
    const [withholdingTax, setWithholdingTax] = useState({});
    const vatContentRef = useRef(null);
    const withholdingContentRef = useRef(null);
    const [direction, setDirection] = useState('rtl'); // Default to 'rtl'
    const [selectedFilterOptions, setSelectedFilterOptions] = useState([]);

    const optionGroups = [
        {
            title: t('status'),
            selectionType: 'multi', // Allows multiple selections
            options: Constants.ReportTrackingStatus.slice(1).map((status) => ({
                id: status,
                label: t(status)
            }))
        },
        {
            title: t('MonthlyBiMonthly'),
            selectionType: 'multi', // Allows multiple selections
            options: Constants.ReportingTypeOptions.map((option) => ({
                id: option.value,
                label: option.name
            }))
        },
        {
            title: t('ClientReportType'),
            selectionType: 'single', // Allows only single selection
            options: Constants.ReportTrackingType.map((type) => ({
                id: type,
                label: t(type)
            }))
        }
    ];


    useEffect(() => {
        setSelectedFilterOptions([
            ...selectedStatus,
            ...selectedReportingType,
            selectedReportTrackingType,
        ]);
    }, [selectedStatus, selectedReportingType, selectedReportTrackingType]);


    const handleFilterOptionChange = (newSelectedOptions) => {


        // Check if `newSelectedOptions` is undefined or empty
        if (!newSelectedOptions || newSelectedOptions.length === 0) {
            console.warn("newSelectedOptions is empty or undefined");
            return;
        }



        // Proceed with setting states as per the logic
        setSelectedFilterOptions(newSelectedOptions);

        // Separate selections for each group
        const statusOptions = Constants.ReportTrackingStatus.slice(1).map((status) => status);
        const reportingTypeOptions = Constants.ReportingTypeOptions.map((option) => option.value);
        const reportTrackingTypeOptions = Constants.ReportTrackingType.map((type) => type);

        const newStatus = newSelectedOptions
            .filter((option) => statusOptions.includes(option))
            .map((status) => status); // Translate each status option
        const newReportingType = newSelectedOptions.filter((option) => reportingTypeOptions.includes(option));
        const newReportTrackingType = newSelectedOptions.find((option) => reportTrackingTypeOptions.includes(option)) || 'VATReport';


        setSelectedStatus(newStatus);
        setSelectedReportingType(newReportingType);
        setSelectedReportTrackingType(newReportTrackingType);
    };




    const handleSubAccChange = (event, newValue) => {

        // Extract labels from the selected options
        const labelsArray = newValue.map(option => option.label);

        // Set the labels array to setSubAccountantName
        setSubAccountantName(labelsArray);

        // Optionally, keep setting the selected option
        setOptionSelected(newValue);
    };

    useEffect(() => {
        if (i18n.language === 'he' || i18n.language === 'ar') {
            setDirection('rtl');
        } else {
            setDirection('ltr');
        }
    }, [i18n.language]);

    // TaxAuthority
    const [taxAuthorityData, setTaxAuthorityData] = useState({
        authUrl: '',
        resumeTaxAuthorityVatOperation: false,
        resumeTaxAuthorityAdvancesOperation: false,
        UpdatedAdd: {}
    });

    const [isModalVisible, setIsModalVisible] = useState(false);


    const handleChangeVatReport = (field, value) => {
        setVatReport(prevReport => {
            value = (parseFloat(value) || 0).toFixed(2).toString();

            const updatedReport = {
                ...prevReport,
                [field]: value
            };

            // Calculate VAT for IncomesNoVats if the updated field is 'IncomesNoVats'
            if (field === "IncomesNoVats") {
                
                const incomesNoVatsValue = parseFloat(value) || 0;
                const calculatedVatForIncomes = incomesNoVatsValue * getVatValue(sortDate); // Calculate VAT
                updatedReport.VATTransactions = calculatedVatForIncomes.toFixed(2).toString();
            }

            // Calculate the new VATNet value
            const vatNet = (parseFloat(updatedReport.VATTransactions) || 0) -
                ((parseFloat(updatedReport.VATEquipment) || 0) +
                    (parseFloat(updatedReport.VATExpenses) || 0));

            updatedReport.VATNet = vatNet.toFixed(2).toString(); // Ensure VATNet is stored as a string
            return updatedReport;
        });
    };

    const updateReportedFields = (updatedData, filteredAndSortedData) => {
        return filteredAndSortedData.map(item => {
            if (item.caseId === updatedData.caseId) {
                return {
                    ...item,
                    reportedVATTransactions: updatedData.vatTransactions,
                    reportedOtherVATTaxes: updatedData.otherVATTaxes,
                    reportedEquipmentVAT: updatedData.equipmentVAT,
                    reportedNetVAT: updatedData.netVAT,
                    reportedAcceptedWithholdingTax: updatedData.acceptedWithholdingTax,
                    reportedPaymentWithholdingTax: updatedData.paymentWithholdingTax,
                    id: updatedData.id,
                    status: updatedData.status,
                    withholdingTaxStatus: updatedData.withholdingTaxStatus,
                    reportedIncomesNoVat: updatedData.incomesNoVat,
                    reportedIncomesNoZeroVats: updatedData.incomesNoZeroVats,
                    reportedIncomeTaxAdvancePercentage: updatedData.incomeTaxAdvancePercentage,
                    reportedIncomeTaxAdvanceAmount: updatedData.incomeTaxAdvanceAmount,
                    reportedWitholdingIncomesNoVat: updatedData.witholdingIncomesNoVat
                };
            }
            return item;
        });
    };

    const handleSaveReportTaxManually = async (reportTax, isSaveVatReport) => {
        try {
            let response;

            if (reportingWay === 'unreported') {
                if (isSaveVatReport) {
                    reportTax.status = 2;
                } else {
                    reportTax.WithholdingTaxStatus = 2;
                }
            } else {
                if (isSaveVatReport) {
                    reportTax.status = 1;
                } else {
                    reportTax.WithholdingTaxStatus = 1;
                }
            }
            if (reportTax.id) {
                // Call update function if id is present
                response = await ClientService.UpdateReportedTax(reportTax, token);
            } else {
                // Call create function if id is not present
                response = await ClientService.CreateReportedTax(reportTax.caseId, reportTax, reportTax.startDate, reportTax.endDate, token);
            }

            if (response && response.ok) {
                const responseData = await response.json();
                // Update the filteredAndSortedData with the new reported values
                setData(prevData => updateReportedFields(responseData, prevData));
                toast.success(t('ReportedTaxUpdatedSuccess'));

            } else {
                toast.error(t('ReportedTaxUpdateFailed'));
            }
        } catch (error) {
            console.error(t('Error saving reported tax:'), error);
            toast.error(t('ReportedTaxSaveFailed'));
        }
    };
    const startAuthCompletionCheck = async (authWindow, scope) => {
        let attempts = 0;
        const maxAttempts = 25;

        const checkAuthCompletion = async () => {
            try {
                const result = await TaxAuthorityService.checkAuthCompletionAccountant(user.accountantId, scope, token);
                if (result.completed) {
                    setTaxAuthorityData((prevState) => {
                        let newState = { ...prevState };

                        if (scope === 'VatReport') {
                            newState = { ...newState, resumeTaxAuthorityVatOperation: true };
                        } else if (scope === 'AdvancesPayment') {
                            newState = { ...newState, resumeTaxAuthorityAdvancesOperation: true };
                        }

                        return newState;
                    });
                    authWindow.close();
                } else {
                    attempts += 1;
                    if (attempts < maxAttempts) {
                        setTimeout(checkAuthCompletion, 3000); // Check every 3 seconds
                    } else {
                        setLoading(false);
                        console.error('Token retrieval failed after maximum attempts.');
                        toast.error('Token retrieval failed after maximum attempts.');
                        authWindow.close();
                    }
                }
            } catch (error) {
                console.error('Error checking auth completion:', error);
                toast.error('Error checking auth completion');
                authWindow.close();
                setLoading(false);
            }
        };

        checkAuthCompletion();
    };
    const handleSaveVatReport = async () => {

        const UpdatedAdd = {
            id: vatReport.id,
            caseId: vatReport.clientCaseId,
            startDate: vatReport.StartDate,
            endDate: vatReport.EndDate,
            VATTransactions: vatReport.VATTransactions,
            OtherVATTaxes: vatReport.VATExpenses,
            EquipmentVAT: vatReport.VATEquipment,
            NetVAT: vatReport.VATNet,
            IncomesNoVat: vatReport.IncomesNoVats,
            IncomesNoZeroVats: vatReport.IncomesNoZeroVats,
            status: currentItem.status,
            WithholdingTaxStatus: currentItem.withholdingTaxStatus
        }

        setTaxAuthorityData((prevState) => ({ ...prevState, UpdatedAdd }));

        if (reportingWay === 'automatic') {
            setActionType("VatReport")
            setIsModalVisible(true);
            // try {
            // const tokenData = await TaxAuthorityService.requestTokenAccountant(user.accountantId, Constants.TaxAuthorityScopes['VatReport'], token);

            //     if (tokenData?.authUrl) {
            //         setTaxAuthorityData((prevState) => ({
            //             ...prevState,
            //             authUrl: tokenData.authUrl,
            //             resumeTaxAuthorityVatOperation: false
            //         }));
            //         const authWindow = window.open(tokenData.authUrl, '_blank');
            //         setOpenVatModal(false);
            //         startAuthCompletionCheck(authWindow, Constants.TaxAuthorityScopes['VatReport']);
            //         return; // Exit the function here to wait for the token creation process
            //     } else if (tokenData?.accessToken) {
            //         await TaxAuthoritySendVatReport(UpdatedAdd, token);

            //     }
            // } catch (error) {
            //     console.error('Error fetching token:', error);
            //     toast.error(t('ErrorFetchingToken'));
            //     setLoading(false);
            //     return;
            // }

        }
        else {
            await handleSaveReportTaxManually(UpdatedAdd, true);
            handleCloseVatModal();
        }
    };


    const handleSaveWithholdingTaxReport = async () => {
        
        const UpdatedAdd = {
            id: withholdingTax.id,
            caseId: withholdingTax.clientCaseId,
            startDate: withholdingTax.StartDate,
            endDate: withholdingTax.EndDate,
            acceptedWithholdingTax: parseFloat(withholdingTax.AcceptedWithholdingTaxMaximum),
            witholdingIncomesNoVat: parseFloat(withholdingTax.witholdingIncomesNoVat),
            status: currentItem.status,
            WithholdingTaxStatus: currentItem.withholdingTaxStatus,
            incomeTaxAdvanceAmount: parseFloat(withholdingTax.incomeTaxAdvanceAmount),
            incomeTaxAdvancePercentage: parseFloat(withholdingTax.incomeTaxAdvancePercentage)
        }
        setTaxAuthorityData((prevState) => ({ ...prevState, UpdatedAdd }));

        if (reportingWay === 'automatic') {
            setActionType("TaxAdvancesReport")
            setIsModalVisible(true);
            // try {

            //     const tokenData = await TaxAuthorityService.requestTokenAccountant(user.accountantId, Constants.TaxAuthorityScopes['AdvancesPayment'], token);

            //     if (tokenData?.authUrl) {
            //         setTaxAuthorityData((prevState) => ({
            //             ...prevState,
            //             authUrl: tokenData.authUrl,
            //             resumeTaxAuthorityAdvancesOperation: false
            //         }));
            //         const authWindow = window.open(tokenData.authUrl, '_blank');
            //         setOpenVatModal(false);
            //         startAuthCompletionCheck(authWindow, Constants.TaxAuthorityScopes['AdvancesPayment']);
            //         return; // Exit the function here to wait for the token creation process
            //     } else if (tokenData?.accessToken) {
            //         await TaxAuthoritySendAdvancesReport(UpdatedAdd, token);

            //     }
            // } catch (error) {
            //     console.error('Error fetching token:', error);
            //     toast.error(t('ErrorFetchingToken'));
            //     setLoading(false);
            //     return;
            // }
        }
        else {
            await handleSaveReportTaxManually(UpdatedAdd, false);
            handleCloseWithholdingTaxModal();
        }

    };

    const handleChangeWithholdingTax = (field, value) => {
        setWithholdingTax(prevState => ({
            ...prevState,
            [field]: value
        }));
    };
    const handleMuiSubAccountantChange = (event) => {
        const {
            target: { value },
        } = event;

        if (value.includes('selectAll')) {
            if (subAccountantName.length === subAccountants.length) {
                setSubAccountantName([]);
            } else {
                setSubAccountantName(subAccountants.map((item) => item.label));
            }
        } else {
            setSubAccountantName(
                typeof value === 'string' ? value.split(',') : value,
            );
        }
    };

    const handleStatusChange = (event) => {
        const {
            target: { value },
        } = event;

        if (value.includes('selectAll')) {
            if (selectedStatus.length === Constants.ReportTrackingStatus.slice(1).length) {
                setSelectedStatus([]);
            } else {
                setSelectedStatus(Constants.ReportTrackingStatus.slice(1).map((status) => t(status)));
            }
        } else {
            setSelectedStatus(
                typeof value === 'string' ? value.split(',') : value,
            );
        }
    };

    const handleReportTrackingTypeChange = (event) => {

        setSelectedReportTrackingType(event.target.value);
    };

    const handleReportingTypeChange = (event) => {
        const {
            target: { value },
        } = event;

        if (value.includes('selectAll')) {
            if (selectedReportingType.length === Constants.ReportingTypeOptions.length) {
                setSelectedReportingType([]);
            } else {
                setSelectedReportingType(Constants.ReportingTypeOptions.map((option) => option.value));
            }
        } else {
            setSelectedReportingType(
                typeof value === 'string' ? value.split(',') : value,
            );
        }
    };

    const isAllSelected = subAccountantName.length === subAccountants.length;
    const isAllSelectedStatus = selectedStatus.length === Constants.ReportTrackingStatus.slice(1).length;
    const isAllSelectedReportingType = selectedReportingType.length === Constants.ReportingTypeOptions.length;

    const calculateIncomeTaxAdvances = (incomesNoVat, acceptedWithholdingTax, incomeTaxAdvancePercentage, useIncomeTaxAdvancePercentage, IncomeTaxAdvanceAmount) => {
        let total = (incomesNoVat * (incomeTaxAdvancePercentage / 100)) || 0;
        if (!useIncomeTaxAdvancePercentage) {
            total = IncomeTaxAdvanceAmount;
        }
        let IncomeTaxAdvances = (total - acceptedWithholdingTax) || 0;
        if (IncomeTaxAdvances < 0) {
            IncomeTaxAdvances = 0;
        }
        let AcceptedWithholdingTaxMaximum = acceptedWithholdingTax;
        if (acceptedWithholdingTax > total) {
            AcceptedWithholdingTaxMaximum = total;
        }
        return { IncomeTaxAdvances, AcceptedWithholdingTaxMaximum, total };
    };


    const calculateDifference = (row, selectedReportTrackingType) => {
        if (selectedReportTrackingType === 'VATReport') {
            return row.computedNetVAT - row.reportedNetVAT;
        } else {
            const reported = calculateIncomeTaxAdvances(
                row.reportedWitholdingIncomesNoVat,
                row.reportedAcceptedWithholdingTax,
                row.reportedIncomeTaxAdvancePercentage,
                row.useIncomeTaxAdvancePercentage,
                row.reportedIncomeTaxAdvanceAmount
            );
            return row.computedAcceptedWithholdingTax - reported.AcceptedWithholdingTaxMaximum;
        }
    };
    const filteredAndSortedData = useMemo(() => {
        if (data.length === 0) {
            return [];
        }


        const query = searchQuery.toLowerCase();

        const sortedData = data.filter((item) => {

            const matchSearchQuery = (
                (item?.startDate && formatDate(new Date(item.startDate)).toLowerCase().includes(query)) ||
                (item?.reportType && item.reportType.toLowerCase().includes(query)) ||
                (item?.caseId && item.caseId.toLowerCase().includes(query)) ||
                (item?.clientName && item.clientName.toLowerCase().includes(query)) ||
                (item?.status && t(Constants.ReportTrackingStatus[item.status]).toLowerCase().includes(query))
            );

            const matchSubAccountant = !subAccountantName.length || subAccountantName.includes(subAccountantMap[item.subAccountantId] || item.subAccountantId);
            const matchStatus = !selectedStatus.length || selectedStatus.includes(Constants.ReportTrackingStatus[selectedReportTrackingType === 'VATReport' ? item.status : item.withholdingTaxStatus]);
            const matchReportingType = !selectedReportingType.length || selectedReportingType.includes(item.reportType);
            return matchSearchQuery && matchSubAccountant && matchStatus && matchReportingType
        });
        if (sortConfig.key) {
            sortedData.sort((a, b) => {
                if (sortConfig.key === 'difference') {
                    const aDifference = calculateDifference(a, selectedReportTrackingType);
                    const bDifference = calculateDifference(b, selectedReportTrackingType);
                    return sortConfig.direction === 'ascending' ? aDifference - bDifference : bDifference - aDifference;
                } else {
                    const aValue = a[sortConfig.key] || ""; // Default to empty string if null or undefined
                    const bValue = b[sortConfig.key] || ""; // Default to empty string if null or undefined
                    if (sortConfig.type === 'number') {
                        return sortConfig.direction === 'ascending' ? aValue - bValue : bValue - aValue;
                    } else if (sortConfig.type === 'date') {
                        return sortConfig.direction === 'ascending' ? new Date(aValue) - new Date(bValue) : new Date(bValue) - new Date(aValue);
                    } else { // default to string comparison
                        return sortConfig.direction === 'ascending' ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
                    }
                }
            });
        }
        return sortedData;
    }, [data, searchQuery, sortConfig, subAccountantName, selectedStatus, selectedReportingType]);

    const [openRows, setOpenRows] = useState(() => filteredAndSortedData.map(() => false));






    const handleStartDateChange = async (date) => {
        const startDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), 10));
        localStorage.setItem('Document' + 'ReportTracking' + '_StartDate', startDate)
        setStartDate(startDate);
        if (isRange) {
            await fetchClientsData(new Date(startDate), new Date(endDate));
        }
    };

    const handleEndDateChange = async (date) => {
        const endDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), 10));
        localStorage.setItem('Document' + 'ReportTracking' + '_EndDate', endDate)
        setEndDate(endDate);
        if (isRange) {
            await fetchClientsData(new Date(startDate), new Date(endDate));
        }
    };


    const handleOpenWithholdingTaxModal = () => setOpenWithholdingTaxModal(true);

    const handleOpenVatModal = () => setOpenVatModal(true);
    const handleCloseVatModal = () => {
        setOpenVatModal(false);
        setCurrentItem({});
        setVatReport({});
    };

    const handleCloseWithholdingTaxModal = () => {
        setOpenWithholdingTaxModal(false);
        setCurrentItem({});
        setWithholdingTax({});
    };
    const handleWithholdingTaxEdit = (item) => {
        setCurrentItem(item);

        const paymentAcceptanceWithholdingTax = parseFloat(item.computedAcceptedWithholdingTax) || 0;
        let total = (item.computedWitholdingIncomesNoVat * (item.incomeTaxAdvancePercentage / 100)) || 0;
        if (!item.useIncomeTaxAdvancePercentage) {
            total = item.computedIncomeTaxAdvanceAmount;
        }
        let IncomeTaxAdvances = (total - paymentAcceptanceWithholdingTax) || 0;
        if (IncomeTaxAdvances < 0) {
            IncomeTaxAdvances = 0;
        }
        let AcceptedWithholdingTaxMaximum = paymentAcceptanceWithholdingTax;
        if (paymentAcceptanceWithholdingTax > total) {
            AcceptedWithholdingTaxMaximum = total;
        }
        const WithholdingTax = {
            id: item.id,
            clientName: item.clientName,
            clientLocation: item.clientLocation || '',
            clientCaseId: item.caseId,
            AcceptedWithholdingTax: paymentAcceptanceWithholdingTax,
            AcceptedWithholdingTaxMaximum: AcceptedWithholdingTaxMaximum,
            incomeTaxAdvancePercentage: item.incomeTaxAdvancePercentage,
            IncomeTaxAdvances: IncomeTaxAdvances,
            total: total,
            witholdingIncomesNoVat: item.computedWitholdingIncomesNoVat,
            StartDate: item.startDate,
            EndDate: item.endDate,
            useIncomeTaxAdvancePercentage: item.useIncomeTaxAdvancePercentage,
            incomeTaxAdvanceAmount: item.computedIncomeTaxAdvanceAmount || 0
        };

        setWithholdingTax(WithholdingTax);
        handleOpenWithholdingTaxModal();
    };



    const handleVatEdit = (item) => {
        setCurrentItem(item);
        
        const vatReport = {
            id: item.id,
            clientName: item.clientName,
            clientLocation: item.clientLocation || '',
            clientCaseId: item.caseId,
            VATEquipment: item.computedEquipmentVAT,
            VATExpenses: item.computedOtherVATTaxes,
            VATTransactions: item.computedVATTransactions,
            VATNet: item.computedNetVAT,
            IncomesNoVats: item.incomesNoVat,
            IncomesNoZeroVats: item.incomesNoZeroVats,
            StartDate: item.startDate,
            EndDate: item.endDate,
            VATRateTransactions: beautifyNumber((item.computedVATTransactions / item.incomesNoVat) * 100 || 0)
        };
        setVatReport(vatReport);
        handleOpenVatModal();
    };


    const handleRadioChange = (e) => {
        setReportingWay(e.target.value);
    };



    const fetchSubAccountantsData = async (accountantId, token, isFirstFetch) => {
        try {
            setLoading(true);
            const data = await SubAccountantService.GetSubAccountantByAccountantId(token, accountantId);
            if (isFirstFetch) {
                let transformedData = data.map(item => ({
                    label: item.name,
                    value: item.subAccountantId
                }));
                setSubAccountants(transformedData);

                let subAccountantMap = {};
                transformedData.forEach(item => {
                    subAccountantMap[item.value] = item.label;
                });
                setSubAccountantMap(subAccountantMap);
                // toast.success(t('fetchSubAccountantsDataSuccess'));
            } else {
                setSubAccountants([...subAccountants, ...data]);
            }
        } catch (error) {
            console.error(error);
            toast.error(t('fetchSubAccountantsDataError'));
        }
        setLoading(false);
    };

    useEffect(() => {
        const fetchUserData = async () => {
            setLoading(true);
            try {

                const currentUser = await UserService.getCurrentUser();
                const currentToken = await UserService.getCurrentToken();
                if (currentUser && currentToken) {
                    setUser(currentUser);
                    setToken(currentToken);
                    await fetchSubAccountantsData(currentUser.accountantId, currentToken, true)
                    setIsFirstUserFetched(true);
                }
                else {
                    navigate("/login");
                }


            } catch (error) {
                // Handle error - maybe navigate to login or show a message
            }

        };


        fetchUserData();
        setLoading(false);
    }, []);


    const fetchClientsData = async (startDate, endDate) => {
        setLoading(true);
        const formattedStartDate = startDate.toISOString();
        const formattedEndDate = endDate.toISOString();
        try {
            const response = await ClientService.WithholdingTaxReportTracking(user.accountantId, formattedStartDate, formattedEndDate, isRange, token)
            if (response.ok) {

                const data = await response.json(); // Parse the JSON in the response
                setData(data);
                // toast.success(t('dataFetchedSuccess'));
            } else {
                const errorText = await response.text();
                console.error(errorText);
                toast.error(t('dataFetchedUnSuccess', { errorText }));
            }
        } catch (error) {
            console.error(error);
            toast.error(t('dataFetchedUnSuccess', { errorText: error.message }));
        }
        setLoading(false);
    };




    useEffect(() => {
        if (isFirstUserFetched) {
            if (!isRange)
                fetchClientsData(sortDate, sortDate);
            else
                fetchClientsData(startDate, endDate)
        }
    }, [isFirstUserFetched]);



    const handleSortChange = (key, type, Direction = '') => {
        let direction = Direction || 'ascending';
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({ key, direction, type });
    };

    const handleToggleRow = (index) => {
        setOpenRows((prevOpenRows) => {
            const newOpenRows = [...prevOpenRows];
            newOpenRows[index] = !newOpenRows[index];
            return newOpenRows;
        });
    };





    const Row = memo(({ row, open, handleToggleRow }) => {
        let Notreported = selectedReportTrackingType === 'VATReport' ? row.status === 2 : row.withholdingTaxStatus === 2;
        const undealtStyle = {
            boxShadow: Notreported
                ? ''  // red with 50% opacity
                : 'inset 0px 2px 200px 0px rgba(57, 196, 94, 0.2)',  // #39c45e with 50% opacity
            borderRadius: '0px', // Adjust the radius as needed
        };
        const computed = calculateIncomeTaxAdvances(row.computedWitholdingIncomesNoVat, row.computedAcceptedWithholdingTax, row.incomeTaxAdvancePercentage, row.useIncomeTaxAdvancePercentage, row.computedIncomeTaxAdvanceAmount);
        const reported = calculateIncomeTaxAdvances(row.reportedWitholdingIncomesNoVat, row.reportedAcceptedWithholdingTax, row.reportedIncomeTaxAdvancePercentage, row.useIncomeTaxAdvancePercentage, row.reportedIncomeTaxAdvanceAmount);
        return (
            <>
                <TableRow key={row.id} sx={{ ...undealtStyle }}>
                    <>
                        {
                            !isRange &&
                            <TableCell className='rightText' align="right" >
                                {t(Constants.ReportTrackingStatus[selectedReportTrackingType === 'VATReport' ? row.status : row.withholdingTaxStatus])}
                                {/* {!isIdInChecks ? (
                                            <p style={{ color: 'green', fontSize: '16px', fontWeight: 'bold' }}>{t('processed')}</p>
                                        ) : (
                                            <p style={{ color: 'red', fontSize: '16px', fontWeight: 'bold' }}>{t('unprocessed')}</p>
                                        )} */}
                            </TableCell>
                        }
                        <TableCell className="rightText" align="right">{selectedReportTrackingType === 'VATReport' ? beautifyNumber(row.computedNetVAT - row.reportedNetVAT) : beautifyNumber(row.computedAcceptedWithholdingTax - reported.AcceptedWithholdingTaxMaximum)}</TableCell>

                        <TableCell className='rightText' align="right" >
                            {formatTableDate(row.startDate, row.endDate)}
                        </TableCell>
                        <TableCell className='rightText' align="right" >
                            {subAccountantMap[row.subAccountantId] || row.subAccountantId}
                        </TableCell>

                        <TableCell className='rightText' align="right" >
                            {row.reportType}
                        </TableCell>
                        <TableCell className='rightText' align="right" >
                            {row.caseId}
                        </TableCell>
                        <TableCell className='rightText' align="right" >
                            {row.clientName}
                        </TableCell>
                        {/* <TableCell>
                                <button className='row-buttons' onClick={() => handleEdit(row, index)}>
                                    <EditIcon className="doc-details-icon" style={{ color: '#E57C22' }} />
                                </button>
                                {(isRange ? !row?.isClosedReportingMonth : !isClosedReportingMonth) && (
                                    <button className='row-buttons' onClick={() => handleDeleteClick(row.id)}>
                                        <DeleteIcon className="doc-details-icon" style={{ color: '#E57C22' }} />
                                    </button>
                                )}
                            </TableCell> */}
                        <TableCell >
                            <div style={{ display: 'flex', flexDirection: 'column' }}>

                                <IconButton
                                    aria-label="expand row"
                                    size="small"
                                    onClick={handleToggleRow}
                                >
                                    {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                </IconButton>

                            </div>
                        </TableCell>
                    </>
                </TableRow>

                <TableRow>
                    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
                        <Collapse in={open} timeout="auto" unmountOnExit>
                            {/* VATTransactions ------------------------------- */}
                            {selectedReportTrackingType === 'VATReport' ?
                                <Box sx={{ margin: 1, marginInlineEnd: 50 }} dir="rtl">
                                    <Typography variant="h6" gutterBottom component="div" align="right" className='rightText'>
                                        {t("VATReport")}
                                    </Typography>
                                    {
                                        !isRange &&
                                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                                            <IconButton sx={{ width: '30px', height: '30px' }} onClick={() => handleVatEdit(row)}>
                                                <img src={editIconImage} alt="Edit" />
                                            </IconButton>
                                        </div>
                                    }

                                    <Table size="small" aria-label="purchases">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell sx={headerStyle} className='rightText' align="right">{t('Type')}</TableCell>
                                                <TableCell sx={headerStyle} className='rightText' align="right">{t('TaxableTransactionsWithoutVAT')}</TableCell>
                                                <TableCell sx={headerStyle} className='rightText' align="right">{t('ExemptTransactions')}</TableCell>
                                                <TableCell sx={headerStyle} className='rightText' align="right">{t('VATTransactions')}</TableCell>
                                                <TableCell sx={headerStyle} className='rightText' align="right">{t('VATExpenses')}</TableCell>
                                                <TableCell sx={headerStyle} className='rightText' align="right">{t('EquipmentVAT')}</TableCell>
                                                <TableCell sx={headerStyle} className='rightText' align="right">{t('NetAmount')}</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <TableRow >
                                                <TableCell className='rightText' align="right">{t('Computed')}</TableCell>
                                                <TableCell className='rightText' align="right">{beautifyNumber(row.incomesNoVat)}</TableCell>
                                                <TableCell className='rightText' align="right">{beautifyNumber(row.incomesNoZeroVats)}</TableCell>
                                                <TableCell className='rightText' align="right">{beautifyNumber(row.computedVATTransactions)}</TableCell>
                                                <TableCell className='rightText' component="th" scope="row" align="right">{beautifyNumber(row.computedOtherVATTaxes)}</TableCell>
                                                <TableCell className='rightText' align="right">{beautifyNumber(row.computedEquipmentVAT)}</TableCell>
                                                <TableCell className='rightText' align="right">{beautifyNumber(row.computedNetVAT)}</TableCell>
                                            </TableRow>
                                            <TableRow >
                                                <TableCell className='rightText' align="right">{t('Reported')}</TableCell>
                                                <TableCell className='rightText' align="right">{beautifyNumber(row.reportedIncomesNoVat)}</TableCell>
                                                <TableCell className='rightText' align="right">{beautifyNumber(row.reportedIncomesNoZeroVats)}</TableCell>
                                                <TableCell className='rightText' align="right">{beautifyNumber(row.reportedVATTransactions)}</TableCell>
                                                <TableCell className='rightText' component="th" scope="row" align="right">{beautifyNumber(row.reportedOtherVATTaxes)}</TableCell>
                                                <TableCell className='rightText' align="right">{beautifyNumber(row.reportedEquipmentVAT)}</TableCell>
                                                <TableCell className='rightText' align="right">{beautifyNumber(row.reportedNetVAT)}</TableCell>
                                            </TableRow>
                                            <TableRow >
                                                <TableCell className='rightText' align="right">{t('Difference')}</TableCell>
                                                <TableCell className='rightText' align="right">{beautifyNumber(row.reportedIncomesNoVat - row.incomesNoVat)}</TableCell>
                                                <TableCell className='rightText' align="right">{beautifyNumber(row.reportedIncomesNoZeroVats - row.incomesNoZeroVats)}</TableCell>
                                                <TableCell className='rightText' align="right">{beautifyNumber(row.computedVATTransactions - row.reportedVATTransactions)}</TableCell>
                                                <TableCell className='rightText' component="th" scope="row" align="right">{beautifyNumber(row.computedOtherVATTaxes - row.reportedOtherVATTaxes)}</TableCell>
                                                <TableCell className='rightText' align="right">{beautifyNumber(row.computedEquipmentVAT - row.reportedEquipmentVAT)}</TableCell>
                                                <TableCell className='rightText' align="right">{beautifyNumber(row.computedNetVAT - row.reportedNetVAT)}</TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </Box >
                                :
                                <Box sx={{ margin: 1, marginInlineEnd: 50 }} dir="rtl">
                                    <Typography variant="h6" gutterBottom component="div" align="right" className='rightText'>
                                        {t("AdvancesReport")}
                                    </Typography>
                                    {
                                        !isRange &&
                                        <div style={{ display: 'flex', flexDirection: 'row' }}>

                                            <IconButton sx={{ width: '30px', height: '30px' }} onClick={() => handleWithholdingTaxEdit(row)}>
                                                <img src={editIconImage} alt="Edit" />
                                            </IconButton>
                                        </div>
                                    }
                                    <Table size="small" aria-label="purchases">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell sx={headerStyle} className='rightText' align="right">{t('Type')}</TableCell>
                                                <TableCell sx={headerStyle} className='rightText' align="right">{t('BusinessTurnoverInILS')}</TableCell>
                                                {row.useIncomeTaxAdvancePercentage && (
                                                    <TableCell sx={headerStyle} className='rightText' align="right">{t('IncomeTaxAdvancePercentage')}</TableCell>
                                                )}
                                                <TableCell sx={headerStyle} className='rightText' align="right">{t(row.useIncomeTaxAdvancePercentage ? 'Advance' : 'FixedAdvance')}</TableCell>
                                                <TableCell sx={headerStyle} className='rightText' align="right">{t('acceptedWithholdingTax')}</TableCell>
                                                <TableCell sx={headerStyle} className='rightText' align="right">{t('Payment')}</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <TableRow >
                                                <TableCell className='rightText' align="right">{t('Computed')}</TableCell>
                                                <TableCell className='rightText' align="right">{beautifyNumber(row.computedWitholdingIncomesNoVat)}</TableCell>
                                                {row.useIncomeTaxAdvancePercentage && (
                                                    <TableCell className='rightText' align="right">{beautifyNumber(row.incomeTaxAdvancePercentage)}</TableCell>
                                                )}
                                                <TableCell className='rightText' align="right">{beautifyNumber(computed.total)}</TableCell>
                                                <TableCell className='rightText' align="right">{beautifyNumber(row.computedAcceptedWithholdingTax)}</TableCell>
                                                <TableCell className='rightText' align="right">{beautifyNumber(computed.IncomeTaxAdvances)}</TableCell>
                                            </TableRow>
                                            <TableRow >
                                                <TableCell className='rightText' align="right">{t('Reported')}</TableCell>
                                                <TableCell className='rightText' align="right">{beautifyNumber(row.reportedWitholdingIncomesNoVat)}</TableCell>
                                                {row.useIncomeTaxAdvancePercentage && (
                                                    <TableCell className='rightText' align="right">{beautifyNumber(row.reportedIncomeTaxAdvancePercentage)}</TableCell>
                                                )}
                                                <TableCell className='rightText' align="right">{beautifyNumber(reported.total)}</TableCell>
                                                <TableCell className='rightText' align="right">{beautifyNumber(reported.AcceptedWithholdingTaxMaximum)}</TableCell>
                                                <TableCell className='rightText' align="right">{beautifyNumber(reported.IncomeTaxAdvances)}</TableCell>
                                            </TableRow>
                                            <TableRow >
                                                <TableCell className="rightText" align="right">{t('Difference')}</TableCell>
                                                <TableCell className='rightText' align="right">{beautifyNumber(row.computedWitholdingIncomesNoVat - row.reportedWitholdingIncomesNoVat)}</TableCell>
                                                {row.useIncomeTaxAdvancePercentage && (
                                                    <TableCell className='rightText' align="right">{beautifyNumber(row.computedIncomeTaxAdvancePercentage - row.reportedIncomeTaxAdvancePercentage)}</TableCell>
                                                )}
                                                <TableCell className='rightText' align="right">{beautifyNumber(computed.total - reported.total)}</TableCell>
                                                <TableCell className="rightText" align="right">{beautifyNumber(row.computedAcceptedWithholdingTax - reported.AcceptedWithholdingTaxMaximum)}</TableCell>
                                                <TableCell className='rightText' align="right">{beautifyNumber(computed.IncomeTaxAdvances - reported.IncomeTaxAdvances)}</TableCell>
                                            </TableRow>
                                        </TableBody >
                                    </Table >
                                </Box>
                            }
                        </Collapse >
                    </TableCell >
                </TableRow >
            </>
        )
    }, (prevProps, nextProps) => {
        return prevProps.row === nextProps.row && prevProps.open === nextProps.open;
    });

    const memoizedRows = useMemo(() => filteredAndSortedData.map((row, index) => {
        const uniqueKey = row.id ?? `row-${index}`;
        return (
            <Row
                key={uniqueKey}
                row={row}
                open={!!openRows[index]}
                handleToggleRow={() => handleToggleRow(index)}
            />
        );
    }), [filteredAndSortedData, openRows, selectedReportTrackingType]);
    const handleGoBack = () => {
        navigate(-1);
    };


    const handleSetSortDate = (date) => {
        const newDate = new Date(date.getFullYear(), date.getMonth(), 10);
        localStorage.setItem('Document' + 'ReportTracking' + '_Date', newDate);
        setSortDate(newDate);
        fetchClientsData(newDate, newDate);
    }



    const handleRangeChange = async () => {
        !isRange ? await fetchClientsData(new Date(startDate), new Date(endDate)) : await fetchClientsData(new Date(sortDate), new Date(sortDate));
        setIsRange(!isRange);
        localStorage.setItem('Document' + 'ReportTracking' + '_IsRange', !isRange);
    }



    //table functions
    const headerStyle = {
        backgroundColor: 'var(--foundation-blue-light-active, #BFC8FF)',
        color: "var(--Foundation-Blue-Normal, #304FFF)",
        textAlign: "center",
        leadingTrim: "both",
        textEdge: "cap",
        fontFamily: "Montserrat",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: 'bold',
        lineHeight: "normal",
    }

    const tableContainerRef = useRef();
    const customScrollbarRef = useRef();



    const handleCustomScroll = (e) => {
        if (tableContainerRef.current) {
            tableContainerRef.current.scrollLeft = e.target.scrollLeft;
        }
    };

    const handleTableScroll = (e) => {
        if (customScrollbarRef.current) {
            customScrollbarRef.current.scrollLeft = e.target.scrollLeft;
        }
    };

    const synchronizeScroll = () => {
        if (tableContainerRef.current && customScrollbarRef.current) {
            customScrollbarRef.current.scrollLeft = tableContainerRef.current.scrollLeft;

        }
    };

    useEffect(() => {
        synchronizeScroll();
    }, [filteredAndSortedData]);

    useEffect(() => {
        if (tableContainerRef.current && customScrollbarRef.current) {
            customScrollbarRef.current.firstChild.style.width = `${tableContainerRef.current.scrollWidth}px`;
        }
    }, [filteredAndSortedData, tableContainerRef.current?.scrollWidth]);

    const isEmptyObject = (obj) => {
        return Object.keys(obj).length === 0 && obj.constructor === Object;
    };

    const generateVatHtml = () => {
        if (isEmptyObject(vatReport)) {
            return;
        }
        const israelTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const israelTime = new Date().toLocaleString('he-IL', { timeZone: israelTimeZone });
        const formattedDate = new Date().toLocaleString('he-IL', { timeZone: israelTimeZone, dateStyle: 'short', timeStyle: 'medium' });
        const startDateFormatted = new Date(vatReport.StartDate).toLocaleString('he-IL', { month: 'long' });
        const endDateFormatted = new Date(vatReport.EndDate).toLocaleString('he-IL', { month: 'long', year: 'numeric' });
        const dateRange = `${startDateFormatted} - ${endDateFormatted}`;



        const htmlContent = `
        <!DOCTYPE html>
        <html lang='en'>
        <head>
            <meta charset='UTF-8' />
            <meta http-equiv='X-UA-Compatible' content='IE=edge' />
            <meta name='viewport' content='width=device-width, initial-scale=1.0' />
            <style>
                a, button, input, select, h1, h2, h3, h4, h5, * {
                    box-sizing: border-box;
                    margin: 0;
                    padding: 0;
                    border: none;
                    text-decoration: none;
                    background: none;
                    -webkit-font-smoothing: antialiased;
                }
                menu, ol, ul {
                    list-style-type: none;
                    margin: 0;
                    padding: 0;
                }
                .body {
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
                .container {
                    width:fit-content
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                }
                .header-vat {
                    width: 1288px;
                    text-align: center;
                    padding: 10px 0;
                    background-color: #f3f3f3;
                }
                .header_container {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                }
                .date, .title, .placeholder {
                    flex: 1;
                    text-align: center;
                }
                .title {
                    font-size: 26px;
                    font-weight: bold;
                }
                .vat_percentage, .date {
                    font-size: 22px;
                    padding: 5px 0;
                    color: black;
                    font-weight: bold;
                }
                .frame-1 {
                    box-sizing: border-box;
                    position: relative;
                    background: white;
                    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
                }
                .vat-report-1-fotor-2024071611375-1 {
                    width: 1288px;
                    height: 584px;
                    object-fit: contain;
                }
                .clientName, .VATEquipment, .VATExpenses, .VATNet, .IncomesNoVats, .IncomesNoZeroVats, .clientLocation, .dateRange, .clientCaseId, .VATTransactions {
                    color: black;
                    direction: rtl;
                    font-family: 'Inter-Regular', sans-serif;
                    font-weight: bold !important;
                    position: absolute;
                    word-wrap: break-word;
                    font-size: 22px !important;
                }
                [class^='clientName'] {
                    overflow: hidden !important;
                    text-overflow: ellipsis !important;
                    white-space: nowrap !important;
                }
                .new-content {}
                .clientName {
                    text-align: right;
                    font-family: 'Inter-Regular', sans-serif;
                    font-size: 26px;
                    font-weight: 400;
                    position: absolute;
                    left: 706px;
                    top: 58px;
                    width: 464px;
                    height: 34px;
                }
                .VATEquipment {
                    text-align: right;
                    font-family: 'Inter-Regular', sans-serif;
                    font-size: 26px;
                    font-weight: 400;
                    position: absolute;
                    left: 881px;
                    top: 220px;
                    width: 333px;
                    height: 34px;
                }
                .VATExpenses {
                    text-align: right;
                    font-family: 'Inter-Regular', sans-serif;
                    font-size: 26px;
                    font-weight: 400;
                    position: absolute;
                    left: 881px;
                    top: 281px;
                    width: 333px;
                    height: 34px;
                }
                .VATNet {
                    text-align: right;
                    font-family: 'Inter-Regular', sans-serif;
                    font-size: 26px;
                    font-weight: 400;
                    position: absolute;
                    left: 881px;
                    top: 455px;
                    width: 333px;
                    height: 34px;
                }
                .IncomesNoVats {
                    text-align: right;
                    font-family: 'Inter-Regular', sans-serif;
                    font-size: 26px;
                    font-weight: 400;
                    position: absolute;
                    left: 432px;
                    top: 159px;
                    width: 333px;
                    height: 34px;
                }
                .IncomesNoZeroVats {
                    text-align: right;
                    font-family: 'Inter-Regular', sans-serif;
                    font-size: 26px;
                    font-weight: 400;
                    position: absolute;
                    left: 59px;
                    top: 159px;
                    width: 304px;
                    height: 34px;
                }
                .clientLocation {
                    text-align: right;
                    font-family: 'Inter-Regular', sans-serif;
                    font-size: 26px;
                    font-weight: 400;
                    position: absolute;
                    left: 250px;
                    top: 58px;
                    height: 34px;
                    width: 445px;
                }
                .dateRange {
                    text-align: right;
                    font-family: 'Inter-Regular', sans-serif;
                    font-size: 26px;
                    font-weight: 400;
                    position: absolute;
                    left: 873px;
                    top: 92px;
                    width: 266px;
                    height: 34px;
                }
                .clientCaseId {
                    text-align: right;
                    font-family: 'Inter-Regular', sans-serif;
                    font-size: 26px;
                    font-weight: 400;
                    position: absolute;
                    left: 40px;
                    top: 92px;
                    width: 244px;
                    height: 25px;
                }
                .VATTransactions {
                    text-align: right;
                    font-family: 'Inter-Regular', sans-serif;
                    font-size: 26px;
                    font-weight: 400;
                    position: absolute;
                    left: 881px;
                    top: 159px;
                    width: 333px;
                    height: 34px;
                }
                .color-text {
                    color: blue;
                    font-weight: bold;
                }
            </style>
            <title>Document</title>
        </head>
        <body>
            <div class='container'>
                <div class='header-vat'>
                    <div class='header_container'>
                        <div class='date'>${formattedDate}</div>
                        <div class='title'>דוח מס ערך מוסף</div>
                        <div class='placeholder'></div>
                    </div>
                    <div class='vat_percentage'><span class='color-text'>${vatReport.VATRateTransactions}%</span> = שיעור מע״מ עסקאות</div>
                </div>
               <div class='frame-1'>
                    <img class='vat-report-1-fotor-2024071611375-1' src='${vatImage}' />
                    <div class='clientName'>${vatReport.clientName}</div>
                    <input class='VATEquipment input-field_Container_required-asterisk' value="${vatReport.VATEquipment}" onfocus="this.value=this.value.replace(/,/g, '')" onblur="this.value=parseFloat(this.value).toLocaleString('en-US')" onchange="window.parent.dispatchEvent(new CustomEvent('vatUpdate', { detail: JSON.stringify({ source: 'vatReport', eventName: 'updateVatEquipment', value: this.value }) }))" />
                    <input class='VATExpenses input-field_Container_required-asterisk' value="${vatReport.VATExpenses}" onfocus="this.value=this.value.replace(/,/g, '')" onblur="this.value=parseFloat(this.value).toLocaleString('en-US')" onchange="window.parent.dispatchEvent(new CustomEvent('vatUpdate', { detail: JSON.stringify({ source: 'vatReport', eventName: 'updateVatExpenses', value: this.value }) }))" />
                    <div class='VATNet'>${vatReport.VATNet}</div>
                    <input class='IncomesNoVats input-field_Container_required-asterisk' value="${vatReport.IncomesNoVats}" onfocus="this.value=this.value.replace(/,/g, '')" onblur="this.value=parseFloat(this.value).toLocaleString('en-US')" onchange="window.parent.dispatchEvent(new CustomEvent('vatUpdate', { detail: JSON.stringify({ source: 'vatReport', eventName: 'updateIncomesNoVats', value: this.value }) }))" />
                    <input class='IncomesNoZeroVats input-field_Container_required-asterisk' value="${vatReport.IncomesNoZeroVats}" onfocus="this.value=this.value.replace(/,/g, '')" onblur="this.value=parseFloat(this.value).toLocaleString('en-US')" onchange="window.parent.dispatchEvent(new CustomEvent('vatUpdate', { detail: JSON.stringify({ source: 'vatReport', eventName: 'updateIncomesNoZeroVats', value: this.value }) }))" />
                    <div class='clientLocation'>${vatReport.clientLocation}</div>
                    <div class='dateRange'>${dateRange}</div>
                    <div class='clientCaseId'>${vatReport.clientCaseId}</div>
                    <input class='VATTransactions input-field_Container_required-asterisk' value="${vatReport.VATTransactions}" onfocus="this.value=this.value.replace(/,/g, '')" onblur="this.value=parseFloat(this.value).toLocaleString('en-US')" onchange="window.parent.dispatchEvent(new CustomEvent('vatUpdate', { detail: JSON.stringify({ source: 'vatReport', eventName: 'updateVatTransactions', value: this.value }) }))" />
                </div>
            </div>
        </body>
        </html>`;
        return htmlContent;
    };

    const generateWithholdingTaxHtml = () => {
        if (isEmptyObject(withholdingTax)) {
            return;
        }
        const israelTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const israelTime = new Date().toLocaleString('he-IL', { timeZone: israelTimeZone });
        const formattedDate = new Date().toLocaleString('he-IL', { timeZone: israelTimeZone, dateStyle: 'short', timeStyle: 'medium' });

        const startDateFormatted = new Date(withholdingTax.StartDate).toLocaleString('he-IL', { month: 'long' });
        const endDateFormatted = new Date(withholdingTax.EndDate).toLocaleString('he-IL', { month: 'long' });
        const dateRange = `${startDateFormatted} - ${endDateFormatted}`;
        const endDate = new Date(withholdingTax.EndDate);
        const htmlContent = `
        <!DOCTYPE html>
        <html lang='en'>
        <head>
            <meta charset='UTF-8' />
            <meta http-equiv='X-UA-Compatible' content='IE=edge' />
            <meta name='viewport' content='width=device-width, initial-scale=1.0' />
            <style>
                a, button, input, select, h1, h2, h3, h4, h5, * {
                    box-sizing: border-box;
                    margin: 0;
                    padding: 0;
                    border: none;
                    text-decoration: none;
                    background: none;
                    -webkit-font-smoothing: antialiased;
                }
                menu, ol, ul {
                    list-style-type: none;
                    margin: 0;
                    padding: 0;
                }
                .body {
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
                .container {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                }
                .header-WithholdingTax {
                    width: 100%;
                    text-align: center;
                    padding: 10px 0;
                    background-color: #f3f3f3;
                }
                .header_container {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin-bottom: 20px;
                }
                .date, .title, .placeholder {
                    flex: 1;
                    text-align: center;
                }
                .title {
                    font-size: 26px;
                    font-weight: bold;
                }
                .vat_percentage, .date {
                    font-size: 22px;
                    padding: 5px 0;
                    color: black;
                    font-weight: bold;
                }
                .frame-3 {
                    box-sizing: border-box;
                    position: relative;
                    background: white;
                    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
                }
                .tax-advances-report-1-fotor-20240716175158-1 {
                    left: 0px;
                    top: 0px;
                    object-fit: cover;
                }
                .client-name {
                    color: #000000;
                    text-align: right;
                    font-family: 'Inter-Regular', sans-serif;
                    font-size: 22px;
                    font-weight: 400;
                    position: absolute;
                    left: 583px;
                    top: 78px;
                    width: 449px;
                    height: 34px;
                }
                .start-month-end-month {
                    color: #000000;
                    text-align: right;
                    font-family: 'Inter-Regular', sans-serif;
                    font-size: 22px;
                    font-weight: 400;
                    position: absolute;
                    left: 618px;
                    top: 142px;
                    width: 276px;
                    height: 34px;
                }
                .incomeswithoutvat {
                    color: #000000;
                    text-align: right;
                    font-family: 'Inter-Regular', sans-serif;
                    font-size: 22px;
                    font-weight: 400;
                    position: absolute;
                    left: 382px;
                    top: 295px;
                    width: 354px;
                    height: 34px;
                }
                .income-tax-advances {
                    color: #000000;
                    text-align: right;
                    font-family: 'Inter-Regular', sans-serif;
                    font-size: 22px;
                    font-weight: 400;
                    position: absolute;
                    left: 382px;
                    top: 390px;
                    width: 354px;
                    height: 34px;
                }
                .total {
                    color: #000000;
                    text-align: right;
                    font-family: 'Inter-Regular', sans-serif;
                    font-size: 22px;
                    font-weight: 400;
                    position: absolute;
                    left: 912px;
                    top: 295px;
                    width: 324px;
                    height: 34px;
                }
                .percent {
                    color: #000000;
                    text-align: right;
                    font-family: 'Inter-Regular', sans-serif;
                    font-size: 18px;
                    font-weight: bold;
                    position: absolute;
                    left: 771px;
                    top: 305px;
                    width: 66px;
                    height: 24px;
                }
                .income-tax-advances2 {
                    color: #000000;
                    text-align: right;
                    font-family: 'Inter-Regular', sans-serif;
                    font-size: 22px;
                    font-weight: 400;
                    position: absolute;
                    left: 912px;
                    top: 390px;
                    width: 324px;
                    height: 34px;
                }
                .total-income-tax-advances {
                    color: #000000;
                    text-align: right;
                    font-family: 'Inter-Regular', sans-serif;
                    font-size: 22px;
                    font-weight: 400;
                    position: absolute;
                    left: 826px;
                    top: 535px;
                    width: 404px;
                    height: 34px;
                }
                .client-case {
                    color: #000000;
                    text-align: right;
                    font-family: 'Inter-Regular', sans-serif;
                    font-size: 22px;
                    font-weight: 400;
                    position: absolute;
                    left: 85px;
                    top: 205px;
                    width: 204px;
                    height: 34px;
                }
                .year {
                    color: #000000;
                    text-align: right;
                    font-family: 'Inter-Regular', sans-serif;
                    font-size: 22px;
                    font-weight: 400;
                    position: absolute;
                    left: 482px;
                    top: 142px;
                    width: 97px;
                    height: 34px;
                }
                .client-location {
                    color: #000000;
                    text-align: right;
                    font-family: 'Inter-Regular', sans-serif;
                    font-size: 22px;
                    font-weight: 400;
                    position: absolute;
                    left: 12px;
                    top: 78px;
                    width: 522px;
                    height: 34px;
                }
            </style>
            <title>Document</title>
        </head>
        <body>
            <div class='container'>
                <div class='header-WithholdingTax'>
                    <div class='header_container'>
                        <div class='title'>${withholdingTax.clientLocation}</div>
                        <div class='date'>עוסק מורשה: ${withholdingTax.clientCaseId}</div>
                        <div class='date'>${withholdingTax.clientName}</div>
                    </div>
                    <div class='header_container'>
                        <div class='date'>${formattedDate}</div>
                        <div class='title'>דוח מקדמות ע״פ מחזור</div>
                        <div class='placeholder'></div>
                    </div>
                    <div class='vat_percentage'>${withholdingTax.incomeTaxAdvancePercentage}% = אחוז מקדמות מהמחזור</div>
                </div>
               <div class='frame-3'>
                    <img class='tax-advances-report-1-fotor-20240716175158-1' src='${TaxAdvancesReportImage}' />
                    <div class='client-name'>${withholdingTax.clientName}</div>
                    <input class='incomeswithoutvat input-field_Container_required-asterisk' value="${withholdingTax.witholdingIncomesNoVat}" onchange="window.parent.dispatchEvent(new CustomEvent('withholdingTaxUpdate', { detail: JSON.stringify({ source: 'withholdingTaxReport', eventName: 'updateIncomesNoVats', value: this.value }) }))" />
                    <input class='income-tax-advances input-field_Container_required-asterisk' value="${withholdingTax.AcceptedWithholdingTax}" onchange="window.parent.dispatchEvent(new CustomEvent('withholdingTaxUpdate', { detail: JSON.stringify({ source: 'withholdingTaxReport', eventName: 'updateAcceptedWithholdingTax', value: this.value }) }))" />
                    ${withholdingTax.useIncomeTaxAdvancePercentage
                ? `<input class='percent input-field_Container_required-asterisk' value="${withholdingTax.incomeTaxAdvancePercentage}" onchange="window.parent.dispatchEvent(new CustomEvent('withholdingTaxUpdate', { detail: JSON.stringify({ source: 'withholdingTaxReport', eventName: 'updateIncomeTaxAdvancePercentage', value: this.value }) }))" />`
                : `<div class='percent'></div>`
            }
                    ${withholdingTax.useIncomeTaxAdvancePercentage
                ? `<div class='total'>${withholdingTax.total}</div>`
                : `<input class='total input-field_Container_required-asterisk' value="${withholdingTax.incomeTaxAdvanceAmount}" onchange="window.parent.dispatchEvent(new CustomEvent('withholdingTaxUpdate', { detail: JSON.stringify({ source: 'withholdingTaxReport', eventName: 'updateIncomeTaxAdvanceAmount', value: this.value }) }))" />`
            }
                    <div class='income-tax-advances2'>${withholdingTax.AcceptedWithholdingTaxMaximum}</div>
                    <div class='total-income-tax-advances'>${withholdingTax.IncomeTaxAdvances}</div>
                    <div class='client-case'>${withholdingTax.clientCaseId}</div>
                    <div class='year'>${endDate?.getFullYear()}</div>
                    <div class='client-location'>${withholdingTax.clientLocation}</div>
                </div>

            </div>
        </body>
        </html>`;
        return htmlContent;
    };

    const withholdingTaxHtmlContent = generateWithholdingTaxHtml();
    const vatHtmlContent = generateVatHtml();

    const handleVatMessage = (event) => {
        try {
            const message = JSON.parse(event.detail);
            if (message.source === 'vatReport') {
                switch (message.eventName) {
                    case 'updateVatTransactions':
                        handleChangeVatReport("VATTransactions", message.value);
                        break;
                    case 'updateVatEquipment':
                        handleChangeVatReport("VATEquipment", message.value);
                        break;
                    case 'updateVatExpenses':
                        handleChangeVatReport("VATExpenses", message.value);
                        break;
                    case 'updateIncomesNoVats':
                        handleChangeVatReport("IncomesNoVats", message.value);
                        break;
                    case 'updateIncomesNoZeroVats':
                        handleChangeVatReport("IncomesNoZeroVats", message.value);
                        break;
                    default:
                        break;
                }
            }
        } catch (error) {
            console.error('Failed to parse message:', event.detail);
        }
    };

    useEffect(() => {
        window.addEventListener('vatUpdate', handleVatMessage);
        return () => {
            window.removeEventListener('vatUpdate', handleVatMessage);
        };
    }, []);



    const updateAcceptedWithholdingTax = (value) => {
        setWithholdingTax(prevState => {
            const paymentAcceptanceWithholdingTax = parseFloat(value) || 0;
            const total = prevState.useIncomeTaxAdvancePercentage
                ? (prevState.witholdingIncomesNoVat * (prevState.incomeTaxAdvancePercentage / 100)) || 0
                : prevState.incomeTaxAdvanceAmount;

            let IncomeTaxAdvances = (total - paymentAcceptanceWithholdingTax) || 0;
            if (IncomeTaxAdvances < 0) {
                IncomeTaxAdvances = 0;
            }
            let AcceptedWithholdingTaxMaximum = paymentAcceptanceWithholdingTax;
            if (paymentAcceptanceWithholdingTax > total) {
                AcceptedWithholdingTaxMaximum = total;
            }


            return {
                ...prevState,
                AcceptedWithholdingTax: value,
                IncomeTaxAdvances: IncomeTaxAdvances.toString(),
                AcceptedWithholdingTaxMaximum: AcceptedWithholdingTaxMaximum.toString(),
                total: total.toString()
            };
        });
    };

    const updateIncomesNoVats = (value) => {
        setWithholdingTax(prevState => {
            const incomesNoVats = parseFloat(value) || 0;
            const total = prevState.useIncomeTaxAdvancePercentage
                ? (incomesNoVats * (prevState.incomeTaxAdvancePercentage / 100)) || 0
                : prevState.incomeTaxAdvanceAmount;

            let IncomeTaxAdvances = (total - (parseFloat(prevState.AcceptedWithholdingTax) || 0)) || 0;
            if (IncomeTaxAdvances < 0) {
                IncomeTaxAdvances = 0;
            }
            let AcceptedWithholdingTaxMaximum = parseFloat(prevState.AcceptedWithholdingTax) || 0;
            if (AcceptedWithholdingTaxMaximum > total) {
                AcceptedWithholdingTaxMaximum = total;
            }



            return {
                ...prevState,
                witholdingIncomesNoVat: value,
                IncomeTaxAdvances: IncomeTaxAdvances.toString(),
                AcceptedWithholdingTaxMaximum: AcceptedWithholdingTaxMaximum.toString(),
                total: total.toString()
            };
        });
    };

    const updateIncomeTaxAdvancePercentage = (value) => {
        setWithholdingTax(prevState => {
            const incomeTaxAdvancePercentage = parseFloat(value) || 0;
            const total = (prevState.witholdingIncomesNoVat * (incomeTaxAdvancePercentage / 100)) || 0;

            let IncomeTaxAdvances = (total - (parseFloat(prevState.AcceptedWithholdingTax) || 0)) || 0;
            if (IncomeTaxAdvances < 0) {
                IncomeTaxAdvances = 0;
            }
            let AcceptedWithholdingTaxMaximum = parseFloat(prevState.AcceptedWithholdingTax) || 0;
            if (AcceptedWithholdingTaxMaximum > total) {
                AcceptedWithholdingTaxMaximum = total;
            }



            return {
                ...prevState,
                incomeTaxAdvancePercentage: value,
                IncomeTaxAdvances: IncomeTaxAdvances.toString(),
                AcceptedWithholdingTaxMaximum: AcceptedWithholdingTaxMaximum.toString(),
                total: total.toString()
            };
        });
    };

    const updateIncomeTaxAdvanceAmount = (value) => {
        setWithholdingTax(prevState => {
            const incomeTaxAdvanceAmount = parseFloat(value) || 0;

            let IncomeTaxAdvances = (incomeTaxAdvanceAmount - (parseFloat(prevState.AcceptedWithholdingTax) || 0)) || 0;
            if (IncomeTaxAdvances < 0) {
                IncomeTaxAdvances = 0;
            }
            let AcceptedWithholdingTaxMaximum = parseFloat(prevState.AcceptedWithholdingTax) || 0;
            if (AcceptedWithholdingTaxMaximum > incomeTaxAdvanceAmount) {
                AcceptedWithholdingTaxMaximum = incomeTaxAdvanceAmount;
            }



            return {
                ...prevState,
                incomeTaxAdvanceAmount: value,
                IncomeTaxAdvances: IncomeTaxAdvances.toString(),
                AcceptedWithholdingTaxMaximum: AcceptedWithholdingTaxMaximum.toString(),
            };
        });
    };

    const handleWithholdingTaxMessage = (event) => {

        try {
            const message = JSON.parse(event.detail);
            if (message.source === 'withholdingTaxReport') {
                switch (message.eventName) {
                    case 'updateAcceptedWithholdingTax':
                        updateAcceptedWithholdingTax(message.value);
                        break;
                    case 'updateIncomesNoVats':
                        updateIncomesNoVats(message.value);
                        break;
                    case 'updateIncomeTaxAdvancePercentage':
                        updateIncomeTaxAdvancePercentage(message.value);
                        break;
                    case 'updateIncomeTaxAdvanceAmount':
                        updateIncomeTaxAdvanceAmount(message.value);
                        break;
                    default:
                        break;
                }
            }
        } catch (error) {
            console.error('Failed to parse message:', event.detail);
        }
    };

    useEffect(() => {
        window.addEventListener('withholdingTaxUpdate', handleWithholdingTaxMessage);
        return () => {
            window.removeEventListener('withholdingTaxUpdate', handleWithholdingTaxMessage);
        };
    }, []);


    useEffect(() => {
        if (taxAuthorityData.resumeTaxAuthorityVatOperation) {
            TaxAuthoritySendVatReport(taxAuthorityData.UpdatedAdd, token);
        }
    }, [taxAuthorityData.resumeTaxAuthorityVatOperation]);

    const TaxAuthoritySendVatReport = async (data, token) => {
        try {
            setLoading(true);
            const sendVatReportingDto = {
                CaseId: data.caseId,
                Date: data.endDate, // Set the date to the current date or as required
                VATTransactions: data.VATTransactions,
                OtherVATTaxes: data.OtherVATTaxes,
                EquipmentVAT: data.EquipmentVAT,
                NetVAT: data.NetVAT,
                IncomesNoVat: data.IncomesNoVat,
                IncomesNoZeroVats: data.IncomesNoZeroVats,
                AccountantId: user.accountantId
            };

            const response = await ClientService.sendVatReport(sendVatReportingDto, token);

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(`Failed to send VAT report: ${errorData.message}`);
            }

            const responseData = await response.json();
            if (responseData.Code === 0) {
                throw new Error(`Error Sending ReportedTax for Case ID ${data.caseId} within the date ${sendVatReportingDto.Date}`);
            }

            const formattedDate = new Date(sendVatReportingDto.Date).toLocaleDateString('en-GB', {
                month: '2-digit',
                year: 'numeric'
            }).replace('/', '');

            toast.success(t('ReportedTaxSuccess', { caseId: data.caseId, date: formattedDate }));
            await handleSaveReportTaxManually(data, true);
        } catch (error) {
            const formattedDate = new Date(data.endDate).toLocaleDateString('en-GB', {
                month: '2-digit',
                year: 'numeric'
            }).replace('/', '');

            toast.error(t('ReportedTaxError', { caseId: data.caseId, date: formattedDate }));
            toast.error('Error sending VAT report:')
        }
        handleCloseVatModal();
        setLoading(false);
    };

    useEffect(() => {
        if (taxAuthorityData.resumeTaxAuthorityAdvancesOperation) {
            TaxAuthoritySendAdvancesReport(taxAuthorityData.UpdatedAdd, token);
        }
    }, [taxAuthorityData.resumeTaxAuthorityAdvancesOperation]);

    const TaxAuthoritySendAdvancesReport = async (data, token) => {
        try {
            setLoading(true);


            // Fetch the client details based on the caseId
            const client = filteredAndSortedData.find(client => client.caseId === data.caseId);

            // Determine the PaymentAmount based on useIncomeTaxAdvancePercentage
            let paymentAmount;
            if (client.useIncomeTaxAdvancePercentage) {
                paymentAmount = (data.witholdingIncomesNoVat * (data.incomeTaxAdvancePercentage / 100)) - data.acceptedWithholdingTax;
            } else {
                paymentAmount = data.incomeTaxAdvanceAmount - data.acceptedWithholdingTax;
            }

            const sendAdvancesReportingDto = {
                CaseId: data.caseId,
                Period: data.endDate,
                Income: data.witholdingIncomesNoVat,
                TaxWithHolding: data.acceptedWithholdingTax,
                PaymentAmount: paymentAmount,
                IncomeTaxAdvancePercentage: data.incomeTaxAdvancePercentage,
                IncomeTaxAdvanceAmount: data.incomeTaxAdvanceAmount,
                AccountantId: user.accountantId
            };

            const response = await ClientService.sendAdvancesReport(sendAdvancesReportingDto, token);

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(`Failed to send VAT report: ${errorData.message}`);
            }

            const responseData = await response.json();
            if (responseData.Code === 0) {
                throw new Error(`Error Sending ReportedTax for Case ID ${data.caseId} within the date ${sendAdvancesReportingDto.Date}`);
            }

            const formattedDate = new Date(sendAdvancesReportingDto.Period).toLocaleDateString('en-GB', {
                month: '2-digit',
                year: 'numeric'
            }).replace('/', '');

            toast.success(t('AdvancesReportSuccess', { caseId: data.caseId, date: formattedDate }));
            await handleSaveReportTaxManually(data, false);
        } catch (error) {
            const formattedDate = new Date(data.endDate).toLocaleDateString('en-GB', {
                month: '2-digit',
                year: 'numeric'
            }).replace('/', '');
            console.error(error);

            toast.error(t('AdvancesReportError', { caseId: data.caseId, date: formattedDate }));
        }
        handleCloseWithholdingTaxModal()
        setLoading(false);
    };

    const handleConfirmVatReport = async () => {
        const { UpdatedAdd } = taxAuthorityData;
        setIsModalVisible(false);
        try {
            const tokenData = await TaxAuthorityService.requestTokenAccountant(user.accountantId, Constants.TaxAuthorityScopes['VatReport'], token);

            if (tokenData?.authUrl) {
                setTaxAuthorityData((prevState) => ({
                    ...prevState,
                    authUrl: tokenData.authUrl,
                    resumeTaxAuthorityVatOperation: false
                }));
                const authWindow = window.open(tokenData.authUrl, '_blank');
                setOpenVatModal(false);
                startAuthCompletionCheck(authWindow, Constants.TaxAuthorityScopes['VatReport']);
            } else if (tokenData?.accessToken) {
                await TaxAuthoritySendVatReport(UpdatedAdd, token);
            }
        } catch (error) {
            console.error('Error fetching token:', error);
            toast.error(t('ErrorFetchingToken'));
            setLoading(false);
        }
    };

    const handleConfirmTaxAdvancesReport = async () => {
        const { UpdatedAdd } = taxAuthorityData;
        setIsModalVisible(false);

        try {
            const tokenData = await TaxAuthorityService.requestTokenAccountant(user.accountantId, Constants.TaxAuthorityScopes['AdvancesPayment'], token);

            if (tokenData?.authUrl) {
                setTaxAuthorityData((prevState) => ({
                    ...prevState,
                    authUrl: tokenData.authUrl,
                    resumeTaxAuthorityAdvancesOperation: false
                }));
                const authWindow = window.open(tokenData.authUrl, '_blank');
                setOpenVatModal(false);
                startAuthCompletionCheck(authWindow, Constants.TaxAuthorityScopes['AdvancesPayment']);
            } else if (tokenData?.accessToken) {
                await TaxAuthoritySendAdvancesReport(UpdatedAdd, token);
            }
        } catch (error) {
            console.error('Error fetching token:', error);
            toast.error(t('ErrorFetchingToken'));
            setLoading(false);
        }
    };
    const handleConfirm = async () => {
        if (actionType === 'VatReport') {
            await handleConfirmVatReport();
        } else if (actionType === 'TaxAdvancesReport') {
            await handleConfirmTaxAdvancesReport();
        }
    };

    const getConfirmMessage = () => {
        switch (actionType) {
            case 'VatReport':
                return t('AreYouSureSendVatReport');
            case 'TaxAdvancesReport':
                return t('AreYouSureSendTaxAdvancesReport');


        }
    };

    const getTitleMessage = () => {
        switch (actionType) {

            case 'VatReport':
                return t('ConfirmVatReport');
            case 'TaxAdvancesReport':
                return t('ConfirmTaxAdvancesReport');

        }
    };
    // const handleDownloadPdf = () => {
    //     const element = vatContentRef.current;

    //     const opt = {
    //         margin: 1,
    //         image: {type: 'jpeg', quality: 0.98},
    //         html2canvas: {scale: 2},
    //         pagebreak: {mode: 'avoid-all'},
    //         jsPDF: {unit: 'mm', format: 'a4', orientation: 'l'}

    //     };

    //     // Generate the PDF
    //     html2pdf().from(element).set(opt).save();
    // };

    const handleDownloadPdf = (pdfRef) => {
        html2canvas(pdfRef.current).then((canvas) => {
            // Convert the canvas to a data URL (image format)
            const data = canvas.toDataURL("image/png");

            // Initialize jsPDF with the appropriate options
            const doc = new jsPDF({
                orientation: "l", // Landscape orientation
                unit: "px", // Unit in pixels
                format: "a4", // A4 paper format
            });

            // Calculate the width and height to fit the A4 page size
            const imgProps = doc.getImageProperties(data);
            const pdfWidth = doc.internal.pageSize.getWidth();
            const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

            // Add the image to the PDF
            doc.addImage(data, "JPEG", 0, 0, pdfWidth, pdfHeight);

            // Save the generated PDF
            doc.save("sample-file.pdf");
        });
    };


    return (
        <>


            <HamburgerMenu />
            <div className="template-page-withoutHeight">
                <div className="template-container">
                    <UpperPanelDetails />



                    <div className={`title-back-container ${direction}`} style={{ justifyContent: 'space-between' }}>
                        <div className={`main-title ${direction}`}>{t('ReportTracking')}</div>

                        <div className='backIconBackground' style={{ alignSelf: i18n.language === 'en' ? 'flex-start' : 'flex-end' }} onClick={handleGoBack}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                                <path d="M20 21.1667L14.8267 15.9933L20 10.82C20.52 10.3 20.52 9.45999 20 8.93999C19.48 8.41999 18.64 8.41999 18.12 8.93999L12 15.06C11.48 15.58 11.48 16.42 12 16.94L18.12 23.06C18.64 23.58 19.48 23.58 20 23.06C20.5067 22.54 20.52 21.6867 20 21.1667Z" fill="black" fill-opacity="0.9" />
                            </svg>
                        </div>
                    </div>
                    <div>
                        {/* <Button variant="contained" onClick={handleOpenVatModal}>Show VAT Report</Button>
                            <Button variant="contained" onClick={handleOpenWithholdingTaxModal}>Show Withholding Tax Report</Button> */}

                        <Modal
                            open={openVatModal}
                            onClose={handleCloseVatModal}
                            aria-labelledby="vat-modal-title"
                            aria-describedby="vat-modal-description"
                        >
                            <>
                                <Box sx={{ ...modalStyle }}>
                                    <div className='advanceVatTaxContainer'>
                                        <div className='advanceVatTaxTitleCloseContainer'>
                                            <div className='advanceVatTaxClose' onClick={handleCloseVatModal}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                    <path d="M15.2501 4.75831C14.9251 4.43331 14.4001 4.43331 14.0751 4.75831L10.0001 8.82498L5.9251 4.74998C5.6001 4.42498 5.0751 4.42498 4.7501 4.74998C4.4251 5.07498 4.4251 5.59998 4.7501 5.92498L8.8251 9.99998L4.7501 14.075C4.4251 14.4 4.4251 14.925 4.7501 15.25C5.0751 15.575 5.6001 15.575 5.9251 15.25L10.0001 11.175L14.0751 15.25C14.4001 15.575 14.9251 15.575 15.2501 15.25C15.5751 14.925 15.5751 14.4 15.2501 14.075L11.1751 9.99998L15.2501 5.92498C15.5668 5.60831 15.5668 5.07498 15.2501 4.75831Z" fill="black" fill-opacity="0.8" />
                                                </svg>
                                            </div>
                                            <div className='advanceVatTaxTitle' >
                                                {t("VATReport")}
                                            </div>
                                        </div>
                                        <div style={{ margin: '0 auto' }} ref={vatContentRef} dangerouslySetInnerHTML={{ __html: vatHtmlContent }} />
                                        <div style={{ marginTop: '20px', textAlign: 'center' }}>
                                            <button onClick={() => handleDownloadPdf(vatContentRef)} className='advanceVatTax-download-button'>
                                                {t('DownloadPDFFile')}
                                            </button>
                                            <div className={`report-Type-radio-container ${direction}`}>
                                                <RadioGroup
                                                    row
                                                    aria-labelledby="demo-form-control-label-placement"
                                                    name="position"
                                                    defaultValue="top"
                                                    onChange={handleRadioChange}
                                                >
                                                    <div style={{ marginTop: '20px', textAlign: 'center', alignSelf: 'flex-start' }}>

                                                        <div className='report-Type-radio-container'>
                                                            <FormControlLabel
                                                                value="automatic"
                                                                control={<Radio />}
                                                                label={t('AutomaticTaxAuthorityReporting')}
                                                                labelPlacement="end" // Changed to place label beside the radio
                                                                checked={reportingWay.toString() === "automatic"}
                                                            />

                                                            <FormControlLabel
                                                                value="manual"
                                                                control={<Radio />}
                                                                label={t('ManualReporting')}
                                                                labelPlacement="end" // Changed to place label beside the radio
                                                                checked={reportingWay.toString() === "manual"}
                                                            />

                                                            <FormControlLabel
                                                                value="unreported"
                                                                control={<Radio />}
                                                                label={t('Unreported')}
                                                                labelPlacement="end" // Changed to place label beside the radio
                                                                checked={reportingWay.toString() === "unreported"}
                                                            />
                                                        </div>
                                                    </div>

                                                </RadioGroup>
                                            </div>
                                        </div>
                                        <div className='save-buttons-container-vat-withholding'>
                                            <button className='sub-add-subAcc-content-button' onClick={handleSaveVatReport}>
                                                {t('save')}
                                            </button>
                                            <button className='sub-close-subAcc-content-button' onClick={handleCloseVatModal}>{t('close')}</button>
                                        </div>
                                    </div>

                                </Box>
                                <CustomDeleteConfirmationModal
                                    isVisible={isModalVisible}
                                    onClose={() => setIsModalVisible(false)}
                                    onDelete={handleConfirm}
                                    confirmMessage={getConfirmMessage()}
                                    titleMessage={getTitleMessage()}
                                    type={'sendReport'}
                                />
                            </>
                        </Modal>

                        <Modal
                            open={openWithholdingTaxModal}
                            onClose={handleCloseWithholdingTaxModal}
                            aria-labelledby="withholding-tax-modal-title"
                            aria-describedby="withholding-tax-modal-description"
                        >
                            <>
                                <Box sx={{ ...modalStyle }}>
                                    <div className={`advanceVatTaxContainer ${direction}`}>
                                        <div className='advanceVatTaxTitleCloseContainer'>
                                            <div className='advanceVatTaxTitle' >
                                                {t("AdvancesReport")}
                                            </div>
                                            <div className='advanceVatTaxClose' onClick={handleCloseWithholdingTaxModal}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                    <path d="M15.2501 4.75831C14.9251 4.43331 14.4001 4.43331 14.0751 4.75831L10.0001 8.82498L5.9251 4.74998C5.6001 4.42498 5.0751 4.42498 4.7501 4.74998C4.4251 5.07498 4.4251 5.59998 4.7501 5.92498L8.8251 9.99998L4.7501 14.075C4.4251 14.4 4.4251 14.925 4.7501 15.25C5.0751 15.575 5.6001 15.575 5.9251 15.25L10.0001 11.175L14.0751 15.25C14.4001 15.575 14.9251 15.575 15.2501 15.25C15.5751 14.925 15.5751 14.4 15.2501 14.075L11.1751 9.99998L15.2501 5.92498C15.5668 5.60831 15.5668 5.07498 15.2501 4.75831Z" fill="black" fill-opacity="0.8" />
                                                </svg>
                                            </div>
                                        </div>
                                        <div style={{ margin: '0 auto' }} ref={withholdingContentRef} dangerouslySetInnerHTML={{ __html: withholdingTaxHtmlContent }} />
                                        <div style={{ marginTop: '20px', textAlign: 'center',  }}>
                                            <button onClick={() => handleDownloadPdf(withholdingContentRef)} className='advanceVatTax-download-button'>
                                                {t('DownloadPDFFile')}
                                            </button>
                                            <div className={`report-Type-radio-container ${direction}`}>
                                                <RadioGroup
                                                    row
                                                    aria-labelledby="demo-form-control-label-placement"
                                                    name="position"
                                                    defaultValue="top"
                                                    onChange={handleRadioChange}
                                                >
                                                    <div style={{ marginTop: '20px', textAlign: 'center', alignSelf: 'flex-start' }}>

                                                        <div className='report-Type-radio-container'>
                                                            <FormControlLabel
                                                                value="automatic"
                                                                control={<Radio />}
                                                                label={t('AutomaticTaxAuthorityReporting')}
                                                                labelPlacement="end"
                                                                checked={reportingWay.toString() === "automatic"}

                                                            />

                                                            <FormControlLabel
                                                                value="manual"
                                                                control={<Radio />}
                                                                label={t('ManualReporting')}
                                                                labelPlacement="end"
                                                                checked={reportingWay.toString() === "manual"}
                                                            />

                                                            <FormControlLabel
                                                                value="unreported"
                                                                control={<Radio />}
                                                                label={t('Unreported')}
                                                                labelPlacement="end"
                                                                checked={reportingWay.toString() === "unreported"}
                                                            />
                                                        </div>
                                                    </div>

                                                </RadioGroup>
                                            </div>
                                        </div>
                                        <div className='save-buttons-container-vat-withholding'>
                                            <button className='sub-close-subAcc-content-button' onClick={handleCloseWithholdingTaxModal}>{t('close')}</button>
                                            <button className='sub-add-subAcc-content-button' onClick={handleSaveWithholdingTaxReport}>
                                                {t('save')}
                                            </button>
                                        </div>
                                    </div>
                                </Box>
                                <CustomDeleteConfirmationModal
                                    isVisible={isModalVisible}
                                    onClose={() => setIsModalVisible(false)}
                                    onDelete={handleConfirm}
                                    confirmMessage={getConfirmMessage()}
                                    titleMessage={getTitleMessage()}
                                    type={'sendReport'}
                                />
                            </>
                        </Modal>
                    </div>

                    <div className={`reportTracking-filters-container ${direction}`}>

                        <TextField
                            fullWidth
                            placeholder={t('searchClient')}
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                            className="search-bar"
                            style={{ height: '55px' }}
                            variant="standard"
                            InputProps={{
                                disableUnderline: true,
                                className: `custom-input-box-sizing ${direction === 'ltr' ? 'ltr-input' : 'rtl-input'}`,
                                sx: {
                                    height: '55px',
                                    padding: '0 10px',
                                    borderRadius: '8px',
                                    background: '#F5F5F6',
                                    border: '1px solid transparent',
                                    '&:focus-within': {
                                        border: '2px solid var(--Foundation-Blue-Normal, #304FFF)',
                                    },
                                },
                                ...(direction === 'ltr'
                                    ? {
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton edge="end" style={{ marginRight: '10px' }}>
                                                    <SearchIcon />
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }
                                    : {
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <IconButton edge="start" style={{ marginLeft: '10px' }}>
                                                    <SearchIcon />
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }),
                            }}
                            inputProps={{
                                style: {
                                    textAlign: direction === 'ltr' ? 'left' : 'right',
                                },
                            }}
                        />
                        <AutocompleteSelect
                            options={subAccountants}
                            selectedValues={optionSelected}
                            onChange={handleSubAccChange}
                            label={t("SubAccountant")}
                            placeholder={t("SubAccountant")}
                        />

                        <FilterOptionMenu
                            optionGroups={optionGroups}
                            selectedOptions={selectedFilterOptions}
                            setSelectedOptions={handleFilterOptionChange}
                        />

                        {isRange ? (
                            <>
                                <CustomMonthDatePicker
                                    onDateChange={(date) => handleStartDateChange(date)}
                                    date={dayjs(startDate)}
                                    label={t("StartDate")}
                                    height='55px'
                                />

                                <FormControlLabel
                                    value={isRange}
                                    control={<Switch
                                        className="custom-input-box-sizing-toggle"
                                        color="primary"
                                        checked={isRange}
                                        onChange={handleRangeChange}
                                    />}
                                    label={t("DateRange")}
                                    labelPlacement="bottom"
                                />

                                <CustomMonthDatePicker
                                    onDateChange={(date) => handleEndDateChange(date)}
                                    date={dayjs(endDate)}
                                    label={t("EndDate")}
                                    height='55px'
                                />
                            </>
                        ) : (
                            <>
                                <CustomMonthDatePicker
                                    onDateChange={(date) => handleSetSortDate(date)}
                                    date={dayjs(sortDate)}
                                    label={t('Period')}
                                    direction={direction}
                                    height='55px'
                                />


                                <FormControlLabel
                                    value={!isRange}
                                    control={<Switch
                                        className="custom-input-box-sizing-toggle"
                                        color="primary"
                                        checked={isRange}
                                        onChange={handleRangeChange}

                                    />}
                                    label={t("DateRange")}
                                    labelPlacement="bottom"
                                />
                            </>
                        )}
                    </div>
                    <div className='table-container' style={{ overflowX: 'hidden', marginTop: '30px' }} >

                        {/* <div
                            ref={customScrollbarRef}
                            className="custom-scrollbar"
                            onScroll={handleCustomScroll}

                        >
                            <div style={{ width: tableContainerRef.current ? tableContainerRef.current.scrollWidth : '100%' }}></div>
                        </div> */}

                        {/* current table */}

                        <TableContainer component={Paper} sx={{ height: '60vh', overflow: 'auto' }} ref={tableContainerRef}>
                            <Table aria-label="collapsible table" stickyHeader className="sticky-header" >
                                <TableHead ref={tableContainerRef} onScroll={handleTableScroll} >
                                    <TableRow sx={headerStyle}>
                                        {
                                            !isRange &&
                                            <TableCell sx={headerStyle} align="right" className='headerTH rightText' onClick={() => handleSortChange('status', 'number')}>
                                                {t('status')}
                                                {sortConfig.key === 'status' ? (sortConfig.direction === 'ascending' ? ' ▲' : ' ▼') : ''}
                                            </TableCell>
                                        }

                                        <TableCell sx={headerStyle} align="right" className='headerTH rightText' onClick={() => handleSortChange('difference', 'number')}>
                                            {t('Difference')}
                                            {sortConfig.key === 'difference' ? (sortConfig.direction === 'ascending' ? ' ▲' : ' ▼') : ''}
                                        </TableCell>
                                        <TableCell sx={headerStyle} align="right" className='headerTH rightText' onClick={() => handleSortChange('startDate', 'date')}>
                                            {t('Period')}
                                            {sortConfig.key === 'startDate' ? (sortConfig.direction === 'ascending' ? ' ▲' : ' ▼') : ''}
                                        </TableCell>
                                        <TableCell sx={headerStyle} align="right" className='headerTH rightText' onClick={() => handleSortChange('subAccountantId', 'string')}>
                                            {t('SubAccountant')}
                                            {sortConfig.key === 'subAccountantId' ? (sortConfig.direction === 'ascending' ? ' ▲' : ' ▼') : ''}
                                        </TableCell>
                                        <TableCell sx={headerStyle} align="right" className='headerTH rightText' onClick={() => handleSortChange('reportType', 'string')}>
                                            {t('ClientReportType')}
                                            {sortConfig.key === 'reportType' ? (sortConfig.direction === 'ascending' ? ' ▲' : ' ▼') : ''}
                                        </TableCell>

                                        <TableCell sx={headerStyle} align="right" className='headerTH rightText' onClick={() => handleSortChange('caseId', 'string')}>
                                            {t('CompanyId')}
                                            {sortConfig.key === 'caseId' && (sortConfig.direction === 'ascending' ? ' ▲' : ' ▼')}
                                        </TableCell>
                                        <TableCell sx={headerStyle} align="right" className='headerTH rightText' onClick={() => handleSortChange('clientName', 'string')}>
                                            {t('Client Name')}
                                            {sortConfig.key === 'clientName' && (sortConfig.direction === 'ascending' ? ' ▲' : ' ▼')}
                                        </TableCell>
                                        <TableCell sx={headerStyle} align="right" >

                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {memoizedRows}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>

                </div>
            </div>


            <ToastContainer />
            {loading && (
                <div className="loader-container">
                    <PuffLoader
                        size={100}        // Larger size for better visibility
                        loading={loading}
                    />
                </div>
            )}


        </>

    );
}
const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '60%',
    height: '100%',
    // bgcolor: 'background.paper',
    borderRadius: "var(--Spacing-xl, 16px)",
    background: "var(--white, #FFF)",
    boxShadow: 24,
    p: 4,
    maxHeight: '80%',
    overflowY: 'auto'
};

export default ReportTracking;
