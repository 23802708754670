// LabelService.js
import config from '../config';
import selectedEnvironment from '../env';

const BASEAPI = config[selectedEnvironment].CHAT_URL;

const LABELS_API_URL = `${BASEAPI}/api/Labels`;

const getCurrentToken = async () => {
    const token =  localStorage.getItem('token');
    return token ? JSON.parse(token) : null;
};

const LabelService = {
    createLabel: async (labelName) => {
        const token = await getCurrentToken();
        const response = await fetch(`${LABELS_API_URL}/Create`, {
            method: 'POST',
            headers: {
                Authorization: 'Bearer ' + token,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ LabelName: labelName }),
        });

        if (!response.ok) {
            throw new Error('Failed to create label');
        }
        return response.json();
    },

    addUsersToLabel: async (labelId, selectedToAdd) => {
        const token = await getCurrentToken();
        const usersArray = Array.from(selectedToAdd);
        
        const response = await fetch(`${LABELS_API_URL}/AddUsers`, {
            method: 'POST',
            headers: {
                Authorization: 'Bearer ' + token,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ LabelId: labelId, users: usersArray }),
        });

        if (!response.ok) {
            throw new Error('Failed to add user to label');
        }
    },

    addUserToLabel: async (labelId, userId, userType) => {
        const token = await getCurrentToken();
        const response = await fetch(`${LABELS_API_URL}/AddUser`, {
            method: 'POST',
            headers: {
                Authorization: 'Bearer ' + token,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ LabelId: labelId, UserId: userId, UserType: userType }),
        });

        if (!response.ok) {
            throw new Error('Failed to add user to label');
        }
    },

    removeUserFromLabel: async (labelId, userId, userType) => {
        const token = await getCurrentToken();
        const response = await fetch(`${LABELS_API_URL}/RemoveUser`, {
            method: 'POST',
            headers: {
                Authorization: 'Bearer ' + token,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ LabelId: labelId, UserId: userId, UserType: userType }),
        });

        if (!response.ok) {
            throw new Error('Failed to remove user from label');
        }
    },

    removeUsersFromLabel: async (labelId, selectedToRemove) => {
        const token = await getCurrentToken();
        const usersArray = Array.from(selectedToRemove);

        const response = await fetch(`${LABELS_API_URL}/RemoveUsers`, {
            method: 'POST',
            headers: {
                Authorization: 'Bearer ' + token,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ LabelId: labelId, users: usersArray }),
        });

        if (!response.ok) {
            throw new Error('Failed to remove user from label');
        }
    },

    listLabels: async () => {
        const token = await getCurrentToken();

        
        const response = await fetch(`${LABELS_API_URL}/List`, {
            method: 'GET',
            headers: {
                Authorization: 'Bearer ' + token,
                'Content-Type': 'application/json',
            },
        });

        if (!response.ok) {
            
            throw new Error('Failed to list labels');
        }
        return response.json();
    },
    updateLabelName: async (labelId, newLabelName) => {
        const token = await getCurrentToken();
        const response = await fetch(`${LABELS_API_URL}/Update`, {
            method: 'POST',
            headers: {
                Authorization: 'Bearer ' + token,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ LabelId: labelId, NewLabelName: newLabelName }),
        });

        if (!response.ok) {
            throw new Error('Failed to update label name');
        }
        return response.text();
    },
    deleteLabel: async (labelId) => {
        const token = await getCurrentToken();
        const response = await fetch(`${LABELS_API_URL}/Delete`, {
            method: 'POST',
            headers: {
                Authorization: 'Bearer ' + token,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ LabelId: labelId }),
        });

        if (!response.ok) {
            throw new Error('Failed to delete label');
        }
        return response.text();
    },
};

export default LabelService;
