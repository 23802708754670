import React, { useRef } from 'react';
import { useVirtualizer } from '@tanstack/react-virtual';
import ContactItem from './ContactItem';
import '../Chat.css';

const ContactsList = ({ filteredAllowedUsers, onUserSelect, t, isLoading }) => {
    const parentRef = useRef();

    const rowVirtualizer = useVirtualizer({
        count: filteredAllowedUsers.length,
        getScrollElement: () => parentRef.current,
        estimateSize: () => 70, // Estimated height of each row
        overscan: 5,
    });

    return (
        <div
            ref={parentRef}
            className="chat-list-container"
           
        >
            {isLoading ? (
                <div className="loading-spinner">{t('Loading...')}</div>
            ) : filteredAllowedUsers.length === 0 ? (
                <div className="empty-message">{t('NoUsers')}</div>
            ) : (
                <div
                    style={{
                        height: `${rowVirtualizer.getTotalSize()}px`,
                        position: 'relative',
                    }}
                >
                    {rowVirtualizer.getVirtualItems().map((virtualRow) => {
                        const item = filteredAllowedUsers[virtualRow.index];
                        return (
                            <div
                                key={virtualRow.key}
                                ref={virtualRow.measureElement}
                                style={{
                                    backgroundColor: virtualRow.index % 2 === 0 ? '#fff' : '#f4f4f4', // Alternate row colors
                                }}
                                className="contact-item-row"
                            >
                                <ContactItem item={item} onView={onUserSelect} t={t} />
                            </div>
                        );
                    })}
                </div>
            )}
        </div>
    );
};

export default ContactsList;
