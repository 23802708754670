// chat.service.js

import config from '../config';
import selectedEnvironment from '../env';
import AuthService from './auth.service';
const BASEAPI = config[selectedEnvironment].CHAT_URL;
const API_URL = `${BASEAPI}/api/ChatMessages`;

// Function to get the current token
const getCurrentToken = async () => {
  const token = localStorage.getItem('token');
  return token != null ? JSON.parse(token) : null;
};

// Function to get the current user
const getCurrentUser = async () => {
  const user = localStorage.getItem('user');
  return user != null ? JSON.parse(user) : null;
};

// Function to send a message
const sendMessage = async (messageDto) => {
  const token = await getCurrentToken();
  const accountantId = await AuthService.getCurrentUserTypeAccountantId();
  messageDto.append('AccountantId', accountantId);
  try {
    const response = await fetch(`${API_URL}/SendMessage`, {
      method: 'POST',
      headers: {
        'Authorization': 'Bearer ' + token,
        // 'Content-Type': 'application/json',
      },
      body: messageDto,
    });

    if (!response.ok) {
      const errorBody = await response.text();
      throw new Error(`Failed to send message: ${errorBody}`);
    }

    const data = await response.text();
    return data;
  } catch (error) {
    console.error('Error sending message:', error);
    throw error;
  }
};

const sendLabelMessage = async (messageDto) => {
  const token = await getCurrentToken();
  const accountantId = await AuthService.getCurrentUserTypeAccountantId();
  messageDto.append('AccountantId', accountantId);
  try {
    const response = await fetch(`${API_URL}/SendLabelMessage`, {
      method: 'POST',
      headers: {
        'Authorization': 'Bearer ' + token,
        // 'Content-Type': 'application/json',
      },
      body: messageDto,
    });

    if (!response.ok) {
      const errorBody = await response.text();
      throw new Error(`Failed to send message: ${errorBody}`);
    }

    const data = await response.text();
    return data;
  } catch (error) {
    console.error('Error sending message:', error);
    throw error;
  }
};

// Function to fetch messages
const fetchMessages = async (senderId, senderType, receiverId, receiverType, pageNumber = 1, pageSize = 20, GetAllForReceiver = false) => {
  const token = await getCurrentToken();

  try {
    const response = await fetch(`${API_URL}/GetMessages`, {
      method: 'POST',
      headers: {
        'Authorization': 'Bearer ' + token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        UserId: senderId,
        UserType: senderType,
        senderId,
        senderType,
        receiverId,
        receiverType,
        pageNumber,
        pageSize,
        GetAllForReceiver
      }),
    });

    if (!response.ok) {
      const errorBody = await response.text();
      throw new Error(`Failed to fetch messages: ${errorBody}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error fetching messages:', error);
    throw error;
  }
};

// Function to mark messages as read
const readMessage = async (receiverId, receiverType, senderId, senderType, update = false) => {
  const token = await getCurrentToken();

  try {
    const response = await fetch(`${API_URL}/ReadMessage`, {
      method: 'POST',
      headers: {
        'Authorization': 'Bearer ' + token,
        'Content-Type': 'application/json',
      },

      body: JSON.stringify({
        ReceiverId: receiverId,
        ReceiverType: parseInt(receiverType),
        SenderId: senderId,
        SenderType: parseInt(senderType),
        update
      }),
    });

    if (!response.ok) {
      const errorBody = await response.text();
      throw new Error(`Failed to mark messages as read: ${errorBody}`);
    }

    const data = await response.text();
    return data;
  } catch (error) {
    console.error('Error marking messages as read:', error);
    throw error;
  }
};

// Function to get allowed users
const getAllowedUsers = async (userId, userType) => {
  const token = await getCurrentToken();

  try {
    const response = await fetch(`${API_URL}/GetAllowedUsers`, {
      method: 'POST',
      headers: {
        'Authorization': 'Bearer ' + token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        UserId: userId,
        UserType: parseInt(userType),
      }),
    });


    if (!response.ok) {
      const errorBody = await response.text();
      throw new Error(`Failed to get allowed users: ${errorBody}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {

    console.error('Error fetching allowed users:', error);
    throw error;
  }
};

// Function to upload files (images, documents, etc.)
const uploadFile = async (file) => {
  const token = await getCurrentToken();

  const formData = new FormData();
  formData.append('file', {
    uri: file.uri,
    type: file.type,
    name: file.name || file.fileName,
  });

  try {
    const response = await fetch(`${API_URL}/UploadFile`, {
      method: 'POST',
      headers: {
        'Authorization': 'Bearer ' + token,
        'Accept': 'application/json',
        'Content-Type': 'multipart/form-data',
      },
      body: formData,
    });

    if (!response.ok) {
      const errorBody = await response.text();
      throw new Error(`Failed to upload file: ${errorBody}`);
    }

    const data = await response.json();
    return data; // Should return the file key or URL
  } catch (error) {
    console.error('Error uploading file:', error);
    throw error;
  }
};

// Function to get unread message count (if applicable)
const getUnreadCount = async (userId, userType, targetUserId, targetUserType) => {
  const token = await getCurrentToken();

  try {
    const response = await fetch(`${API_URL}/GetUnreadCount`, {
      method: 'POST',
      headers: {
        'Authorization': 'Bearer ' + token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        UserId: userId,
        UserType: parseInt(userType),
        TargetUserId: targetUserId,
        TargetUserType: parseInt(targetUserType),
      }),
    });

    if (!response.ok) {
      const errorBody = await response.text();
      throw new Error(`Failed to get unread count: ${errorBody}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error fetching unread count:', error);
    throw error;
  }
};


const getUnreadCountSummary = async (userId, userType) => {
  const token = await getCurrentToken();

  try {
    const response = await fetch(`${API_URL}/GetUnreadSummary`, {
      method: 'POST',
      headers: {
        'Authorization': 'Bearer ' + token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        UserId: userId,
        UserType: parseInt(userType),
      }),
    });

    if (!response.ok) {
      const errorBody = await response.text();
      throw new Error(`Failed to get unread count: ${errorBody}`);
    }

    const data = await response.json();
    return data.totalUnread || 0;
  } catch (error) {
    console.error('Error fetching unread count:', error);
    throw error;
  }
};
// Function to get user typing status (if applicable)
const sendTypingStatus = async (targetUserId, targetUserType) => {
  const token = await getCurrentToken();

  try {
    const response = await fetch(`${API_URL}/SendTypingStatus`, {
      method: 'POST',
      headers: {
        'Authorization': 'Bearer ' + token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        TargetUserId: targetUserId,
        TargetUserType: parseInt(targetUserType),
      }),
    });

    if (!response.ok) {
      const errorBody = await response.text();
      throw new Error(`Failed to send typing status: ${errorBody}`);
    }

    return;
  } catch (error) {
    console.error('Error sending typing status:', error);
    throw error;
  }
};

const checkUserOnlineStatus = async (userId) => {
  const token = await getCurrentToken();

  try {
    const response = await fetch(`${API_URL}/CheckUserOnlineStatus/${userId}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      const errorBody = await response.text();
      throw new Error(`Failed to send typing status: ${errorBody}`);
    }

    const data = await response.json();

    return data.isOnline;
  } catch (error) {
    console.error('Error sending typing status:', error);
    throw error;
  }
};
const searchMessages = async (senderId, senderType, receiverId, receiverType, query) => {
  const token = await getCurrentToken();
  const payload = {
    SenderId: senderId,
    SenderType: senderType,
    ReceiverId: receiverId,
    ReceiverType: receiverType,
    Query: query
  };
  const response = await fetch(`${API_URL}/SearchMessages`, {
    method: 'POST',
    headers: {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  });
  if (!response.ok) {
    throw new Error('Failed to search messages');
  }
  const data = await response.json();
  // data expected to be an array of { messageId, excerpt, pageNumber }
  return data;
}

const deleteMessage = async (messageId) => {
  const token = await getCurrentToken();

  try {
    const response = await fetch(`${API_URL}/DeleteMessage/${messageId}`, {
      method: 'DELETE',
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      const errorBody = await response.text();
      throw new Error(`Failed to delete message: ${errorBody}`);
    }

    return true; // Return success
  } catch (error) {
    console.error('Error deleting message:', error);
    throw error;
  }
};

// ChatService object
const ChatApiService = {
  sendMessage,
  sendLabelMessage,
  fetchMessages,
  readMessage,
  getAllowedUsers,
  uploadFile,
  getUnreadCount,
  sendTypingStatus,
  getUnreadCountSummary,
  checkUserOnlineStatus,
  searchMessages,
  deleteMessage
};

export default ChatApiService;
