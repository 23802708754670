import React, { useEffect } from 'react';
import {
    Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField
} from '@mui/material';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

const EditExternalTransactionDialog = ({
    editBankTransactionDialog,
    handleCloseBankEditTransactionDialog,
    editedTransaction,
    changeNewBankTransaction,
    handleAddBankTransaction,
    handleDelete
}) => {
    const { t, i18n } = useTranslation();
    const direction = i18n.dir();

    useEffect(() => {
        if (!editedTransaction.transactionDate) {
            changeNewBankTransaction('transactionDate', dayjs());
        }
    }, [editBankTransactionDialog, editedTransaction]);


    const handleClose = () => {
        handleCloseBankEditTransactionDialog();
    }

    return (
        <Dialog
            open={editBankTransactionDialog}
            onClose={handleCloseBankEditTransactionDialog}
            aria-labelledby="form-dialog-title"
        >
            
            <DialogTitle id="form-dialog-title" sx={{ textAlign: 'center' }}>
                {t("EditBankCommand")}
            </DialogTitle>
            <DialogContent
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: '16px',
                    textAlign: 'center',
                    minWidth: '300px'
                }}
                className={`${direction}`}
            >
                {/* Description */}
                <div className="form-row-item full-width">
                    <div className="form-row-item-title">
                        {t('Description')}<span className="required-asterisk">*</span>
                    </div>
                    <TextField
                        fullWidth
                        placeholder={t('Description')}
                        value={editedTransaction?.description || ''}
                        onChange={(e) => changeNewBankTransaction('description', e.target.value)}
                        className="add-client-input-style full-width"
                        variant="standard"
                        InputProps={{
                            disableUnderline: true,
                            className: `custom-input-box-sizing-toggle full-width ${direction === 'ltr' ? 'ltr-input' : 'rtl-input'}`,
                            sx: {
                                height: '48px',
                                padding: '0 10px',
                                borderRadius: '8px',
                                background: '#F5F5F6',
                                border: '1px solid transparent',
                                '&:focus-within': {
                                    border: '2px solid #304FFF',
                                },
                            },
                        }}
                        inputProps={{
                            style: {
                                width: '100%',
                                textAlign: direction === 'ltr' ? 'left' : 'right',
                            },
                        }}
                        required
                    />
                </div>
                {/* Reference */}
                <div className="form-row-item full-width">
                    <div className="form-row-item-title">
                        {t('Reference')}<span className="required-asterisk">*</span>
                    </div>
                    <TextField
                        fullWidth
                        placeholder={t('Reference')}
                        value={editedTransaction?.reference1 || ''}
                        onChange={(e) => changeNewBankTransaction('reference', e.target.value)}
                        className="add-client-input-style full-width"
                        variant="standard"
                        InputProps={{
                            disableUnderline: true,
                            className: `custom-input-box-sizing-toggle full-width ${direction === 'ltr' ? 'ltr-input' : 'rtl-input'}`,
                            sx: {
                                height: '48px',
                                padding: '0 10px',
                                borderRadius: '8px',
                                background: '#F5F5F6',
                                border: '1px solid transparent',
                                '&:focus-within': {
                                    border: '2px solid #304FFF',
                                },
                            },
                        }}
                        inputProps={{
                            style: {
                                width: '100%',
                                textAlign: direction === 'ltr' ? 'left' : 'right',
                            },
                        }}
                        required
                    />
                </div>
                {/* Transaction Date */}
                <div className="form-row-item full-width">
                    <div className="form-row-item-title">
                        {t('TransactionDate')}<span className="required-asterisk">*</span>
                    </div>
                    <TextField
                        fullWidth
                        type="date"
                        value={editedTransaction.transactionDate ? dayjs(editedTransaction.transactionDate).format('YYYY-MM-DD') : dayjs().format('YYYY-MM-DD')}
                        onChange={(e) => changeNewBankTransaction('transactionDate', e.target.value)}
                        className="add-client-input-style full-width"
                        variant="standard"
                        InputProps={{
                            disableUnderline: true,
                            className: `custom-input-box-sizing-toggle full-width ${direction === 'ltr' ? 'ltr-input' : 'rtl-input'}`,
                            sx: {
                                height: '48px',
                                padding: '0 10px',
                                borderRadius: '8px',
                                background: '#F5F5F6',
                                border: '1px solid transparent',
                                '&:focus-within': {
                                    border: '2px solid #304FFF',
                                },
                            },
                        }}
                        inputProps={{
                            style: {
                                width: '100%',
                                textAlign: direction === 'ltr' ? 'left' : 'right',
                            },
                        }}
                        required
                    />
                </div>
            

                 {/* amount */}
                 <div className="form-row-item full-width">
                    <div className="form-row-item-title">
                        {t('Amount')}<span className="required-asterisk">*</span>
                    </div>
                    <TextField
                        fullWidth
                        placeholder={t('Amount')}
                        value={editedTransaction.amount || ''}
                        onChange={(e) => changeNewBankTransaction('amount', e.target.value)}
                        className="add-client-input-style full-width"
                        variant="standard"
                        type="number"
                        InputProps={{
                            disableUnderline: true,
                            className: `custom-input-box-sizing-toggle full-width ${direction === 'ltr' ? 'ltr-input' : 'rtl-input'}`,
                            sx: {
                                height: '48px',
                                padding: '0 10px',
                                borderRadius: '8px',
                                background: '#F5F5F6',
                                border: '1px solid transparent',
                                '&:focus-within': {
                                    border: '2px solid #304FFF',
                                },
                            },
                        }}
                        inputProps={{
                            style: {
                                width: '100%',
                                textAlign: direction === 'ltr' ? 'left' : 'right',
                            },
                        }}
                        required
                    />
                </div>

               
            </DialogContent>
            <DialogActions
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    gap: '16px',
                    textAlign: 'center',
                }}
            >
                <Button
                    onClick={handleDelete}
                    color="primary"
                    variant="contained"
                    sx={{
                        backgroundColor: 'white',
                        color: 'var(--Foundation-Yellow-Normal, var(--red, #fa0400))',
                        padding: '10px 20px',
                        fontSize: '16px',
                        fontWeight: 'bold',
                        cursor: 'pointer',
                        marginTop: '20px',
                        borderRadius: 'var(--Spacing-md, 8px)',
                        border: '2px solid var(--red, #fa0400)',
                        boxShadow: '0px 1px 4px 0px rgba(0, 0, 0, 0.25)',
                        '&:hover': {
                            transform: 'scale(1.01)',
                        }
                    }}
                >
                    {t("Delete")}
                </Button>
                <Button
                    onClick={handleClose}
                    color="primary"
                    variant="contained"
                    sx={{
                        backgroundColor: 'white',
                        color: 'var(--Foundation-Yellow-Normal, var(--orange, #F9AA2A))',
                        padding: '10px 20px',
                        fontSize: '16px',
                        fontWeight: 'bold',
                        cursor: 'pointer',
                        marginTop: '20px',
                        borderRadius: 'var(--Spacing-md, 8px)',
                        border: '2px solid var(--orange, #F9AA2A)',
                        boxShadow: '0px 1px 4px 0px rgba(0, 0, 0, 0.25)',
                        '&:hover': {
                            transform: 'scale(1.01)',
                        }
                    }}
                >
                    {t("Cancel")}
                </Button>
                <Button
                    onClick={handleAddBankTransaction}
                    color="primary"
                    variant="contained"
                    sx={{
                        backgroundColor: editedTransaction?.description && editedTransaction.reference1 && editedTransaction.transactionDate? '#304FFF' : '#b0b0b0',
                        color: 'white',
                        padding: '10px 20px',
                        fontSize: '16px',
                        fontWeight: 'bold',
                        cursor: 'pointer',
                        marginTop: '20px',
                        borderRadius: '8px',
                        border: '1px solid var(--foreground, #000)',
                        boxShadow: '0px 1px 4px 0px rgba(0, 0, 0, 0.25)',
                        '&:hover': {
                            transform: 'scale(1.01)',
                            backgroundColor: editedTransaction?.description && editedTransaction.reference1 && editedTransaction.transactionDate ? '#1565c0' : '#b0b0b0',
                        },
                    }}
                    disabled={!(editedTransaction?.description && editedTransaction.reference1 && editedTransaction.transactionDate && editedTransaction.amount)}
                >
                    {t("Edit")}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default EditExternalTransactionDialog;
