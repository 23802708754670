import React, { createContext, useContext, useEffect, useState, useRef, useCallback } from 'react';
import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import config from '../config';
import selectedEnvironment from '../env';

const BASEAPI = config[selectedEnvironment].CHAT_URL;
const HUB_URL = `${BASEAPI}/chathub`;

const ChatContext = createContext(null);

export const ChatProvider = ({ children }) => {
  const [isConnected, setIsConnected] = useState(false);
  const [subscriptions, setSubscriptions] = useState({});
  const [chatToken, setChatToken] = useState(null);
  const subscriptionsRef = useRef({});
  const tokenRef = useRef(null); // Ref to always have the latest token
  const connectionRef = useRef(null);

  // Active user tracking state
  const [activeUsers, setActiveUsers] = useState({});

  useEffect(() => {
    subscriptionsRef.current = subscriptions;
  }, [subscriptions]);

  const getAccessToken = async () => {
    const storedToken = localStorage.getItem('token');
    if (!storedToken) {
      console.error('No token found in localStorage');
      return null;
    }
    try {
      const parsedToken = JSON.parse(storedToken);
      return parsedToken;
    } catch (error) {
      console.error('Error parsing token:', error);
      return null;
    }
  };


  const startConnection = async () => {

    if (!tokenRef.current) {
      console.log('No token found, chat will not connect.');
      return;
    }

    if (connectionRef.current && connectionRef.current.state === 'Connected') {
      console.log('Already connected');
      return;
    }
    
    
    // Use tokenRef.current in the accessTokenFactory
    connectionRef.current = new HubConnectionBuilder()
      .withUrl(HUB_URL, { accessTokenFactory: () => tokenRef.current })
      .withAutomaticReconnect([0, 2000, 5000, 10000])
      .configureLogging(LogLevel.Debug)
      .build();

    registerHandlers(connectionRef.current);

    try {
      await connectionRef.current.start();
      console.log('ChatProvider Global Chat SignalR Connected.');
      console.log('Connection State:', connectionRef.current.state);
      setIsConnected(true);
    } catch (err) {
      console.error('ChatProvider SignalR Connection Error:', err);
    }
  };

  const stopConnection = async () => {
    if (connectionRef.current && isConnected) {
      await connectionRef.current.stop();
      setIsConnected(false);
      console.log('Chat connection stopped.');
    }
  };

  const registerHandlers = (connection) => {
    if (!connection) return;

    connection.onreconnecting(error => {
      setIsConnected(false);
      console.warn('ChatProvider SignalR reconnecting...', error);
    });

    connection.onreconnected(connectionId => {
      console.log('ChatProvider SignalR reconnected. Connection ID:', connectionId);
      setIsConnected(true);
    });

    connection.onclose(error => {
      setIsConnected(false);
      console.warn('ChatProvider SignalR connection closed.', error);
      // Only attempt to reconnect if a token still exists
      if (tokenRef.current) {
        startConnection();
      }
    });

    // Common chat events
    connection.on('UserSend', (senderId, senderType) => {
      emit('UserSend', senderId, senderType);
    });

    connection.on('Typing', (senderId, senderType) => {
      emit('Typing', senderId, senderType);
    });

    connection.on('MessageRead', (senderId, senderType) => {
      emit('MessageRead', senderId, senderType);
    });
    connection.off('IsRecording'); // Remove any existing handlers
    connection.on('IsRecording', (senderId, senderType, isRecording) => {
      console.log(`IsRecording event received: senderId=${senderId}, isRecording=${isRecording}`);
      emit('IsRecording', senderId, senderType, isRecording);
    });
    connection.on('UserActive', (userId, active) => {
      console.log(`User ${userId} active status: ${active}`);
      updateActiveUsers(userId, active);
      emit('UserActive', userId, active);
    });
  };

  const emit = (eventName, ...args) => {
    console.log(' const emit eventName', eventName);
    console.log('Current subscriptions:', subscriptionsRef.current);

    const eventSubscriptions = subscriptionsRef.current[eventName];
    console.log(eventSubscriptions);
    
    if (!eventSubscriptions) {
      // console.log(`No subscription found for event: ${eventName}`);
      return;
    }

    console.log(`Emitting event: ${eventName} with args:`, args);
    eventSubscriptions.forEach(callback => callback(...args));
  };

  const subscribe = useCallback((eventName, callback) => {
    setSubscriptions(prev => {
      const newSubs = { ...prev };
      if (!newSubs[eventName]) {
        newSubs[eventName] = [];
      }
      newSubs[eventName].push(callback);
      console.log(`Subscribed to event: ${eventName}`, newSubs);
      return newSubs;
    });
  }, []);

  const unsubscribe = useCallback((eventName, callback) => {
    setSubscriptions(prev => {
      if (!prev[eventName]) return prev;
      const updatedSubs = {
        ...prev,
        [eventName]: prev[eventName].filter(cb => cb !== callback),
      };
      console.log(`Unsubscribed from event: ${eventName}`, updatedSubs);
      return updatedSubs;
    });
  }, []);

  // Update active users state
  const updateActiveUsers = (userId, isActive) => {
    setActiveUsers(prev => ({
      ...prev,
      [userId]: isActive,
    }));
  };

  // Hub methods
  const sendTyping = async (targetUserId, targetUserType) => {
    if (connectionRef.current && isConnected) {
      try {
        await connectionRef.current.invoke('Typing', targetUserId, targetUserType);
      } catch (error) {
        console.error('Error sending typing notification:', error);
      }
    }
  };

  const sendMessageRead = async (targetUserId, targetUserType) => {
    if (connectionRef.current && isConnected) {
      try {
        await connectionRef.current.invoke('MessageRead', targetUserId, targetUserType);
      } catch (error) {
        console.error('Error sending read receipt:', error);
      }
    }
  };

  const notifyUserSend = async (targetUserId, targetUserType) => {
    console.log("notifyUserSend triggered for:", targetUserId);

    if (connectionRef.current && isConnected) {
      try {
        await connectionRef.current.invoke('UserSend', targetUserId, targetUserType);
      } catch (error) {
        console.error('Error notifying user send:', error);
      }
    }
  };


  const sendUserActive = async (isActive) => {
    if (connectionRef.current && isConnected) {
      try {
        await connectionRef.current.invoke('UserActive', isActive);
      } catch (error) {
        console.error('Error sending user active status:', error);
      }
    }
  };

  useEffect(() => {
    const initToken = async () => {
      const token = await getAccessToken();
      setChatToken(token);
      tokenRef.current = token; // Update ref whenever token is fetched
    };
    initToken();

    return () => {
      // Stop the connection only when ChatProvider unmounts
      if (connectionRef.current && isConnected) {
        connectionRef.current.stop();
        setIsConnected(false);
        console.log('ChatProvider unmounted, connection stopped.');
      }
    };
  }, []);

  useEffect(() => {
    // Whenever chatToken changes, keep tokenRef in sync
    tokenRef.current = chatToken;

    if (chatToken) {
      startConnection();
    } else {
      stopConnection();
    }
  }, [chatToken]);

  const refreshToken = async () => {
    const token = await getAccessToken();
    console.log("refreshToken", token);

    setChatToken(token);
    tokenRef.current = token; // Also update the ref here
  };

  return (
    <ChatContext.Provider value={{
      isConnected,
      activeUsers,
      subscribe,
      unsubscribe,
      sendTyping,
      sendMessageRead,
      notifyUserSend,
      sendUserActive,
      refreshToken,
    }}>
      {children}
    </ChatContext.Provider>
  );
};

export const useChat = () => useContext(ChatContext);
