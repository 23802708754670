/**
 * Returns the Israel Invoice Amount Limit based on the given date.
 *
 * @param {Date|string} date - A Date object or date string to compare
 * @returns {number} - The invoice amount limit
 */
export function getIsraelInvoiceAmountLimit(date) {

    if (!date) {
        return 0;
    }
    // Ensure we have a Date object
    const inputDate = date instanceof Date ? date : new Date(date);

    // Compare against thresholds in descending order (latest date first)
    if (inputDate >= new Date('2028-01-01')) {
        return 5000;
    }
    if (inputDate >= new Date('2027-01-01')) {
        return 10000;
    }
    if (inputDate >= new Date('2026-01-01')) {
        return 15000;
    }
    if (inputDate >= new Date('2025-01-01')) {
        return 20000;
    }

    // Default for dates before 2025-01-01
    return 25000;
}
