import React, { useState, useEffect, memo } from 'react';
import './SelectableUserAvatar.css'; // You'll create this CSS file (example below)

// Helper: Generate initials
function getInitials(name) {
    if (!name) return '';
    const parts = name.trim().split(/\s+/); // split on whitespace
    const initials = parts.map((p) => p[0].toUpperCase()).join('');
    // Limit to 2 chars if needed
    return initials.length > 2 ? initials.slice(0, 2) : initials;
}

// Helper: Generate color from name
function generateColorFromName(name) {
    const colors = [
        '#455A64', // Blue Grey
        '#6D4C41', // Brown
        '#00796B', // Teal
        '#388E3C', // Green
        '#F57C00', // Deep Orange
        '#5D4037', // Dark Brown
        '#7B1FA2', // Purple
        '#1976D2', // Blue
        '#0288D1', // Light Blue
        '#0097A7', // Cyan
    ];

    let hash = 0;
    for (let i = 0; i < name.length; i++) {
        hash = name.charCodeAt(i) + ((hash << 5) - hash);
    }
    const index = Math.abs(hash) % colors.length;
    return colors[index];
}

const SelectableUserAvatar = memo(function SelectableUserAvatar({
    name = '',
    isSelected = false,
    onToggle = () => { },
}) {
    // Keep local state in sync with isSelected
    const [localSelected, setLocalSelected] = useState(isSelected);
    
    useEffect(() => {
        setLocalSelected(isSelected);
    }, [isSelected]);

    // Toggles selection state
    const handleClick = () => {
        // If you also want local UI to reflect changes, update state
        setLocalSelected(!localSelected);
        onToggle(); // Notify parent to handle selection logic
    };

    const initials = getInitials(name);
    const backgroundColor = generateColorFromName(name);

    return (
        <div
            className={`selectable-avatar-container ${localSelected ? 'selected' : ''}`}
            onClick={handleClick}
            style={{
                backgroundColor,
                cursor: 'pointer',
            }}
        >
            {localSelected ? (
                <span className="checkmark">✓</span>
            ) : (
                <span className="initials">{initials}</span>
            )}
        </div>
    );
});

export default SelectableUserAvatar;
