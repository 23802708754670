import React, { useEffect, useState } from 'react';
import { IoArrowBack, IoSearch, IoClose } from 'react-icons/io5';
import { useTranslation } from 'react-i18next';
import '../Chat.css';

function ChatHeader({
  selectedUser,
  isTyping,
  isTargetRecording,
  isOnline,
  deselectUser,
  handleSearchSubmit,
  onCancelSearch,
  isSearchMode,
  setIsSearchMode,
  searchChatQuery,
  setSearchChatQuery,
  getAllForReceiver,
  setGetAllForReceiver,
  userType
}) {
  const { t } = useTranslation();
  
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {
        if (isSearchMode) {
          onCancelSearch();
        } else {
          deselectUser();
        }
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [deselectUser, isSearchMode]);

  return (
    <div className="chat-page-header">
      <div className={`chat-header-details ${isSearchMode ? 'search-mode' : ''}`}>
        {isSearchMode ? (
          <div className="search-input-container">
            <IoSearch className="search-icon" />
            <input
              type="text"
              className="search-chat-input"
              placeholder={t('Search')}
              value={searchChatQuery}
              onChange={(e) => setSearchChatQuery(e.target.value)}
              onKeyPress={(e) => e.key === 'Enter' && handleSearchSubmit()}
              autoFocus
            />
            <IoClose
              className="close-icon"
              onClick={onCancelSearch}
              title={t('Cancel')}
            />
          </div>
        ) : (
          <>
            <div className='chat-header-details2'>
              <h2 className="chat-room-title">{selectedUser?.name}</h2>
              {
                userType.toString() === '2' &&
                <label className="getall-checkbox-label">
                  <input
                    type="checkbox"
                    checked={getAllForReceiver}
                    onChange={(e) => {
                      const isChecked = e.target.checked;

                      setGetAllForReceiver(isChecked);
                    }}
                  />
                  <span>{t('ViewAllMessages')}</span>
                </label>
              }

            </div>
            <div className="chat-status">
              {isTyping && <span className="chat-typing-indicator">{t('Typing')}</span>}
              {isTargetRecording && (
                <span className="chat-recording-indicator">{t('RecordingAudio')}</span>
              )}
              {!isTyping && !isTargetRecording && (
                <span className={`status-badge ${isOnline ? 'online' : 'offline'}`}>
                  {t(isOnline ? 'Online' : 'Offline')}
                </span>
              )}
              {!isSearchMode && (
                <IoSearch
                  className="search-icon-chat"
                  onClick={() => setIsSearchMode(true)}
                  title={t('Search')}
                />
              )}
            </div>
          </>
        )}
      </div>
      <div className="chat-header-icons">
        <IoArrowBack
          className="back-icon-chat"
          onClick={isSearchMode ? onCancelSearch : deselectUser}
          title={t('Back')}
        />

      </div>
    </div>
  );
}

export default ChatHeader;
