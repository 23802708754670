import React, { useState, useEffect } from "react";
import { Modal, Box, Typography, IconButton, Button, CircularProgress } from "@mui/material";
import Close from "@mui/icons-material/Close";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import { useTranslation } from "react-i18next";
function PreviewModal({ open, files, onClose, onSend, onRemove }) {
  const [fileContents, setFileContents] = useState({}); // Store file contents indexed by file name
  const { i18n, t } = useTranslation();

  useEffect(() => {
    const readTextFiles = async () => {
      const contents = {};
      for (const file of files) {
        const fileType = file.type || file.name.split(".").pop().toLowerCase();
        if (fileType.startsWith("text/") || ["txt", "csv"].includes(fileType)) {
          const reader = new FileReader();
          contents[file.name] = await new Promise((resolve) => {
            reader.onload = (e) => resolve(e.target.result);
            reader.readAsText(file);
          });
        }
      }
      setFileContents(contents);
    };

    if (open) {
      readTextFiles();
    }
  }, [files, open]);

  const renderPreview = (file) => {
    const fileType = file.type || file.name.split(".").pop().toLowerCase();

    if (fileType.startsWith("image/") || ["jpg", "jpeg", "png", "gif", "bmp", "webp"].includes(fileType)) {
      return (
        <img
          src={URL.createObjectURL(file)}
          alt={file.name}
          style={{ maxWidth: "100%", maxHeight: 200, borderRadius: 8 }}
        />
      );
    }

    if (fileType === "application/pdf" || fileType === "pdf") {
      return (
        <embed
          src={URL.createObjectURL(file)}
          type="application/pdf"
          style={{ width: "100%", height: 200, borderRadius: 8 }}
        />
      );
    }

    if (["doc", "docx", "xls", "xlsx", "ppt", "pptx"].includes(fileType)) {
      return (
        <div style={{ textAlign: "center" }}>
          <DescriptionOutlinedIcon style={{ fontSize: 50, color: "#304FFF" }} />
          <Typography variant="body2" style={{ marginTop: 8 }}>
            {file.name}
          </Typography>
          <Typography variant="caption" style={{ color: "#888" }}>
            Office file preview not available
          </Typography>
        </div>
      );
    }

    if (fileType.startsWith("text/") || ["txt", "csv"].includes(fileType)) {
      const content = fileContents[file.name] || <CircularProgress size={20} />;
      return (
        <textarea
          value={content}
          readOnly
          style={{
            width: "100%",
            height: 200,
            border: "1px solid #ccc",
            borderRadius: 8,
            padding: 8,
            resize: "none",
          }}
        />
      );
    }

    return (
      <Typography variant="body2" style={{ color: "#888", fontStyle: "italic" }}>
        Preview not available for this file type.
      </Typography>
    );
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      sx={{
        zIndex: 10000, // Set a high zIndex to ensure it appears above everything
      }}
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: "background.paper",
          borderRadius: 2,
          boxShadow: 24,
          p: 4,
        }}
      >
        <Typography variant="h6" component="h2" style={{ textAlign: "center", marginBottom: 16 }}>
          {t('PreviewFiles')}
        </Typography>
        <div style={{ maxHeight: 300, overflowY: "auto", marginTop: 16 }}>
          {files.map((file, index) => (
            <div
              key={index}
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                marginBottom: 16,
                border: "1px solid #ddd",
                borderRadius: 8,
                padding: 8,
              }}
            >
              <Typography variant="body2" style={{ marginBottom: 8, fontWeight: "bold" }}>
                {file.name}
              </Typography>
              <div style={{ marginBottom: 8 }}>{renderPreview(file)}</div>
              <IconButton onClick={() => onRemove(index)} style={{ alignSelf: "flex-end" }}>
                <Close />
              </IconButton>
            </div>
          ))}
        </div>
        <div style={{ display: "flex", justifyContent: "space-between", marginTop: 16 }}>
          <Button onClick={onClose} variant="outlined">
            {t('Cancel')}
          </Button>
          <Button onClick={onSend} variant="contained" color="primary">
            {t('Send')}
          </Button>
        </div>
      </Box>
    </Modal>
  );
}

export default PreviewModal;
