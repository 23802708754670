import React, {useEffect} from 'react';
import { Avatar, Typography } from "@mui/material";
import { getInitials } from '../../../Utils/helpers';

const generateColorFromName = (name) => {
    const colors = [
        "#455A64", // Blue Grey
        "#6D4C41", // Brown
        "#00796B", // Teal
        "#388E3C", // Green
        "#F57C00", // Deep Orange
        "#5D4037", // Dark Brown
        "#7B1FA2", // Purple
        "#1976D2", // Blue
        "#0288D1", // Light Blue
        "#0097A7"  // Cyan
    ];
    // Create a hash from the name
    let hash = 0;
    for (let i = 0; i < name.length; i++) {
        hash = name.charCodeAt(i) + ((hash << 5) - hash);
    }
    // Use the hash to pick a color
    const index = Math.abs(hash) % colors.length;
    return colors[index];
};

const UserAvatar = ({ name, flip = false, withName = true , size = 24, caseId = null, deduction = null }) => {

    const backgroundColor = generateColorFromName(name);
    return (
        <div
            style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
                gap: "8px",
                flexDirection: flip ? "row-reverse" : "row",
            }}
        >
            <Avatar
                sx={{
                    bgcolor: backgroundColor,
                    width: size,
                    height: size,
                    fontSize: `${size / 1.6}px`
                }}
            >
                {getInitials(name)}
            </Avatar>
            {withName ? (
                <Typography variant="body2" fontWeight="bold"  sx={{ fontSize: `${size / 1.8}px` }}>
                    {name}
                </Typography>) : (<></>)
            }
            {caseId ? (
                <Typography variant="body2" fontWeight="bold"  sx={{ fontSize: `${size / 1.8}px` }}>
                    {caseId} -
                </Typography>) : (<></>)
            }
            {deduction ? (
                <Typography variant="body2" fontWeight="bold"  sx={{ fontSize: `${size / 1.8}px` }}>
                    {deduction} - 
                </Typography>) : (<></>)
            }
        </div>
    );
};


export default UserAvatar;
