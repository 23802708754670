// SignatureDialog.jsx
import React, { useState } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    IconButton
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import ClientService from '../../../Services/client.service';
import { ToastContainer, toast } from 'react-toastify';


const ProfileSignatureDialog = ({
    open,
    onClose,
    existingSignature,       // a URL or path for the existing signature preview
    existingProfilePicture,  // a URL or path for the existing profile pic preview
    token,
    accountantId,
    caseId
}) => {
    const { t } = useTranslation();

    // We store only the preview URLs for display, and the actual Files for uploading.
    const [signaturePreview, setSignaturePreview] = useState(existingSignature || '');
    const [profilePreview, setProfilePreview] = useState(existingProfilePicture || '');

    // These track whether a new file has been selected:
    const [newSignatureFile, setNewSignatureFile] = useState(null);
    const [newProfileFile, setNewProfileFile] = useState(null);

    // For “zoom” dialogs:
    const [signatureZoomOpen, setSignatureZoomOpen] = useState(false);
    const [profileZoomOpen, setProfileZoomOpen] = useState(false);

    // Handle user selecting a signature file
    const handleSignatureUpload = (e) => {
        const file = e.target.files[0];
        if (file) {
            const objectUrl = URL.createObjectURL(file);
            setSignaturePreview(objectUrl);
            setNewSignatureFile(file);
        }
    };

    // Handle user selecting a profile picture file
    const handleProfilePictureUpload = (e) => {
        const file = e.target.files[0];
        if (file) {
            const objectUrl = URL.createObjectURL(file);
            setProfilePreview(objectUrl);
            setNewProfileFile(file);
        }
    };

    // Called when user hits "Save"
    const handleSave = async () => {
        try {
            // If user selected a new signature file => upload it
            if (newSignatureFile) {
                const response = await ClientService.UploadSignature(
                    token,
                    accountantId,
                    caseId,
                    newSignatureFile
                );
                if (response.ok) {
                    toast.success(t('SignatureUploadedSuccessfully'));
                }else{
                    toast.error(t('ErrorUploadingSignatureFiles'));
                }
            }
            // If user selected a new profile file => upload it
            if (newProfileFile) {
                const response = await ClientService.UploadProfilePhoto(
                    token,
                    accountantId,
                    caseId,
                    newProfileFile
                );
                if (response.ok) {
                    toast.success(t('ProfilePhotoUploadedSuccessfully'));
                }else{
                    toast.error(t('ErrorUploadingProfileFiles'));
                }
            }
        } catch (error) {
            toast.error(t('ErrorUploadingFiles'));
        } finally {
            // Close the dialog in any case
            onClose();
        }
    };

    return (
        <>
            <Dialog
                open={open}
                onClose={onClose}
                maxWidth="sm"
                fullWidth
                // Force RTL on the Dialog (everything in right-to-left layout)
                sx={{ direction: 'rtl' }}
            >
                <DialogTitle
                    sx={{
                        direction: 'rtl',
                        textAlign: 'left',
                        position: 'relative',
                    }}
                >
                    {t('AddOrUpdateSignatureOrProfilePicture')}
                    <IconButton
                        aria-label="close"
                        onClick={onClose}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>

                <DialogContent
                    dividers
                    sx={{
                        textAlign: 'right',
                        direction: 'rtl',
                    }}
                >
                    {/* Signature Upload */}
                    <div style={{ marginBottom: '16px' }}>
                        <h3 style={{ marginBottom: '8px', textAlign: 'right' }}>
                            {t('Signature')}
                        </h3>
                        {signaturePreview ? (
                            <div style={{ marginBottom: '8px', textAlign: 'right' }}>
                                {/* Clickable preview => opens zoom dialog */}
                                <img
                                    src={signaturePreview}
                                    alt="signature-preview"
                                    style={{
                                        maxWidth: '100%',
                                        maxHeight: '100px',
                                        border: '1px solid #ccc',
                                        cursor: 'pointer'
                                    }}
                                    onClick={() => setSignatureZoomOpen(true)}
                                />
                            </div>
                        ) : (
                            <p style={{ textAlign: 'right' }}>
                                {t('NoSignatureUploadedYet')}
                            </p>
                        )}
                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <button
                                type="button"
                                className="addClient-button-2"
                                style={{ cursor: 'pointer', textAlign: 'right' }}
                            >
                                <label style={{ cursor: 'pointer', margin: 0 }}>
                                    {t('UploadSignature')}
                                    <input
                                        type="file"
                                        hidden
                                        onChange={handleSignatureUpload}
                                    />
                                </label>
                            </button>
                        </div>
                    </div>

                    {/* Profile Picture Upload */}
                    <div style={{ marginBottom: '16px' }}>
                        <h3 style={{ marginBottom: '8px', textAlign: 'right' }}>
                            {t('ProfilePicture')}
                        </h3>
                        {profilePreview ? (
                            <div style={{ marginBottom: '8px', textAlign: 'right' }}>
                                {/* Clickable preview => opens zoom dialog */}
                                <img
                                    src={profilePreview}
                                    alt="profile-preview"
                                    style={{
                                        maxWidth: '100%',
                                        maxHeight: '100px',
                                        border: '1px solid #ccc',
                                        cursor: 'pointer'
                                    }}
                                    onClick={() => setProfileZoomOpen(true)}
                                />
                            </div>
                        ) : (
                            <p style={{ textAlign: 'right' }}>
                                {t('NoProfilePictureUploadedYet')}
                            </p>
                        )}
                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <button
                                type="button"
                                className="addClient-button-2"
                                style={{ cursor: 'pointer', textAlign: 'right' }}
                            >
                                <label style={{ cursor: 'pointer', margin: 0 }}>
                                    {t('UploadProfilePicture')}
                                    <input
                                        type="file"
                                        hidden
                                        onChange={handleProfilePictureUpload}
                                    />
                                </label>
                            </button>
                        </div>
                    </div>
                </DialogContent>

                <DialogActions
                    sx={{
                        justifyContent: 'center',
                        direction: 'rtl',
                    }}
                >
                    <button
                        type="button"
                        className="addClient-button-2"
                        style={{ marginRight: '8px', cursor: 'pointer' }}
                        onClick={onClose}
                    >
                        {t('Cancel')}
                    </button>
                    <button
                        type="button"
                        className="addClient-button-2"
                        style={{ cursor: 'pointer' }}
                        onClick={handleSave}
                    >
                        {t('Save')}
                    </button>
                </DialogActions>
            </Dialog>

            {/* Signature Zoom Dialog */}
            <Dialog
                open={signatureZoomOpen}
                onClose={() => setSignatureZoomOpen(false)}
                maxWidth="lg"
                sx={{ direction: 'rtl' }}
            >
                <DialogTitle sx={{ direction: 'rtl' }}>
                    {t('Signature')}
                    <IconButton
                        aria-label="close"
                        onClick={() => setSignatureZoomOpen(false)}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent dividers sx={{ textAlign: 'center' }}>
                    {signaturePreview && (
                        <img
                            src={signaturePreview}
                            alt="signature-zoom"
                            style={{ maxWidth: '90vw', maxHeight: '80vh' }}
                        />
                    )}
                </DialogContent>
            </Dialog>

            {/* Profile Zoom Dialog */}
            <Dialog
                open={profileZoomOpen}
                onClose={() => setProfileZoomOpen(false)}
                maxWidth="lg"
                sx={{ direction: 'ltr' }}
            >
                <DialogTitle sx={{ direction: 'ltr' }}>
                    {t('ProfilePicture')}
                    <IconButton
                        aria-label="close"
                        onClick={() => setProfileZoomOpen(false)}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent dividers sx={{ textAlign: 'center' }}>
                    {profilePreview && (
                        <img
                            src={profilePreview}
                            alt="profile-zoom"
                            style={{ maxWidth: '90vw', maxHeight: '80vh' }}
                        />
                    )}
                </DialogContent>
            </Dialog>
        </>
    );
};

export default ProfileSignatureDialog;
