import React, { useRef } from 'react';
import { useVirtualizer } from '@tanstack/react-virtual';
import LabelItem from './LabelItem.js';
import '../Chat.css';

const LabelsList = ({ labels, onLabelSelect, onLabelDelete, translate, isLoading }) => {
    const parentRef = useRef();

    const rowVirtualizer = useVirtualizer({
        count: labels.length,
        getScrollElement: () => parentRef.current,
        estimateSize: () => 80, // Estimated height of each row
        overscan: 5,
    });

    return (
        <div ref={parentRef} className="labels-list-container">
            {isLoading ? (
                <div className="loading-spinner">{translate('Loading...')}</div>
            ) : labels.length === 0 ? (
                <div className="empty-message">{translate('NoLabels')}</div>
            ) : (
                <div
                    style={{
                        height: `${rowVirtualizer.getTotalSize()}px`,
                        position: 'relative',
                    }}
                >
                    {rowVirtualizer.getVirtualItems().map((virtualRow) => {
                        const label = labels[virtualRow.index];
                        return (
                            <div
                                key={virtualRow.key}
                                ref={virtualRow.measureElement}
                                style={{
                                    backgroundColor: virtualRow.index % 2 === 0 ? '#fff' : '#f4f4f4', // Alternate row colors
                                }}
                                className="contact-item-row"
                            >
                                <LabelItem
                                    item={label}
                                    translate={translate}
                                    onView={onLabelSelect}
                                    onDelete={() => onLabelDelete(label)}
                                />
                            </div>
                        );
                    })}
                </div>
            )}
        </div>
    );
};

export default LabelsList;
