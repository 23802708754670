import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useContext } from 'react';
import { PermissionsContext } from '../Contexts/Permissions';
import PuffLoader from "react-spinners/PuffLoader";
import Constants from '../assests/Constants/constants'; // Adjust the import path as necessary

function ProtectedRoute({
    children,
    requiredPermission = [],
    unauthorizedPermission = [],
    requireAccountant,
    preventClient = false, // Prevent all clients from accessing
    premiumClientOnly = false, // Allow only premium clients
}) {
    const { permissionsState, loading, isAccountant, userTypeContext } = useContext(PermissionsContext);
    const location = useLocation(); // Get location dynamically
    const { isEdit, dynamicPermissionCheck } = location.state || {}; // Extract dynamic fields

    // Handle the case where permissions are not yet loaded
    if (loading) {
        return (
            <div className="loader-container">
                <PuffLoader
                    size={100} // Larger size for better visibility
                    loading={true}
                />
            </div>
        );
    }

    let isAllowed = true;

    // Check if the route requires the user to be an accountant
    if (requireAccountant && !isAccountant) {
        isAllowed = false;
    }

    // General prevent client logic
    if (preventClient && userTypeContext?.toString() === Constants.UserType.Client) {
        isAllowed = false;
    }

    // Premium client-only logic
    if (premiumClientOnly && userTypeContext?.toString() === Constants.UserType.Client) {
        if (!permissionsState.Premium) {
            isAllowed = false;
        }
    }

    // Check if the user has all required permissions
    if (Array.isArray(requiredPermission) && requiredPermission.length > 0) {
        const hasAllRequiredPermissions = requiredPermission.every(
            (permission) => permissionsState[permission]
        );
        isAllowed = isAllowed && hasAllRequiredPermissions;
    }

    // Handle multiple unauthorized permissions
    if (Array.isArray(unauthorizedPermission) && unauthorizedPermission.length > 0) {
        const hasUnauthorizedPermission = unauthorizedPermission.some(
            (permission) => permissionsState[permission]
        );

        // Allow access dynamically if a specific permission (like `CannotAddClient`) is present
        // and other conditions (e.g., `isEdit`) are met
        if (
            hasUnauthorizedPermission &&
            unauthorizedPermission.includes("CannotAddClient") &&
            isEdit
        ) {
            isAllowed = true;
        } else if (hasUnauthorizedPermission) {
            isAllowed = false;
        }
    }

    // Dynamic permission check from `location.state`
    if (dynamicPermissionCheck && !permissionsState[dynamicPermissionCheck]) {
        isAllowed = false;
    }

    // Render children if the user is allowed, otherwise navigate to unauthorized page
    if (isAllowed) {
        return children;
    } else {
        return <Navigate to="/unauthorized" />;
    }
}

export default ProtectedRoute;
