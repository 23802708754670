import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import HamburgerMenu from '../HamburgerMenu/HamburgerMenu';
import Select from 'react-select';
import ReactDatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import enGB from 'date-fns/locale/en-GB';
import { useTranslation } from 'react-i18next';
import { useBack } from "use-back";
import UserService from '../../Services/user.service';
import { deepOrange } from "@mui/material/colors";
import '../OtherDocuments/OtherDocument.css';
import backIcon from '../../assests/images/left-arrow.png';
import EnumsService from '../../Services/enums.service';
import CustomDeleteConfirmationModal from '../CustomDeleteConfirmationModal/CustomDeleteConfirmationModal'
import { ToastContainer, toast } from "react-toastify";
import DownloadIcon from '@mui/icons-material/Download';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { useSignalR } from '../../Contexts/signalR';
import { Dropdown } from 'primereact/dropdown';
import 'primereact/resources/themes/saga-blue/theme.css';  // Optional: PrimeReact theme
import 'primereact/resources/primereact.min.css';           // PrimeReact CSS
import DraggableModal from '../DraggableModal/DraggableModal';
import 'primeicons/primeicons.css';
import SaveIcon from '@mui/icons-material/Save'; // Save icon
import ClearIcon from '@mui/icons-material/Clear';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import AdvancedDropdown from '../AdvancedDropdown/AdvancedDropdown';
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
import MergeIcon from '@mui/icons-material/Merge';
import AutoAwesomeMotionIcon from '@mui/icons-material/AutoAwesomeMotion';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import CustomMonthDatePicker from '../Widgets/CustomDates/CustomMonthDatePicker/CustomMonthDatePicker';
import editIcon from '../../assests/images/Icons/editIcon.svg';
import PuffLoader from "react-spinners/PuffLoader";
import AssignmentService from '../../Services/assignment.service';
import Avatar from '@mui/material/Avatar';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import Quill's styling
import CustomDatePicker from '../CustomDatePicker/CustomDatePicker';
import FileView from './FileView';
import { PieChart } from '@mui/x-charts/PieChart';
import { LineChart } from '@mui/x-charts/LineChart';
import { BarChart } from '@mui/x-charts/BarChart';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';
import { TablePagination } from '@mui/material';


import { Delta } from 'quill';
import './Assignments.css'

// Function to calculate the first and last day of the current month



//table imports
import { Edit as EditIcon, Delete as DeleteIcon, Download as DownloadIconMui, Search as SearchIcon } from '@mui/icons-material';
import {
    Button, Dialog, DialogTitle, DialogContent, Box, DialogActions, Typography, TextField, Checkbox,
    InputAdornment, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, MenuItem,
    ToggleButton, ToggleButtonGroup, Switch, CircularProgress, IconButton, Tooltip, FormControlLabel, Divider,
    Grid
} from '@mui/material';
import dayjs, { Dayjs } from 'dayjs';
import { beautifyNumber } from '../../Utils/FormatNumber';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import styled from '@mui/system/styled';
import UpperPanelDetails from '../Widgets/UpperPanelDetails/UpperPanelDetails';
import AutocompleteSelect from '../Widgets/AutocompleteSelect/AutocompleteSelect';
import Constants from '../../assests/Constants/constants';
import SubAccountantService from '../../Services/subAccountant.service';
import UserAvatar from '../Widgets/UserAvatar/UserAvatar';
import DOMPurify from 'dompurify';
import ClientService from '../../Services/client.service';
import { display } from '@mui/system';
import CustomMonthYearIndividualDatePicker from '../Widgets/CustomDates/CustomMonthYearIndividualDatePicker/CustomMonthYearIndividualDatePicker';
registerLocale('en-GB', {
    ...enGB,
    localize: {
        ...enGB.localize,
        month: n => n + 1, // Display months as numbers starting from 1
    },
    formatLong: {
        ...enGB.formatLong,
        date: () => 'MM/yyyy', // Date format when the picker is not showing
    },
});

setDefaultLocale('en-GB'); // Set the default locale to use it
const StyledDialogTitle = styled(DialogTitle)({
    fontSize: '1.8rem',
    fontWeight: 'bold',
    textAlign: 'center',
    color: '#304FFF',
});

const StyledDialogContent = styled(DialogContent)({
    textAlign: 'center',
    padding: '20px 30px',
});

const StyledDialogActions = styled(DialogActions)({
    justifyContent: 'center',
    paddingBottom: '20px',
});

const StyledButton = styled(Button)({
    margin: '0 8px',
    padding: '10px 20px',
    fontSize: '1.1rem',
    textTransform: 'none',
    color: '#fff',
    backgroundColor: '#E57C22',
    '&:hover': {
        backgroundColor: '#d9731a',
    },
});
const PaymentTypeOptions = [
    { name: "CreditCard", value: "0" },
    { name: "Check", value: "1" },
    { name: "BankTransfer", value: "2" },
    { name: "Cash", value: "3" }
];



const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
    width: '100%',
    height: '10vh',
    '& .MuiAutocomplete-root': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: "center"

    },
    '& *': {
        boxSizing: 'border-box !important',

    },
    '& .MuiDataGrid-columnHeaders': {
        opacity: 0.7,
        backgroundColor: '#BFC8FF',
    },
    '& .MuiDataGrid-columnHeader': {
        backgroundColor: '#BFC8FF',
    },
    '& .MuiDataGrid-columnHeaderTitle': {
        color: '#304FFF',
        textAlign: 'center',
        fontFamily: 'Montserrat',
        fontSize: '14px',
        fontWeight: 600,
        lineHeight: 'normal',
    },
    '& .MuiDataGrid-cell': {
        borderTop: '1px solid rgba(191, 191, 193, 0.56)',
        backgroundColor: 'rgba(234, 237, 255, 0.32)',
        whiteSpace: 'normal',
        wordWrap: 'break-word',
    },
    '& .MuiDataGrid-row:hover': {
        backgroundColor: 'rgba(191, 200, 255, 0.3)',
    },
    '& .MuiDataGrid-cell:focus': {
        outline: 'none',
    },
    '& .MuiDataGrid-cell': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: "center"
    },
    '& .disableUser': {
        pointerEvents: 'none',
        opacity: 0.5,
    },
    // New styles for edit mode
    '& .MuiDataGrid-cell--editing': {
        paddingTop: '8px',
        paddingBottom: '8px',
        paddingLeft: '8px',
        paddingRight: '8px',
    },
    '& .MuiDataGrid-row--editing .MuiDataGrid-cell': {
        borderRight: 'none',
    },
    '& .MuiDataGrid-row--editing .MuiDataGrid-cell:not(:last-child)': {
        borderRight: '8px solid transparent',
    },
    '& .MuiDataGrid-cell--editing': {
        padding: 0,
        backgroundColor: 'inherit',
    },
}));

const OptionButton = styled(Button)({
    margin: '10px 8px',
    padding: '12px 25px',
    fontSize: '1.1rem',
    textTransform: 'none',
    color: '#304FFF',
    backgroundColor: '#f5f5f5',
    border: '1px solid #E57C22',
    width: 'auto',  // Allows the button to shrink when there are many buttons
    maxWidth: '200px',  // Set a maximum width to ensure consistency
    flexGrow: 1,  // Allows buttons to fill available space equally
    '&:hover': {
        backgroundColor: '#E57C22',
        color: '#fff',
    },
});

const ActionButton = styled(Button)({
    backgroundColor: '#E57C22', // Consistent background with other elements
    borderRadius: 4, // Same border-radius as MUI TextField and Switch
    color: 'white',
    height: 40, // Same height as other input elements
    padding: '0 16px', // Consistent padding
    fontSize: '0.875rem', // Matching font size with TextField
    textTransform: 'none',
    boxSizing: 'border-box',
    '&:hover': {
        backgroundColor: '#d9731a', // Slightly darker on hover
    },
    '&:disabled': {
        backgroundColor: '#E0E0E0',
        color: '#BDBDBD',
    },
});



function Assignments() {
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();
    const direction = i18n.dir();
    const [documentTypeFetch, setDocumentTypeFetch] = useState('');
    const [user, setUser] = useState(null);
    const [token, setToken] = useState('');
    const [client, setClient] = useState(null);
    const [type, setType] = useState('');
    const [searchQuery, setSearchQuery] = useState('');
    const location = useLocation();

    const [loading, setLoading] = useState(false);
    const [isClientDataFetched, setIsClientDataFetched] = useState(false);

    const [sortConfig, setSortConfig] = useState({ key: 'cardId', direction: 'descending', type: 'number' });
    const [focused, setFocused] = useState(false);
    const customScrollbarRef = useRef();
    const tableContainerRef = useRef();
    const [calculatedWidth, setCalculatedWidth] = useState('70%');
    const [currentUserType, setCurrentUserType] = useState(0);

    //account cards
    const emptyAssignment = {
        id: null,
        accountantId: user?.accountantId || "",
        caseId: null,
        reporterId: user?.subAccountantId || user?.accountantId || "",
        reporterType: currentUserType,
        assigneeId: "",
        assigneeType: null,
        summary: "",
        description: "",
        priority: 0,
        status: 0,
        createDate: new Date().toISOString(),
        dueDate: new Date().toISOString(),
        attachedDocuments: [],
        comments: [],
    };

    const fileInputRef1 = useRef(null); // Reference to the first hidden file input



    // const handleFileChange = (event, uploadFunction) => {
    //     const file = event.target.files[0];
    //     if (file) {
    //         uploadFunction(file); // Call the passed upload function with the selected file
    //     }
    // };

    // const handleFileButtonClick = (fileInputRef, uploadFunction) => {
    //     // Reset the file input value
    //     fileInputRef.current.value = '';

    //     // Set up the onchange event handler
    //     fileInputRef.current.onchange = (event) => handleFileChange(event, uploadFunction);

    //     // Trigger the file input click
    //     fileInputRef.current.click();
    // };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            addFileToAssignment(file);
        }
    };

    const handleUploadClick = () => {
        if (fileInputRefUpload.current) {
            // Reset the file input value so user can select the same file again if needed
            fileInputRefUpload.current.value = "";
            fileInputRefUpload.current.click();
        }
    };

    const [collectionPageData, setCollectionPageData] = useState({});
    // const [startDate, setStartDate] = useState(() => {
    //     const storedDateString = localStorage.getItem('_StartDate');
    //     return storedDateString ? new Date(storedDateString) : new Date();
    // });;

    const [assignmentsPage, setAssignmentsPage] = useState([]);
    const [subAccountants, setSubAccountants] = useState([]);
    const [editDialogOpen, setEditDialogOpen] = useState(false);
    const [selectedAssignment, setSelectedAssignment] = useState(null);

    const [bigIframeModalOpen, setBigIframeModalOpen] = useState(false);
    const [openedIframeUrl, setOpenedIframeUrl] = useState('');

    const handleOpenModal = (url) => {
        setOpenedIframeUrl(url);
        setBigIframeModalOpen(true);
    };

    const handleCloseModal = () => {
        setBigIframeModalOpen(false);
        setOpenedIframeUrl('');
    };

    const [newComment, setNewComment] = useState({
        content: '',
        attachedDocuments: [],
    });
    const [currentAssignment, setCurrentAssignment] = useState({});
    const [currentDescription, setCurrentDescription] = useState('');

    const handleChangeNewCommentContent = (content) => {
        setNewComment((prev) => ({
            ...prev,
            content,
        }));
    };

    const handleChangeCurrentDescription = (description) => {
        setCurrentAssignment((prev) => ({
            ...prev,
            description,
        }));
    };


    useEffect(() => {
        const filteredAssignmentss = assignmentsPage.filter(
            (assignment) => assignment.status === 2
        );
    }, [subAccountants]);




    const [caseIdOptions, setCaseIdOptions] = useState([]);
    const [categoryOptions, setCategoryOptions] = useState([]);

    const [subAccountantOptions, setSubAccountantOptions] = useState([]);

    const [isRange, setIsRange] = useState(false); // Toggle single date or range
    const [startDate, setStartDate] = useState(() => {
        const now = new Date();
        return new Date(now.getFullYear(), now.getMonth(), 1); // First day of the current month
    });

    const [endDate, setEndDate] = useState(() => {
        const now = new Date();
        return new Date(now.getFullYear(), now.getMonth() + 1, 0); // Last day of the current month
    });

    const [sortDate, setSortDate] = useState(dayjs()); // Default for single date
    const [asccountant, setAccountant] = useState({});

    const fetchEnums = async (user, token, client) => {
        setLoading(true);
        if (!user?.accountantId) {
            return;
        }


        try {

            fetchAssignments(startDate, endDate, isRange, user.accountantId, token, client?.caseId);
            const result2 = await SubAccountantService.GetSubAccountantByAccountantId(token, user.accountantId);
            const accountantResult = await SubAccountantService.GetAccountantById(token, user.accountantId);
            const categoriesResponse = await AssignmentService.GetAllAssignmentCategories(user.accountantId, token);
            const enhancedAccountantResult = {
                ...accountantResult,
                subAccountantId: accountantResult.accountantId, // Add subAccountantId as same as accountantId
            };
            setAccountant(enhancedAccountantResult);


            const clientsData = await ClientService.GetClients(user.accountantId, token, 1, 1000, true);


            //getting subaccountants
            if (result2) {

                const combinedSubAccountants = [enhancedAccountantResult, ...result2];
                setSubAccountants(combinedSubAccountants);
                const combinedOptions = combinedSubAccountants.map((acc) => ({
                    value: acc.subAccountantId,
                    label: acc.name,
                }));

                setSubAccountantOptions(combinedOptions);
            } else {
                toast.error(t('fetchSubAccountantsDataError'));
            }



            if (clientsData) {
                const caseIdOptions = [
                    {
                        value: null,
                        deductionsCase: null,
                        label: t('Office'),
                    },
                    ...clientsData.clients.map((client) => ({
                        value: client.caseId,
                        deductionsCase: client.deductionsCase,
                        label: client.name,
                    })),
                ];
                setCaseIdOptions(caseIdOptions);
            } else {
                toast.error(t('fetchClientsDataError'));
            }


            if (categoriesResponse.ok) {
                const categoriesData = await categoriesResponse.json();

                const mappedCategories = categoriesData.map((cat) => ({
                    value: cat.id,
                    label: cat.name,
                }));

                // Add special "new category" item at the bottom or top
                mappedCategories.push({
                    value: "NEW_CATEGORY",
                    label: t("AddNewCategory"), // e.g. "➕ Add New Category"
                });

                setCategoryOptions(mappedCategories);
                setLoading(false);

            } else {
                toast.error(t("FailedToRetrieveCategories"));
            }

            setIsClientDataFetched(true);
        } catch (error) {

            toast.error(t('fetchEnumsError'), error);
            // Handle the error appropriately
            setIsClientDataFetched(false);
            setLoading(false);

        }
        setLoading(false);

    };

    const fetchAssignments = async (startDate, endDate, isRange, accountantId, token, caseId) => {
        try {
            const response = await AssignmentService.GetAssignmentsByAccountantId(
                accountantId,
                token,
                dayjs(startDate),
                isRange ? dayjs(endDate) : null,
                caseId
            );

            if (response.ok) {
                const result = await response.json();
                setAssignmentsPage(result); // Return the result to handle it where needed
                setLoading(false);

            } else {
                toast.error(t('FailedToGetCollectionPage'));
                return null; // Return null if failed
                setLoading(false);

            }
        } catch (error) {
            console.error("Error fetching assignments:", error);
            toast.error(t('ErrorFetchingAssignments'));
            setLoading(false);
            return null;
        }
    };




    const handleCloseDialog = () => {
        setDialogOpen(false);
    };

    // Function to handle input changes for the assignment fields
    const handleAssignmentChange = (field, value) => {
        setCurrentAssignment((prev) => ({
            ...prev,
            [field]: value,
        }));
    };




    const handleAddComment = async (assignmentId) => {
        try {


            // Create a FormData object to send the data as multipart/form-data
            const formData = new FormData();
            formData.append('AccountantId', user.accountantId);
            formData.append('AssignmentId', assignmentId);
            formData.append('CommentorId', user.subAccountantId || user.accountantId);
            formData.append('CommentorName', user.name);
            formData.append('CommentorType', currentUserType);
            formData.append('Content', newComment.content);

            // Append each file in `newAttachedDocuments` to the formData
            if (newComment.attachedDocuments && newComment.attachedDocuments.length > 0) {
                newComment.attachedDocuments.forEach((file) => {
                    formData.append("NewAttachedDocuments", file, file.name); // Backend expects "NewAttachedDocuments"
                });
            }
            // Call the AddComment method with FormData
            const response = await AssignmentService.AddComment(formData, token);

            if (response.ok) {
                const createdComment = await response.json();
                setCurrentAssignment((prev) => ({
                    ...prev,
                    comments: [...prev.comments, createdComment],
                }));
                setNewComment({ content: '', attachedDocuments: [] }); // Reset newComment state
                toast.success(t("CommentAddedSuccessfully"));
                fetchAssignments(startDate, endDate, isRange, user.accountantId, token, client?.caseId);

            } else {
                toast.error(t("FailedToAddComment"));
            }
        } catch (error) {
            console.error("Error adding comment:", error);
            toast.error(t("AnErrorOccurredWhileAddingComment"));
        }
    };


    // Function to save changes
    const handleSaveChanges = async () => {
        try {
            // API call to update the assignment
            const response = await AssignmentService.UpdateAssignment(currentAssignment, token);
            if (response.ok) {
                toast.success(t('AssignmentUpdatedSuccessfully'));
                // Refresh data after successful update
                await fetchEnums(user, token, client);
            } else {
                toast.error(t('FailedToUpdateAssignment'));
            }
        } catch (error) {
            console.error(error);
            toast.error(t('AnErrorOccurredWhileUpdating'));
        } finally {
            handleCloseDialog();
        }
    };

    const handleAddAssignment = () => {
        setCurrentAssignment(emptyAssignment); // Set the empty assignment
        setIsAddMode(true); // Set to Add Mode
        setIsEditMode(true); // Open the form for editing
        setDialogOpen(true); // Open the dialog
    };


    const [selectedCaseIds, setSelectedCaseIds] = useState([]);
    const [selectedCategories, setSelectedCategories] = useState([]);


    const setSingleCaseId = (caseId) => {
        setSelectedCaseIds([caseId]);
    };

    const handleSetSelectedCaseIds = (value) => {
        if (client) {
            setClient(null);
            fetchAssignments(startDate, endDate, isRange, user.accountantId, token, null);
        }
        setSelectedCaseIds(value);
    };

    useEffect(() => {
        const fetchUserData = async () => {
            setLoading(true);
            try {
                const currentUser = await UserService.getCurrentUser();
                const currentToken = await UserService.getCurrentToken();
                const currentUserType = await UserService.getCurrentUserType();
                if (currentUser && currentToken && currentUserType) {
                    setUser(currentUser);

                    setCurrentUserType(currentUserType);
                    setToken(currentToken);

                    // Get client data from location.state
                    if (location.state && location.state.type) {
                        setType(location.state.type)

                    }
                    if (location.state && location.state.client) {
                        setClient(location.state.client);
                        setSingleCaseId({ value: location.state.client.caseId, label: location.state.client.name });
                    }
                    await fetchEnums(currentUser, currentToken, location.state.client);
                    // Fetch financial data for the current date on initial load only


                }
                else {
                    navigate("/login");
                }


            } catch (error) {
                // Handle error - maybe navigate to login or show a message
            }
            setLoading(false);
        };
        fetchUserData();
    }, [location.state.client]);

    const [cardIdOption, setCardIdOption] = useState(-1);



    const formatDate = dateString => {
        const date = new Date(dateString);
        const day = ('0' + date.getDate()).slice(-2);
        const month = ('0' + (date.getMonth() + 1)).slice(-2);
        const year = date.getFullYear();
        return `${day}-${month}-${year}`;
    };

    const formatDateWithHour = (dateString) => {
        const date = new Date(dateString);
        const day = ('0' + date.getDate()).slice(-2);
        const month = ('0' + (date.getMonth() + 1)).slice(-2);
        const year = date.getFullYear();
        const hours = ('0' + date.getHours()).slice(-2);
        const minutes = ('0' + date.getMinutes()).slice(-2);
        return `${day}-${month}-${year} ${hours}:${minutes}`;
    };




    const handleSortChange = (key, type, Direction = '') => {
        let direction = Direction || 'ascending';
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }

        setSortConfig({ key, direction, type });
    };

    // table functions
    const headerStyle = {
        backgroundColor: '#f7f7f7',
        color: 'black',
        fontWeight: 'bold'
    }


    useEffect(() => {
        // Calculate the width as 70% of the container width minus 20px
        const containerWidth = document.querySelector('.table-container').offsetWidth;
        const newWidth = (containerWidth * 0.7) - 20;
        setCalculatedWidth(newWidth + 'px');
    }, []);


    const { hasBack, handleBack } = useBack();

    const handleGoBack = () => {
        handleBack();
    };





    const [itemIdToDelete, setItemIdToDelete] = useState(null);
    const [deleteModalVisable, setDeleteModalVisable] = useState(null);


    const formatDate2 = (value) => {

        if (!value) {
            return "-"
        }
        return dayjs(value).format('MM/YYYY');
    };

    // Define columns for each DataGrid


    const [isConfirmModalVisable, setIsConfirmModalVisable] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [itemType, setItemType] = useState('');
    const [newItem, setNewItem] = useState({}); // State to hold new item data


    useEffect(() => {
        if (startDate && client && token) {
            fetchEnums(null, token, client);
        }
    }, [startDate]);



    const getSubAccountantName = (subAccountantId) => {
        const subAccountant = subAccountants.find(sa => sa.subAccountantId === subAccountantId);
        return subAccountant ? subAccountant.name : t("NoAssignee");
    };

    const getClientName = (caseId) => {



        const clients = caseIdOptions.find(c => c.value === caseId);
        return clients ? clients.label : '-';
    };

    const [clickedTable, setClickedTable] = useState('');

    const columns = [
        {
            label: t("Assignments"),
            field: "summary",
            render: (row) => {
                const priorityEmoji = Constants.PriorityEmojis[
                    Object.keys(Constants.PriorityEnum).find(
                        (key) => Constants.PriorityEnum[key] === row.priority
                    )
                ];
                return `${priorityEmoji} ${row.summary}`;
            },
        },
        {
            label: t("DueDate"),
            field: "dueDate",
            render: (row) => formatDate(row.dueDate),
        },
        {
            label: t("Assignee"),
            field: "assigneeId",
            render: (row) => {
                const assigneeName = getSubAccountantName(row.assigneeId); // Fetch the name
                return <UserAvatar name={assigneeName} />;
            },
        },
        {
            label: t("Client Name"),
            field: "caseId",
            render: (row) => {
                const clientName = getClientName(row.caseId); // Fetch the name
                return clientName && clientName !== '-'
                    ? <UserAvatar name={clientName} />
                    : '-';
            },
        },
        ...(clickedTable
            ? [
                { label: t("Category"), field: "category" },
                {
                    label: t("CreateDate"),
                    field: "createDate",
                    render: (row) => formatDate(row.createDate),
                },
                {
                    label: t("Description"),
                    field: "description",
                    render: (row) => (
                        <div
                            dangerouslySetInnerHTML={{ __html: row.description }}
                        />
                    ),
                },
            ]
            : []),
    ];

    const getConfirmMessage = () => {
        const actionType = 'assignment';

        switch (actionType) {
            case 'assignment':
                return t('AreYouSureDeleteAssignment');
            default:
                return t('confirmDeleteMessage');

        }
    };


    const getTitleMessage = () => {
        const actionType = 'assignment';
        switch (actionType) {

            case 'assignment':
                return t('ConfirmDeleteAssignments');

            default:
                return t('confirmDeleteTitle');
        }
    };

    const columnsDoneTable = [
        {
            label: t("Assignments"),
            field: "summary",
            render: (row) => {
                const priorityEmoji = Constants.PriorityEmojis[
                    Object.keys(Constants.PriorityEnum).find(
                        (key) => Constants.PriorityEnum[key] === row.priority
                    )
                ];
                return `${priorityEmoji} ${row.summary}`;
            },
        },
        { label: t("DueDate"), field: "dueDate", render: (row) => formatDate(row.dueDate) },
        {
            label: t("Assignee"),
            field: "assigneeId",
            render: (row) => {
                const assigneeName = getSubAccountantName(row.assigneeId); // Fetch the name

                return (
                    <UserAvatar name={assigneeName} />
                );
            },
        },
        {
            label: t("Client Name"),
            field: "caseId",
            render: (row) => {
                const clientName = getClientName(row.caseId); // Fetch the name

                return clientName && clientName !== '-'
                    ? <UserAvatar name={clientName} />
                    : '-';
            },
        },
        {
            label: t("DoneDate"),
            field: "finishDate",
            render: (row) => row.finishDate ? formatDate(row.finishDate) : "-"
        },
        ...(clickedTable
            ? [
                { label: t("Category"), field: "category" },
                {
                    label: t("CreateDate"),
                    field: "createDate",
                    render: (row) => formatDate(row.createDate),
                },
                {
                    label: t("Description"),
                    field: "description",
                    render: (row) => (
                        <div
                            dangerouslySetInnerHTML={{ __html: row.description }}
                        />
                    ),
                },
            ]
            : []),

    ];

    const handleUpdateAssignmentStatus = async (assignmentId, newStatus) => {
        setLoading(true);
        try {
            const response = await AssignmentService.UpdateAssignmentStatus(user.accountantId, assignmentId, newStatus, token);
            if (response.ok) {
                let finishDate = null;
                try {
                    const finishDateRaw = await response.text();
                    finishDate = finishDateRaw.replace(/^"|"$/g, '') || null; // Remove leading and trailing quotes


                } catch (error) {
                    toast.error("Response is not valid JSON");
                }


                if (finishDate !== null) {

                    setAssignmentsPage((prevAssignments) =>
                        prevAssignments.map((assignment) => {
                            if (assignment.id === assignmentId) {
                                return {
                                    ...assignment,
                                    status: newStatus,
                                    finishDate: finishDate, // Add the new finishDate
                                };
                            }
                            return assignment;
                        })
                    );
                }
            } else {
                toast.error(t("ErrorUpdatingStatus"));
            }
        } catch (error) {
            toast.error(t("FailedToUpdateStatus"));
        } finally {
            setLoading(false);
        }
    };

    const handleStatusChange = (assignmentId, newStatus) => {
        setAssignmentsPage((prevAssignments) =>
            prevAssignments.map((assignment) =>
                assignment.id === assignmentId
                    ? { ...assignment, status: newStatus }
                    : assignment
            )
        );

        handleUpdateAssignmentStatus(assignmentId, newStatus);
    };

    const [todoScrollPos, setTodoScrollPos] = useState(0);
    const todoTableRef = useRef(null);

    const handleChangeClickedTable = (whatTable) => {
        if (clickedTable === whatTable) {
            setClickedTable('')
        } else {
            setClickedTable(whatTable)
        }
    }


    function GenericTable({
        // Required props
        data = [],
        columns = [],
        title = 'My Table',
        noDataMessage = 'No data available',

        // Optional
        filterFn,                     // <-- This is used for filteredData
        height = '600px',
        rowHeight = '50px',
        onRowClick,
        onDropStatus,
        tableRef,
        clickedTable,
        handleChangeClickedTable,
        whatTable,

        // Sorting
        defaultSort = {
            key: '',
            direction: 'ascending',  // or 'descending'
        },

        // Pagination
        defaultRowsPerPage = 100,   // default is 100
        rowsPerPageOptions = [5, 10, 25, 50, 100],
    }) {
        // 1) Filter the data (optional filterFn)
        const filteredData = useMemo(() => {
            if (!Array.isArray(data)) return [];
            return filterFn ? data.filter(filterFn) : data;
        }, [data, filterFn]);

        // 2) Sorting state
        const [sortConfig, setSortConfig] = useState(defaultSort);

        // 3) Pagination state
        const [page, setPage] = useState(0);
        const [rowsPerPage, setRowsPerPage] = useState(defaultRowsPerPage);



        // 4) Sorting
        const sortedData = useMemo(() => {
            const arrayCopy = [...filteredData];
            const { key, direction } = sortConfig;
            if (!key) return arrayCopy;

            arrayCopy.sort((a, b) => {
                const aVal = a[key];
                const bVal = b[key];

                // string compare
                if (typeof aVal === 'string' && typeof bVal === 'string') {
                    return direction === 'ascending'
                        ? aVal.localeCompare(bVal)
                        : bVal.localeCompare(aVal);
                }
                // numeric compare
                if (typeof aVal === 'number' && typeof bVal === 'number') {
                    return direction === 'ascending' ? aVal - bVal : bVal - aVal;
                }
                // fallback
                return 0;
            });

            return arrayCopy;
        }, [filteredData, sortConfig]);

        // 5) Paginate
        const paginatedData = useMemo(() => {
            const startIndex = page * rowsPerPage;
            return sortedData.slice(startIndex, startIndex + rowsPerPage);
        }, [sortedData, page, rowsPerPage]);

        // 6) Pagination handlers
        const handleChangePage = (event, newPage) => {
            setPage(newPage);
        };
        const handleChangeRowsPerPage = (event) => {
            setRowsPerPage(parseInt(event.target.value, 10));
            setPage(0);
        };

        // 7) Sorting by clicking column header
        const handleSortColumn = (col) => {
            if (!col.field) return; // skip if no field to sort on
            setSortConfig((prev) => {
                if (prev.key === col.field) {
                    // toggle direction
                    return {
                        ...prev,
                        direction: prev.direction === 'ascending' ? 'descending' : 'ascending',
                    };
                }
                // otherwise start ascending
                return { key: col.field, direction: 'ascending' };
            });
        };

        // 8) Drag & drop
        const handleDragOver = (event) => {
            event.preventDefault();
        };
        const handleDrop = (event) => {
            if (!onDropStatus) return;
            const draggedId = event.dataTransfer.getData('text/plain');
            onDropStatus(draggedId);
        };

        return (
            <Paper style={{ height, display: 'flex', flexDirection: 'column' }}>
                {/* Table Header */}
                <div style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }}>
                    <Typography
                        variant="h6"
                        style={{ padding: '16px', backgroundColor: '#fff', zIndex: 2 }}
                    >
                        {title}
                    </Typography>

                    {/* Expand/Collapse icons */}
                    {clickedTable !== whatTable ? (
                        <IconButton
                            onClick={() => handleChangeClickedTable && handleChangeClickedTable(whatTable)}
                            style={{ marginRight: '16px' }}
                        >
                            <OpenInFullIcon style={{ color: '#304FFF' }} />
                        </IconButton>
                    ) : (
                        <IconButton
                            onClick={() => handleChangeClickedTable && handleChangeClickedTable(whatTable)}
                            style={{ marginRight: '16px' }}
                        >
                            <CloseFullscreenIcon style={{ color: '#304FFF' }} />
                        </IconButton>
                    )}
                </div>

                {/* Scrollable Table */}
                <TableContainer
                    component={Paper}
                    style={{ height, overflowY: 'auto' }}
                    ref={tableRef}
                    onDragOver={handleDragOver}
                    onDrop={handleDrop}
                >
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow>
                                {columns.map((col, index) => (
                                    <TableCell
                                        key={index}
                                        style={{
                                            backgroundColor: '#BFC8FF',
                                            position: 'sticky',
                                            top: 0,
                                            color: '#304FFF',
                                            fontFamily: 'Montserrat',
                                            fontSize: '14px',
                                            fontWeight: 600,
                                            textAlign: 'center',
                                            textTransform: 'uppercase',
                                            borderBottom: '2px solid #d3d3d3',
                                            cursor: col.field ? 'pointer' : undefined,
                                        }}
                                        onClick={() => handleSortColumn(col)}
                                    >
                                        {col.label}
                                        {/* Show arrow if sorting by this col */}
                                        {sortConfig.key === col.field &&
                                            (sortConfig.direction === 'ascending' ? ' ▲' : ' ▼')}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {paginatedData.length > 0 ? (
                                paginatedData.map((row, rowIndex) => (
                                    <TableRow
                                        key={rowIndex}
                                        style={{ height: rowHeight }}
                                        draggable
                                        onDragStart={(e) => {
                                            e.dataTransfer.setData('text/plain', row.id);
                                        }}
                                    >
                                        {columns.map((col, colIndex) => (
                                            <TableCell
                                                key={colIndex}
                                                onClick={() => {
                                                    if (onRowClick) onRowClick(row);
                                                }}
                                            >
                                                {/* If there's a render function, call it */}
                                                {col.render ? col.render(row) : row[col.field]}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                ))
                            ) : (
                                <TableRow style={{ height: rowHeight }}>
                                    <TableCell colSpan={columns.length} align="center">
                                        {noDataMessage}
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>

                {/* Pagination Footer */}
                <TablePagination
                    component="div"
                    count={filteredData.length} // total count from the filtered data
                    page={page}
                    onPageChange={handleChangePage}
                    rowsPerPage={rowsPerPage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    rowsPerPageOptions={rowsPerPageOptions}
                    labelRowsPerPage={t('RowsPerPage')}
                    style={{ overflow: 'hidden' }}
                />
            </Paper>
        );
    }




    const [isEditMode, setIsEditMode] = useState(false); // Track edit mode
    const [isAddMode, setIsAddMode] = useState(false);

    const handleEditAssignment = () => {
        setIsEditMode(true); // Enable edit mode
    };


    const handleOpenEditDialog = (assignment) => {
        setCurrentAssignment(assignment);
        setDialogOpen(true);
        setIsEditMode(false);
        setIsAddMode(false);
    };

    const handleDeleteClick = async () => {
        try {
            const response = await AssignmentService.DeleteAssignment(currentAssignment.id, user.accountantId, token);
            if (response.ok) {
                toast.success(t("AssignmentDeletedSuccessfully"));
                setAssignmentsPage((prevAssignments) =>
                    prevAssignments.filter((assignment) => assignment.id !== currentAssignment.id)
                );
                setDialogOpen(false);
                setIsConfirmModalVisable(false);
            } else {
                const errorMessage = await response.text();
                toast.error("FailedToDeleteAssignment");
            }
        } catch (error) {
            toast.error(t("FailedToDeleteAssignment"));
        }
    };

    const validateFieldsBeforeSave = (user, currentAssignment, t) => {
        const missingFields = [];


        if (!currentAssignment?.summary) {
            missingFields.push(t('SummaryMissing')); // Translation for missing Summary
        }
        // if (!currentAssignment?.description) {
        //     missingFields.push(t('DescriptionMissing')); // Translation for missing Description
        // }
        if (currentAssignment?.priority === null || currentAssignment?.priority === undefined) {
            missingFields.push(t('PriorityMissing')); // Translation for missing Priority
        }


        if (missingFields.length > 0) {
            // Show a toast for each missing field
            missingFields.forEach(field => {
                toast.info(field);
            });
            return false; // Validation failed
        }

        return true; // Validation passed
    };



    const handleSaveAssignment = async () => {
        setLoading(true);
        if (!validateFieldsBeforeSave(user, currentAssignment, t)) {
            return
        }
        try {
            const formData = new FormData();

            formData.append('AccountantId', user.accountantId);
            formData.append('ReporterId', user.subAccountantId || user.accountantId);
            formData.append('ReporterType', currentUserType);
            formData.append('AssigneeId', currentAssignment.assigneeId);
            formData.append('AssigneeId', currentAssignment.assigneeType);
            formData.append('Summary', currentAssignment.summary || "");
            formData.append('Description', currentAssignment.description || "");
            formData.append('Priority', currentAssignment.priority || 0);
            formData.append('Status', currentAssignment.status || 0);
            formData.append('DueDate', currentAssignment.dueDate || "");
            formData.append('Category', currentAssignment.category || "");


            if (currentAssignment.caseId) {
                formData.append('CaseId', currentAssignment.caseId);
            } else {
                if (client) {
                    formData.append('CaseId', client.caseId);
                }
            }

            if (currentAssignment.newAttachedDocuments?.length) {
                currentAssignment.newAttachedDocuments.forEach((file) => {
                    formData.append("NewAttachedDocuments", file, file.name);
                });
            }


            if (isAddMode) {
                // Add new assignment API call
                const response = await AssignmentService.CreateAssignment(formData, token);
                if (response.ok) {
                    toast.success(t("AssignmentAddedSuccessfully"));
                    const newAssignment = await response.json();
                    setAssignmentsPage((prev) => [...prev, newAssignment]);
                    setDialogOpen(false);
                    setIsAddMode(false);
                    fetchAssignments(startDate, endDate, isRange, user.accountantId, token, client?.caseId);
                } else {
                    toast.error(t("FailedToAddAssignment"));
                }
            } else {
                // Update existing assignment API call
                if (currentAssignment.attachedDocuments?.length) {
                    currentAssignment.attachedDocuments.forEach((url) => {
                        if (typeof url === "string") {
                            formData.append("AttachedDocuments", url);
                        } else {
                            console.warn("Skipping non-string document:", url);
                        }
                    });
                }
                formData.append('Id', currentAssignment.id);
                const response = await AssignmentService.UpdateAssignment(formData, token);
                if (response.ok) {
                    setDialogOpen(false);
                    setIsAddMode(false);
                    toast.success(t("AssignmentUpdatedSuccessfully"));
                    setLoading(false);
                    // Refresh assignments list
                    fetchAssignments(startDate, endDate, isRange, user.accountantId, token, client?.caseId);
                } else {
                    toast.error(t("FailedToUpdateAssignment"));
                }
            }
        } catch (error) {
            toast.error(isAddMode ? t("ErrorAddingAssignment") : t("ErrorUpdatingAssignment"));
            setLoading(false);
        } finally {
            setLoading(false);
        }
    };



    const handleDeleteComment = async (commentId) => {
        try {
            const response = await AssignmentService.DeleteComment(commentId, user.accountantId, token); // Call your API
            if (response.ok) {
                setCurrentAssignment((prev) => ({
                    ...prev,
                    comments: prev.comments.filter((comment) => comment.id !== commentId),
                }));
                toast.success(t("CommentDeletedSuccessfully"));
                await fetchEnums(user, token, client);
            } else {
                toast.error(t("FailedToDeleteComment"));
            }
        } catch (error) {
            toast.error(t("AnErrorOccurredWhileDeletingComment"));
        }
    };

    const handleDropDocumentToAssignment = (event) => {
        event.preventDefault();
        const file = event.dataTransfer.files[0];

        if (file) {
            // Process the file (e.g., upload to a server)

            setCurrentAssignment((prev) => ({
                ...prev,
                attachedDocuments: [...(prev.attachedDocuments || []), file],
                newAttachedDocuments: [...(prev.newAttachedDocuments || []), file],
            }));
        }
    };

    const handleRemoveDocumentFromAssignment = (indexToRemove) => {
        setCurrentAssignment((prev) => {
            const updatedAttachedDocuments = prev.attachedDocuments.filter((_, index) => index !== indexToRemove);
            const updatedNewAttachedDocuments = prev.newAttachedDocuments?.filter((_, index) => index !== indexToRemove);

            return {
                ...prev,
                attachedDocuments: updatedAttachedDocuments,
                newAttachedDocuments: updatedNewAttachedDocuments,
            };
        });
    };

    const handleDropDocumentToComment = (event) => {
        event.preventDefault();
        const file = event.dataTransfer.files[0];

        if (file) {
            // Process the file (e.g., upload to a server)

            setNewComment((prev) => ({
                ...prev,
                attachedDocuments: [...(prev.attachedDocuments || []), file],
                newAttachedDocuments: [...(prev.newAttachedDocuments || []), file],
            }));
        }
    };

    const handleRemoveDocumentFromComment = (indexToRemove) => {
        setNewComment((prev) => {
            const updatedAttachedDocuments = prev.attachedDocuments.filter((_, index) => index !== indexToRemove);
            const updatedNewAttachedDocuments = prev.newAttachedDocuments?.filter((_, index) => index !== indexToRemove);

            return {
                ...prev,
                attachedDocuments: updatedAttachedDocuments,
                newAttachedDocuments: updatedNewAttachedDocuments,
            };
        });
    };

    const [selectedSubAccountants, setSelectedSubAccountants] = useState([]);
    const [selectedPriorities, setSelectedPriorities] = useState([]);


    const priorityOptions = Object.keys(Constants.PriorityEnum).map((key) => ({
        value: Constants.PriorityEnum[key],
        label: t(key),
    }));




    // const filteredAssignments = useMemo(() => {
    //     if (!Array.isArray(assignmentsPage)) {
    //         console.warn("assignmentsPage is not an array or is undefined, falling back to an empty array.");
    //         return [];
    //     }
    //     return assignmentsPage.filter((assignment) => {
    //         const matchesSubAccountant = selectedSubAccountants.length
    //             ? selectedSubAccountants.some((opt) => opt.value === assignment.assigneeId)
    //             : true;
    //         const matchesCaseId = selectedCaseIds.length
    //             ? selectedCaseIds.some((opt) => opt.value === assignment.caseId)
    //             : true;
    //         const matchesPriority = selectedPriorities.length
    //             ? selectedPriorities.some((opt) => opt.value === assignment.priority)
    //             : true;
    //         const matchesCategory = selectedCategories.length
    //             ? selectedCategories.some((opt) => opt.label === assignment.category)
    //             : true;
    //         return matchesSubAccountant && matchesCaseId && matchesPriority && matchesCategory;
    //     });
    // }, [assignmentsPage, selectedSubAccountants, selectedCaseIds, selectedPriorities, selectedCategories]);

    const filteredAssignments = useMemo(() => {

        if (!Array.isArray(assignmentsPage)) return [];

        // 1) Filter for subAccountant / caseId / priority / category
        let baseFiltered = assignmentsPage.filter((assignment) => {
            const matchesSubAccountant = selectedSubAccountants.length
                ? selectedSubAccountants.some((opt) => opt.value === assignment.assigneeId)
                : true;
            const matchesCaseId = selectedCaseIds.length
                ? selectedCaseIds.some((opt) => opt.value === assignment.caseId)
                : true;
            const matchesPriority = selectedPriorities.length
                ? selectedPriorities.some((opt) => opt.value === assignment.priority)
                : true;
            const matchesCategory = selectedCategories.length
                ? selectedCategories.some((opt) => opt.label === assignment.category)
                : true;

            return (
                matchesSubAccountant &&
                matchesCaseId &&
                matchesPriority &&
                matchesCategory
            );
        });


        // 2) Filter by searchQuery (summary/description fields, etc.)
        if (searchQuery.trim()) {
            const lowerSearch = searchQuery.toLowerCase();
            baseFiltered = baseFiltered.filter((assignment) => {
                // Check whichever fields you want
                const sum = assignment.summary?.toLowerCase() || "";
                const desc = assignment.description?.toLowerCase() || "";
                return sum.includes(lowerSearch) || desc.includes(lowerSearch);
            });
        }

        return baseFiltered;
    }, [
        assignmentsPage,
        selectedSubAccountants,
        selectedCaseIds,
        selectedPriorities,
        selectedCategories,
        searchQuery, // <-- Add searchQuery as a dependency
    ]);





    const pieChartData = useMemo(() => {
        const statusCounts = {
            ToDo: 0,
            InProgress: 0,
            Done: 0,
        };

        filteredAssignments.forEach((assignment) => {
            if (assignment.status === Constants.StatusEnum.ToDo) statusCounts.ToDo++;
            if (assignment.status === Constants.StatusEnum.InProgress) statusCounts.InProgress++;
            if (assignment.status === Constants.StatusEnum.Done) statusCounts.Done++;
        });

        return [
            { id: 0, value: statusCounts.ToDo, label: t('ToDo') },
            { id: 1, value: statusCounts.InProgress, label: t('InProgress') },
            { id: 2, value: statusCounts.Done, label: t('Done') },
        ];
    }, [filteredAssignments, t]);

    const startOfMonth = dayjs().startOf("month").toDate();
    const endOfMonth = dayjs().endOf("month").toDate();

    const assignmentsDoneThisMonth = useMemo(() => {
        return assignmentsPage.filter((assignment) => {
            const finishDate = assignment.finishDate ? new Date(assignment.finishDate) : null;
            return (
                assignment.status === Constants.StatusEnum.Done &&
                finishDate >= startOfMonth &&
                finishDate <= endOfMonth
            );
        });
    }, [assignmentsPage]);

    const daysInMonth = Array.from({ length: dayjs().daysInMonth() }, (_, i) => i + 1);

    const groupAssignmentsByDate = (assignments, startDate, endDate) => {
        const start = dayjs(startDate);
        const end = dayjs(endDate);
        const rangeInDays = end.diff(start, 'days') + 1; // Total days in range
        const rangeInMonths = end.diff(start, 'month') + 1; // Total months in range

        const groupedData = {};

        // Determine key format: Month or Day
        const keyFormat = rangeInMonths > 2 ? 'YYYY-MM' : 'MM-DD';

        // Initialize keys for all months/days in range
        if (rangeInMonths > 2) {
            let currentMonth = start.startOf('month');
            while (currentMonth.isBefore(end.startOf('month')) || currentMonth.isSame(end.startOf('month'))) {
                groupedData[currentMonth.format(keyFormat)] = 0;
                currentMonth = currentMonth.add(1, 'month');
            }
        } else {
            let currentDate = start.startOf('day');
            while (currentDate.isBefore(end.startOf('day')) || currentDate.isSame(end.startOf('day'))) {
                groupedData[currentDate.format(keyFormat)] = 0;
                currentDate = currentDate.add(1, 'day');
            }
        }

        // Populate data for completed assignments
        assignments.forEach((assignment) => {
            if (assignment.status === Constants.StatusEnum.Done && assignment.finishDate) {
                const finishDate = dayjs(assignment.finishDate);
                const key = finishDate.format(keyFormat);
                if (groupedData[key] !== undefined) {
                    groupedData[key]++;
                }
            }
        });

        // Convert grouped data to array for the chart
        return Object.entries(groupedData).map(([key, count]) => ({
            x: key, // Month/Day
            y: count, // Total done assignments
        }));
    };


    const lineGraphData = useMemo(() => {
        return groupAssignmentsByDate(assignmentsPage, startDate, endDate);
    }, [assignmentsPage, startDate, endDate, isRange]);

    const changeOnAssignment = (value) => {

        setCurrentAssignment({ ...currentAssignment, caseId: value.value });
    }

    const changeOnAssignmentCategory = (value) => {

        setCurrentAssignment({ ...currentAssignment, category: value.label });
    }

    // a) Create a ref for your hidden file input
    const fileInputRefUpload = useRef(null);

    const addFileToAssignment = (file) => {
        setCurrentAssignment((prev) => ({
            ...prev,
            attachedDocuments: [...(prev.attachedDocuments || []), file],
            newAttachedDocuments: [...(prev.newAttachedDocuments || []), file],
        }));
    };

    const handleStartDateChange = (date) => {
        setStartDate(date);
        
        
        fetchAssignments(date, endDate, isRange, user.accountantId, token, client?.caseId);
    };

    const handleEndDateChange = (date) => {
        setEndDate(date);
        fetchAssignments(startDate, date, isRange, user.accountantId, token, client?.caseId);
    };



    const handleStartDateChangeNew = async (newValue, type) => {
        const newDate = new Date(newValue);
        let updatedDate;


        if (type === 'year') {
            updatedDate = new Date(Date.UTC(newDate.getFullYear(), startDate.getMonth(), 1));
        } else if (type === 'month') {
            updatedDate = new Date(Date.UTC(startDate.getFullYear(), newDate.getMonth(), 1));
        }

        setStartDate(updatedDate);
        fetchAssignments(updatedDate, endDate, isRange, user.accountantId, token, client?.caseId);

    };

    const handleEndDateChangeNew = async (newValue, type) => {
        const newDate = new Date(newValue);
        let updatedDate;

        if (type === 'year') {
            updatedDate = new Date(Date.UTC(newDate.getFullYear(), endDate.getMonth() + 1, 0));
        } else if (type === 'month') {
            updatedDate = new Date(Date.UTC(endDate.getFullYear(), newDate.getMonth() + 1, 0));
        }
        

        setEndDate(updatedDate);
        fetchAssignments(startDate, updatedDate, isRange, user.accountantId, token, client?.caseId);

    };


    const handleRangeChange = () => {
        fetchAssignments(startDate, endDate, !isRange, user.accountantId, token, client?.caseId);
        setIsRange((prev) => !prev);
    };

    const fileInputRefComment = useRef(null);

    const handleUploadClickComment = () => {
        if (fileInputRefComment.current) {
            // Reset the file input value so user can select the same file again
            fileInputRefComment.current.value = "";
            fileInputRefComment.current.click();
        }
    };

    const handleCommentFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            // Add the file to newComment arrays
            setNewComment((prev) => ({
                ...prev,
                attachedDocuments: [...(prev.attachedDocuments || []), file],
                newAttachedDocuments: [...(prev.newAttachedDocuments || []), file],
            }));
        }
    };

    const [newCategoryModalOpen, setNewCategoryModalOpen] = useState(false);
    const [newCategoryName, setNewCategoryName] = useState("");

    const handleOpenNewCategoryModal = () => {
        setNewCategoryName("");
        setNewCategoryModalOpen(true);
    };

    const handleCloseNewCategoryModal = () => {
        setNewCategoryModalOpen(false);
    };

    const handleSubmitNewCategory = async () => {
        try {
            if (!newCategoryName.trim()) {
                toast.error(t("CategoryNameRequired"));
                return;
            }
            const response = await AssignmentService.AddAssignmentCategory(
                user.accountantId,
                newCategoryName,
                token
            );
            if (response.ok) {
                toast.success(t("NewCategoryAddedSuccessfully"));

                // 1) Refresh categories from server:
                // const newCat = await response.json(); // assume API returns the newly created category
                // // 2) or simply push it to categoryOptions
                // setCategoryOptions((prev) => [
                //   ...prev.filter((opt) => opt.value !== "NEW_CATEGORY"),
                //   { value: newCat.id, label: newCat.name },
                //   { value: "NEW_CATEGORY", label: t("AddNewCategory") },
                // ]);

                await fetchEnums(user, token, client);
                // setSelectedCategories([{ value: newCat.id, label: newCat.name }]);
                handleCloseNewCategoryModal();
            } else {
                toast.error(t("FailedToAddCategory"));
            }
        } catch (error) {
            console.error("Error adding new category:", error);
            toast.error(t("AnErrorOccurredWhileAddingCategory"));
        }
    };

    return (
        <>

            <Dialog
                open={newCategoryModalOpen}
                onClose={handleCloseNewCategoryModal}
            >
                <DialogTitle sx={{ textAlign: "center" }}>{t("AddNewCategory")}</DialogTitle>
                <DialogContent sx={{ padding: '30px' }}>
                    <TextField
                        label={t("CategoryName")}
                        value={newCategoryName}
                        onChange={(e) => setNewCategoryName(e.target.value)}
                        fullWidth
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseNewCategoryModal}>{t("Cancel")}</Button>
                    <Button
                        onClick={async () => {
                            await handleSubmitNewCategory();
                        }}
                        variant="contained"
                        color="primary"
                    >
                        {t("Save")}
                    </Button>
                </DialogActions>
            </Dialog>


            <CustomDeleteConfirmationModal
                isVisible={isConfirmModalVisable}
                onClose={() => setIsConfirmModalVisable(false)}
                onDelete={handleDeleteClick}
                confirmMessage={getConfirmMessage()}
                titleMessage={getTitleMessage()}
                type={'delete'}
            />

            <DraggableModal
                bigIframeModalOpen={bigIframeModalOpen}
                handleCloseIframeModal={handleCloseModal}
                openedIframeUrl={openedIframeUrl}
            />
            <Dialog
                open={dialogOpen}
                onClose={handleCloseDialog}
                maxWidth="md"
                fullWidth
                disableScrollLock

            >
                <DialogTitle sx={{ textAlign: "left", position: "relative", fontWeight: "bold" }}>
                    {isEditMode ? (
                        <>
                            {isAddMode && <Box component="strong">:{t("AssignmentTitle")}</Box>}
                            <TextField
                                value={currentAssignment?.summary || ""}
                                onChange={(e) =>
                                    setCurrentAssignment({ ...currentAssignment, summary: e.target.value })
                                }
                                fullWidth
                                label={t("Assignment")}
                                sx={{ direction: "ltr" }}
                            />
                        </>
                    ) : (
                        <>
                            {currentAssignment?.summary || t("AssignmentDetails")} : {t("Assignment")}

                            <IconButton
                                onClick={handleEditAssignment}
                                sx={{ position: "absolute", top: 8, right: 8 }}
                            >
                                <EditIcon />
                            </IconButton>


                            {(currentAssignment?.reporterId === user?.accountantId && !user?.subAccountantId) && (
                                <IconButton
                                    onClick={handleEditAssignment}
                                    sx={{ position: "absolute", top: 8, right: 8 }}
                                >
                                    <EditIcon />
                                </IconButton>
                            )}
                        </>
                    )}
                </DialogTitle>
                <DialogContent>
                    <Box display="flex" flexDirection="column" gap={2} sx={{ textAlign: "left", direction: "rtl" }}>
                        {/* ---------- ROW 1: Reporter + Assignee ---------- */}
                        <Box display="flex" gap={2}>
                            {/* Right Column: Assignee */}
                            <Box flex={1}>
                                <Typography sx={{ display: "flex", justifyContent: "flex-end", gap: 1 }}>
                                    {isEditMode ? (
                                        <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
                                            <Box sx={{ direction: "ltr", marginBottom: "8px" }}>
                                                <Box component="strong" style={{ marginBottom: "50px" }}>{t("Assignee")}</Box>
                                                <AutocompleteSelect
                                                    options={subAccountants.map((acc) => ({
                                                        value: acc.subAccountantId,
                                                        label: acc.name,
                                                    }))}
                                                    selectedValues={
                                                        subAccountants
                                                            .map((acc) => ({ value: acc.subAccountantId, label: acc.name }))
                                                            .find((option) => option.value === currentAssignment?.assigneeId) || null
                                                    }
                                                    onChange={(event, values) =>
                                                        setCurrentAssignment({
                                                            ...currentAssignment,
                                                            assigneeId: values?.value || ''
                                                        })
                                                    }
                                                    placeholder={t("SelectAssignee")}
                                                    isMultiple={false}
                                                    width="300px"
                                                    isDisabled={false}
                                                    style={{ marginBottom: "20px" }}
                                                />
                                            </Box>
                                        </div>
                                    ) : (
                                        <>
                                            <UserAvatar
                                                name={
                                                    subAccountants?.find((acc) => acc.subAccountantId === currentAssignment?.assigneeId)?.name ||
                                                    currentAssignment?.assigneeId ||
                                                    t("NoAssignee")
                                                }
                                                flip
                                                size={30}
                                            />
                                            <Box sx={{ fontSize: "1.25rem", marginTop: "8px" }}>:{t("Assignee")}</Box>
                                        </>
                                    )}
                                </Typography>
                            </Box>

                            {/* Left Column: Reporter */}
                            <Box flex={1}>
                                <Typography sx={{ display: "flex", justifyContent: "flex-end", gap: 1 }}>
                                    {isEditMode ? (
                                        <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
                                            <Box sx={{ direction: "ltr", marginBottom: "8px" }}>
                                                <Box component="strong" style={{ marginBottom: '50px' }}>{t("Reporter")}</Box>
                                                <AutocompleteSelect
                                                    options={subAccountants.map((acc) => ({
                                                        value: acc.subAccountantId,
                                                        label: acc.name,
                                                    }))}
                                                    selectedValues={
                                                        subAccountants
                                                            .map((acc) => ({ value: acc.subAccountantId, label: acc.name }))
                                                            .find((option) => option.value === currentAssignment?.reporterId) || null
                                                    }
                                                    onChange={(event, values) =>
                                                        setCurrentAssignment({
                                                            ...currentAssignment,
                                                            reporterId: values?.value || ''
                                                        })
                                                    }
                                                    placeholder={t("SelectReporter")}
                                                    isMultiple={false}
                                                    width="300px"
                                                    isDisabled={false}
                                                    style={{ marginBottom: '20px' }}
                                                />
                                            </Box>
                                        </div>
                                    ) : (
                                        <>
                                            <UserAvatar
                                                name={
                                                    subAccountants?.find((acc) => acc.subAccountantId === currentAssignment?.reporterId)?.name ||
                                                    currentAssignment?.reporterId ||
                                                    t("NoClient")
                                                }
                                                flip
                                                size={30}
                                            />
                                            <Box sx={{ fontSize: "1.25rem", marginTop: "8px" }}>:{t("Reporter")}</Box>
                                        </>
                                    )}
                                </Typography>
                            </Box>
                        </Box>

                        {/* ---------- ROW 2: Client + Creation Date ---------- */}
                        <Box display="flex" gap={2}>
                            {/* Left Column: Status */}
                            <Box flex={1}>
                                <Typography sx={{ display: "flex", justifyContent: "flex-end", gap: 1 }}>
                                    {isEditMode ? (
                                        <Box>
                                            <Box component="strong">:{t("Status")}</Box>
                                            <Box sx={{ direction: "ltr" }}>
                                                <AutocompleteSelect
                                                    options={[
                                                        { value: Constants.StatusEnum.ToDo, label: t("ToDo") },
                                                        { value: Constants.StatusEnum.InProgress, label: t("InProgress") },
                                                        { value: Constants.StatusEnum.Done, label: t("Done") },
                                                    ]}
                                                    selectedValues={{
                                                        value: currentAssignment?.status,
                                                        label: t(
                                                            Object.keys(Constants.StatusEnum).find(
                                                                (key) => Constants.StatusEnum[key] === currentAssignment?.status
                                                            )
                                                        ),
                                                    }}
                                                    onChange={(event, values) =>
                                                        setCurrentAssignment({ ...currentAssignment, status: values?.value })
                                                    }
                                                    placeholder={t("SelectPriority")}
                                                    isMultiple={false}
                                                    width="300px"
                                                    isDisabled={false}
                                                />
                                            </Box>
                                        </Box>
                                    ) : (
                                        <>
                                            <Box
                                                sx={{
                                                    fontSize: "1.25rem",
                                                    marginTop: "8px",
                                                    fontWeight: "bold",
                                                }}
                                            >
                                                {t(
                                                    Object.keys(Constants.StatusEnum).find(
                                                        (key) => Constants.StatusEnum[key] === currentAssignment?.status
                                                    )
                                                ) || ""}
                                            </Box>
                                            <Box sx={{ fontSize: "1.25rem", marginTop: "8px" }}>:{t("Status")}</Box>
                                        </>
                                    )}
                                </Typography>
                            </Box>

                            {/* Left Column: Client */}
                            <Box flex={1}>
                                <Typography sx={{ display: "flex", justifyContent: "flex-end", gap: 1 }}>
                                    {isEditMode ? (
                                        <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
                                            <Box sx={{ direction: "ltr", marginBottom: "8px" }}>
                                                <Box component="strong" style={{ marginBottom: "50px" }}>{t("Client Name")}</Box>
                                                <AutocompleteSelect
                                                    options={caseIdOptions.map((acc) => ({
                                                        value: acc.value,
                                                        label: acc.value ? `${acc.label} - ${acc.value}` : acc.label,
                                                    }))}
                                                    selectedValues={
                                                        caseIdOptions
                                                            .map((acc) => ({ value: acc.value, label: acc.label }))
                                                            .find((option) => option.value === (client?.caseId || currentAssignment?.caseId)) || null
                                                    }
                                                    onChange={(event, values) => changeOnAssignment(values)}
                                                    placeholder={t("SelectClient")}
                                                    isMultiple={false}
                                                    width="300px"
                                                    isDisabled={false}
                                                    style={{ marginBottom: "20px" }}
                                                />
                                            </Box>
                                        </div>
                                    ) : (
                                        <>
                                            <UserAvatar
                                                name={
                                                    caseIdOptions?.find((acc) => acc.value === currentAssignment?.caseId)?.label ||
                                                    t("NoClient")
                                                }
                                                flip
                                                size={30}
                                                caseId={
                                                    caseIdOptions?.find((acc) => acc.value === currentAssignment?.caseId)?.value || null
                                                }
                                                deduction={
                                                    caseIdOptions?.find((acc) => acc.value === currentAssignment?.caseId)?.deductionsCase || null
                                                }
                                            />
                                            <Box sx={{ fontSize: "1.25rem", marginTop: "8px" }}>:{t("Client Name")}</Box>
                                        </>
                                    )}
                                </Typography>
                            </Box>



                        </Box>

                        {/* ---------- ROW 3: Status (and empty placeholder) ---------- */}
                        <Box display="flex" gap={2}>
                            {/* Right Column: Created Date */}
                            <Box flex={1}>
                                <Typography sx={{ display: "flex", justifyContent: "flex-end", gap: 1 }}>
                                    <Box
                                        sx={{
                                            textAlign: "left",
                                            fontWeight: "bold",
                                            fontSize: "1.25rem",
                                        }}
                                    >
                                        {formatDateWithHour(currentAssignment?.createDate) || ""}
                                    </Box>
                                    <Box sx={{ fontSize: "1.25rem" }}>:{t("CreationDate")}</Box>
                                </Typography>
                            </Box>

                            {/* Left Column: Catagory */}
                            <Box flex={1}>
                                <Typography sx={{ display: "flex", justifyContent: "flex-end", gap: 1 }}>
                                    {isEditMode ? (
                                        <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
                                            <Box sx={{ direction: "ltr", marginBottom: "8px" }}>
                                                <Box component="strong" style={{ marginBottom: "50px" }}>{t("Category")}</Box>
                                                <AutocompleteSelect
                                                    options={categoryOptions.map((acc) => ({
                                                        value: acc.value,
                                                        label: acc.label,
                                                    }))}
                                                    selectedValues={
                                                        categoryOptions
                                                            .map((acc) => ({ value: acc?.value, label: acc?.label }))
                                                            .find((option) => option.label === currentAssignment?.category) || null
                                                    }
                                                    onChange={(event, values) => {
                                                        if (values?.value === "NEW_CATEGORY") {
                                                            // Open the modal if "NEW_CATEGORY" is selected
                                                            handleOpenNewCategoryModal();

                                                            // Do not set "NEW_CATEGORY" in selected values
                                                            changeOnAssignmentCategory(values); // Or pass any default fallback value
                                                        } else {
                                                            // Otherwise, update the selected category
                                                            changeOnAssignmentCategory(values);
                                                        }
                                                    }}
                                                    placeholder={t("SelectCategory")}
                                                    isMultiple={false}
                                                    width="300px"
                                                    isDisabled={false}
                                                    style={{ marginBottom: "20px" }}
                                                />
                                            </Box>
                                        </div>
                                    ) : (
                                        <>
                                            <Box
                                                sx={{
                                                    textAlign: "left",
                                                    fontWeight: "bold",
                                                    fontSize: "1.25rem",
                                                }}
                                            >
                                                {categoryOptions?.find((acc) => acc.label === currentAssignment?.category)?.label ||
                                                    t("NoCategory")}
                                            </Box>
                                            <Box sx={{ fontSize: "1.25rem" }}>:{t("Category")}</Box>
                                        </>
                                    )}
                                </Typography>
                            </Box>



                        </Box>

                        <Divider sx={{ margin: "8px 0" }} />
                        {/* Description */}
                        <Typography variant="h6" sx={{ textAlign: "left", margin: " 0  0 2px 0", fontWeight: "bold" }}>:{t("Description")}</Typography>
                        <Typography sx={{
                            display: "flex", flexDirection: "column", justifyContent: "flex-end", gap: 1,
                            alignItems: "top", // Center horizontally
                            ...(isEditMode
                                ? {} // No additional styles in edit mode
                                : {
                                    border: "1px solid #ccc",
                                    borderRadius: "4px",
                                    padding: "10px",
                                    minWidth: "100px",
                                }),
                        }}>

                            {isEditMode ? (
                                <Box style={{ width: "96.7%" }}>
                                    <div
                                        onDrop={handleDropDocumentToAssignment}
                                        onDragOver={(e) => e.preventDefault()} // Prevent default browser behavior
                                        style={{ position: 'relative' }}
                                    >
                                        <IconButton
                                            sx={{
                                                position: 'absolute',
                                                right: 8,
                                                top: 3,
                                                zIndex: 10, // Ensure icon stays on top
                                            }}
                                            onClick={handleUploadClick}
                                        >
                                            <CloudUploadIcon />
                                        </IconButton>
                                        {/* Hidden file input */}
                                        <input
                                            ref={fileInputRefUpload}
                                            type="file"
                                            style={{ display: 'none' }}
                                            onChange={handleFileChange}
                                        />
                                        <ReactQuill
                                            theme="snow"
                                            value={currentAssignment.description || ""}
                                            onChange={handleChangeCurrentDescription}
                                            formats={[
                                                'bold',
                                                'italic',
                                                'underline',
                                                'strike',
                                                'blockquote',
                                                'list',
                                                'bullet',
                                                'indent',
                                                'direction',
                                                'align',
                                                'link',
                                                'image',
                                                'clean',
                                                'direction',
                                                'align'
                                            ]}
                                            modules={{
                                                toolbar: [
                                                    ['bold', 'italic', 'underline', 'strike'],
                                                ],
                                            }}
                                            className="myCustomQuill"
                                            placeholder={t("TypeYourCommentHere")}
                                            style={{
                                                overflowY: "auto",
                                                // The direction and text-align here are optional since we did it in CSS
                                            }}
                                        />
                                        {currentAssignment?.attachedDocuments?.length ? (
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    gap: '8px',
                                                    flexWrap: 'nowrap',
                                                    overflowX: 'auto',
                                                    // border: '1px solid #ccc',
                                                    borderRadius: '4px',
                                                    width: '100%', // 2/3 of the container width
                                                }}
                                            >
                                                {currentAssignment.attachedDocuments?.map((file, index) => (
                                                    <FileView
                                                        key={index}
                                                        file={file}
                                                        onRemove={() => handleRemoveDocumentFromAssignment(index)}
                                                        onOpenModal={handleOpenModal}
                                                    />
                                                ))}
                                            </div>
                                        ) : (<div></div>)}
                                    </div>
                                </Box>
                            ) : (
                                <>
                                    <Box
                                        sx={{
                                            "& ul, & ol": {
                                                paddingInlineStart: "20px",
                                                margin: 0,
                                                listStylePosition: "inside",
                                            },
                                            "& li": {
                                                textAlign: "inherit", // Follows the dynamically set direction
                                                direction: "inherit", // Inherits the parent direction
                                            },
                                        }}
                                        style={{
                                            direction: currentAssignment?.description?.includes('ql-direction-rtl') ? "rtl" : "ltr", // Dynamically set the direction
                                        }}
                                        dangerouslySetInnerHTML={{
                                            __html: currentAssignment?.description || "",
                                        }}
                                    />
                                    {currentAssignment?.attachedDocuments?.length ? (
                                        <div
                                            style={{
                                                display: 'flex',
                                                gap: '8px',
                                                flexWrap: 'nowrap',
                                                overflowX: 'auto',

                                                borderRadius: '4px',
                                                width: '100%', // 2/3 of the container width
                                            }}
                                        >
                                            {currentAssignment.attachedDocuments?.map((file, index) => (
                                                <FileView
                                                    key={index}
                                                    file={file}
                                                    onRemove={() => handleRemoveDocumentFromAssignment(index)}
                                                    isCreate={false}
                                                    onOpenModal={handleOpenModal}
                                                />
                                            ))}
                                        </div>
                                    ) : (<div></div>)}

                                </>
                            )}
                        </Typography>

                        {/* Priority */}
                        <Typography sx={{ display: "flex", justifyContent: "flex-end", gap: 1 }}>
                            {isEditMode ? (
                                <Box>
                                    <Box component="strong">:{t("Priority")}</Box>

                                    <Box sx={{ direction: "ltr" }}>
                                        <AutocompleteSelect
                                            options={[
                                                { value: Constants.PriorityEnum.Low, label: t("Low") },
                                                { value: Constants.PriorityEnum.High, label: t("High") },
                                                { value: Constants.PriorityEnum.Critical, label: t("Critical") },
                                            ]}
                                            selectedValues={{
                                                value: currentAssignment?.priority,
                                                label: t(
                                                    Object.keys(Constants.PriorityEnum).find(
                                                        (key) => Constants.PriorityEnum[key] === currentAssignment?.priority
                                                    )
                                                ),
                                            }}
                                            onChange={(event, values) =>
                                                setCurrentAssignment({ ...currentAssignment, priority: values?.value })
                                            }
                                            placeholder={t("SelectPriority")}
                                            isMultiple={false} // Single selection
                                            width="300px" // Adjust width if needed
                                            isDisabled={false} // Enable or disable the dropdown
                                        />

                                    </Box>
                                </Box>
                            ) : (
                                <>
                                    <Box
                                        sx={{
                                            fontSize: "1.25rem", // Larger font size
                                            marginTop: "8px", // Adds space above the text
                                            fontWeight: "bold",
                                        }}
                                    >
                                        {t(
                                            Object.keys(Constants.PriorityEnum).find(
                                                (key) => Constants.PriorityEnum[key] === currentAssignment?.priority
                                            )
                                        ) || ""}
                                    </Box>
                                    <Box
                                        sx={{
                                            fontSize: "1.25rem", // Larger font size
                                            marginTop: "8px", // Adds space above the text
                                        }}
                                    >
                                        :{t("Priority")}
                                    </Box>
                                </>
                            )}
                        </Typography>

                        {/* Due Date */}
                        <Typography sx={{ display: "flex", justifyContent: "flex-end", gap: 1 }}>
                            {isEditMode ? (

                                <Box sx={{ maxWidth: "300px" }}>
                                    <Box component="strong">:{t("DueDate")}</Box>
                                    <CustomDatePicker
                                        date={
                                            currentAssignment?.dueDate
                                                ? new Date(currentAssignment.dueDate)
                                                : null
                                        }
                                        onDateChange={(date) => {
                                            if (date) {
                                                setCurrentAssignment({ ...currentAssignment, dueDate: date.toISOString() });
                                            }
                                        }}
                                        height="48px"
                                    />
                                </Box>
                            ) : (
                                <>
                                    <Box
                                        sx={{
                                            textAlign: "left",
                                            fontWeight: "bold", // Makes the text bold
                                            fontSize: "1.25rem"
                                        }}>
                                        {formatDate(currentAssignment?.dueDate) || ""}
                                    </Box>
                                    <Box sx={{
                                        fontSize: "1.25rem", // Larger font size
                                    }}
                                    >:{t("DueDate")}
                                    </Box>
                                </>
                            )}
                        </Typography>
                        {/* Comments */}
                        <Divider sx={{ margin: "8px 0" }} />
                        {(!isAddMode && !isEditMode) && (
                            <>
                                <Box>
                                    <Typography variant="h6" sx={{ textAlign: "left", margin: " 0  0 8px 0", fontWeight: "bold" }}>{t("Comments")}</Typography>

                                    {/* ReactQuill Editor */}
                                    <div
                                        onDrop={handleDropDocumentToComment}
                                        onDragOver={(e) => e.preventDefault()}
                                        style={{ position: "relative" }}
                                    >
                                        {/* Upload Icon */}
                                        <IconButton
                                            sx={{
                                                position: "absolute",
                                                right: 8,
                                                top: 3,
                                                zIndex: 10,
                                            }}
                                            onClick={handleUploadClickComment} // triggers hidden input
                                        >
                                            <CloudUploadIcon />
                                        </IconButton>
                                        {/* Hidden file input */}
                                        <input
                                            ref={fileInputRefComment}
                                            type="file"
                                            style={{ display: 'none' }}
                                            onChange={handleCommentFileChange}
                                        />
                                        <ReactQuill
                                            theme="snow"
                                            value={newComment.content || ""}
                                            onChange={(value) => handleChangeNewCommentContent(value)}
                                            formats={[
                                                'bold',
                                                'italic',
                                                'underline',
                                                'strike',
                                                'blockquote',
                                                'list',
                                                'bullet',
                                                'indent',
                                                'direction',
                                                'align',
                                                'link',
                                                'image',
                                                'clean',
                                                'direction',
                                                'align'
                                            ]}
                                            modules={{
                                                toolbar: [
                                                    ['bold', 'italic', 'underline', 'strike'],
                                                ],
                                            }}
                                            className="myCustomQuill"
                                            placeholder={t("TypeYourCommentHere")}
                                            style={{
                                                overflowY: "auto",
                                                // The direction and text-align here are optional since we did it in CSS
                                            }}
                                        />

                                        {/* Add Comment Button */}
                                        <div
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'space-between',
                                                gap: '16px',
                                                overflow: 'hidden',

                                                padding: '8px',
                                            }}
                                        >
                                            {/* Container for attached files with scroll */}
                                            {newComment?.attachedDocuments?.length ? (
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        gap: '8px',
                                                        flexWrap: 'nowrap',
                                                        overflowX: 'scroll',
                                                        border: '1px solid #ccc',
                                                        borderRadius: '4px',
                                                        width: '80%', // 2/3 of the container width
                                                    }}
                                                >
                                                    {newComment.attachedDocuments?.map((file, index) => (
                                                        <FileView
                                                            key={index}
                                                            file={file}
                                                            onRemove={() => handleRemoveDocumentFromComment(index)}
                                                            onOpenModal={handleOpenModal}
                                                        />
                                                    ))}
                                                </div>
                                            ) : (<div></div>)}
                                            {/* Button for adding a comment */}
                                            <div
                                                style={{
                                                    width: '20%', // 1/3 of the container width
                                                    display: 'flex',
                                                    justifyContent: 'flex-end', // Align button to the end
                                                }}
                                            >
                                                <button
                                                    onClick={() => handleAddComment(currentAssignment.id)}
                                                    className="create-Invoice-selector-2-small"
                                                    style={{ marginRight: 8, height: '20px', weight: '15px' }}

                                                >
                                                    {t('AddComment')}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </Box>

                                {currentAssignment?.comments?.map((comment, index) => (
                                    <Box
                                        key={index}
                                        p={2}
                                        border="1px solid #ccc"
                                        borderRadius="8px"
                                        mb={2}
                                        sx={{ direction: "rtl", position: "relative" }}
                                    >
                                        {/* Commentor Name and Type */}
                                        <Typography sx={{ textAlign: "right", position: "relative" }}>
                                            <div style={{ display: "flex", alignItems: "center", gap: "8px", justifyContent: "flex-end" }}>
                                                <strong>{comment.commentorName}</strong>

                                                <UserAvatar
                                                    name={comment.commentorName}
                                                    flip={true}
                                                    withName={false} // Ensures the name is not repeated in the avatar
                                                />
                                            </div>
                                            {/* ({t("Type")}: {comment.commentorType}) */}
                                        </Typography>
                                        {/* Comment Content */}
                                        <Box
                                            sx={{
                                                "& ul, & ol": {
                                                    paddingInlineStart: "20px",
                                                    margin: 0,
                                                    listStylePosition: "inside",
                                                },
                                                "& li": {
                                                    textAlign: "inherit", // Follows the dynamically set direction
                                                    direction: "inherit", // Inherits the parent direction
                                                },
                                            }}
                                            style={{
                                                direction: comment.content.includes('ql-direction-rtl') ? 'rtl' : 'ltr', // Dynamically set the direction
                                            }}
                                            dangerouslySetInnerHTML={{
                                                __html: comment.content,
                                            }}
                                        />

                                        {comment?.attachedDocuments?.length ? (
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    gap: '8px',
                                                    flexWrap: 'nowrap',
                                                    overflowX: 'auto',
                                                    width: '80%', // 2/3 of the container width
                                                }}
                                            >
                                                {comment.attachedDocuments?.map((file, index) => (
                                                    <FileView
                                                        key={index}
                                                        file={file}
                                                        isCreate={false}
                                                        onRemove={() => handleRemoveDocumentFromComment(index)}
                                                        onOpenModal={handleOpenModal}
                                                    />
                                                ))}
                                            </div>
                                        ) : (<div></div>)}

                                        {/* Comment Date */}
                                        <Typography sx={{ textAlign: "right" }}>
                                            {formatDateWithHour(comment.commentDate)}
                                        </Typography>

                                        {((!user?.subAccountantId && user?.accountantId === comment.commentorId) ||
                                            (user?.subAccountantId === comment.commentorId)) && (
                                                <IconButton
                                                    onClick={() => handleDeleteComment(comment.id)}
                                                    sx={{ position: "absolute", top: 8, right: 8 }}
                                                    color="error"
                                                >
                                                    <DeleteIcon />
                                                </IconButton>
                                            )}
                                    </Box>
                                ))}
                            </>
                        )}
                    </Box>
                </DialogContent>
                <DialogActions sx={{ justifyContent: "center", gap: "40px" }}>
                    {isEditMode && (<>
                        {!isAddMode && (
                            <button
                                onClick={() => setIsConfirmModalVisable(true)}
                                className="create-Invoice-selector-2"
                                style={{ backgroundColor: "#d9534f", marginRight: 8 }} // Red color for delete button

                            >
                                <DeleteIcon style={{ fontSize: 24, marginLeft: 8, color: "white" }} />
                                <span>{t("Delete")}</span>
                            </button>
                        )}
                        <button
                            onClick={handleSaveAssignment}
                            className="create-Invoice-selector-2"
                        >
                            <SaveIcon style={{ fontSize: 24, marginLeft: 8, color: "white" }} />
                            <span>{t("Save")}</span>
                        </button>

                    </>
                    )}
                    <button
                        onClick={handleCloseDialog}
                        className="create-Invoice-selector-2"
                    >
                        <span>{t("Close")}</span>
                    </button>
                    {/* <Button onClick={handleCloseDialog}>{t("Close")}</Button> */}
                </DialogActions>
            </Dialog >


            <div className="template-page-withoutHeight">
                <div className={`template-container ${direction}`}>
                    <UpperPanelDetails client={client} />

                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>

                        <div className={`main-title ${direction}`}>{t(type)}</div>
                        <div className='backIconBackground' style={{ alignSelf: i18n.language === 'en' ? 'flex-start' : 'flex-end' }} onClick={handleGoBack}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                                <path d="M20 21.1667L14.8267 15.9933L20 10.82C20.52 10.3 20.52 9.45999 20 8.93999C19.48 8.41999 18.64 8.41999 18.12 8.93999L12 15.06C11.48 15.58 11.48 16.42 12 16.94L18.12 23.06C18.64 23.58 19.48 23.58 20 23.06C20.5067 22.54 20.52 21.6867 20 21.1667Z" fill="black" fill-opacity="0.9" />
                            </svg>
                        </div>
                    </div>
                    <div className={`title-back-container ${direction}`} style={{ display: 'flex', gap: 10 }}>


                        {/* Right Half for Add Button and Autocomplete */}
                        <div style={{ flex: 1 }}>
                            <div className="add-assignment-button-container" style={{ textAlign: "right", marginBottom: "16px" }}>
                                <button
                                    onClick={handleAddAssignment}
                                    className="create-Invoice-selector"
                                >
                                    <AddCircleIcon style={{ fontSize: 24, marginRight: 8, color: "white" }} />
                                    <span>{t("AddAssignment")}</span>
                                </button>
                            </div>
                            <Box sx={{ display: 'flex', gap: 2, marginBottom: 2 }}>
                                <AutocompleteSelect
                                    options={subAccountantOptions}
                                    selectedValues={selectedSubAccountants}
                                    onChange={(event, values) => setSelectedSubAccountants(values)}
                                    label={t("SubAccountant")}
                                    placeholder={t("SelectSubAccountant")}
                                />
                                <AutocompleteSelect
                                    options={caseIdOptions}
                                    selectedValues={selectedCaseIds}
                                    onChange={(event, values) => handleSetSelectedCaseIds(values)}
                                    label={t("Client Name")}
                                    placeholder={t("SelectCustomer")}
                                />
                                <AutocompleteSelect
                                    options={priorityOptions}
                                    selectedValues={selectedPriorities}
                                    onChange={(event, values) => setSelectedPriorities(values)}
                                    label={t("Priority")}
                                    placeholder={t("SelectPriority")}
                                />
                                <AutocompleteSelect
                                    options={categoryOptions}
                                    selectedValues={selectedCategories}
                                    onChange={(event, values) => {
                                        if (Array.isArray(values)) {
                                            // For multiple selection, check if "NEW_CATEGORY" is selected
                                            const filteredValues = values.filter(v => v.value !== "NEW_CATEGORY");
                                            const foundNewCategory = values.some(v => v.value === "NEW_CATEGORY");

                                            // Update selected categories without "NEW_CATEGORY"
                                            setSelectedCategories(filteredValues);

                                            if (foundNewCategory) {
                                                handleOpenNewCategoryModal();
                                            }
                                        } else {
                                            // For single selection, check if "NEW_CATEGORY" is selected
                                            if (values?.value === "NEW_CATEGORY") {
                                                handleOpenNewCategoryModal();
                                                // Do not set "NEW_CATEGORY" in selected categories
                                                setSelectedCategories(null); // or an empty array if you prefer []
                                            } else {
                                                // Set the single value
                                                setSelectedCategories(values);
                                            }
                                        }
                                    }}
                                    label={t("Category")}
                                    placeholder={t("SelectCategory")}
                                />

                            </Box>
                            <Box sx={{ display: 'flex', gap: 2, marginBottom: 2 }}>
                                {isRange ? (
                                    <>
                                        {/* <CustomMonthDatePicker
                                            onDateChange={(date) => handleStartDateChange(date)}
                                            date={dayjs(startDate)}
                                            label={t("StartDate")}
                                        /> */}
                                        <CustomMonthYearIndividualDatePicker
                                            year={dayjs(startDate)}
                                            month={dayjs(startDate)}
                                            onYearChange={(newYear) => handleStartDateChangeNew(newYear, 'year')}
                                            onMonthChange={(newMonth) => handleStartDateChangeNew(newMonth, 'month')}
                                            labelYear={t("StartYear")}
                                            labelMonth={t("StartMonth")}
                                            height="48px"
                                            widthOuter='270px'
                                            widthInner='120px'
                                        />

                                        <FormControlLabel
                                            value={isRange}
                                            control={
                                                <Switch
                                                    className="custom-input-box-sizing-toggle"
                                                    color="primary"
                                                    checked={isRange}
                                                    onChange={handleRangeChange}
                                                />
                                            }
                                            label={t("DateRange")}
                                            labelPlacement="bottom"
                                        />
                                        {/* <CustomMonthDatePicker
                                            onDateChange={(date) => handleEndDateChange(date)}
                                            date={dayjs(endDate)}
                                            label={t("EndDate")}
                                        /> */}
                                        <CustomMonthYearIndividualDatePicker
                                            year={dayjs(endDate)}
                                            month={dayjs(endDate)}
                                            onYearChange={(newYear) => handleEndDateChangeNew(newYear, 'year')}
                                            onMonthChange={(newMonth) => handleEndDateChangeNew(newMonth, 'month')}
                                            labelYear={t("EndYear")}
                                            labelMonth={t("EndMonth")}
                                            height="48px"
                                            widthOuter='270px'
                                            widthInner='120px'
                                        />
                                    </>
                                ) : (
                                    <>
                                        {/* <CustomMonthDatePicker
                                            onDateChange={(date) => handleStartDateChange(date)}
                                            date={dayjs(startDate)}
                                            label={t("Date")}
                                        /> */}
                                        <CustomMonthYearIndividualDatePicker
                                            year={dayjs(startDate)}
                                            month={dayjs(startDate)}
                                            onYearChange={(newYear) => handleStartDateChangeNew(newYear, 'year')}
                                            onMonthChange={(newMonth) => handleStartDateChangeNew(newMonth, 'month')}
                                            labelYear={t("Year")}
                                            labelMonth={t("Month")}
                                            height="48px"
                                            widthOuter='270px'
                                            widthInner='120px'
                                        />
                                        <FormControlLabel
                                            value={!isRange}
                                            control={
                                                <Switch
                                                    className="custom-input-box-sizing-toggle"
                                                    color="primary"
                                                    checked={isRange}
                                                    onChange={handleRangeChange}
                                                />
                                            }
                                            label={t("DateRange")}
                                            labelPlacement="bottom"
                                        />
                                    </>
                                )}
                            </Box>

                            <TextField
                                variant="outlined"
                                size="small"
                                placeholder={t("Search")}
                                value={searchQuery}
                                onChange={(e) => setSearchQuery(e.target.value)}
                                style={{ width: '250px' }} // adjust as needed
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <SearchIcon />
                                        </InputAdornment>
                                    ),
                                }}
                            />

                        </div>

                        {/* Left Half for Pie Chart */}
                        <div style={{ flex: 1, display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', gap: '100px' }}>
                            <BarChart
                                xAxis={[
                                    {
                                        data: lineGraphData.map((item) => item.x),
                                        scaleType: "band",
                                        label: isRange && dayjs(endDate).diff(dayjs(startDate), 'month') > 2
                                            ? t("Month") // Label for month
                                            : t("Day"),  // Label for day
                                    },
                                ]}
                                series={[
                                    {
                                        data: lineGraphData.map((item) => item.y),
                                        label: t("TasksDone"),
                                    },
                                ]}
                                width={600}
                                height={200}
                            />
                            <Box>
                                <PieChart
                                    series={[
                                        {
                                            data: pieChartData,
                                            innerRadius: 50, // Creates a "donut" chart
                                            outerRadius: 100,
                                            label: ({ dataEntry }) => `${dataEntry.label}: ${dataEntry.value}`, // Display labels
                                        },
                                    ]}
                                    width={300}
                                    height={200}
                                    margin={{ right: 150 }}
                                    slotProps={{
                                        legend: {
                                            direction: 'column',
                                            position: { vertical: 'top', horizontal: 'right' },
                                        },
                                    }}
                                />

                            </Box>
                        </div>
                    </div>



                    {/* current table */}
                    <div
                        className="table-container"
                        style={{
                            overflowX: "hidden",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <Box
                            sx={{
                                display: "flex",
                                gap: 3,
                                p: 3,
                                width: "100%",
                                flexDirection: "row",
                            }}
                        >
                            {(clickedTable === '' || clickedTable === 'todo') &&
                                <Box sx={{ flex: 1 }}>
                                    <GenericTable
                                        title={t("ToDo")}
                                        data={filteredAssignments}
                                        columns={columns}
                                        filterFn={(assignment) => assignment.status === Constants.StatusEnum.ToDo}
                                        noDataMessage={t("NoData")}
                                        onDropStatus={(draggedId) =>
                                            handleStatusChange(draggedId, Constants.StatusEnum.ToDo)
                                        }
                                        tableRef={todoTableRef}
                                        handleChangeClickedTable={handleChangeClickedTable}
                                        onRowClick={handleOpenEditDialog}
                                        whatTable={'todo'}
                                    />
                                </Box>
                            }
                            {(clickedTable === '' || clickedTable === 'inprogress') &&
                                <Box sx={{ flex: 1 }}>
                                    <GenericTable
                                        title={t("InProgress")}
                                        data={filteredAssignments}
                                        columns={columns}
                                        filterFn={(assignment) => assignment.status === Constants.StatusEnum.InProgress}
                                        noDataMessage={t("NoData")}
                                        handleChangeClickedTable={handleChangeClickedTable}
                                        onDropStatus={(draggedId) =>
                                            handleStatusChange(draggedId, Constants.StatusEnum.InProgress)
                                        }
                                        tableRef={todoTableRef}
                                        onRowClick={handleOpenEditDialog}
                                        whatTable={'inprogress'}
                                    />
                                </Box>
                            }
                            {(clickedTable === '' || clickedTable === 'done') &&
                                <Box sx={{ flex: 1 }}>
                                    <GenericTable
                                        title={t("Done")}
                                        data={filteredAssignments}
                                        columns={columnsDoneTable}
                                        handleChangeClickedTable={handleChangeClickedTable}
                                        filterFn={(assignment) => assignment.status === Constants.StatusEnum.Done}
                                        noDataMessage={t("NoData")}
                                        onDropStatus={(draggedId) =>
                                            handleStatusChange(draggedId, Constants.StatusEnum.Done)
                                        }
                                        tableRef={todoTableRef}
                                        onRowClick={handleOpenEditDialog}
                                        whatTable={'done'}
                                    />
                                </Box>
                            }
                        </Box>
                    </div>


                </div>
                <HamburgerMenu client={client} />

                <ToastContainer />
                {loading && (
                    <div className="loader-container">
                        <PuffLoader
                            size={100}        // Larger size for better visibility
                            loading={loading}
                        />
                    </div>
                )}
            </div >

        </>
    );
}


export default Assignments;
