// src/components/BankPages/BankPages.jsx

import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import HamburgerMenu from '../HamburgerMenu/HamburgerMenu';
import PuffLoader from 'react-spinners/PuffLoader';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useTranslation } from 'react-i18next';
import UserService from '../../Services/user.service';
import EnumsService from '../../Services/enums.service';
import ExternalTransactionsService from '../../Services/externalTransaction.service';
import NoDocumentsPdf from '../../assests/pdf/NoDocument.pdf';
import Constants from '../../assests/Constants/constants';
import DenseTable from '../DenseTable/DenseTable';
import UpperPanelDetails from '../Widgets/UpperPanelDetails/UpperPanelDetails';
import CustomMonthDatePicker from '../Widgets/CustomDates/CustomMonthDatePicker/CustomMonthDatePicker';
import { useBack } from "use-back";

import dayjs from 'dayjs';
import './BankPages.css';
import {
    TextField, InputAdornment, FormControlLabel, Switch, Button,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { beautifyNumber } from '../../Utils/FormatNumber';
import AddIcon from '@mui/icons-material/Add';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import AutocompleteSelect from '../Widgets/AutocompleteSelect/AutocompleteSelect';
import ImportDialog from '../BankMatching/ImportDialog';
import TransactionsTable from './SubComponents/TransactionsTable';
// import EditExternalTransactionModal from './SubComponents/EditExternalTransactionModal';
import AddOrEditBankDialog from '../BankMatching/AddOrEditBankDialog'; // Assuming this component exists
import AddExternalTransactionDialog from '../BankMatching/AddExternalTransactionDialog';
import ClickAbleDenseTable from '../BankMatching/ClickAbleDenseTable/ClickAbleDenseTable';
import EditExternalTransactionDialog from './EditExternalTransaction';
import CustomMonthYearIndividualDatePicker from '../Widgets/CustomDates/CustomMonthYearIndividualDatePicker/CustomMonthYearIndividualDatePicker';
function BankPages() {
    const navigate = useNavigate();
    const location = useLocation();
    const { t, i18n } = useTranslation();
    const direction = i18n.dir();

    const [user, setUser] = useState(null);
    const [token, setToken] = useState('');
    const [client, setClient] = useState(null);
    const [loading, setLoading] = useState(false);
    const [type, setType] = useState('');
    const [processedCardsData, setProcessedCardsData] = useState([]);
    const [accountCardOptionsForBank, setAccountCardOptionsForBank] = useState([]);
    const [tBanksData, setTBanksData] = useState([]);
    const [accountCardsData, setAccountCardsData] = useState([]);
    const [accountCardEditDownloadOptions, setAccountCardEditDownloadOptions] =
        useState([]);
    const [formattedPalCodeOptions, setFormattedPalCodeOptions] = useState([]);
    const [isClientDataFetched, setIsClientDataFetched] = useState(false);
    const [denseTableCreditDebitRows, setDenseTableCreditDebitRows] = useState([]);

    const [transactions, setTransactions] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedTBankIndex, setSelectedTBankIndex] = useState(-1); //used for dense table
    const [selectedTBank, setSelectedTBank] = useState("");
    const [startDate, setStartDate] = useState(() => {
        const storedDateString = localStorage.getItem("BankPages_StartDate");
        return storedDateString
            ? new Date(storedDateString)
            : new Date(new Date().getFullYear(), 0, 2); // Start of the current year
    });

    const [endDate, setEndDate] = useState(() => {
        const storedDateString = localStorage.getItem("BankPages_EndDate");
        return storedDateString
            ? new Date(storedDateString)
            : new Date(new Date().getFullYear(), 11, 30); // End of the current year
    });
    const [addBankTransactionDialog, setAddBankTransactionDialog] =
        useState(false);

    const [editBankTransactionDialog, setEditBankTransactionDialog] = useState(false);

    const [isMonthly, setIsMonthly] = useState(() => {
        const storedIsMonthly = localStorage.getItem('BankPages_IsMonthly');
        return storedIsMonthly !== null ? JSON.parse(storedIsMonthly) : true;
    });

    const [accountCardOptions, setAccountCardOptions] = useState([]);
    const [selectedAccountCard, setSelectedAccountCard] = useState('');

    useEffect(() => {
        if (!endDate || isNaN(new Date(endDate).getTime())) {
            // Set endDate to December 31 of the current year
            setEndDate(new Date(new Date().getFullYear(), 11, 31));
        }

        // Check if startDate is null, undefined, or an invalid date
        if (!startDate || isNaN(new Date(startDate).getTime())) {
            // Set startDate to January 1 of the current year
            setStartDate(new Date(new Date().getFullYear(), 0, 2));
        }

        
    }, [endDate, startDate, setEndDate, setStartDate]);



    const [sortConfig, setSortConfig] = useState({
        key: 'transactionDate',
        direction: 'descending',
        type: 'date',
    });
    const [oldEditedTransaction, setOldEditedTransaction] = useState(null);
    const [editedTransaction, setEditedTransaction] = useState({
        caseId: '',
        accountCodeId: '',
        accountCodeName: '',
        description: '',
        debitAccount: 0,
        creditAccount: 0,
        reference: '',
        amount: '',
        transactionDate: ''
    });
    const [openImportDialog, setOpenImportDialog] = useState(false);
    const [oldBank, setOldBank] = useState(null);
    const bankOptions = Object.keys(Constants.BankNames).map((key) => ({
        value: key,
        label: `${Constants.BankNames[key]} - ${key}`, // Format: BankName - BankNumber
    }));
    const [denseTableRows, setDenseTableRows] = useState([]);
    const [mainCard, setMainCard] = useState("");
    const [selectedBankForCreate, setSelectedBankForCreate] = useState(null);
    const [editedBank, setEditedBank] = useState(false);
    const [bankBranch, setBankBranch] = useState("");
    const [bankAccountNumber, setBankAccountNumber] = useState("");
    const [cardForNewBank, setCardForNewBank] = useState(null);
    const [isEditingBank, setIsEditingBank] = useState(false);

    const [denseTableHeader, setDenseTableHeader] = useState([
        t("BankNumber"),
        t("BankName"),
        t("BankBranch"),
        t("BankAccount"),
        t("AccountCardNumber"),
    ]);
    // Additional states for main card and dialogs
    const [mainCardOptions, setMainCardOptions] = useState([]);
    const [selectedMainCard, setSelectedMainCard] = useState(null);
    const [createBankModal, setCreateBankModal] = useState(false);
    const [selectedFormattedAccountCard, setSelectedFormattedAccountCard] =
        useState(null);
    // Fetch user and client data


    const selectedDenseTableRow = async (index) => {
        const tBank = tBanksData[index];
        if (!tBank) {
            return;
        }
        setSelectedTBank(tBank);
        setSelectedTBankIndex(index);
        setSelectedAccountCard(tBank.cardId);

        const cardIndexForAdv = processedCardsData.findIndex(
            (item) => item.cardId === tBank.cardId
        );

        if (cardIndexForAdv !== -1) {
            // If cardId is found
            const updatedMainCard = {
                ...processedCardsData[cardIndexForAdv],
                index: cardIndexForAdv,
            }; // Add the index to mainCard
            setMainCard(updatedMainCard); // Set the updated mainCard with the index
            setSelectedFormattedAccountCard(processedCardsData[cardIndexForAdv]);
        } else {
            // setMainCard(-1);
            // toast.info(t('NonBankAccountCardSelected'));
        }
    };


    useEffect(() => {

        if (mainCard) {


            // setSelectedAccountCard(mainCard.cardId);
            const cardIndex = tBanksData.findIndex(
                (item) => item.cardId?.toString() === mainCard.cardId.toString()
            );

            if (cardIndex !== -1) {
                setSelectedTBankIndex(cardIndex);
            } else {
                setSelectedTBankIndex(-1);
                // toast.info(t('NonBankAccountCardSelected'));
            }
        }
    }, [mainCard]);
    const handleFormattedAccountCardChange = (event, newValue) => {
        setMainCard(newValue);
        setSelectedAccountCard(newValue?.cardId);
        setSelectedFormattedAccountCard(newValue);
    };
    useEffect(() => {
        fetchUserData();
    }, []);


    const handleOpenEditTBank = (index) => {
        const selectedBankData = tBanksData[index]; // Get the selected bank data from tBanksData

        setOldBank(selectedBankData);
        // Find the bank option that matches the selected bank's bankCode
        const selectedBank = bankOptions.find(
            (bank) => bank.value === selectedBankData.bankCode
        );

        setSelectedBankForCreate(selectedBank);
        setEditedBank(selectedBankData); // Set the entire bank data for editing

        // Set other values for editing
        setBankBranch(selectedBankData.branchNumber);
        setBankAccountNumber(selectedBankData.accountNumber);
        setCardForNewBank(selectedBankData.cardId);
        setIsEditingBank(true);

        setCreateBankModal(true); // Open the modal
    };
    const [bankFromBankPage, setBankFromBankPage] = useState(null);

    const fetchUserData = async () => {
        setLoading(true);
        try {
            const currentUser = await UserService.getCurrentUser();
            const currentToken = await UserService.getCurrentToken();
            if (currentUser && currentToken) {
                setUser(currentUser);
                setToken(currentToken);

                if (location.state && location.state.client) {

                    setClient(location.state.client);
                    setType(location.state.type)
                    await fetchEnums(currentUser, currentToken, location.state.client);
                }
                if (!isNaN(Number(location.state.bankFromBankPage))) {
                    setBankFromBankPage(location.state.bankFromBankPage);
                }
            } else {
                navigate('/login');
            }
        } catch (error) {
            console.error('Error fetching user data:', error);
        }
        setLoading(false);
    };

    useEffect(() => {
        if (tBanksData && !isNaN(Number(location.state.bankFromBankPage)) && processedCardsData) {
            selectedDenseTableRow(bankFromBankPage);
        }
    }, [tBanksData, bankFromBankPage, processedCardsData]);

    // Fetch enums and account cards
    const fetchEnums = async (user, token, client) => {
        setLoading(true);
        try {
            const tBanks = await EnumsService.GetAllTBanksByCaseId(
                client.caseId,
                token
            );
            const accountCardsResponse = await EnumsService.GetAccountCardsByCaseId(
                client.caseId,
                token
            );
            const accountCardsData = await accountCardsResponse.json();


            const tBanksRows = tBanks.map((bank) => [
                bank.bankCode || "-",
                bank.bankName || "-",
                bank.branchNumber || "-",
                bank.accountNumber || "-",
                bank.cardId || "-",
            ]);

            //processed account cards data
            if (accountCardsData && accountCardsData.length > 0) {
                const transformedData = accountCardsData.map((card) => ({
                    ...card,
                    value: card.cardId, // Set value to cardId
                    label: `${card.cardId} - ${card.name} `, // Set label to name
                }));
                setProcessedCardsData(transformedData);
            }
            const accountCardOptions = [{ name: t('AddAccountCard'), value1: 'AddItem' }, ...accountCardsData];
            setAccountCardOptionsForBank(accountCardOptions);
            setDenseTableRows(tBanksRows);
            setTBanksData(tBanks);
            setAccountCardsData(accountCardsData);



            // Fetching creditAccountOptions and debitAccountOptions
            const accountsResponse = await EnumsService.GetAccountCardsByCaseId(
                client.caseId,
                token
            );
            if (accountsResponse.ok) {
                const accountsData = await accountsResponse.json();

                // Map accountsData to extract name and cardId
                const accountOptions = accountsData.map((account) => ({
                    label: account.name, // Display name in the dropdown
                    value: account.cardId, // Use cardId as the value
                }));


                setAccountCardEditDownloadOptions(accountOptions);

                const accountCardOptionsEdit = [
                    { name: t("AddAccountCard"), value1: "AddItem" },
                    ...accountsData,
                ];

                setAccountCardOptions(accountCardOptionsEdit);
            }
            const palCodesResponse = await EnumsService.GetPalCodesByAccountantId(
                client.accountantId,
                token
            );
            // Process palCodes if response is okay
            if (palCodesResponse.ok) {
                const palCodesData = await palCodesResponse.json();
                const formattedPalCodes = palCodesData.map((palCode) => ({
                    label: palCode.palCodeId,
                    value: palCode.palCodeId,
                }));

                setFormattedPalCodeOptions(formattedPalCodes);
            }

            setIsClientDataFetched(true);
        } catch (error) {
            console.error(error);
            toast.error(t("fetchEnumsError"), error);
            setIsClientDataFetched(false);
        }
        setLoading(false);
    };

    // Fetch transactions data
    const fetchTransactionsData = async (startDate, endDate) => {
        setLoading(true);
        try {
            const accountCardId = selectedAccountCard;
            if (!accountCardId) {
                setLoading(false);
                return;
            }
            const response = await ExternalTransactionsService.GetExternalTransactionsByDateRange(
                client.caseId,
                startDate,
                endDate,
                accountCardId,
                token
            );
            if (response.ok) {
                const data = await response.json();

                // data is of type ExternalTransactionSummaryDTO
                setTransactions(data.transactions || []);

                const response1 = await ExternalTransactionsService.GetTransactionSummaryByCaseAndAccount(client.caseId, selectedAccountCard, token);
                if (response1.ok) {
                    const summary = await response1.json();
                    setDenseTableCreditDebitRows([{ balance: beautifyNumber(summary.balance), credit: beautifyNumber(summary.credit), debit: beautifyNumber(summary.debit) }]);
                } else {
                    setDenseTableCreditDebitRows([{}]);
                    const errorMessage = await response1.text();
                    console.error("Error fetching summary:", errorMessage);
                }
                // toast.success(t('Transactions fetched successfully'));
            } else {
                const errorText = await response.text();
                toast.error(`${t('Error fetching transactions')}: ${errorText}`);
            }
        } catch (error) {
            toast.error(`${t('Error fetching transactions')}: ${error.message}`);
        }
        setLoading(false);
    };

    // Calculate totals for DenseTable
    const calculateDenseTableRows = (data) => {
        setDenseTableRows([
            {
                Credit: beautifyNumber(data.creditAccountAmount || 0),
                Debit: beautifyNumber(data.debitAccountAmount || 0),
                Balance: beautifyNumber(data.balance || 0),
            },
        ]);
    };

    // Handle date changes
    const handleStartDateChange = (date) => {
        const newDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), 10));

        setStartDate(newDate);
        localStorage.setItem('BankPages_StartDate', newDate);
        fetchTransactionsData(newDate, endDate);
    };

    const handleEndDateChange = (date) => {
        const endDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), 10));
        localStorage.setItem('BankPages_EndDate', endDate);
        fetchTransactionsData(startDate, endDate);
    };

    const handleEndDateChangeNew = async (newValue, type) => {
        const newDate = new Date(newValue);
        let updatedDate;

        if (type === 'year') {
            updatedDate = new Date(Date.UTC(newDate.getFullYear(), startDate.getMonth(), 10));
        } else if (type === 'month') {
            updatedDate = new Date(Date.UTC(startDate.getFullYear(), newDate.getMonth(), 10));
        }
        startDate = new Date(Date.UTC(newDate.getFullYear(), 1, 2));


        // Save the updated date in localStorage for persistence
        localStorage.setItem('BankPages_EndDate', updatedDate);


        setStartDate(updatedDate);
        fetchTransactionsData(startDate, endDate);
    };

    const handleEndDateChangeNew2 = async (newValue) => {
        const newDate = new Date(newValue);
        let updatedDate;
        console.log(newDate.getFullYear());


        const updatedEndDate = new Date(Date.UTC(newDate.getFullYear(), 11, 30, 23, 59, 59));
        const updatedStartDate = new Date(Date.UTC(newDate.getFullYear(), 0, 2, 0, 0, 0));



        // Save the updated date in localStorage for persistence
        localStorage.setItem('BankPages_EndDate', updatedDate);
        localStorage.setItem('BankPages_StartDate', updatedStartDate);

        setEndDate(updatedEndDate);
        setStartDate(updatedStartDate);
        fetchTransactionsData(updatedStartDate, updatedEndDate);
    };

    // Handle isMonthly toggle
    const handleIsMonthlyToggle = () => {
        setIsMonthly(!isMonthly);
        localStorage.setItem('BankPages_IsMonthly', JSON.stringify(!isMonthly));
    };

    // Handle search query change
    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };

    // Handle account card selection
    const handleAccountCardChange = (event, value) => {
        setSelectedAccountCard(value);
        fetchTransactionsData(startDate, endDate);
    };

    // Handle main card selection
    const handleMainCardChange = (event, value) => {
        setSelectedMainCard(value);
        // If main card affects data fetching, call fetchTransactionsData here
    };

    // Filtered and sorted transactions
    const filteredTransactions = useMemo(() => {
        let filtered = [...transactions];
        if (searchQuery) {
            filtered = filtered.filter((transaction) => {
                const query = searchQuery.toLowerCase();

                return (
                    (transaction.description && transaction.description.toLowerCase().includes(query)) ||
                    (transaction.reference1 && transaction.reference1.toLowerCase().includes(query)) ||
                    (transaction.reference2 && transaction.reference2.toLowerCase().includes(query)) ||
                    (transaction.debitAccount && transaction.debitAccount.toString().includes(query)) ||
                    (transaction.creditAccount && transaction.creditAccount.toString().includes(query))
                );
            });
        }

        if (sortConfig.key) {
            filtered.sort((a, b) => {
                const aValue = a[sortConfig.key] || '';
                const bValue = b[sortConfig.key] || '';

                if (sortConfig.type === 'number') {
                    return sortConfig.direction === 'ascending' ? aValue - bValue : bValue - aValue;
                } else if (sortConfig.type === 'date') {
                    return sortConfig.direction === 'ascending'
                        ? new Date(aValue) - new Date(bValue)
                        : new Date(bValue) - new Date(aValue);
                } else {
                    return sortConfig.direction === 'ascending'
                        ? aValue.localeCompare(bValue)
                        : bValue.localeCompare(aValue);
                }
            });
        }


        return filtered;
    }, [transactions, searchQuery, sortConfig]);

    // Handle sort change
    const handleSortChange = (key, type) => {
        let direction = 'ascending';
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({ key, direction, type });
    };

    // Handle edit transaction
    const handleEdit = (transaction, index) => {
        if (transaction.matchNumber !== -1) {
            toast.info(t('CannotEditMatchedCommand'));
            return;
        }
        setOldEditedTransaction(transaction);
        if (transaction.creditAccount > 0) {
            setEditedTransaction({
                ...transaction,
                amount: -transaction.creditAccount
            });
        } else {
            setEditedTransaction({
                ...transaction,
                amount: transaction.debitAccount
            });
        }
        setEditBankTransactionDialog(true);
    };

    // Handle add new transaction
    const [openAddTransactionDialog, setOpenAddTransactionDialog] = useState(false);
    const handleAddClick = () => {
        setOpenAddTransactionDialog(true);
    };

    const handleCloseAddTransactionDialog = () => {
        setOpenAddTransactionDialog(false);
    };
    const handleChangeBankForCreate = (value) => {
        setSelectedBankForCreate(value);
    };

    const handleChangeCreateBank = (field, value) => {
        if (field === "bankBranch") {
            setBankBranch(value);
        } else if (field === "bankAccountNumber") {
            setBankAccountNumber(value);
        } else if (field === "itemName") {
            // setItemName(value);
        }
    };
    const handleChangeSetCardForNewBank = (value) => {


        if (value) {
            setCardForNewBank(value);
        } else {
            setCardForNewBank(null);
        }
    }

    const handleEditBank = async () => {
        try {
            const bankName =
                Constants.BankNames[parseInt(selectedBankForCreate.value)];
            const bankCode = selectedBankForCreate.value;

            if (!selectedBankForCreate || !bankBranch || !bankAccountNumber) {
                toast.error(t("PleaseFillAllFields"));
                return;
            }

            if (!oldBank) {
                toast.error(t("OldBankDataNotFound"));
                return;
            }

            const response = await EnumsService.EditTBank(
                client.caseId, // Use the CaseId from the edited bank
                oldBank.bankCode, // Old bank code
                oldBank.accountNumber, // Old account number
                bankName, // New bank name
                bankCode, // New bank code
                bankBranch, // New branch number
                bankAccountNumber, // New account number
                cardForNewBank.toString(),
                token // Authorization token
            );

            if (response.ok) {
                toast.success(t("BankAccountUpdatedSuccessfully"));
                fetchEnums(user, token, client); // Fetch updated data
                handleCloseCreateModal(); // Close the modal
            } else {
                toast.error(t("FailedToUpdateBankAccount"));
            }
        } catch (error) {
            console.error(error);
            toast.error(t("ErrorUpdatingBankAccount"));
        }
    };


    const handleAddBank = async () => {
        try {
            const bankName =
                Constants.BankNames[parseInt(selectedBankForCreate.value)];
            const bankCode = selectedBankForCreate.value;

            if (!selectedBankForCreate || !bankBranch || !bankAccountNumber) {
                toast.error(t("PleaseFillAllFields"));
                return;
            }

            const response = await EnumsService.AddTBank(
                client.caseId,
                bankName,
                bankCode,
                bankBranch,
                bankAccountNumber,
                cardForNewBank.toString(),
                token
            );
            if (response.ok) {
                toast.success(t("BankAccountAddedSuccessfully"));
                fetchEnums(user, token, client);
                handleCloseCreateModal();
            } else {
                toast.error(t("FailedToAddBankAccount"));
            }
        } catch (error) {
            console.error(error);
            toast.error(t("ErrorAddingBankAccount"));
        }
    };

    const handleAddOrEditBank = () => {

        if (isEditingBank && editedBank) {
            handleEditBank();
        } else {
            handleAddBank();
        }
        handleCloseCreateModal(); // Close the modal after adding/editing
    };

    const handleRemoveBank = async () => {
        try {
            const response = await EnumsService.DeleteTBank(
                client.caseId,
                bankAccountNumber,
                selectedBankForCreate.value,
                token
            );
            if (response.ok) {
                toast.success(t('BankDeletedSuccessfully'));
                fetchEnums(user, token, client);
                handleCloseCreateModal();
            } else {
                toast.error(t("BankFailedToDelete"));
            }
        } catch (error) {
            console.error("BankFailedToDelete");
        }
    };

    // Handle import click
    const handleImportClick = () => {
        if (!selectedTBank) {
            toast.info(t("YouNeedToSelectABankFirst"));
            return;
        }

        // Search for a matching bank entry in tBanks
        const bankEntry = tBanksData.find(
            (bank) => bank.cardId?.toString() === mainCard.cardId?.toString()
        );

        // If a matching bank entry is found and it has a corresponding URL
        if (bankEntry && Constants.BankUrls[parseInt(bankEntry.bankCode)]) {
            // Open the bank's URL in a new tab
            window.open(Constants.BankUrls[parseInt(bankEntry.bankCode)], "_blank");
        }

        // Open the dialog
        setOpenImportDialog(true);
    };

    const handleOpenBankAddTransactionDialog = () => {
        if (!selectedTBank) {
            toast.info(t("YouNeedToSelectABankFirst"));
            return;
        }
        setAddBankTransactionDialog(true);
    };

    const handleNavigateToBankMatch = () => {
        const previosPage = '/BankPages'
        navigate('/BankTransactions', { state: { type: 'BankMatching', client, selectedTBankIndex, previosPage } });
    };

    const handleCloseBankAddTransactionDialog = () => {
        setAddBankTransactionDialog(false);
        setNewBankEditableItem({
            caseId: '',
            accountCodeId: '',
            accountCodeName: '',
            description: '',
            reference: '',
            amount: '',
            transactionDate: ''
        });

    };

    const handleCloseBankEditTransactionDialog = () => {

        setEditBankTransactionDialog(false);
        setEditedTransaction({
            caseId: '',
            accountCodeId: '',
            accountCodeName: '',
            description: '',
            creditAccount: 0,
            debitAccount: 0,
            reference: '',
            amount: '',
            transactionDate: ''
        });
    };




    const handleAddBankTransaction = async () => {
        try {


            const updatedBankItem = {
                ...newBankEditableItem,
                caseId: client.caseId,
                accountCodeId: mainCard.cardId,
                accountCodeName: mainCard.name,
            };



            const response = await ExternalTransactionsService.CreateTransaction(updatedBankItem, token);

            if (response.ok) {

                setNewBankEditableItem({
                    caseId: '',
                    accountCodeId: '',
                    accountCodeName: '',
                    description: '',
                    reference: '',
                    amount: '',
                    transactionDate: new Date()
                });
                handleCloseBankAddTransactionDialog();
                await fetchTransactionsData(startDate, endDate);
            } else {
                const errorMessage = await response.text();

                if (errorMessage === "A transaction with the same reference already exists.") {
                    toast.error(t('TransactionWithSameReferenceExists'));
                }
                toast.error(t('TransactionWithSameReferenceExists'));
            }
        } catch (error) {

            toast.error(t('FailedToCreateBankCommand'));
        }
    };

    const handleDelete = async () => {
        try {
            const deleteDTO = {
                Id: editedTransaction.id,
                CaseId: client.caseId,
                AccountCodeId: mainCard.cardId,
                Credit: editedTransaction.creditAccount,
                Debit: editedTransaction.debitAccount
            };

            const response = await ExternalTransactionsService.DeleteExternalTransaction(deleteDTO, token);

            if (response.ok) {
                setEditedTransaction({
                    caseId: '',
                    accountCodeId: '',
                    accountCodeName: '',
                    description: '',
                    creditAccount: 0,
                    debitAccount: 0,
                    reference: '',
                    amount: '',
                    transactionDate: ''
                });
                handleCloseBankEditTransactionDialog();
                await fetchTransactionsData(startDate, endDate);
            } else {
                const errorMessage = await response.text();
                toast.error(t('FailedToDeleteTransaction'));
            }
        } catch (error) {
            toast.error('FailedToDeleteTransaction');
        }
    };


    const handleEditBankTransaction = async () => {
        try {

            let addAmountTo = '';
            let amountToAdd = 0;
            let zerofy = '';
            let updatedBankItem = {};
            if (typeof editedTransaction.amount === 'string') {
                editedTransaction.amount = parseFloat(editedTransaction.amount);
            }
            if (editedTransaction.amount < 0) {
                addAmountTo = 'creditAccount';

                // if sign flipped
                if (editedTransaction.debitAccount > 0) {
                    updatedBankItem = {
                        ...editedTransaction,
                        caseId: client.caseId,
                        accountCodeId: mainCard.cardId,
                        reference: editedTransaction.reference1,
                        accountCodeName: mainCard.name,
                        ['debitAccount']: 0,
                        creditAccount: -1 * editedTransaction.amount
                    };
                } else {
                    updatedBankItem = {
                        ...editedTransaction,
                        caseId: client.caseId,
                        accountCodeId: mainCard.cardId,
                        reference: editedTransaction.reference1,
                        accountCodeName: mainCard.name,
                        creditAccount: -1 * editedTransaction.amount
                    };
                }
            } else {
                // if sign flipped
                if (editedTransaction.creditAccount > 0) {
                    updatedBankItem = {
                        ...editedTransaction,
                        caseId: client.caseId,
                        accountCodeId: mainCard.cardId,
                        reference: editedTransaction.reference1,
                        accountCodeName: mainCard.name,
                        ['creditAccount']: 0,
                        debitAccount: editedTransaction.amount
                    };
                } else {
                    updatedBankItem = {
                        ...editedTransaction,
                        caseId: client.caseId,
                        accountCodeId: mainCard.cardId,
                        reference: editedTransaction.reference1,
                        accountCodeName: mainCard.name,
                        debitAccount: editedTransaction.amount
                    };
                }
            }


            const response = await ExternalTransactionsService.UpdateExternalTransaction(updatedBankItem
                , oldEditedTransaction.creditAccount, oldEditedTransaction.debitAccount, token);

            if (response.ok) {
                setEditedTransaction({
                    caseId: '',
                    accountCodeId: '',
                    accountCodeName: '',
                    description: '',
                    creditAccount: 0,
                    debitAccount: 0,
                    reference: '',
                    amount: '',
                    transactionDate: ''
                });
                handleCloseBankEditTransactionDialog();
                await fetchTransactionsData(startDate, endDate);
            } else {
                const errorMessage = await response.text();
                toast.error(t('FailedToUpdateBankCommand'));
            }
        } catch (error) {
            toast.error(t('FailedToUpdateBankCommand'));
        }
    };
    const changeNewBankTransaction = (field, value) => {
        setNewBankEditableItem(prevState => ({
            ...prevState,
            [field]: value
        }));

    };

    const changeEditedBankTransaction = (field, value) => {
        setEditedTransaction(prevState => ({
            ...prevState,
            [field]: value
        }));
    };

    const [newBankEditableItem, setNewBankEditableItem] = useState({
        caseId: '',
        accountCodeId: '',
        accountCodeName: '',
        description: '',
        reference: '',
        amount: '',
        transactionDate: new Date()
    });

    // Format date
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = ('0' + date.getDate()).slice(-2);
        const month = ('0' + (date.getMonth() + 1)).slice(-2);
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
    };

    // Handle update transaction
    const handleUpdate = async (updatedTransaction) => {
        setLoading(true);
        try {
            // Update transaction logic
            // Call the API to update the transaction
            // Refresh transactions data
            await fetchTransactionsData(startDate, endDate);
            toast.success(t('Transaction updated successfully'));
        } catch (error) {
            toast.error(t('Error updating transaction'));
        }
        setEditedTransaction(null);
        setLoading(false);
    };

    // Handle cancel edit
    const handleCancelEdit = () => {
        setEditedTransaction(null);
    };

    // Handle isMonthly change effect
    useEffect(() => {
        if (client) {

            fetchTransactionsData(startDate, endDate);

        }
    }, [isMonthly, client]);

    // Handle account card change effect
    useEffect(() => {

        if (selectedAccountCard && client) {

            fetchTransactionsData(startDate, endDate);
        }
    }, [selectedAccountCard]);

    // Handle create bank modal
    const handleOpenCreateModal = () => {
        setIsEditingBank(false);
        setCreateBankModal(true);
    };
    const handleCloseCreateModal = () => {
        setCreateBankModal(false);
        setSelectedBankForCreate(null);
        setBankBranch("");
        setBankAccountNumber("");
        setCardForNewBank(null)
        setIsEditingBank(false);
    };
    const handleImport = async (file) => {
        setLoading(true);
        // selectedTBank is the selected bank
        const bankType = Constants.BankTypes[parseInt(selectedTBank.bankCode)];
        const accountCard = accountCardsData.find(
            (card) => card.cardId === selectedAccountCard
        );

        try {
            // Call the service method to import transactions
            const response =
                await ExternalTransactionsService.ImportExternalTransactions(
                    client.caseId,
                    selectedAccountCard,
                    accountCard.name,
                    bankType,
                    file,
                    token
                );

            if (response.ok) {
                toast.success(t("BankDataImportSuccessful"));

                await fetchTransactionsData(startDate, endDate);
            } else {
                toast.error(t("BankDataImportFailed"));
            }
        } catch (error) {
            toast.error(t("BankDataImportFailed"));

            console.error("Error importing file:", error);
        }
        setLoading(false);
    };
    const [selectedTransactions, setSelectedTransactions] = useState([]);

    const handleCheckboxChange = (transaction) => {
        setSelectedTransactions((prevSelected) => {
            if (prevSelected.includes(transaction.id)) {
                // Remove the transaction if it's already selected
                return prevSelected.filter((id) => id !== transaction.id);
            } else {
                // Add the transaction if it's not selected
                return [...prevSelected, transaction.id];
            }
        });
    }

    const deleteMultipleExternal = async () => {

        if (!selectedTransactions || selectedTransactions.length === 0) {
            console.error("No rows selected for deletion.");
            return;
        }

        try {
            // Call the API
            const response = await ExternalTransactionsService.DeleteMultipleExternalTransactions(client.caseId, selectedTransactions, token);

            if (response.ok) {
                setSelectedTransactions([]);
                await fetchTransactionsData(startDate, endDate);
            } else {
                console.error("Failed to delete transactions:", await response.text());
            }
        } catch (error) {
            console.error("An error occurred while deleting transactions:", error);
        }
    };

    const { hasBack, handleBack } = useBack();

    const handleGoBack = () => {
        // Navigate back to the previous page using React Router
        if (client) {
            handleBack();

        }
    };
    return (
        <>
            <div className="template-page-withoutHeight">
                <div className={`template-container ${direction}`}>
                    {client && <UpperPanelDetails client={client} />}
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <div className={`main-title ${direction}`}>{t(type)}</div>

                        {/* Import and Add buttons */}
                        <div className={`title-back-container ${direction}`} style={{ marginTop: '10px', gap: '10px' }}>
                            <Button
                                variant="contained"
                                onClick={handleImportClick}
                                sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    height: '30px',
                                    backgroundColor: !selectedTBank ? "#ccc" : "#304FFF",
                                    color: !selectedTBank ? "#777" : "#fff",
                                    "&:hover": {
                                        backgroundColor: !selectedTBank
                                            ? "#ccc"
                                            : "#1565c0",
                                    },
                                    gap: "10px",
                                }}
                            >
                                <CloudUploadIcon />
                                {t("Import")}
                            </Button>
                            <Button
                                variant="contained"
                                onClick={handleOpenBankAddTransactionDialog}
                                sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    height: '30px',
                                    backgroundColor: !selectedTBank ? "#ccc" : "#304FFF",
                                    color: !selectedTBank ? "#777" : "#fff",
                                    "&:hover": {
                                        backgroundColor: !selectedTBank
                                            ? "#ccc"
                                            : "#1565c0",
                                    },
                                    gap: "10px",
                                }}
                            >
                                <AddCircleOutlineIcon style={{ marginRight: "10px" }} />
                                {t("AddBankCommand")}
                            </Button>
                            <Button
                                variant="contained"
                                onClick={handleNavigateToBankMatch}
                                sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    height: '30px',
                                    backgroundColor: !selectedTBank ? "#ccc" : "#304FFF",
                                    color: !selectedTBank ? "#777" : "#fff",
                                    "&:hover": {
                                        backgroundColor: !selectedTBank
                                            ? "#ccc"
                                            : "#1565c0",
                                    },
                                    gap: "10px",
                                }}
                            >
                                {t("NavigateToBankMatches")}
                            </Button>

                            <div
                                className="backIconBackground"
                                style={{
                                    alignSelf: i18n.language === "en" ? "flex-start" : "flex-end",
                                }}
                                onClick={handleGoBack}
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="32"
                                    height="32"
                                    viewBox="0 0 32 32"
                                    fill="none"
                                >
                                    <path
                                        d="M20 21.1667L14.8267 15.9933L20 10.82C20.52 10.3 20.52 9.45999 20 8.93999C19.48 8.41999 18.64 8.41999 18.12 8.93999L12 15.06C11.48 15.58 11.48 16.42 12 16.94L18.12 23.06C18.64 23.58 19.48 23.58 20 23.06C20.5067 22.54 20.52 21.6867 20 21.1667Z"
                                        fill="black"
                                        fill-opacity="0.9"
                                    />
                                </svg>
                            </div>

                        </div>
                    </div>
                    {/* Search and Date Filters */}
                    <div className={`searchFilter-bar__container ${direction}`}>
                        {/* Search Field */}
                        <TextField
                            fullWidth
                            placeholder={t('Search')}
                            value={searchQuery}
                            onChange={handleSearchChange}
                            className="search-bar"
                            variant="standard"
                            InputProps={{
                                disableUnderline: true,
                                className: `custom-input-box-sizing ${direction === 'ltr' ? 'ltr-input' : 'rtl-input'}`,
                                sx: {
                                    height: '55px',
                                    padding: '0 10px',
                                    borderRadius: '8px',
                                    background: '#F5F5F6',
                                    border: '1px solid transparent',
                                    '&:focus-within': {
                                        border: '2px solid var(--Foundation-Blue-Normal, #304FFF)',
                                    },
                                },
                                ...(direction === 'ltr'
                                    ? {
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <SearchIcon />
                                            </InputAdornment>
                                        ),
                                    }
                                    : {
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <SearchIcon />
                                            </InputAdornment>
                                        ),
                                    }),
                            }}
                            inputProps={{
                                style: {
                                    textAlign: direction === 'ltr' ? 'left' : 'right',
                                },
                            }}
                        />

                        {/* Date Pickers */}
                        {/* <CustomMonthDatePicker
                            onDateChange={(date) => handleStartDateChange(date)}
                            date={dayjs(startDate)}
                            label={t('StartDate')}
                        />
                        <FormControlLabel
                            value={isMonthly}
                            control={
                                <Switch
                                    className="custom-input-box-sizing-toggle"
                                    color="primary"
                                    checked={!isMonthly}
                                    onChange={handleIsMonthlyToggle}
                                />
                            }
                            label={t('yearly')}
                            labelPlacement="bottom"
                        /> */}
                        {/* <CustomMonthDatePicker
                            onDateChange={(date) => handleEndDateChange(date)}
                            date={dayjs(endDate)}
                            label={t('EndDate')}
                        /> */}
                        <CustomMonthYearIndividualDatePicker
                            year={dayjs(endDate)}
                            month={dayjs(endDate)}
                            onYearChange={(newYear) => handleEndDateChangeNew(newYear, 'year')}
                            onMonthChange={(newMonth) => handleEndDateChangeNew(newMonth, 'month')}
                            labelYear={t("EndYear")}
                            labelMonth={t("EndMonth")}
                            height="48px"
                            widthOuter='270px'
                            widthInner='120px'
                        />
                    </div>
                    {/* DenseTable for Credit/Debit/Balance */}
                    <div className="document-Dense-container">
                        <div
                            style={{ display: "flex", flexDirection: "column", width: "30%" }}
                        >
                            <AutocompleteSelect
                                options={processedCardsData}
                                selectedValues={selectedFormattedAccountCard}
                                onChange={handleFormattedAccountCardChange}
                                label={t("Select AccountCard")}
                                placeholder={t("Select AccountCard")}
                                isMultiple={false}
                                width="100%"
                            />
                            <Button
                                variant="contained"
                                onClick={() => handleOpenCreateModal()}
                                startIcon={<AddCircleOutlineIcon sx={{ ml: 1 }} />}
                                sx={{
                                    backgroundColor: "#304FFF",
                                    color: "#fff",
                                    margin: "10px 0 0 0",
                                    "&:hover": { backgroundColor: "#F9AA2A" },
                                }}
                            >
                                {t("AddBank")}
                            </Button>
                        </div>
                        <ClickAbleDenseTable
                            headers={denseTableHeader}
                            content={denseTableRows}
                            styleFlag={false}
                            numericrows={false}
                            setSelectedRow={selectedDenseTableRow}
                            selectedRowIndex={selectedTBankIndex}
                            align="left"
                            reverse={true}
                            width="700px"
                            openEdit={handleOpenEditTBank}
                        />
                        <DenseTable headers={[t('Balance'), t('Credit'), t('Debit')]}
                            rows={denseTableCreditDebitRows} width='300px'
                            headerStyle={{
                                opacity: 0.6,
                                background: 'var(--orange, #F9AA2A)',
                                textAlign: 'center',
                                minHeight: '50px'
                            }}
                            rowStyle={{
                                borderTop: '1px solid rgba(191, 191, 193, 0.56)',
                                background: 'var(--secondary-12, rgba(254, 247, 234, 0.12))',
                                minHeight: '50px'
                            }}
                        />


                    </div>
                    <TransactionsTable
                        transactions={filteredTransactions}
                        handleEdit={handleEdit}
                        formatDate={formatDate}
                        handleSortChange={handleSortChange}
                        sortConfig={sortConfig}
                        handleCheckboxChange={handleCheckboxChange}
                        selectedTransactions={selectedTransactions}
                        handleDeleteExternalRows={deleteMultipleExternal}
                    />
                </div>
                <ToastContainer />
                {client && <HamburgerMenu client={client} />}
                {loading && (
                    <div className="loader-container">
                        <PuffLoader size={100} loading={loading} />
                    </div>
                )}
                {/* Import Dialog */}
                {openImportDialog && (
                    <ImportDialog
                        open={openImportDialog}
                        onClose={() => setOpenImportDialog(false)}
                        onImport={handleImport}
                    />
                )}
                {/* Add External Transaction Dialog */}
                {openAddTransactionDialog && (
                    <AddExternalTransactionDialog
                        open={openAddTransactionDialog}
                        onClose={handleCloseAddTransactionDialog}
                        client={client}
                        token={token}
                        accountCardOptions={accountCardOptions}
                        onAddSuccess={() => fetchTransactionsData(startDate, endDate)}
                    />
                )}
                {/* Edit Transaction Modal */}
                {/* {editedTransaction && (
                    <EditExternalTransactionModal
                        editedTransaction={editedTransaction}
                        onUpdate={handleUpdate}
                        onCancel={handleCancelEdit}
                        accountCardOptions={accountCardOptions}
                    />
                )} */}

                <EditExternalTransactionDialog
                    editBankTransactionDialog={editBankTransactionDialog}
                    handleCloseBankEditTransactionDialog={handleCloseBankEditTransactionDialog}
                    t={t}
                    editedTransaction={editedTransaction}
                    changeNewBankTransaction={changeEditedBankTransaction}
                    handleAddBankTransaction={handleEditBankTransaction}
                    handleDelete={handleDelete}
                />



                <AddExternalTransactionDialog
                    addBankTransactionDialog={addBankTransactionDialog}
                    handleCloseBankAddTransactionDialog={handleCloseBankAddTransactionDialog}
                    t={t}
                    newBankEditableItem={newBankEditableItem}
                    changeNewBankTransaction={changeNewBankTransaction}
                    handleAddBankTransaction={handleAddBankTransaction}
                />




                {/* AddOrEditBankDialog */}
                {createBankModal && (
                    <AddOrEditBankDialog
                        open={createBankModal}
                        onClose={handleCloseCreateModal}
                        isEditingBank={isEditingBank}
                        selectedBankForCreate={selectedBankForCreate}
                        bankOptions={bankOptions} // Add actual bank options
                        handleChangeBankForCreate={handleChangeBankForCreate}
                        bankBranch={bankBranch}
                        handleChangeCreateBank={handleChangeCreateBank}
                        bankAccountNumber={bankAccountNumber}
                        cardForNewBank={cardForNewBank}
                        processedCardsData={accountCardOptionsForBank}
                        setCardForNewBank={handleChangeSetCardForNewBank}
                        handleAddOrEditBank={handleAddOrEditBank}
                        handleRemoveBank={handleRemoveBank}
                        client={client}
                        token={token}
                        setLoading={setLoading}
                        palCodes={formattedPalCodeOptions}
                        fetchEnums={fetchEnums}
                    />
                )}
            </div>
        </>
    );
}

export default BankPages;
