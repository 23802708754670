import React, { useEffect, useState } from 'react';
import { Box, IconButton, Typography } from '@mui/material';
import { Visibility as EyeIcon, Close as CloseIcon } from '@mui/icons-material';

const FileView = ({ file, onRemove, isCreate = true, onOpenModal  }) => {
    const [previewURL, setPreviewURL] = useState(null);
    const isBlob = file instanceof Blob;

    useEffect(() => {
        let tempURL = null;

        if (isBlob) {
            // Generate temporary object URL for Blobs
            tempURL = URL.createObjectURL(file);
            setPreviewURL(tempURL);
        } else if (typeof file === "string") {
            setPreviewURL(file); // Use the file URL directly
        }

        // Cleanup function to revoke object URLs
        return () => {
            if (tempURL) {
                URL.revokeObjectURL(tempURL);
            }
        };
    }, [file, isBlob]);

    const handleViewFile = () => {
        if (previewURL) {
            onOpenModal(previewURL);
        }
    };

    const renderPreview = () => {
        if (typeof file === "string") {
            // Extract the file name (remove query parameters)
            const fileName = file.split("?")[0].toLowerCase();

            // Handle image URLs
            if (fileName.endsWith(".jpg") || fileName.endsWith(".jpeg") || fileName.endsWith(".png")) {
                return (
                    <img
                        src={file}
                        alt="Image Preview"
                        style={{
                            width: "100%",
                            height: "70px",
                            borderRadius: "4px",
                            border: "2px solid black",
                            objectFit: "cover",
                        }}
                    />
                );
            }

            // Handle PDF URLs
            if (fileName.endsWith(".pdf")) {
                return (
                    <iframe
                        src={file}
                        title="PDF Preview"
                        style={{
                            width: "100%",
                            height: "70px",
                            borderRadius: "4px",
                            border: "2px solid black",
                        }}
                    />
                );
            }

            // Fallback for unsupported URLs
            return (
                <Typography variant="caption" color="textSecondary">
                    File preview not available
                </Typography>
            );
        }

        // Handle Blobs
        if (isBlob) {
            const fileType = file.type;

            if (fileType.startsWith("image/")) {
                return (
                    <img
                        src={URL.createObjectURL(file)}
                        alt={file.name}
                        style={{
                            width: "100%",
                            height: "70px",
                            borderRadius: "4px",
                            border: "2px solid black",
                            objectFit: "cover",
                        }}
                    />
                );
            }

            if (fileType === "application/pdf") {
                return (
                    <iframe
                        src={URL.createObjectURL(file)}
                        title={file.name}
                        style={{
                            width: "100%",
                            height: "70px",
                            borderRadius: "4px",
                            border: "2px solid black",
                        }}
                    />
                );
            }

            return <Typography variant="caption">No preview available</Typography>;
        }

        return <Typography variant="caption">Unsupported file type</Typography>;
    };


    return (
        <Box
            sx={{
                position: "relative",
                display: "inline-block",
                width: "150px",
                height: isCreate ? "150px" : "100px",
                margin: "8px",
                border: "1px solid #ccc",
                borderRadius: "8px",
                overflow: "hidden",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                textAlign: "center",
                padding: '0',
                backgroundColor: "#f9f9f9",
            }}
        >
            {isCreate && (
                <Box
                    sx={{
                        position: "absolute",
                        top: "8px",
                        right: "8px",
                        display: "flex",
                        flexDirection: "row",
                        gap: "4px",
                    }}
                >
                    <IconButton color="primary" onClick={handleViewFile} size="small">
                        <EyeIcon />
                    </IconButton>

                    <IconButton color="error" onClick={onRemove} size="small">
                        <CloseIcon />
                    </IconButton>

                </Box>)}
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    ...(isCreate && { height: "100%" }),

                }}

            >
                {renderPreview()}
            </Box>
            <Typography
                variant="body2"
                sx={{
                    position: "absolute",
                    bottom: "8px",
                    left: "8px",
                    right: "8px",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    textAlign: "center",
                }}
                onClick={handleViewFile}
            >
                {isBlob
                    ? file.name
                    : (() => {
                        const match = file.match(/name-([^?]*)/);
                        return match ? decodeURIComponent(match[1]) : "Unknown File";
                    })()}
            </Typography>
        </Box>
    );
};

export default FileView;
