import React from 'react';
import moment from 'moment';
import UserAvatar from '../../Widgets/UserAvatar/UserAvatar'; // Adjust the path as per your project structure
import { useTranslation } from 'react-i18next';
import '../Chat.css'
const SearchItem = ({ item, currentUserId,targetName ,onSelect }) => {
  const { t } = useTranslation();
  const isFromMe = item.senderId === currentUserId;
  const formattedDate = moment(item.messageDate).format('DD/MM/YYYY HH:mm');

  return (
    <div
      className="search-result-item"
      onClick={() => onSelect(item)}
    >
      <div className="search-result-content">
        <div className="search-result-text">
          <p className="search-result-excerpt">{item.excerpt}</p>
          <p className="search-result-meta">{formattedDate}</p>
        </div>
        {isFromMe ? (
          <div className="you-pill">{t('You')}</div>
        ) : (
          <div className="search-result-avatar">
            <UserAvatar name={targetName || 'Unknown'} size={30} />
          </div>
        )}
      </div>
    </div>
  );
};

export default SearchItem;
