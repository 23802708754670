import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';


const DropzoneArea = ({ onDrop, accept }) => {
    const { i18n, t } = useTranslation();
    const [direction, setDirection] = useState('rtl'); // Default to 'rtl'

    useEffect(() => {
        // Update direction based on the current language
        if (i18n.language === 'he' || i18n.language === 'ar') {
            setDirection('rtl');
        } else {
            setDirection('ltr');
        }
    }, [i18n.language]);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        accept,
        multiple: false, // Assuming one file per dropzone
    });

    return (
        <div
            {...getRootProps()}
            style={{
                border: '2px dashed #cccccc',
                borderRadius: '5px',
                padding: '20px',
                textAlign: 'center',
                backgroundColor: isDragActive ? '#f0f8ff' : '#fafafa',
                cursor: 'pointer',
            }}
        >
            <input {...getInputProps()} />
            {isDragActive ? (
                <p>{t('DropTheFileHere')}...</p>
            ) : (
                <p>{t('DragAndDropFileOrClickToSelect')}</p>
            )}
        </div>
    );
};

export default DropzoneArea;
