import React, { useState, useEffect, useCallback } from 'react';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { debounce } from 'lodash';
import { useTranslation } from 'react-i18next';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import updateLocale from 'dayjs/plugin/updateLocale';
// Import Day.js locales
import 'dayjs/locale/en';
import 'dayjs/locale/ar';
import 'dayjs/locale/he';

// Add necessary plugins for formatting and localization
dayjs.extend(customParseFormat);
dayjs.extend(localizedFormat);
dayjs.extend(updateLocale);

function CustomMonthYearIndividualDatePicker({
    year,
    month,
    onYearChange,
    onMonthChange,
    labelYear,
    labelMonth,
    height = '48px',
    widthInner = '100%',
    widthOuter = '100%',
    /** 
     * If true, the Month picker is rendered first, then Year.
     * If false, Year is first, then Month.
     */
    flip = false,
}) {
    const [openYear, setOpenYear] = useState(false);
    const [openMonth, setOpenMonth] = useState(false);
    const [inputYearValue, setInputYearValue] = useState(
        year ? dayjs(year).format('YYYY') : ''
    );
    const [inputMonthValue, setInputMonthValue] = useState(
        month ? dayjs(month).format('MM') : ''
    );

    const { i18n } = useTranslation();
    const direction = i18n.dir();

    // Debounced callbacks for typed input
    const debouncedOnYearChange = useCallback(
        debounce((val) => {
            onYearChange(val);
        }, 300),
        [onYearChange]
    );

    const debouncedOnMonthChange = useCallback(
        debounce((val) => {
            onMonthChange(val);
        }, 300),
        [onMonthChange]
    );

    useEffect(() => {
        // Update Day.js locale based on i18n.language
        dayjs.locale(i18n.language);
    }, [i18n.language]);

    const handleYearOpen = () => setOpenYear(true);
    const handleYearClose = () => setOpenYear(false);

    const handleMonthOpen = () => setOpenMonth(true);
    const handleMonthClose = () => setOpenMonth(false);

    // YEAR changes
    const handleYearPickerChange = (newValue) => {
        const isTyping = !openYear;
        setInputYearValue(newValue ? newValue.format('YYYY') : '');

        if (isTyping) {
            // Typing
            if (newValue && newValue.isValid()) {
                debouncedOnYearChange(newValue.toDate());
            }
        } else {
            // Picker
            setOpenYear(false);
            if (newValue && newValue.isValid()) {
                onYearChange(newValue.toDate());
            }
        }
    };

    // MONTH changes
    const handleMonthPickerChange = (newValue) => {
        const isTyping = !openMonth;
        setInputMonthValue(newValue ? newValue.format('MM') : '');

        if (isTyping) {
            // Typing
            if (newValue && newValue.isValid()) {
                debouncedOnMonthChange(newValue.toDate());
            }
        } else {
            // Picker
            setOpenMonth(false);
            if (newValue && newValue.isValid()) {
                onMonthChange(newValue.toDate());
            }
        }
    };

    // Common props for both pickers:
    const yearPicker = (
        <DatePicker
            open={openYear}
            onOpen={handleYearOpen}
            onClose={handleYearClose}
            label={labelYear}
            views={['year']}
            value={year ? dayjs(year) : null}
            inputValue={inputYearValue}
            onChange={handleYearPickerChange}
            format="YYYY"
            placeholder="YYYY"
            className="custom-input-box-sizing"
            slotProps={{
                textField: {
                    sx: {
                        width: widthInner,
                    },
                    InputProps: {
                        disableUnderline: true,
                        className: `custom-box-sizing-border-box ${
                            direction === 'ltr' ? 'ltr-input' : 'rtl-input'
                        }`,
                        sx: {
                            height: height,
                            padding: '0 10px',
                            borderRadius: 'var(--Spacing-md, 8px)',
                            background: 'var(--bg-input, #F5F5F6)',
                            border: '1px solid transparent',
                        },
                    },
                    inputProps: {
                        style: {
                            textAlign: direction === 'ltr' ? 'left' : 'right',
                        },
                    },
                },
            }}
        />
    );

    const monthPicker = (
        <DatePicker
            open={openMonth}
            onOpen={handleMonthOpen}
            onClose={handleMonthClose}
            label={labelMonth}
            views={['month']}
            value={month ? dayjs(month) : null}
            inputValue={inputMonthValue}
            onChange={handleMonthPickerChange}
            format="MM"
            placeholder="MM"
            className="custom-input-box-sizing"
            slotProps={{
                textField: {
                    sx: {
                        width: widthInner,
                    },
                    InputProps: {
                        disableUnderline: true,
                        className: `custom-box-sizing-border-box ${
                            direction === 'ltr' ? 'ltr-input' : 'rtl-input'
                        }`,
                        sx: {
                            height: height,
                            padding: '0 10px',
                            borderRadius: 'var(--Spacing-md, 8px)',
                            background: 'var(--bg-input, #F5F5F6)',
                            border: '1px solid transparent',
                        },
                    },
                    inputProps: {
                        style: {
                            textAlign: direction === 'ltr' ? 'left' : 'right',
                        },
                    },
                },
            }}
        />
    );

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={i18n.language}>
            <div
                style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    gap: '16px',
                    width: widthOuter,
                }}
            >
                {/* 
                    If flip is true, show Month -> Year 
                    Otherwise, show Year -> Month 
                */}
                {flip ? (
                    <>
                        <div style={{ flex: '1 1 0' }}>{monthPicker}</div>
                        <div style={{ flex: '1 1 0' }}>{yearPicker}</div>
                    </>
                ) : (
                    <>
                        <div style={{ flex: '1 1 0' }}>{yearPicker}</div>
                        <div style={{ flex: '1 1 0' }}>{monthPicker}</div>
                    </>
                )}
            </div>
        </LocalizationProvider>
    );
}

export default CustomMonthYearIndividualDatePicker;
