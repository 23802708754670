import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import '../ClientDetails.css'
const ClientActionCard = ({ icon, title, items, subItemNumber = false, disabled = false }) => {
    const [isHovered, setIsHovered] = useState(false);
    const { i18n, t } = useTranslation();
    const [direction, setDirection] = useState('rtl'); // Default to 'rtl'
    useEffect(() => {
        // Update direction based on the current language
        if (i18n.language === 'he' || i18n.language === 'ar') {
            setDirection('rtl');
        } else {
            setDirection('ltr');
        }
    }, [i18n.language]);


    return (
        <div
            className={`client-action-card ${direction} ${disabled ? 'disabled' : ''}`}
            onMouseEnter={() => !disabled && setIsHovered(true)}
            onMouseLeave={() => !disabled && setIsHovered(false)}
        >
            {/* <div className="client-action-card-header">
                <img src={icon} alt={`${title} Icon`} />
                <span className="client-action-card-title">{title}</span>
            </div> */}
            <div className='client-action-card-icon-title-container'>

                <div className='client-action-card-icon-container'>
                    <img src={icon} alt="accountingManagmentIcon" />
                </div>
                <div className='client-action-card-title'>
                    {title}

                </div>
            </div>
            <div className='client-action-card-icon-arrow'>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path d="M8.12461 9.00002L12.0046 12.88L15.8846 9.00002C16.2746 8.61002 16.9046 8.61002 17.2946 9.00002C17.6846 9.39002 17.6846 10.02 17.2946 10.41L12.7046 15C12.3146 15.39 11.6846 15.39 11.2946 15L6.70461 10.41C6.31461 10.02 6.31461 9.39002 6.70461 9.00002C7.09461 8.62002 7.73461 8.61002 8.12461 9.00002Z" fill="white" />
                </svg>
            </div>
            <div className='client-action-card-shape-container-corner'
                style={{
                    [direction === 'rtl' ? 'left' : 'right']: 0,
                    transform: direction === 'rtl' ? 'scaleX(-1)' : 'scaleX(1)',
                }}>
                <svg xmlns="http://www.w3.org/2000/svg" width="40" height="56" viewBox="0 0 40 56" fill="none">
                    <path d="M0.499987 -1.52441L60.2963 -1.52441V55.8625C60.2963 55.8625 33.4225 58.71 27.0031 28.8118C20.5837 -1.08635 0.499987 -1.52441 0.499987 -1.52441Z" fill="#F9AA2A" />
                </svg>
            </div>
            {(isHovered && !disabled) && (
                <div className="client-action-card-menu">
                    {items.map((item, index) => (
                        <div key={index} className="client-action-card-menu-item" onClick={item.onClick}>
                            <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', marginBottom: '10px' }}>

                                {/* Rendering even if it was a component like MUI's SvgIcon */}
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '5px' }}>
                                    {typeof item.icon === 'string' ? (
                                        // Render as an image if the icon is a string (image src)
                                        <img src={item.icon} alt={`${item.label} Icon`} className="item-icon" />
                                    ) : (
                                        // Render as a React component if the icon is not a string
                                        <span className="item-icon">
                                            {React.isValidElement(item.icon) ? item.icon : <item.icon />}
                                        </span>
                                    )}
                                    <span className="item-label">{item.label}</span>
                                </div>

                                {(item.count !== null && item.count !== undefined) && (
                                    <div className="item-notification-count">{item.count}</div>
                                )}
                            </div>
                            {item?.subItems && (
                                <div className="client-action-card-submenu">
                                    {item?.subItems?.map((subItem, subIndex) => (
                                        <div key={subIndex} className="client-action-card-submenu-item"
                                            onClick={(e) => {
                                                e.stopPropagation(); // Prevent parent click event
                                                subItem.onClick(); // Call subItem's onClick
                                            }}>
                                            <span className="item-label">{subItem.label}</span>
                                            {subItemNumber && (
                                                <div className="item-notification-count-small">{subItem.number}</div>
                                            )}
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default ClientActionCard;
