// VatModal.jsx
import React, { useState, useEffect, useRef } from 'react';
import { Modal, Box, RadioGroup, FormControlLabel, Radio } from '@mui/material';
import CustomDeleteConfirmationModal from '../../CustomDeleteConfirmationModal/CustomDeleteConfirmationModal';

// For PDF generation
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

// For toast messages (optional)
import { toast } from "react-toastify";

// For i18n (optional)
import { useTranslation } from 'react-i18next';

// Services
import TaxAuthorityService from '../../../Services/taxAuthority.service';
import ClientService from '../../../Services/client.service';
import { getVatValue } from '../../../Utils/GetVatValue';
import Constants from '../../../assests/Constants/constants';

import ReportsService from '../../../Services/reports.service';
import vatImage from '../../../assests/images/VATReport.jpg';

// Example styling for the modal container
const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    height: '100%',
    borderRadius: "16px",
    background: "#FFF",
    boxShadow: 24,
    maxHeight: '90%',
    overflowY: 'auto'
};

export default function VatModal({
    open,
    onClose,
    /** The client/item data object from the parent */
    item,
    /** The logged in user & token (if needed for API calls) */
    user,
    token,
    /** Callback after we finish in the child (to refresh parent’s data) */
    onDataUpdate = () => { },
}) {
    const { t, i18n } = useTranslation();
    const direction = i18n.dir();

    // ======= LOCAL STATES =======
    // The entire VAT form data
    const [vatReport, setVatReport] = useState({});
    // Reporting way => 'automatic' | 'manual' | 'unreported'
    const [reportingWay, setReportingWay] = useState('unreported');
    // For the “Are you sure?” style confirmation
    const [actionType, setActionType] = useState('');
    const [isConfirmationVisible, setIsConfirmationVisible] = useState(false);
    // Possibly loading state
    const [loading, setLoading] = useState(false);

    // This holds data about token / tax authority flow
    const [taxAuthorityData, setTaxAuthorityData] = useState({
        authUrl: '',
        resumeTaxAuthorityVatOperation: false,
        UpdatedAdd: {}
    });

    // For referencing the HTML to convert to PDF
    const vatContentRef = useRef(null);

    // ======= USE EFFECT: BUILD INITIAL vatReport =======
    useEffect(() => {
        if (!item) return;

        // Destructure the exact fields you’re passing in from the parent
        const {
            id,
            clientName,
            clientLocation,
            clientCaseId,
            VATEquipment,
            VATExpenses,
            VATTransactions,
            VATNet,
            IncomesNoVats,
            IncomesNoZeroVats,
            StartDate,
            EndDate,
            VATRateTransactions
        } = item;

        // Build the local vatReport object
        const builtReport = {
            id: id ?? null,
            clientName: clientName ?? '',
            clientLocation: clientLocation ?? '',
            clientCaseId: clientCaseId ?? '',
            VATEquipment: parseInt(VATEquipment) ?? 0,
            VATExpenses: parseInt(VATExpenses) ?? 0,
            VATTransactions: parseInt(VATTransactions) ?? 0,
            VATNet: parseInt(VATNet) ?? 0,
            IncomesNoVats: parseInt(IncomesNoVats) ?? 0,
            IncomesNoZeroVats: parseInt(IncomesNoZeroVats) ?? 0,
            StartDate: StartDate ?? new Date(),
            EndDate: EndDate ?? new Date(),
            // If you want a numeric VATRateTransactions
            // but you're receiving it as a string from beautifyNumber,
            // you might parse it OR just store it as string:
            VATRateTransactions: VATRateTransactions ?? '0',
        };

        setVatReport(builtReport);
    }, [item]);

    // ======= HANDLE CUSTOM EVENTS (vatUpdate) =======
    useEffect(() => {
        const handleVatMessage = (event) => {
            try {
                const message = JSON.parse(event.detail);
                if (message.source === 'vatReport') {
                    switch (message.eventName) {
                        case 'updateVatTransactions':
                            handleChangeVatReport("VATTransactions", message.value);
                            break;
                        case 'updateVatEquipment':
                            handleChangeVatReport("VATEquipment", message.value);
                            break;
                        case 'updateVatExpenses':
                            handleChangeVatReport("VATExpenses", message.value);
                            break;
                        case 'updateIncomesNoVats':
                            handleChangeVatReport("IncomesNoVats", message.value);
                            break;
                        case 'updateIncomesNoZeroVats':
                            handleChangeVatReport("IncomesNoZeroVats", message.value);
                            break;
                        default:
                            break;
                    }
                }
            } catch (err) {
                console.error("Failed to parse vatUpdate event:", event.detail);
            }
        };

        window.addEventListener('vatUpdate', handleVatMessage);
        return () => {
            window.removeEventListener('vatUpdate', handleVatMessage);
        };
    }, []);

    // ======= HELPER: UPDATE FIELDS (and recalc Net) =======
    const handleChangeVatReport = (field, val) => {
        const newValue = parseFloat(val) || 0;
        setVatReport((prev) => {
            let updated = { ...prev, [field]: newValue };

            // If user changed IncomesNoVats => recalc VATTransactions
            if (field === 'IncomesNoVats') {
                const vatRate = getVatValue(new Date()); // or use updated.StartDate
                updated.VATTransactions = +(newValue * vatRate).toFixed(2);
            }

            // Always recalc the Net
            updated.VATNet = +(
                (updated.VATTransactions || 0) -
                ((updated.VATEquipment || 0) + (updated.VATExpenses || 0))
            ).toFixed(2);

            return updated;
        });
    };
    const isEmptyObject = (obj) => {
        return Object.keys(obj).length === 0 && obj.constructor === Object;
    };
    // ======= HELPER: GENERATE HTML =======
    const generateVatHtml = () => {
        if (isEmptyObject(vatReport)) {
            return;
        }
        const israelTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const israelTime = new Date().toLocaleString('he-IL', { timeZone: israelTimeZone });
        const formattedDate = new Date().toLocaleString('he-IL', { timeZone: israelTimeZone, dateStyle: 'short', timeStyle: 'medium' });
        const startDateFormatted = new Date(vatReport.StartDate).toLocaleString('he-IL', { month: 'long' });
        const endDateFormatted = new Date(vatReport.EndDate).toLocaleString('he-IL', { month: 'long', year: 'numeric' });
        const dateRange = `${startDateFormatted} - ${endDateFormatted}`;



        const htmlContent = `
         <!DOCTYPE html>
         <html lang='en'>
         <head>
             <meta charset='UTF-8' />
             <meta http-equiv='X-UA-Compatible' content='IE=edge' />
             <meta name='viewport' content='width=device-width, initial-scale=1.0' />
             <style>
                 a, button, input, select, h1, h2, h3, h4, h5, * {
                     box-sizing: border-box !important;
                     margin: 0;
                     padding: 0;
                     border: none;
                     text-decoration: none;
                     background: none;
                     -webkit-font-smoothing: antialiased;
                 }
                 menu, ol, ul {
                     list-style-type: none;
                     margin: 0;
                     padding: 0;
                 }
                 .body {
                     width: 100%;
                     display: flex;
                     justify-content: center;
                     align-items: center;
                 }
                 .container {
                     width:fit-content
                     display: flex;
                     flex-direction: column;
                     align-items: center;
                 }
                 .header-vat {
                     width: 1288px;
                     text-align: center;
                     padding: 10px 0;
                     background-color: #f3f3f3;
                 }
                 .header_container {
                     display: flex;
                     justify-content: space-between;
                     align-items: center;
                 }
                 .date, .title, .placeholder {
                     flex: 1;
                     text-align: center;
                 }
                 .title {
                     font-size: 26px;
                     font-weight: bold;
                 }
                 .vat_percentage, .date {
                     font-size: 22px;
                     padding: 5px 0;
                     color: black;
                     font-weight: bold;
                 }
                 .frame-1 {
                     box-sizing: border-box;
                     position: relative;
                     background: white;
                     box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
                 }
                 .vat-report-1-fotor-2024071611375-1 {
                     width: 1288px;
                     height: 584px;
                     object-fit: contain;
                 }
                 .clientName, .VATEquipment, .VATExpenses, .VATNet, .IncomesNoVats, .IncomesNoZeroVats, .clientLocation, .dateRange, .clientCaseId, .VATTransactions {
                     color: black;
                     direction: rtl;
                     font-family: 'Inter-Regular', sans-serif;
                     font-weight: bold !important;
                     position: absolute;
                     word-wrap: break-word;
                     font-size: 22px !important;
                 }
                 [class^='clientName'] {
                     overflow: hidden !important;
                     text-overflow: ellipsis !important;
                     white-space: nowrap !important;
                 }
                 .new-content {}
                 .clientName {
                     text-align: right;
                     font-family: 'Inter-Regular', sans-serif;
                     font-size: 26px;
                     font-weight: 400;
                     position: absolute;
                     left: 706px;
                     top: 58px;
                     width: 464px;
                     height: 34px;
                 }
                 .VATEquipment {
                     text-align: right;
                     font-family: 'Inter-Regular', sans-serif;
                     font-size: 26px;
                     font-weight: 400;
                     position: absolute;
                     left: 881px;
                     top: 220px;
                     width: 333px;
                     height: 34px;
                 }
                 .VATExpenses {
                     text-align: right;
                     font-family: 'Inter-Regular', sans-serif;
                     font-size: 26px;
                     font-weight: 400;
                     position: absolute;
                     left: 881px;
                     top: 281px;
                     width: 333px;
                     height: 34px;
                 }
                 .VATNet {
                     text-align: right;
                     font-family: 'Inter-Regular', sans-serif;
                     font-size: 26px;
                     font-weight: 400;
                     position: absolute;
                     left: 881px;
                     top: 455px;
                     width: 333px;
                     height: 34px;
                 }
                 .IncomesNoVats {
                     text-align: right;
                     font-family: 'Inter-Regular', sans-serif;
                     font-size: 26px;
                     font-weight: 400;
                     position: absolute;
                     left: 432px;
                     top: 159px;
                     width: 333px;
                     height: 34px;
                 }
                 .IncomesNoZeroVats {
                     text-align: right;
                     font-family: 'Inter-Regular', sans-serif;
                     font-size: 26px;
                     font-weight: 400;
                     position: absolute;
                     left: 59px;
                     top: 159px;
                     width: 304px;
                     height: 34px;
                 }
                 .clientLocation {
                     text-align: right;
                     font-family: 'Inter-Regular', sans-serif;
                     font-size: 26px;
                     font-weight: 400;
                     position: absolute;
                     left: 250px;
                     top: 58px;
                     height: 34px;
                     width: 445px;
                 }
                 .dateRange {
                     text-align: right;
                     font-family: 'Inter-Regular', sans-serif;
                     font-size: 26px;
                     font-weight: 400;
                     position: absolute;
                     left: 873px;
                     top: 92px;
                     width: 266px;
                     height: 34px;
                 }
                 .clientCaseId {
                     text-align: right;
                     font-family: 'Inter-Regular', sans-serif;
                     font-size: 26px;
                     font-weight: 400;
                     position: absolute;
                     left: 40px;
                     top: 92px;
                     width: 244px;
                     height: 25px;
                 }
                 .VATTransactions {
                     text-align: right;
                     font-family: 'Inter-Regular', sans-serif;
                     font-size: 26px;
                     font-weight: 400;
                     position: absolute;
                     left: 881px;
                     top: 159px;
                     width: 333px;
                     height: 34px;
                 }
                 .color-text {
                     color: blue;
                     font-weight: bold;
                 }
             </style>
             <title>Document</title>
         </head>
         <body>
             <div class='container'>
                 <div class='header-vat'>
                     <div class='header_container'>
                         <div class='date'>${formattedDate}</div>
                         <div class='title'>דוח מס ערך מוסף</div>
                         <div class='placeholder'></div>
                     </div>
                     <div class='vat_percentage'><span class='color-text'>${vatReport.VATRateTransactions}%</span> = שיעור מע״מ עסקאות</div>
                 </div>
                <div class='frame-1'>
                     <img class='vat-report-1-fotor-2024071611375-1' src='${vatImage}' />
                     <div class='clientName'>${vatReport.clientName}</div>
                     <input class='VATEquipment input-field_Container_required-asterisk' value="${vatReport.VATEquipment}" onfocus="this.value=this.value.replace(/,/g, '')" onblur="this.value=parseFloat(this.value).toLocaleString('en-US')" onchange="window.parent.dispatchEvent(new CustomEvent('vatUpdate', { detail: JSON.stringify({ source: 'vatReport', eventName: 'updateVatEquipment', value: this.value }) }))" />
                     <input class='VATExpenses input-field_Container_required-asterisk' value="${vatReport.VATExpenses}" onfocus="this.value=this.value.replace(/,/g, '')" onblur="this.value=parseFloat(this.value).toLocaleString('en-US')" onchange="window.parent.dispatchEvent(new CustomEvent('vatUpdate', { detail: JSON.stringify({ source: 'vatReport', eventName: 'updateVatExpenses', value: this.value }) }))" />
                     <div class='VATNet'>${vatReport.VATNet}</div>
                     <input class='IncomesNoVats input-field_Container_required-asterisk' value="${vatReport.IncomesNoVats}" onfocus="this.value=this.value.replace(/,/g, '')" onblur="this.value=parseFloat(this.value).toLocaleString('en-US')" onchange="window.parent.dispatchEvent(new CustomEvent('vatUpdate', { detail: JSON.stringify({ source: 'vatReport', eventName: 'updateIncomesNoVats', value: this.value }) }))" />
                     <input class='IncomesNoZeroVats input-field_Container_required-asterisk' value="${vatReport.IncomesNoZeroVats}" onfocus="this.value=this.value.replace(/,/g, '')" onblur="this.value=parseFloat(this.value).toLocaleString('en-US')" onchange="window.parent.dispatchEvent(new CustomEvent('vatUpdate', { detail: JSON.stringify({ source: 'vatReport', eventName: 'updateIncomesNoZeroVats', value: this.value }) }))" />
                     <div class='clientLocation'>${vatReport.clientLocation}</div>
                     <div class='dateRange'>${dateRange}</div>
                     <div class='clientCaseId'>${vatReport.clientCaseId}</div>
                     <input class='VATTransactions input-field_Container_required-asterisk' value="${vatReport.VATTransactions}" onfocus="this.value=this.value.replace(/,/g, '')" onblur="this.value=parseFloat(this.value).toLocaleString('en-US')" onchange="window.parent.dispatchEvent(new CustomEvent('vatUpdate', { detail: JSON.stringify({ source: 'vatReport', eventName: 'updateVatTransactions', value: this.value }) }))" />
                 </div>
             </div>
         </body>
         </html>`;
        return htmlContent;
    };

    const [vatHtmlContent, setVatHtmlContent] = useState('');
    useEffect(() => {
        setVatHtmlContent(generateVatHtml());
    }, [vatReport]);

    // ======= PDF DOWNLOAD =======
    const handleDownloadPdf = (ref) => {
        if (!ref?.current) return;

        // 1) Clone the original HTML node
        const clonedElement = ref.current.cloneNode(true);

        // 2) Remove or override the red border style.
        //    Example: removing the class "input-field_Container_required-asterisk"
        //    or manually overriding the border styles
        const inputs = clonedElement.querySelectorAll('input.input-field_Container_required-asterisk');
        inputs.forEach((input) => {
            // Either remove the class:
            input.classList.remove('input-field_Container_required-asterisk');

            // Optionally override inline styles (in case there's a border from CSS):
            input.style.border = '1px solid transparent';
            // or input.style.border = 'none';
        });

        // 3) Temporarily append this cloned element to the DOM so html2canvas can render it
        clonedElement.style.position = 'fixed';
        clonedElement.style.top = '-9999px'; // hide it
        document.body.appendChild(clonedElement);

        // 4) Use html2canvas on the cloned node
        html2canvas(clonedElement).then((canvas) => {
            const data = canvas.toDataURL('image/png');
            const doc = new jsPDF({ orientation: 'l', unit: 'px', format: 'a4' });
            const imgProps = doc.getImageProperties(data);
            const pdfWidth = doc.internal.pageSize.getWidth();
            const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
            doc.addImage(data, 'JPEG', 0, 0, pdfWidth, pdfHeight);
            doc.save('vatReport.pdf');

            // 5) Remove the cloned element after generating PDF
            document.body.removeChild(clonedElement);
        });
    };


    // ======= MANUAL SAVE =======
    const saveReportTaxManually = async (reportTax) => {
        try {
            const statusValue = (reportingWay === 'unreported') ? 2 : 1;
            reportTax.status = statusValue;

            let response;
            // if there's an ID => update
            // if (reportTax.id) {
            //     response = await ReportsService.updateReportedVat(reportTax.id, {
            //         caseId: reportTax.caseId,
            //         VATTransactions: reportTax.VATTransactions,
            //         OtherVATTaxes: reportTax.OtherVATTaxes,
            //         EquipmentVAT: reportTax.EquipmentVAT,
            //         NetVAT: reportTax.NetVAT,
            //         IncomesNoVat: reportTax.IncomesNoVat,
            //         IncomesNoZeroVats: reportTax.IncomesNoZeroVats,
            //         ExpensesNoVat: 0, // or parse from your data
            //         EquipmentExpenses: 0, // or parse from your data
            //         StartDate: reportTax.startDate,
            //         EndDate: reportTax.endDate
            //         // etc. depends on your EditReportedVat DTO
            //     }, token);
            // } else {
            //     // no ID => create
            //     response = await ReportsService.createReportedVat({
            //         caseId: reportTax.caseId,
            //         VATTransactions: reportTax.VATTransactions,
            //         OtherVATTaxes: reportTax.OtherVATTaxes,
            //         EquipmentVAT: reportTax.EquipmentVAT,
            //         NetVAT: reportTax.NetVAT,
            //         IncomesNoVat: reportTax.IncomesNoVat,
            //         IncomesNoZeroVats: reportTax.IncomesNoZeroVats,
            //         ExpensesNoVat: 0,
            //         EquipmentExpenses: 0,
            //         StartDate: reportTax.startDate,
            //         EndDate: reportTax.endDate
            //     }, token);
            // }
            response = await ReportsService.createReportedVat({
                caseId: reportTax.caseId,
                VATTransactions: reportTax.VATTransactions,
                OtherVATTaxes: reportTax.OtherVATTaxes,
                EquipmentVAT: reportTax.EquipmentVAT,
                NetVAT: reportTax.NetVAT,
                IncomesNoVat: reportTax.IncomesNoVat,
                IncomesNoZeroVats: reportTax.IncomesNoZeroVats,
                ExpensesNoVat: 0,
                EquipmentExpenses: 0,
                StartDate: reportTax.startDate,
                EndDate: reportTax.endDate
            }, token);
            if (response && response.ok) {
                const responseData = await response.json();
                toast.success(t('ReportedTaxUpdatedSuccess'));
                onDataUpdate(responseData);
            } else {
                toast.error(t('ReportedTaxUpdateFailed'));
            }
        } catch (error) {
            console.error(t('Error saving reported tax:'), error);
            toast.error(t('ReportedTaxSaveFailed'));
        }
    };


    // ======= AUTOMATIC SEND TO TAX AUTHORITY =======
    const startAuthCompletionCheck = async (authWindow, scope) => {
        let attempts = 0;
        const maxAttempts = 25;

        const checkAuthCompletion = async () => {
            try {
                const result = await TaxAuthorityService.checkAuthCompletionAccountant(
                    user.accountantId,
                    scope,
                    token
                );
                if (result.completed) {
                    setTaxAuthorityData((prev) => ({
                        ...prev,
                        resumeTaxAuthorityVatOperation: true
                    }));
                    authWindow.close();
                } else {
                    attempts += 1;
                    if (attempts < maxAttempts) {
                        setTimeout(checkAuthCompletion, 3000);
                    } else {
                        setLoading(false);
                        console.error('Token retrieval failed after maximum attempts.');
                        toast.error('Token retrieval failed after maximum attempts.');
                        authWindow.close();
                    }
                }
            } catch (err) {
                console.error('Error checking auth completion:', err);
                toast.error(t('Error checking auth completion'));
                authWindow.close();
                setLoading(false);
            }
        };

        checkAuthCompletion();
    };

    // If we get the token or see resumeTaxAuthorityVatOperation = true:
    useEffect(() => {
        if (taxAuthorityData.resumeTaxAuthorityVatOperation) {
            // Actually send the data
            TaxAuthoritySendVatReport(taxAuthorityData.UpdatedAdd, token);
        }
    }, [taxAuthorityData.resumeTaxAuthorityVatOperation]);

    const TaxAuthoritySendVatReport = async (data, tokenParam) => {
        try {
            setLoading(true);
            const sendVatReportingDto = {
                CaseId: data.caseId,
                Date: data.endDate,
                VATTransactions: data.VATTransactions,
                OtherVATTaxes: data.OtherVATTaxes,
                EquipmentVAT: data.EquipmentVAT,
                NetVAT: data.NetVAT,
                IncomesNoVat: data.IncomesNoVat,
                IncomesNoZeroVats: data.IncomesNoZeroVats,
                AccountantId: user.accountantId
            };

            const response = await ReportsService.sendVatReporting(sendVatReportingDto, tokenParam);
            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(`Failed to send VAT report: ${errorData.message}`);
            }
            const responseData = await response.json();
            if (responseData.Code === 0) {
                throw new Error(`Error Sending ReportedTax for Case ${data.caseId}`);
            }
            const formattedDate = new Date(sendVatReportingDto.Date).toLocaleDateString('en-GB', {
                month: '2-digit',
                year: 'numeric'
            });

            toast.success(t('ReportedTaxSuccess', { caseId: data.caseId, date: formattedDate }));
            // Now also store it manually in DB
            await saveReportTaxManually(data);
        } catch (error) {
            const formattedDate = new Date(data.endDate).toLocaleDateString('en-GB', {
                month: '2-digit',
                year: 'numeric'
            });

            toast.error(t('ReportedTaxError', { caseId: data.caseId, date: formattedDate })); console.error('Error sending VAT report:', error);
        }
        onClose();
        setLoading(false);
    };

    // ======= CONFIRMATION FLOW =======
    const handleConfirmVatReport = async () => {
        const { UpdatedAdd } = taxAuthorityData;
        setIsConfirmationVisible(false);
        try {
            const tokenData = await TaxAuthorityService.requestTokenAccountant(
                user.accountantId,
                Constants.TaxAuthorityScopes['VatReport'],
                token
            );
            if (tokenData?.authUrl) {
                setTaxAuthorityData((prev) => ({
                    ...prev,
                    authUrl: tokenData.authUrl,
                    resumeTaxAuthorityVatOperation: false
                }));
                const authWindow = window.open(tokenData.authUrl, '_blank');
                onClose(); // close the modal
                startAuthCompletionCheck(authWindow, Constants.TaxAuthorityScopes['VatReport']);
            } else if (tokenData?.accessToken) {
                await TaxAuthoritySendVatReport(UpdatedAdd, token);
            }
        } catch (err) {
            console.error('Error fetching token:', err);
            toast.error(t('ErrorFetchingToken'));
            setLoading(false);
        }
    };

    const handleConfirm = async () => {
        // For now, only one action type => 'VatReport'
        if (actionType === 'VatReport') {
            await handleConfirmVatReport();
        }
    };

    const getConfirmMessage = () => t('AreYouSureSendVatReport');
    const getTitleMessage = () => t('ConfirmVatReport');

    const deleteVatReport = async (reportData, token) => {
        try {
            const response = await ReportsService.deleteReportedVatByPeriod({
                caseId: reportData.caseId,
                StartDate: reportData.startDate,
                EndDate: reportData.endDate,
            }, token);

            if (response && response.ok) {
                toast.success(t('ReportedVatDeletedSuccess'));
                const responseData = {
                    vatTransactions: 0,
                    otherVATTaxes: 0,
                    equipmentVAT: 0,
                    netVAT: 0,
                }
                onDataUpdate(responseData);

            } else {
                toast.error(t('ReportedVatDeleteFailed'));
            }
            onClose();
        } catch (error) {
            console.error(t('Error deleting VAT report:'), error);
            toast.error(t('ReportedVatDeleteFailed'));
        }
    };


    // ======= WHEN CLICK “SAVE” =======
    const handleSaveVatReport = async () => {
        // Build the data object to store
        const UpdatedAdd = {
            id: vatReport.id,
            caseId: vatReport.clientCaseId,
            startDate: vatReport.StartDate,
            endDate: vatReport.EndDate,
            VATTransactions: vatReport.VATTransactions,
            OtherVATTaxes: vatReport.VATExpenses,
            EquipmentVAT: vatReport.VATEquipment,
            NetVAT: vatReport.VATNet,
            IncomesNoVat: vatReport.IncomesNoVats,
            IncomesNoZeroVats: vatReport.IncomesNoZeroVats
        };

        setTaxAuthorityData((prev) => ({ ...prev, UpdatedAdd }));

        if (reportingWay === 'unreported') {
            await deleteVatReport(UpdatedAdd, token);
        }
        else if (reportingWay === 'automatic') {
            setActionType('VatReport');
            setIsConfirmationVisible(true);
        } else {
            // manual or unreported => just save
            await saveReportTaxManually(UpdatedAdd);
            onClose();
        }
    };

    // ======= RENDER =======
    return (
        <Modal open={open} onClose={onClose}>
            <>
                <Box sx={modalStyle}>
                    <div className="advanceVatTaxContainer" style={{ direction }}>
                        {/* Header */}
                        <div className="advanceVatTaxTitleCloseContainer">
                            <div className="advanceVatTaxTitle">{t('VATReport')}</div>

                            <div className="advanceVatTaxClose" onClick={onClose}>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="20"
                                    height="20"
                                    viewBox="0 0 20 20"
                                    fill="none"
                                >
                                    <path
                                        d="M15.2501 4.75831C14.9251 4.43331 14.4001 4.43331 14.0751 4.75831L10.0001 8.82498L5.9251 4.74998C5.6001 4.42498 5.0751 4.42498 4.7501 4.74998C4.4251 5.07498 4.4251 5.59998 4.7501 5.92498L8.8251 9.99998L4.7501 14.075C4.4251 14.4 4.4251 14.925 4.7501 15.25C5.0751 15.575 5.6001 15.575 5.9251 15.25L10.0001 11.175L14.0751 15.25C14.4001 15.575 14.9251 15.575 15.2501 15.25C15.5751 14.925 15.5751 14.4 15.2501 14.075L11.1751 9.99998L15.2501 5.92498C15.5668 5.60831 15.5668 5.07498 15.2501 4.75831Z"
                                        fill="black"
                                        fillOpacity="0.8"
                                    />
                                </svg>
                            </div>
                        </div>

                        {/* The main HTML preview */}
                        <div style={{ margin: '0 auto' }} ref={vatContentRef} dangerouslySetInnerHTML={{ __html: vatHtmlContent }} />

                        {/* Download PDF & Choose Reporting Way */}
                        <div style={{ marginTop: '20px', textAlign: 'center' }} className="advanceVatTaxTitleOperationContainer">
                            <button onClick={() => handleDownloadPdf(vatContentRef)} className='advanceVatTax-download-button'>
                                {t('DownloadPDFFile')}
                            </button>
                            <div className={`report-Type-radio-container ${direction}`}>
                                <RadioGroup
                                    row
                                    value={reportingWay}
                                    onChange={(e) => setReportingWay(e.target.value)}
                                >
                                    <FormControlLabel
                                        value="automatic"
                                        control={<Radio />}
                                        label={t('AutomaticTaxAuthorityReporting')}
                                        labelPlacement="end"
                                    />
                                    <FormControlLabel
                                        value="manual"
                                        control={<Radio />}
                                        label={t('ManualReporting')}
                                        labelPlacement="end"
                                    />
                                    <FormControlLabel
                                        value="unreported"
                                        control={<Radio />}
                                        label={t('Unreported')}
                                        labelPlacement="end"
                                    />
                                </RadioGroup>
                            </div>
                        </div>

                        {/* Buttons */}
                        <div className='save-buttons-container-vat-withholding'>
                            <button className='sub-add-subAcc-content-button' onClick={handleSaveVatReport}>
                                {t('save')}
                            </button>
                            <button className='sub-close-subAcc-content-button' onClick={onClose}>
                                {t('close')}
                            </button>
                        </div>
                    </div>
                </Box>

                {/* Confirmation Modal */}
                <CustomDeleteConfirmationModal
                    isVisible={isConfirmationVisible}
                    onClose={() => setIsConfirmationVisible(false)}
                    onDelete={handleConfirm}
                    confirmMessage={getConfirmMessage()}
                    titleMessage={getTitleMessage()}
                    type={'sendReport'}
                />
            </>
        </Modal>
    );
}
