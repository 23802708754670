// DraggableModal.js

import React, { useRef, useState, useEffect } from 'react';
import { IconButton, Portal } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import Iframe from 'react-iframe';
import './DraggableModal.css';
import { useTranslation } from 'react-i18next';
import { Rnd } from 'react-rnd';

const DraggableModal = ({
    bigIframeModalOpen,
    handleClose,
    openedIframeUrl,
    handleCloseIframeModal,
}) => {
    const nodeRef = useRef(null);
    const [showPdfIframe, setShowPdfIframe] = useState(true);
    const { i18n, t } = useTranslation();
    const direction = i18n.dir();
    const container = document.getElementById('root'); // Adjust based on your app's root element

    // State for modal size and position
    const [modalSize, setModalSize] = useState({
        width: 600,
        height: window.innerHeight * 0.8,
    });
    const [modalPosition, setModalPosition] = useState({ x: 0, y: 0 });

    const handleToggleIframe = () => {
        setShowPdfIframe(!showPdfIframe);
    };

    // Calculate center position when modal opens
    useEffect(() => {
        if (bigIframeModalOpen) {
            const modalWidth = modalSize.width;
            const modalHeight = modalSize.height;
            const x = (window.innerWidth - modalWidth) / 2;
            const y = (window.innerHeight - modalHeight) / 2;
            setModalPosition({ x, y });
        }
    }, [bigIframeModalOpen, modalSize]);

    return (
        bigIframeModalOpen && (
            <Portal container={container}>
                <Rnd
                    bounds="parent"
                    size={{ width: modalSize.width, height: modalSize.height }}
                    position={{ x: modalPosition.x, y: modalPosition.y }}
                    minWidth={300}
                    minHeight={200}
                    enableResizing={{
                        top: true,
                        right: true,
                        bottom: true,
                        left: true,
                        topRight: true,
                        bottomRight: true,
                        bottomLeft: true,
                        topLeft: true,
                    }}
                    onDragStop={(e, d) => {
                        setModalPosition({ x: d.x, y: d.y });
                    }}
                    onResizeStop={(e, direction, ref, delta, position) => {
                        setModalSize({
                            width: ref.offsetWidth,
                            height: ref.offsetHeight,
                        });
                        setModalPosition(position);
                    }}
                    dragHandleClassName="handle"
                    style={{ zIndex: 2000 }}
                >
                    <div ref={nodeRef} className="draggable-modal">
                        {/* Modal Header */}
                        <div className={`modal-header handle ${direction}`}>
                            <div className="documentPreview-modal-title">
                                {t('DocumentPreview')}
                            </div>

                            <div>
                                <IconButton onClick={handleToggleIframe}>
                                    {showPdfIframe ? (
                                        <VisibilityOffIcon />
                                    ) : (
                                        <VisibilityIcon />
                                    )}
                                </IconButton>
                                <IconButton onClick={handleCloseIframeModal}>
                                    <CloseIcon />
                                </IconButton>
                            </div>
                        </div>
                        {/* Modal Content */}
                        <div
                            className="documentPreview-modal-content"
                            style={{ height: 'calc(100% - 50px)' }} // Adjust height based on header
                        >
                            {showPdfIframe && (
                                <Iframe
                                    url={`${openedIframeUrl}#page=1&view=FitH,FitV`}
                                    width="100%"
                                    height="100%"
                                    style={{ border: 'none', flexGrow: 1 }}
                                    title="PDF Viewer"
                                />
                            )}
                        </div>
                    </div>
                </Rnd>
            </Portal>
        )
    );
};

export default DraggableModal;
