import { NotificationManager } from 'react-notifications';
/**
 * Play a notification sound.
 * @param {string} fileName - The name of the sound file (e.g., 'notification.mp3').
 */
export const playNotificationSound = (fileName = 'notification.mp3') => {
    try {
        const audio = new Audio(`/sounds/${fileName}`);
        audio.play().catch((err) => {
            console.warn('Audio playback prevented by browser policy:', err);
        });
    } catch (err) {
        console.error('Error playing notification sound:', err);
    }
};

/**
 * Ask for browser notification permission and show a notification.
 * If the browser does not support notifications or permission is denied,
 * fallback to react-notifications.
 * @param {string} title - The title of the notification.
 * @param {string} body - The body content of the notification.
 */
export const showBrowserNotification = async (title, body) => {

    console.warn('Browser notifications are not supported. Using react-notifications as fallback.');
    NotificationManager.info(body, title);

};