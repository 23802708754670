import React from 'react';
import PropTypes from 'prop-types';
import '../Chat.css';
import { FaTrash } from 'react-icons/fa';
const LabelItem = React.memo(({ item, onView, onDelete, translate }) => {
    const handleView = () => {
        onView(item);
    };

    return (
        <div className="label-item-container" onClick={handleView}>
            {/* Label Info */}
            <div className="label-info-container">
                <div className="label-name">{item.labelName}</div>
                <div className="user-count">
                    {`${translate('Users')}: ${item.users?.length || 0}`}
                </div>
            </div>

            {/* Actions */}
            <div className="label-actions-container">
                <FaTrash
                    className="delete-icon"
                    onClick={(e) => {
                        e.stopPropagation(); // Prevent triggering view action
                        onDelete(item);
                    }}
                    title={translate('Delete')}
                />
            </div>
        </div>
    );
}, (prevProps, nextProps) => prevProps.item === nextProps.item);

LabelItem.propTypes = {
    item: PropTypes.object.isRequired,
    onView: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    translate: PropTypes.func.isRequired,
};

export default LabelItem;
