import React, { useState, useMemo, useEffect } from 'react';
import {
    TableContainer,
    Paper,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    IconButton,
    TablePagination,
} from '@mui/material';
import {
    ExpandLess as ExpandLessIcon,
    ExpandMore as ExpandMoreIcon,
} from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { beautifyNumber } from '../../../Utils/FormatNumber';
// Placeholder functions for formatting; replace with your actual implementations
const formatDate = (startDate, endDate) => {
    const date = new Date(startDate);
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const year = date.getFullYear();
    const Enddate = new Date(endDate);
    const EndMonth = ('0' + (Enddate.getMonth() + 1)).slice(-2);
    const EndYear = Enddate.getFullYear();
    return `${EndMonth}/${EndYear} - ${month}/${year}`;
};

const formatYearRange = (dateRange) => {
    const [start, end] = dateRange.split(" - ");
    const startYear = new Date(start).getFullYear();
    const startMonth = new Date(start).getMonth() + 1; // getMonth() is zero-indexed
    const endMonth = new Date(end).getMonth() + 1;
    return `${startMonth}-${endMonth}/${startYear}`;
}


const ProfitAndLossReportTable = ({
    filteredAndSortedNodes,
    columns,
    isMonthly,
    startDate,
    endDate,
    handleExpandRow,
    expandedRows,
    handleSelect,
    footerTotals,
    specialNode,
}) => {
    const { t, i18n } = useTranslation();
    const direction = i18n.dir();
    // Pagination state
    const [currentPage, setCurrentPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(100);

    // Reset page when data changes
    useEffect(() => {
        setCurrentPage(0);
    }, [filteredAndSortedNodes]);

    // Memoized page data
    const pageData = useMemo(() => {
        const start = currentPage * rowsPerPage;
        const end = start + rowsPerPage;
        return filteredAndSortedNodes.slice(start, end);
    }, [filteredAndSortedNodes, currentPage, rowsPerPage]);

    // Pagination handlers
    const handleChangePage = (event, newPage) => {
        setCurrentPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setCurrentPage(0);
    };

    // Styles
    const headerStyle = {
        backgroundColor: 'var(--foundation-blue-light-active, #BFC8FF)',
        color: 'var(--Foundation-Blue-Normal, #304FFF)',
        textAlign: 'right',
        fontFamily: 'Montserrat',
        fontSize: '14px',
        fontWeight: 'bold',
        height: '56px',
        padding: '0',
    };

    return (
        <div className={`${direction}`} style={{ width: '100%', height: '65vh' }}>
            <TableContainer
                sx={{ maxHeight: '60vh', overflow: 'auto' }}
                component={Paper}
            >
                <Table
                    aria-label="Profit and Loss Report"
                    stickyHeader
                    className="sticky-header"
                    style={{ zIndex: '1' }}
                >
                    <TableHead>
                        <TableRow>
                            <TableCell style={headerStyle} />
                            <TableCell style={headerStyle}>
                                <strong>{t('Description')}</strong>
                            </TableCell>
                            {columns.map((col) => (
                                <TableCell key={col.field} style={headerStyle}>
                                    <strong>
                                        {isMonthly
                                            ? col.header
                                            : formatYearRange(col.header)}
                                    </strong>
                                </TableCell>
                            ))}
                            {isMonthly && (
                                <TableCell key="total" style={headerStyle}>
                                    <strong>
                                        {`${t('PeriodProfit')} ${formatDate(
                                            startDate,
                                            endDate
                                        )}`}
                                    </strong>
                                </TableCell>
                            )}
                        </TableRow>
                    </TableHead>
                    <TableBody sx={{ position: 'relative' }}>
                        {pageData && pageData.length > 0 && (
                            <>
                                {pageData.map((node) => (
                                    <React.Fragment key={node.key}>
                                        {/* Parent Row */}
                                        <TableRow
                                            hover
                                            onClick={() =>
                                                node.children &&
                                                handleExpandRow(node.key)
                                            }
                                        >
                                            <TableCell align="left" sx={{ padding: 0 }}>
                                                {node.children && (
                                                    <IconButton size="small">
                                                        {expandedRows[
                                                            node.key
                                                        ] ? (
                                                            <ExpandLessIcon />
                                                        ) : (
                                                            <ExpandMoreIcon />
                                                        )}
                                                    </IconButton>
                                                )}
                                            </TableCell>
                                            <TableCell align="left" sx={{ padding: 0 }}>
                                                {node.data.Description &&
                                                    node.data.Description.trim() !==
                                                    ''
                                                    ? node.data.Description
                                                    : t('GeneralExpenses')}
                                            </TableCell>
                                            {columns.map((col) => (
                                                <TableCell
                                                    key={col.field}
                                                    align="left"
                                                    sx={{
                                                        paddingTop: '16px',
                                                        paddingBottom: '16px',
                                                        paddingRight: 0,
                                                        paddingLeft: 0
                                                    }}
                                                >
                                                    {node.data[col.field] !==
                                                        null
                                                        ? beautifyNumber(
                                                            node.data[
                                                            col.field
                                                            ]
                                                        )
                                                        : '0.00'}{' '}
                                                    ₪
                                                </TableCell>
                                            ))}
                                            {isMonthly && (
                                                <TableCell align="left" sx={{ padding: 0 }}>
                                                    {node.data.total !== null
                                                        ? beautifyNumber(
                                                            node.data.total
                                                        )
                                                        : '0.00'}{' '}
                                                    ₪
                                                </TableCell>
                                            )}
                                        </TableRow>

                                        {/* Expanded Child Rows */}
                                        {expandedRows[node.key] &&
                                            node.children &&
                                            node.children.map((child) => (
                                                <TableRow
                                                    key={child.key}
                                                    sx={{
                                                        boxShadow:
                                                            'inset 0px 2px 200px 0px rgba(250, 235, 215, 0.2)',
                                                        transition:
                                                            'transform 0.2s ease-in-out',
                                                        '&:hover': {
                                                            transform:
                                                                'scale(1.02)',
                                                            cursor: 'pointer',
                                                        },
                                                    }}
                                                    onClick={() =>
                                                        handleSelect(
                                                            child.data
                                                                .Description,
                                                            true
                                                        )
                                                    }
                                                >
                                                    <TableCell
                                                        align="left"
                                                        style={{
                                                            paddingLeft: '50px',
                                                        }}
                                                    >
                                                        {/* Indented cell for child row */}
                                                    </TableCell>
                                                    <TableCell align="left" sx={{ padding: 0 }}>
                                                        {child.data
                                                            .Description &&
                                                            child.data.Description.trim() !==
                                                            ''
                                                            ? child.data
                                                                .Description
                                                            : t(
                                                                'GeneralExpenses'
                                                            )}
                                                    </TableCell>
                                                    {columns.map((col) => (
                                                        <TableCell
                                                            key={col.field}
                                                            align="left"

                                                        >
                                                            {child.data[
                                                                col.field
                                                            ] !== null
                                                                ? beautifyNumber(
                                                                    child.data[
                                                                    col
                                                                        .field
                                                                    ]
                                                                )
                                                                : '0.00'}{' '}
                                                            ₪
                                                        </TableCell>
                                                    ))}
                                                    {isMonthly && (
                                                        <TableCell align="left" sx={{ padding: 0 }}>
                                                            {child.data.total !==
                                                                null
                                                                ? beautifyNumber(
                                                                    child.data
                                                                        .total
                                                                )
                                                                : '0.00'}{' '}
                                                            ₪
                                                        </TableCell>
                                                    )}
                                                </TableRow>
                                            ))}
                                    </React.Fragment>
                                ))}

                                {/* Render specialNode if available */}
                                {specialNode && (
                                    <React.Fragment key={specialNode.key}>
                                        <TableRow
                                            hover
                                            onClick={() =>
                                                specialNode.children &&
                                                handleExpandRow(specialNode.key)
                                            }
                                        >
                                            <TableCell align="left" sx={{ padding: 0 }}>
                                                {specialNode.children && (
                                                    <IconButton size="small">
                                                        {expandedRows[
                                                            specialNode.key
                                                        ] ? (
                                                            <ExpandLessIcon />
                                                        ) : (
                                                            <ExpandMoreIcon />
                                                        )}
                                                    </IconButton>
                                                )}
                                            </TableCell>
                                            <TableCell align="left" sx={{ padding: 0 }}>
                                                {t('GeneralExpenses')}
                                            </TableCell>
                                            {columns.map((col) => (
                                                <TableCell
                                                    key={col.field}
                                                    align="left"
                                                >
                                                    {specialNode.data[
                                                        col.field
                                                    ] !== null
                                                        ? beautifyNumber(
                                                            specialNode.data[
                                                            col.field
                                                            ]
                                                        )
                                                        : '0.00'}{' '}
                                                    ₪
                                                </TableCell>
                                            ))}
                                            {isMonthly && (
                                                <TableCell align="left" sx={{ padding: 0 }}>
                                                    {specialNode.data.total !==
                                                        null
                                                        ? beautifyNumber(
                                                            specialNode.data
                                                                .total
                                                        )
                                                        : '0.00'}{' '}
                                                    ₪
                                                </TableCell>
                                            )}
                                        </TableRow>

                                        {/* Expanded Child Rows for specialNode */}
                                        {expandedRows[specialNode.key] &&
                                            specialNode.children &&
                                            specialNode.children.map((child) => (
                                                <TableRow
                                                    key={child.key}
                                                    sx={{
                                                        boxShadow:
                                                            'inset 0px 2px 200px 0px rgba(250, 235, 215, 0.2)',
                                                        transition:
                                                            'transform 0.2s ease-in-out',
                                                        '&:hover': {
                                                            transform:
                                                                'scale(1.02)',
                                                            cursor: 'pointer',
                                                        },
                                                    }}
                                                    onClick={() =>
                                                        handleSelect(
                                                            child.data
                                                                .Description,
                                                            true
                                                        )
                                                    }
                                                >
                                                    <TableCell
                                                        align="left"
                                                        style={{
                                                            paddingLeft: '50px',
                                                        }}
                                                    >
                                                        {/* Indented cell for child row */}
                                                    </TableCell>
                                                    <TableCell align="left" sx={{ padding: 0 }}>
                                                        {child.data
                                                            .Description &&
                                                            child.data.Description.trim() !==
                                                            ''
                                                            ? child.data
                                                                .Description
                                                            : t(
                                                                'GeneralExpenses'
                                                            )}
                                                    </TableCell>
                                                    {columns.map((col) => (
                                                        <TableCell
                                                            key={col.field}
                                                            align="left"
                                                        >
                                                            {child.data[
                                                                col.field
                                                            ] !== null
                                                                ? beautifyNumber(
                                                                    child.data[
                                                                    col
                                                                        .field
                                                                    ]
                                                                )
                                                                : '0.00'}{' '}
                                                            ₪
                                                        </TableCell>
                                                    ))}
                                                    {isMonthly && (
                                                        <TableCell align="left" sx={{ padding: 0 }}>
                                                            {child.data.total !==
                                                                null
                                                                ? beautifyNumber(
                                                                    child.data
                                                                        .total
                                                                )
                                                                : '0.00'}{' '}
                                                            ₪
                                                        </TableCell>
                                                    )}
                                                </TableRow>
                                            ))}
                                    </React.Fragment>
                                )}

                                {/* Total Row */}
                                <TableRow sx={{ backgroundColor: '#e8e8e8', position: 'sticky', bottom: 0, zIndex: 1 }}>
                                    <TableCell align="left" />
                                    <TableCell align="left" sx={{ padding: 0 }}>
                                        <strong>{t('Total')}</strong>
                                    </TableCell>
                                    {columns.map((col) => {
                                        const totalValue =
                                            footerTotals[col.field] || 0;

                                        return (
                                            <TableCell
                                                key={col.field}
                                                align="left"
                                                sx={{
                                                    paddingTop: '16px',
                                                    paddingBottom: '16px',
                                                    paddingRight: 0,
                                                    paddingLeft: 0
                                                }}
                                            >
                                                <strong>
                                                    {beautifyNumber(
                                                        totalValue
                                                    )}{' '}
                                                    ₪
                                                </strong>
                                            </TableCell>
                                        );
                                    })}
                                    {isMonthly && (
                                        <TableCell align="left" sx={{ padding: 0 }}>
                                            <strong>
                                                {beautifyNumber(
                                                    footerTotals.total || 0
                                                )}{' '}
                                                ₪
                                            </strong>
                                        </TableCell>
                                    )}
                                </TableRow>
                            </>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[5, 10, 25, 50, 100]}
                component="div"
                count={filteredAndSortedNodes.length}
                rowsPerPage={rowsPerPage}
                page={currentPage}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                labelRowsPerPage={t('Rows per page')}
                labelDisplayedRows={({ from, to, count }) =>
                    `${from}-${to} ${t('of')} ${count !== -1 ? count : `${t('more than')} ${to}`
                    }`
                }
            />
        </div>
    );
};

export default ProfitAndLossReportTable;
