import React, { useState, useEffect } from 'react';
import {
    Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, Checkbox, FormControlLabel,
    MenuItem, Select, FormControl, Typography, CircularProgress
} from '@mui/material';
import dayjs from 'dayjs';
import Constants from '../../assests/Constants/constants';
import { useTranslation } from 'react-i18next';
import CustomMonthDatePicker from '../Widgets/CustomDates/CustomMonthDatePicker/CustomMonthDatePicker';
const PaymentTypeOptions = [
    { name: "CreditCard", value: "0" },
    { name: "Check", value: "1" },
    { name: "BankTransfer", value: "2" },
    { name: "Cash", value: "3" }
];

const AddNewCollectionItem = ({
    isOpen,
    onClose,
    newItem,
    handleInputChange,
    handleAddItem,
    handleEditItem,
    itemType,
    isEditMode = false,
    handleDeleteItem,
    loading = false    // Assuming loading is passed as a prop
}) => {
    const [hasEndDate, setHasEndDate] = useState(false);
    const { t, i18n } = useTranslation();
    const direction = i18n.dir();
    useEffect(() => {
        if (isEditMode && newItem) {
            setHasEndDate(!!newItem.endDate);
        }
    }, [isEditMode, newItem]);

    useEffect(() => {
        if (itemType === 'payments' && !newItem.date) {
            handleInputChange('date', dayjs());
        }
    }, [!isEditMode, newItem]);

    const renderFields = () => {
        switch (itemType) {
            case 'monthlyCharges':
                return (
                    <>
                        {/* Start Date */}
                        <div className="form-row-item full-width">
                            <div className="form-row-item-title">
                                {t('StartDate')}
                            </div>
                            <CustomMonthDatePicker
                                date={newItem.startDate ? dayjs(new Date(newItem.startDate)) : null}
                                onDateChange={(date) => {
                                    if (date) {
                                        const tenthOfMonth = dayjs(date).date(10); // Set the day to the 10th
                                        handleInputChange('startDate', tenthOfMonth.toISOString());
                                    }
                                }}
                                height="48px"
                            />
                        </div>
                        {/* End Date Toggle */}
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={hasEndDate}
                                    onChange={(e) => {
                                        setHasEndDate(e.target.checked);
                                        if (!e.target.checked) {
                                            handleInputChange('endDate', 'null');
                                        }
                                    }}
                                    color="primary"
                                />
                            }
                            label={t("IncludeEndDate")}
                            sx={{ marginBottom: '10px' }}
                        />
                        {/* End Date */}
                        {hasEndDate && (
                            <div className="form-row-item full-width">
                                <div className="form-row-item-title">
                                    {t('EndDate')}
                                </div>
                                <CustomMonthDatePicker
                                    date={newItem.endDate ? dayjs(new Date(newItem.endDate)) : null}
                                    onDateChange={(date) => {
                                        if (date) {
                                            handleInputChange('endDate', date.toISOString());
                                        }
                                    }}
                                    height="48px"
                                />
                            </div>
                        )}
                        {/* Amount */}
                        <div className="form-row-item full-width">
                            <div className="form-row-item-title">
                                {t('Amount')}<span className="required-asterisk">*</span>
                            </div>
                            <TextField
                                fullWidth
                                placeholder={t('Amount')}
                                value={newItem.amount || ''}
                                onChange={(e) => handleInputChange('amount', e.target.value)}
                                className="add-client-input-style full-width"
                                variant="standard"
                                type="number"
                                InputProps={{
                                    disableUnderline: true,
                                    className: `custom-input-box-sizing-toggle full-width ${direction === 'ltr' ? 'ltr-input' : 'rtl-input'}`,
                                    sx: {
                                        height: '48px',
                                        padding: '0 10px',
                                        borderRadius: '8px',
                                        background: '#F5F5F6',
                                        border: '1px solid transparent',
                                        '&:focus-within': {
                                            border: '2px solid #304FFF',
                                        },
                                    },
                                }}
                                inputProps={{
                                    style: {
                                        width: '100%',
                                        textAlign: direction === 'ltr' ? 'left' : 'right',
                                    },
                                }}
                                required
                            />
                        </div>
                        {/* Description */}
                        <div className="form-row-item full-width">
                            <div className="form-row-item-title">
                                {t('Description')}<span className="required-asterisk">*</span>
                            </div>
                            <TextField
                                fullWidth
                                placeholder={t('Description')}
                                value={newItem.description || ''}
                                onChange={(e) => handleInputChange('description', e.target.value)}
                                className="add-client-input-style full-width"
                                variant="standard"
                                InputProps={{
                                    disableUnderline: true,
                                    className: `custom-input-box-sizing-toggle full-width ${direction === 'ltr' ? 'ltr-input' : 'rtl-input'}`,
                                    sx: {
                                        height: '48px',
                                        padding: '0 10px',
                                        borderRadius: '8px',
                                        background: '#F5F5F6',
                                        border: '1px solid transparent',
                                        '&:focus-within': {
                                            border: '2px solid #304FFF',
                                        },
                                    },
                                }}
                                inputProps={{
                                    style: {
                                        width: '100%',
                                        textAlign: direction === 'ltr' ? 'left' : 'right',
                                    },
                                }}
                                required
                            />
                        </div>
                    </>
                );

            case 'oneTimeCharges':
                return (
                    <>
                        {/* Date */}
                        <div className="form-row-item full-width">
                            <div className="form-row-item-title">
                                {t('Date')}
                            </div>
                            <TextField
                                fullWidth
                                type="date"
                                value={newItem.date ? dayjs(newItem.date).format('YYYY-MM-DD') : dayjs().format('YYYY-MM-DD')}
                                onChange={(e) => handleInputChange('date', e.target.value)}
                                className="add-client-input-style full-width"
                                variant="standard"
                                InputProps={{
                                    disableUnderline: true,
                                    className: `custom-input-box-sizing-toggle full-width ${direction === 'ltr' ? 'ltr-input' : 'rtl-input'}`,
                                    sx: {
                                        height: '48px',
                                        padding: '0 10px',
                                        borderRadius: '8px',
                                        background: '#F5F5F6',
                                        border: '1px solid transparent',
                                        '&:focus-within': {
                                            border: '2px solid #304FFF',
                                        },
                                    },
                                }}
                                inputProps={{
                                    style: {
                                        width: '100%',
                                        textAlign: direction === 'ltr' ? 'left' : 'right',
                                    },
                                }}
                            />
                        </div>
                        {/* Amount */}
                        <div className="form-row-item full-width">
                            <div className="form-row-item-title">
                                {t('Amount')}<span className="required-asterisk">*</span>
                            </div>
                            <TextField
                                fullWidth
                                placeholder={t('Amount')}
                                value={newItem.amount || ''}
                                onChange={(e) => handleInputChange('amount', e.target.value)}
                                className="add-client-input-style full-width"
                                variant="standard"
                                type="number"
                                InputProps={{
                                    disableUnderline: true,
                                    className: `custom-input-box-sizing-toggle full-width ${direction === 'ltr' ? 'ltr-input' : 'rtl-input'}`,
                                    sx: {
                                        height: '48px',
                                        padding: '0 10px',
                                        borderRadius: '8px',
                                        background: '#F5F5F6',
                                        border: '1px solid transparent',
                                        '&:focus-within': {
                                            border: '2px solid #304FFF',
                                        },
                                    },
                                }}
                                inputProps={{
                                    style: {
                                        width: '100%',
                                        textAlign: direction === 'ltr' ? 'left' : 'right',
                                    },
                                }}
                                required
                            />
                        </div>
                        {/* Description */}
                        <div className="form-row-item full-width">
                            <div className="form-row-item-title">
                                {t('Description')}<span className="required-asterisk">*</span>
                            </div>
                            <TextField
                                fullWidth
                                placeholder={t('Description')}
                                value={newItem.description || ''}
                                onChange={(e) => handleInputChange('description', e.target.value)}
                                className="add-client-input-style full-width"
                                variant="standard"
                                InputProps={{
                                    disableUnderline: true,
                                    className: `custom-input-box-sizing-toggle full-width ${direction === 'ltr' ? 'ltr-input' : 'rtl-input'}`,
                                    sx: {
                                        height: '48px',
                                        padding: '0 10px',
                                        borderRadius: '8px',
                                        background: '#F5F5F6',
                                        border: '1px solid transparent',
                                        '&:focus-within': {
                                            border: '2px solid #304FFF',
                                        },
                                    },
                                }}
                                inputProps={{
                                    style: {
                                        width: '100%',
                                        textAlign: direction === 'ltr' ? 'left' : 'right',
                                    },
                                }}
                                required
                            />
                        </div>
                    </>
                );

            case 'payments':
                return (
                    <>
                        {/* Type */}
                        <div className="form-row-item full-width">
                            <div className="form-row-item-title">
                                {t('Type')}<span className="required-asterisk">*</span>
                            </div>
                            <FormControl
                                fullWidth
                                variant="standard"
                                sx={{
                                    background: '#F5F5F6',
                                    borderRadius: '8px',
                                    height: '48px',
                                    '& .MuiInputBase-root': {
                                        height: '48px',
                                        padding: '0 10px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        background: '#F5F5F6',
                                        borderRadius: '8px',
                                        border: '1px solid transparent',
                                        '&:focus-within': {
                                            border: '2px solid #304FFF',
                                        },
                                    },
                                    '& .MuiSelect-select': {
                                        textAlign: direction === 'ltr' ? 'left' : 'right',
                                    },
                                }}
                            >
                                <Select
                                    value={newItem.type || ''}
                                    onChange={(e) => handleInputChange('type', e.target.value)}
                                    disableUnderline
                                    displayEmpty
                                >
                                    {PaymentTypeOptions.map((option) => (
                                        <MenuItem key={option.value} value={option.value}>
                                            {t(option.name)}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>
                        {/* Date */}
                        <div className="form-row-item full-width">
                            <div className="form-row-item-title">
                                {t('Date')}
                            </div>
                            <TextField
                                fullWidth
                                type="date"
                                value={newItem.date ? dayjs(newItem.date).format('YYYY-MM-DD') : dayjs().format('YYYY-MM-DD')}
                                onChange={(e) => handleInputChange('date', e.target.value)}
                                className="add-client-input-style full-width"
                                variant="standard"
                                InputProps={{
                                    disableUnderline: true,
                                    className: `custom-input-box-sizing-toggle full-width ${direction === 'ltr' ? 'ltr-input' : 'rtl-input'}`,
                                    sx: {
                                        height: '48px',
                                        padding: '0 10px',
                                        borderRadius: '8px',
                                        background: '#F5F5F6',
                                        border: '1px solid transparent',
                                        '&:focus-within': {
                                            border: '2px solid #304FFF',
                                        },
                                    },
                                }}
                                inputProps={{
                                    style: {
                                        width: '100%',
                                        textAlign: direction === 'ltr' ? 'left' : 'right',
                                    },
                                }}
                            />
                        </div>
                        {/* Amount */}
                        <div className="form-row-item full-width">
                            <div className="form-row-item-title">
                                {t('Amount')}<span className="required-asterisk">*</span>
                            </div>
                            <TextField
                                fullWidth
                                placeholder={t('Amount')}
                                value={newItem.amount || ''}
                                onChange={(e) => handleInputChange('amount', e.target.value)}
                                className="add-client-input-style full-width"
                                variant="standard"
                                type="number"
                                InputProps={{
                                    disableUnderline: true,
                                    className: `custom-input-box-sizing-toggle full-width ${direction === 'ltr' ? 'ltr-input' : 'rtl-input'}`,
                                    sx: {
                                        height: '48px',
                                        padding: '0 10px',
                                        borderRadius: '8px',
                                        background: '#F5F5F6',
                                        border: '1px solid transparent',
                                        '&:focus-within': {
                                            border: '2px solid #304FFF',
                                        },
                                    },
                                }}
                                inputProps={{
                                    style: {
                                        width: '100%',
                                        textAlign: direction === 'ltr' ? 'left' : 'right',
                                    },
                                }}
                                required
                            />
                        </div>
                        {/* Description */}
                        <div className="form-row-item full-width">
                            <div className="form-row-item-title">
                                {t('Description')}<span className="required-asterisk">*</span>
                            </div>
                            <TextField
                                fullWidth
                                placeholder={t('Description')}
                                value={newItem.description || ''}
                                onChange={(e) => handleInputChange('description', e.target.value)}
                                className="add-client-input-style full-width"
                                variant="standard"
                                InputProps={{
                                    disableUnderline: true,
                                    className: `custom-input-box-sizing-toggle full-width ${direction === 'ltr' ? 'ltr-input' : 'rtl-input'}`,
                                    sx: {
                                        height: '48px',
                                        padding: '0 10px',
                                        borderRadius: '8px',
                                        background: '#F5F5F6',
                                        border: '1px solid transparent',
                                        '&:focus-within': {
                                            border: '2px solid #304FFF',
                                        },
                                    },
                                }}
                                inputProps={{
                                    style: {
                                        width: '100%',
                                        textAlign: direction === 'ltr' ? 'left' : 'right',
                                    },
                                }}
                                required
                            />
                        </div>
                        {/* Reference */}
                        <div className="form-row-item full-width">
                            <div className="form-row-item-title">
                                {t('Reference')}
                            </div>
                            <TextField
                                fullWidth
                                placeholder={t('Reference')}
                                value={newItem.reference || ''}
                                onChange={(e) => handleInputChange('reference', e.target.value)}
                                className="add-client-input-style full-width"
                                variant="standard"
                                InputProps={{
                                    disableUnderline: true,
                                    className: `custom-input-box-sizing-toggle full-width ${direction === 'ltr' ? 'ltr-input' : 'rtl-input'}`,
                                    sx: {
                                        height: '48px',
                                        padding: '0 10px',
                                        borderRadius: '8px',
                                        background: '#F5F5F6',
                                        border: '1px solid transparent',
                                        '&:focus-within': {
                                            border: '2px solid #304FFF',
                                        },
                                    },
                                }}
                                inputProps={{
                                    style: {
                                        width: '100%',
                                        textAlign: direction === 'ltr' ? 'left' : 'right',
                                    },
                                }}
                            />
                        </div>
                    </>
                );

            default:
                return null;
        }
    };

    return (
        <Dialog open={isOpen} onClose={onClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title" sx={{ textAlign: 'center' }}>
                {t(isEditMode ? `Edit ${itemType}` : `Add ${itemType}`)}
            </DialogTitle>
            <DialogContent
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: '16px',
                    textAlign: 'center',
                    minWidth: '300px'
                }}
                className={`${direction}`}
            >
                {renderFields()}
            </DialogContent>
            <DialogActions
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    gap: '16px',
                    textAlign: 'center',
                }}
            >
                {isEditMode && (
                    <Button
                        onClick={() => handleDeleteItem(newItem.id)}
                        color="error"
                        variant="contained"
                        sx={{
                            backgroundColor: '#d32f2f', // Red background color
                            color: '#fff', // White text color
                            '&:hover': {
                                backgroundColor: '#b71c1c' // Darker red on hover
                            },
                            padding: '10px 20px',
                            fontSize: '16px',
                            fontWeight: 'bold',
                            cursor: 'pointer',
                            marginTop: '20px',
                            borderRadius: '8px',
                            boxShadow: '0px 1px 4px 0px rgba(0, 0, 0, 0.25)',
                        }}
                    >
                        {t("Delete")}
                    </Button>
                )}
                <Button
                    onClick={onClose}
                    color="primary"
                    variant="contained"
                    sx={{
                        backgroundColor: 'white',
                        color: 'var(--Foundation-Yellow-Normal, var(--orange, #F9AA2A))',
                        padding: '10px 20px',
                        fontSize: '16px',
                        fontWeight: 'bold',
                        cursor: 'pointer',
                        marginTop: '20px',
                        borderRadius: 'var(--Spacing-md, 8px)',
                        border: '2px solid var(--orange, #F9AA2A)',
                        boxShadow: '0px 1px 4px 0px rgba(0, 0, 0, 0.25)',
                        '&:hover': {
                            transform: 'scale(1.01)',
                        }
                    }}
                >
                    {t("Cancel")}
                </Button>

                <Button
                    onClick={isEditMode ? handleEditItem : handleAddItem}
                    color="primary"
                    variant="contained"
                    sx={{
                        backgroundColor: newItem.amount && newItem.description ? '#304FFF' : '#b0b0b0',
                        color: 'white',
                        padding: '10px 20px',
                        fontSize: '16px',
                        fontWeight: 'bold',
                        cursor: 'pointer',
                        marginTop: '20px',
                        borderRadius: '8px',
                        border: '1px solid var(--foreground, #000)',
                        boxShadow: '0px 1px 4px 0px rgba(0, 0, 0, 0.25)',
                        '&:hover': {
                            transform: 'scale(1.01)',
                            backgroundColor: newItem.amount && newItem.description ? '#1565c0' : '#b0b0b0',
                        },
                    }}
                    disabled={!newItem.amount || !newItem.description}
                >
                    {isEditMode ? t("Save") : t("Add")}
                </Button>

            </DialogActions>
            {loading && (
                <div className="loader-container">
                    <CircularProgress
                        size={50}
                        thickness={4}
                        sx={{
                            color: '#025366',
                        }}
                    />
                </div>
            )}
        </Dialog>
    );
};

export default AddNewCollectionItem;
