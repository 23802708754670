import Constants from "../assests/Constants/constants";


export function getVatBySortCode(value, sortCodeIdOptions) {
    
    
    
    if (!value || !sortCodeIdOptions.some((option) => option.value === value)) {
        return 100;
    }
    let newVat = 100;
    const name = sortCodeIdOptions.find(
        (option) => option.value === value
    )?.label || ''

    const nameWithoutParentheses = name.replace(/\s*\(.*?\)\s*/, '').trim();

    const match = name.match(/\((\d+)%?/);
    let customVat = match ? parseInt(match[1], 10) : null;



    // Determine the VAT based on the name without parentheses
    if (Constants.SortCodesWith66Vat.includes(nameWithoutParentheses)) {
        newVat = 66;
    } else if (Constants.SortCodesWithZeroVat.includes(nameWithoutParentheses)) {
        newVat = 0;

    } else if (Constants.SortCodesWith16Vat.includes(nameWithoutParentheses)) {
        newVat = 16;
    } else if (customVat !== null) {
        // If a custom VAT was found, use it
        newVat = customVat === 66 ? 66 : customVat;
    } else {
        // Default VAT if no special conditions are met
        newVat = 100;
    }
    return newVat;
}