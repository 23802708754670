import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import HamburgerMenu from '../HamburgerMenu/HamburgerMenu';
import 'react-datepicker/dist/react-datepicker.css';
import DocumentService from '../../Services/documents.service';
import { registerLocale, setDefaultLocale } from "react-datepicker";
import enGB from 'date-fns/locale/en-GB';
import { useTranslation } from 'react-i18next';
import UserService from '../../Services/user.service';
import backIcon from '../../assests/images/left-arrow.png';
import EnumsService from '../../Services/enums.service';
import ClientDocumentCountService from '../../Services/ClientDocumentCount.service';
import PuffLoader from "react-spinners/PuffLoader";
import { ToastContainer, toast } from "react-toastify";
import './AccountCardDashboard.css';
import ClientService from '../../Services/client.service';
import NoDocumentsPdf from "../../assests/pdf/NoDocument.pdf";
import DescriptionIcon from '@mui/icons-material/Description';
import CustomDeleteConfirmationModal from '../CustomDeleteConfirmationModal/CustomDeleteConfirmationModal';
import cardMatchingIcon from '../../assests/images/Icons/cardMatching.svg';
import { useBack } from "use-back";

import EditModal from '../EditModal/EditModal';
import CircularProgress, {
    circularProgressClasses,
} from '@mui/material/CircularProgress';
import Switch from '@mui/material/Switch';
import CustomMonthDatePicker from '../Widgets/CustomDates/CustomMonthDatePicker/CustomMonthDatePicker';
import CustomYearDatePicker from '../Widgets/CustomDates/CustomYearDatePicker/CustomYearDatePicker';
import dayjs, { Dayjs } from 'dayjs';
import FormControlLabel from '@mui/material/FormControlLabel';
import Constants from '../../assests/Constants/constants';
import { TextField } from '@mui/material';
import {
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Collapse, IconButton, Box, Checkbox
} from '@mui/material';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { beautifyNumber } from '../../Utils/FormatNumber';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import { Edit as EditIcon } from '@mui/icons-material';
import CustomDropdown from '../CustomDropdown/CustomDropdown';
import Select, { components } from 'react-select';
import addIcon from "../../assests/images/plusO.png";
import cancelIcon from "../../assests/images/cancel.png";
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import EditPaymentAcceptanceModal from '../EditPaymentAcceptanceModal/EditPaymentAcceptanceModal';
import AccountCardReportHTML from './AccountCardReportHTML/AccountCardReportHTML';
import DenseTable from '../DenseTable/DenseTable';
import UpperPanelDetails from '../Widgets/UpperPanelDetails/UpperPanelDetails';
import AccountCardDashboardTable from './SubComponents/AccountCardDashboardTable';
import PaymentAcceptanceCreateEditModal from '../PaymentAcceptance/SubComponents/PaymentAcceptanceCreateEditModal';
import CustomMonthYearIndividualDatePicker from '../Widgets/CustomDates/CustomMonthYearIndividualDatePicker/CustomMonthYearIndividualDatePicker';



registerLocale('en-GB', {
    ...enGB,
    localize: {
        ...enGB.localize,
        month: n => n + 1, // Display months as numbers starting from 1
    },
    formatLong: {
        ...enGB.formatLong,
        date: () => 'MM/yyyy', // Date format when the picker is not showing
    },
});

setDefaultLocale('en-GB'); // Set the default locale to use it

// Helper function to adjust date to Israel time zone (UTC+2)
const adjustToIsraelTime = (date) => {
    const israelTimeOffset = 2 * 3600 * 1000; // 2 hours in milliseconds
    return new Date(date.getTime() + israelTimeOffset);
};

// Helper function to get the start of the year in Israel time
const getStartOfYear = (date) => {
    const startOfYear = new Date(date.getFullYear(), 0, 1); // Jan 1st of the given year
    return adjustToIsraelTime(startOfYear);
};

// Helper function to get the current month in Israel time
const getCurrentMonth = (date) => {
    const currentMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0); // Last day of the current month
    return adjustToIsraelTime(currentMonth);
};



const formatDate = (startDate, endDate) => {
    const date = new Date(startDate);
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const year = date.getFullYear();
    const Enddate = new Date(endDate);
    const EndMonth = ('0' + (Enddate.getMonth() + 1)).slice(-2);
    const EndYear = Enddate.getFullYear();
    return `${EndMonth}/${EndYear} - ${month}/${year}`;
};

const formatYearRange = (dateRange) => {
    const [start, end] = dateRange.split(" - ");
    const startYear = new Date(start).getFullYear();
    const startMonth = new Date(start).getMonth() + 1; // getMonth() is zero-indexed
    const endMonth = new Date(end).getMonth() + 1;
    return `${startMonth}-${endMonth}/${startYear}`;
}

const formatDateNew = dateString => {
    const date = new Date(dateString);
    const day = ('0' + date.getDate()).slice(-2);
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
};

const customStyles = {
    valueContainer: (base, state) => ({
        ...base,
        justifyContent: "center"
    }),
}



function AccountCardDashboard() {
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();
    const direction = i18n.dir();
    const [user, setUser] = useState(null);
    const [token, setToken] = useState('');
    // const [data, setData] = useState([]);
    const [client, setClient] = useState(null);
    const [type, setType] = useState('');



    const location = useLocation();
    const [loading, setLoading] = useState(false);

    const [startDate, setStartDate] = useState(null); // Initially null
    const [endDate, setEndDate] = useState(null); // Initially null
    const [isMonthly, setIsMonthly] = useState(() => {
        return JSON.parse(localStorage.getItem('isMonthly')) || true;
    });
    const [data, setData] = useState([]);
    const [sortDate, setSortDate] = useState(new Date());
    const [startYearDate, setStartYearDate] = useState(new Date());
    const [endYearDate, setEndYearDate] = useState(new Date());
    const [sortCodesGroupOptions, setSortCodesGroupOptions] = useState([]);
    const [isDataFetched, setIsDataFetched] = useState(false);
    const [footerTotals, setFooterTotals] = useState({});

    const [responseData, setResponseData] = useState({});
    const [formattedPalCodeOptions, setFormattedPalCodeOptions] = useState([]);
    //for item edit

    const [creditAccountOptions, setCreditAccountOptions] = useState([]);
    const [debitAccountOptions, setDebitAccountOptions] = useState([]);
    const [editedItem, setEditedItem] = useState({});
    const [statusCheck, setStatusCheck] = useState(false);
    const [editingRow, setEditingRow] = useState(null);
    const [editedAddAccountCard, setEditedAddAccountCard] = useState(false);
    const [accountCardOptions, setAccountCardOptions] = useState([]);
    const [accountCardEditDownloadOptions, setAccountCardEditDownloadOptions] = useState([]);
    const [originalAccountCardId, setOriginalAccountCardId] = useState('');
    const [isClosedReportingMonth, setIsClosedReportingMonth] = useState(false);
    const [sortCodeIdOptions, setSortCodeIdOptions] = useState([]);
    const [sortConfig, setSortConfig] = useState({ key: 'uploadNumber', direction: 'descending', type: 'number' });
    const [currentIndex, setCurrentIndex] = useState(-1);
    const [showType, setShowType] = useState({ type: 'all', index: -1 });

    const [fade, setFade] = useState(false);
    const [editedPopupItem, setEditedPopupItem] = useState(true);
    const [editedJournalItem, setEditedJournalItem] = useState(true);
    const [processedFilesCheckbox, setProcessedFilesCheckbox] = useState(() => {
        const savedValue = localStorage.getItem('processedFilesCheckbox');
        return savedValue === null ? true : JSON.parse(savedValue);
    });

    // Update localStorage whenever the checkbox value changes
    useEffect(() => {
        localStorage.setItem('processedFilesCheckbox', JSON.stringify(processedFilesCheckbox));
    }, [processedFilesCheckbox]);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [itemToDelete, setItemToDelete] = useState({});

    //new constants
    const [focused, setFocused] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const handleStartYearDate = async (date) => {
        const newDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), 10));
        setStartYearDate(newDate);
    }


    const handleEndYearDate = async (date) => {
        const newDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), 10));
        setEndYearDate(newDate);
    }



    const [isRange, setIsRange] = useState(() => {
        const storedIsRange = localStorage.getItem('Document' + location.state.type + '_IsRange');
        return storedIsRange === "true";
    });
    const handleCancel = () => {
        setEditingRow(null);
        setEditedItem({});
        setEditedAddAccountCard(false);
    };


    const handleCloseEditAddNewAccountCardDocument = () => {
        setEditedItem({ ...editedItem, accountCodeName: '', accountCodeId: '' })
        setEditedAddAccountCard(false);
    };

    useEffect(() => {
        if (currentIndex != -1) {
            setFade(true);
            const timer = setTimeout(() => setFade(false), 500);
            return () => clearTimeout(timer);
        }
    }, [currentIndex]);
    const handleEditAddAccountChange = (selectedOption, field) => {

        if (field === 'AccountCodeId') {
            type === 'Incomes' ? setEditedItem({ ...editedItem, accountCodeId: selectedOption, debitAccount: selectedOption }) : setEditedItem({ ...editedItem, accountCodeId: selectedOption, creditAccount: selectedOption });
        }
        else if (field === 'AccountCodeName') {
            setEditedItem({ ...editedItem, accountCodeName: selectedOption })
        }
        else {
            setEditedItem({ ...editedItem, accountCaseId: selectedOption })

        }
    };

    const updateAccountFields = (newValue, accountType) => {


        const isIncomesDebit = editedItem.docType === 'Incomes' && accountType === 'debitAccount';
        const isExpensesCredit = editedItem.docType === 'Expenses' && accountType === 'creditAccount';

        const updatedFields = {
            accountCodeId: isIncomesDebit || isExpensesCredit ? (newValue ? newValue.cardId : '') : '',
            accountCodeName: isIncomesDebit || isExpensesCredit ? (newValue ? newValue.name : '') : '',
            accountCaseId: isIncomesDebit || isExpensesCredit ? (newValue ? newValue.accountCaseId : '') : '',
        };

        // Update the edited item with the new fields
        setEditedItem((prev) => ({
            ...prev,
            [accountType]: newValue ? newValue.cardId : '',
            ...(isIncomesDebit || isExpensesCredit ? updatedFields : {}),
        }));
    };

    const { hasBack, handleBack } = useBack();


    const handleChange = (field, value) => {

        const updatedValue = value;

        if (field === 'sortCodeId') {

            if (!value || !sortCodeIdOptions.some((option) => option.sortCodeId === value)) {
                // Set default values for the empty state
                setEditedItem({
                    ...editedItem,
                    vat: 100, // Default VAT
                    [field]: '',
                });
                return; // Exit early since there's no valid sortCodeId
            }

            let newVat = 100;
            const name = sortCodeIdOptions.find(
                (option) => option.sortCodeId === value
            )?.sortCodeName || ''

            // Extract the name without the parentheses
            const nameWithoutParentheses = name.replace(/\s*\(.*?\)\s*/, '').trim();

            // Check for a number within parentheses
            const match = name.match(/\((\d+)%?/);
            let customVat = match ? parseInt(match[1], 10) : null;


            const sortCode = sortCodeIdOptions.filter(sc => sc.sortCodeId === updatedValue)[0];

            // Determine the VAT based on the name without parentheses
            if (Constants.SortCodesWith66Vat.includes(nameWithoutParentheses)) {
                newVat = 66;
            } else if (Constants.SortCodesWithZeroVat.includes(nameWithoutParentheses) || sortCode?.sortCodeVatType === 0) {
                newVat = 0;

            } else if (Constants.SortCodesWith16Vat.includes(nameWithoutParentheses)) {
                newVat = 16;
            } else if (customVat !== null) {
                // If a custom VAT was found, use it
                newVat = customVat === 66 ? 66 : customVat;
            } else {
                // Default VAT if no special conditions are met
                newVat = 100;
            }

            const accountCard = accountCardOptions.find(
                (item) => item.cardId === (type === 'Incomes' ? sortCode.debitAccount : sortCode.creditAccount)
            );
            setEditedItem({
                ...editedItem,
                vat: newVat,
                [field]: updatedValue,
                [type === 'Incomes' ? 'debitAccount' : 'creditAccount']:
                    editedItem[type === 'Incomes' ? 'debitAccount' : 'creditAccount']
                        ? (accountCard ? accountCard.cardId : editedItem[type === 'Incomes' ? 'debitAccount' : 'creditAccount'])
                        : editedItem[type === 'Incomes' ? 'debitAccount' : 'creditAccount'],
                accountCodeName: accountCard ? accountCard.name : editedItem.accountCodeName,
                accountCodeId: accountCard ? accountCard.cardId : editedItem.accountCodeId,
                accountCaseId: accountCard ? accountCard.accountCaseId : editedItem.accountCaseId,
            });

        }
        else if (field === 'creditAccount' || field === 'debitAccount') {

            // Use the helper function to update account fields for credit or debit account
            const newValue = accountCardOptions.find(option => option.cardId === updatedValue) || null;
            updateAccountFields(newValue, field);
        }
        else {
            // For fields other than sortCodeId, just update the edited item
            setEditedItem({
                ...editedItem,
                [field]: updatedValue,
            });
        }
    };
    const handleDateChange = (e, field) => {
        setEditedItem({ ...editedItem, [field]: e });
    };

    const handleReopenEdit = async (item, index) => {
        await fetchEnums(user, token, client);
        item.key = item.id;
        handleEdit(item);
    };


    const handleAccountChange = (selectedOption, field) => {
        if (field === 'debitAccount' || field === 'creditAccount') {
            const accountCardEditDownload = accountCardEditDownloadOptions.find(option => option.cardId === selectedOption.value);
            const name = accountCardEditDownload?.name;
            const accountCaseId = accountCardEditDownload?.accountCaseId;
            if (selectedOption.value < 0) {

                setOriginalAccountCardId(editedItem.cardId)
            }
            if (editedItem.docType === 'Incomes') {
                setEditedItem({ ...editedItem, accountCodeName: name, accountCodeId: selectedOption.value, debitAccount: selectedOption.value, accountCaseId: accountCaseId })
            }
            else if (editedItem.docType === 'Expenses') {
                setEditedItem({ ...editedItem, accountCodeName: name, accountCodeId: selectedOption.value, creditAccount: selectedOption.value, accountCaseId: accountCaseId });
            }
            else if (editedItem.docType === 'JournalEntries') {
                setEditedItem({
                    ...editedItem,
                    [field]: selectedOption.value
                });
            }
        } else if (field === 'accountCodeName') {
            if (selectedOption.value1 === 'AddItem') {
                setEditedAddAccountCard(true);
                setEditedItem({ ...editedItem, accountCodeName: '', accountCodeId: '', accountCaseId: '' })
            }
            else {
                const accountCardEdit = accountCardEditDownloadOptions.find(option =>
                    option.cardId === selectedOption.value
                );
                const name = accountCardEdit?.name;
                const accountCaseId = accountCardEdit?.accountCaseId;
                const cardId = selectedOption.value;
                if (editedItem.docType === 'Incomes') {
                    setEditedItem({ ...editedItem, accountCodeName: name, accountCodeId: cardId, debitAccount: cardId, accountCaseId: accountCaseId })
                }
                else if (editedItem.docType === 'Expenses') {
                    setEditedItem({ ...editedItem, accountCodeName: name, accountCodeId: cardId, creditAccount: cardId, accountCaseId: accountCaseId });
                }
                else if (editedItem.docType === 'JournalEntries') {
                    setEditedItem({ ...editedItem, accountCodeName: name, accountCodeId: cardId, accountCaseId: accountCaseId })
                }
            }
        }
    };
    const CustomInput1 = React.forwardRef(({ value, onClick }, ref) => (
        <button className="document-custom-input1" onClick={onClick} ref={ref}>
            {value}
        </button>
    ));

    const CustomInput2 = React.forwardRef(({ value, onClick }, ref) => (
        <button className="edit-custom-input" onClick={onClick} ref={ref}>
            {value}
        </button>
    ));

    const setReportingMonthDate = async (selectedDate, field) => {
        if (!selectedDate) {
            return;
        }
        const newDate = new Date(Date.UTC(selectedDate.getFullYear(), selectedDate.getMonth(), 10));
        const response1 = await ClientService.CheckIfMonthClosed(client.caseId, newDate, token);
        if (response1.ok) {
            const isClosedReportingMonth = await response1.json();
            if (isClosedReportingMonth) {
                toast.info(t('MonthReportAlreadyClosedTryAgain'));
                return
            }
            else {
                setEditedItem({ ...editedItem, [field]: newDate });
            }
        }
    }
    const handleRadioChange = (e) => {
        if (e.target.value == 'done') {

            if (!editedItem.sortCodeId) {
                toast.error();
                return
            }
            setStatusCheck(true);
        }
        else if (e.target.value == 'notDone') {
            setStatusCheck(false);
        }
        setEditedItem({ ...editedItem, ["status"]: e.target.value });
    };
    const handleVatRadioChange = (e) => {
        setEditedItem({ ...editedItem, ["vat"]: parseFloat(e.target.value) });
    };
    const handleUpdate = async (itemId) => {
        setLoading(true);
        let finalResponse = null;
        setEditedPopupItem(false);
        try {
            editedItem.accountCaseId = client.accountCaseId;
            delete editedItem.documentUrl; // Ensure the document URL is not part of the update if present


            finalResponse = await DocumentService.UpdateDocument(editedItem.docType, token, editedItem);
            if (finalResponse && finalResponse.ok) {
                if (editedItem.status === 'done') {
                    await handleDoneClick(editedItem.id, editedItem.docType);
                    handleSortChange('id', 'newest', 'descending');
                } else if (editedItem.status === 'notDone') {
                    await handleNotDoneClick(editedItem.id, editedItem.docType);
                }

                if (editedAddAccountCard) {
                    await handleAccountCardSave(editedItem.accountCodeId, editedItem.accountCodeName, editedItem.accountCaseId);
                    await fetchEnums(user, token, client);
                }

                await fetchAccountCardDashboard(new Date(startDate), new Date(endDate));
                toast.success(t("documentUpdateSuccess"));
            } else {
                const errorText = await finalResponse.text();
                toast.error(t("documentUpdateError") + ": " + errorText);
            }
        } catch (error) {
            // This block catches unexpected errors such as network issues, not HTTP errors which are handled above
            console.error("Unexpected error occurred: ", error);
            toast.error(t("documentUpdateError"));
        }
        setEditedItem({});
        setEditingRow(null);
        setEditedAddAccountCard(false);
        setLoading(false); // Always stop loading at the end of the operation
    };

    const handleDoneClick = async (itemId, docType) => {
        setLoading(true);
        try {
            const response = await ClientDocumentCountService.removeDocumentByTypeAndId(client.caseId, client.accountantId, docType, itemId, token)
            if (response.ok) {
                await fetchDocumentsNotification(client, token)
                toast.success(t('DocumentProcessed'));
            } else {
                const errorText = await response.text();
                toast.error(t('DocumentNotProcessed', { errorText }));
            }
        } catch (error) {
            toast.error(t('DocumentNotProcessed', { errorText: error.message }));
        }
        setLoading(false);
    };
    const handleSortChange = (key, type, Direction = '') => {
        let direction = Direction || 'ascending';
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({ key, direction, type });
    };

    const fetchDocumentsNotification = async (client, token) => {
        const documentCounts = await ClientDocumentCountService.getClientDocumentCountByCaseId(client.caseId, token);
        // Enrich clients with their total document counts
        const enrichedClient = {
            ...client,
            totalCounts: documentCounts.totalCounts || 0, // Default to 0 if no matching count is found
            documentCounts: documentCounts.documentCounts || {},
            documentIds: documentCounts.documentIds || {},
        };
        setClient(enrichedClient);
    }
    const handleNotDoneClick = async (itemId, docType) => {
        setLoading(true);
        try {
            const response = await ClientDocumentCountService.addDocumentByTypeAndId(client.caseId, client.accountantId, docType, itemId, token)
            if (response.ok) {
                await fetchDocumentsNotification(client, token)
                toast.success(t('DocumentProcessed'));
            } else {
                const errorText = await response.text();
                toast.error(t('DocumentNotProcessed', { errorText }));
            }
        } catch (error) {
            toast.error(t('DocumentNotProcessed', { errorText: error.message }));
        }
        setLoading(false);
    };
    const handleAccountCardSave = async (AccountCodeId, AccountCodeName, AccountCaseId) => {
        const response2 = await EnumsService.AddAccountCard(client.caseId, AccountCodeId, AccountCodeName, AccountCaseId, token);
        if (!response2.ok) {
            throw new Error(`HTTP error! status: ${response2.status}`);
        }
    };

    //FetchEnums
    const fetchEnums = async (user, token, client) => {
        setLoading(true);
        try {
            const sortCodeGroupResponse = await EnumsService.GetSortCodesGroupByAccountantId(client.accountantId, token);
            const sortCodeGroupData = await sortCodeGroupResponse.json();
            setSortCodesGroupOptions(sortCodeGroupData)

            // Fetching sortCodeIdOptions
            const codeIdResponse = await EnumsService.GetSortCodesByAccountantId(client.accountantId, token);
            if (codeIdResponse.ok) {
                const codeIdData = await codeIdResponse.json();
                setSortCodeIdOptions(codeIdData); // Assuming codeIdData is already in the correct format
            }


            // Fetching creditAccountOptions and debitAccountOptions
            const accountsResponse = await EnumsService.GetAccountCardsByCaseId(client.caseId, token);
            if (accountsResponse.ok) {
                const accountsData = await accountsResponse.json();
                setCreditAccountOptions(accountsData);
                setDebitAccountOptions(accountsData); // Assuming both options are the same
                setAccountCardEditDownloadOptions(accountsData);
                const accountCardOptions = [{ name: t('AddAccountCard'), value1: 'AddItem' }, ...accountsData];


                setAccountCardOptions(accountCardOptions);
            }
            const palCodesResponse = await EnumsService.GetPalCodesByAccountantId(client.accountantId, token)
            // Process palCodes if response is okay
            if (palCodesResponse.ok) {
                const palCodesData = await palCodesResponse.json();
                const formattedPalCodes = palCodesData.map(palCode => ({
                    label: palCode.palCodeId,
                    value: palCode.palCodeId
                }));


                setFormattedPalCodeOptions(formattedPalCodes);
            }
            setIsDataFetched(true);
            toast.success(t('enumFetchSuccess'));
        } catch (error) {
            console.error("Error fetching enum data:", error);
            toast.error(`${t("Error fetching enum data")}: ${error}`);
        }
        setLoading(false);
    };




    const handleRangeChange = () => {
        setIsMonthly(prevIsMonthly => {
            const newIsMonthly = !prevIsMonthly;
            localStorage.setItem('isMonthly', JSON.stringify(newIsMonthly));
            return newIsMonthly;
        });
    };

    useEffect(() => {
        if (client) {
            refreshData();
        }
    }, [isMonthly]);

    const handleEndDateChange = async (date) => {
        const newDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), 10));
        let endDateToSet;

        if (isMonthly) {
            endDateToSet = newDate;
            setEndDate(newDate);
        } else {
            endDateToSet = new Date(Date.UTC(date.getFullYear(), 11, 31));
            setEndYearDate(endDateToSet);
            setEndDate(endDateToSet);
        }

        // Call fetchAccountCardDashboard after updating the end date
        const startOfYear = new Date(Date.UTC(startDate.getFullYear(), 0, 1));
        fetchAccountCardDashboard(isMonthly ? startDate : startYearDate, endDateToSet);
    };

    const handleStartDateChange = async (date) => {
        const newDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), 10));
        let startDateToSet;

        if (isMonthly) {
            startDateToSet = newDate;
            setStartDate(newDate);
        } else {
            startDateToSet = new Date(Date.UTC(date.getFullYear(), 0, 1));
            setStartYearDate(startDateToSet);
            setStartDate(startDateToSet);
        }

        // Call fetchAccountCardDashboard after updating the start date
        const endOfYear = new Date(Date.UTC(endDate.getFullYear(), 11, 31));
        fetchAccountCardDashboard(startDateToSet, isMonthly ? endDate : endYearDate, processedFilesCheckbox);
    };

    const handleStartDateChangeNew = async (newValue, type) => {
        const newDate = new Date(newValue);
        let updatedDate;
        let startDateToSet;

        if (type === 'year') {
            updatedDate = new Date(Date.UTC(newDate.getFullYear(), startDate.getMonth(), 10));
        } else if (type === 'month') {
            updatedDate = new Date(Date.UTC(startDate.getFullYear(), newDate.getMonth(), 10));
        }

        if (isMonthly) {
            startDateToSet = updatedDate;
            setStartDate(updatedDate);
            localStorage.setItem('_StartDate', updatedDate.toISOString()); // Save to localStorage
        } else {
            startDateToSet = new Date(Date.UTC(newDate.getFullYear(), 0, 1));
            setStartYearDate(startDateToSet);
            setStartDate(startDateToSet);
            localStorage.setItem('_StartYearDate', startDateToSet.toISOString()); // Save to localStorage
        }


        // Save the updated date in localStorage for persistence
        const endOfYear = new Date(Date.UTC(endDate.getFullYear(), 11, 31));
        fetchAccountCardDashboard(startDateToSet, isMonthly ? endDate : endYearDate, processedFilesCheckbox);
    };

    const handleEndDateChangeNew = async (newValue, type) => {
        const newDate = new Date(newValue);
        let updatedDate;
        let endDateToSet;

        if (type === 'year') {
            updatedDate = new Date(Date.UTC(newDate.getFullYear(), endDate.getMonth(), 10));
        } else if (type === 'month') {
            updatedDate = new Date(Date.UTC(endDate.getFullYear(), newDate.getMonth(), 10));
        }


        if (isMonthly) {
            endDateToSet = updatedDate;
            setEndDate(updatedDate);
            localStorage.setItem('_EndDate', updatedDate.toISOString()); // Save to localStorage
        } else {
            endDateToSet = new Date(Date.UTC(newDate.getFullYear(), 11, 31));
            setEndYearDate(endDateToSet);
            setEndDate(endDateToSet);
            localStorage.setItem('_EndYearDate', endDateToSet.toISOString()); // Save to localStorage
        }


        // Call fetchAccountCardDashboard after updating the end date
        const startOfYear = new Date(Date.UTC(startDate.getFullYear(), 0, 1));
        fetchAccountCardDashboard(isMonthly ? startDate : startYearDate, endDateToSet);
    };

    //for item edit
    function formatDate(input) {
        const [datePart, timePart] = input.split(' ');
        const [day, month, year] = datePart.split('/');
        const date = new Date(year, month - 1, day);
        if (timePart) {
            const [hours, minutes, seconds] = timePart.split(':');
            date.setHours(hours, minutes, seconds, 0);
        }
        return date.toISOString().slice(0, 19) + '.000';
    }



    //payment functions
    const [openInvoiceCreatorModal, setOpenInvoiceCreatorModal] = useState(false);
    const [addEditableItem, setAddEditableItem] = useState({
        checks: [],
        creditCards: [],
        cash: [],
        bankTransactions: [],
        date: new Date()
    });
    const [modalVisible, setModalVisible] = useState(false);
    const [itemEditing, setItemEditing] = useState(true);
    const [addNewAccountCard, setAddNewAccountCard] = useState(false);
    const [addNewAccountCardDocument, setAddNewAccountCardDocument] = useState(false);
    const [selectedOption, setSelectedOption] = useState("");
    const isClosedReportingMonthAndIsRange = !isRange ? isClosedReportingMonth : addEditableItem.isClosedReportingMonth;
    const [buttonDisabled, setButtonDisabled] = useState(false);
    const [paymentAcceptanceItems, setPaymentAcceptanceItems] = useState([]);
    const [accountCodeName, setAccountCodeName] = useState('');
    const [companyId, setCompanyId] = useState('');
    const [accountCodeId, setAccountCodeId] = useState('');
    const [invoiceClientCaseId, setInvoiceClientCaseId] = useState('');
    const [accountType, setAccountType] = useState(null);
    const [description, setDescription] = useState('');
    const [showAccountCardInputsEdit, setShowAccountCardInputsEdit] = useState(false);
    const [acceptedWithholdingTax, setAcceptedWithholdingTax] = useState(0);
    const [palCode, setPalCode] = useState('');
    const [paymentWithholdingTax, setPaymentWithholdingTax] = useState(0);
    const [newItem, setNewItem] = useState(false);
    const [isUpdated, setIsUpdated] = useState(false);
    const [actionType, setActionType] = useState('delete');
    const [accountCardIdToDelete, setAccountCardIdToDelete] = useState('');
    const [paymentBeingEdited, setPaymentBeingEdited] = useState(true);





    const handleAddAccountCard = async () => {
        if (checkIfExistingAccountCard(accountCardOptions, accountCodeId, companyId)) {
            return;
        }
        const Item = addEditableItem;
        handleClose();
        try {
            setLoading(true);
            const response = await EnumsService.AddAccountCard(client.caseId, accountCodeId, accountCodeName, companyId, palCode, token);
            if (response.ok) {
                const accountCodeId = await response.json();
                let selectedAccountCard = {
                    cardId: accountCodeId,
                    caseId: client.caseId,
                    accountCaseId: companyId,
                    name: accountCodeName,
                    palCode: palCode,
                    client: null
                };

                const newItem = {
                    ...Item,
                    accountCodeId: accountCodeId?.toString(),
                    accountCodeName: accountCodeName?.toString(),
                    accountCaseId: invoiceClientCaseId?.toString(),
                };
                setNewItem(newItem);
                setAccountCardOptions(prevCards => {
                    const updatedCards = [...prevCards, selectedAccountCard];
                    setIsUpdated(true);
                    return updatedCards;
                });

                toast.success(t('CustomerSupplierAddedSuccessfully'));
                setSelectedOption(selectedAccountCard ? selectedAccountCard : null);

            } else {
                const errorText = await response.text();
                console.error('Failed to edit user', errorText);
                toast.error(t('FailedToAddCustomerSupplier'));
            }
        } catch (error) {
            console.error('Error edit user:', error);
            toast.error(t('FailedToAddCustomerSupplier'));
        }
        setLoading(false);
        setPalCode('');
        setAccountCodeId("");
        setAccountCodeName("");
        setAddNewAccountCardDocument(false);
    };
    const handleAddEditableItemChange = (name, value) => {
        setAddEditableItem(prev => ({ ...prev, [name]: value }));

    };

    async function fetchDataOnDateRangeChange(startDate, endDate) {

        setLoading(true)
        const formattedStartDate = startDate.toISOString();
        const formattedEndDate = endDate.toISOString();

        try {
            const response = await DocumentService.GetDocumentbyDateRange(type, client.caseId, formattedStartDate, formattedEndDate, token)
            if (response.ok) {
                const data = await response.json(); // Parse the JSON in the response
                extractVatByType(data);
                setData(data);
                toast.success(t('documentFetchSuccess'));
            } else {
                const errorText = await response.text();
                toast.error(t('documentFetchError', { errorText }));
            }
        } catch (error) {
            toast.error(t('documentFetchError', { errorText: error.message }));
        }

        setLoading(false)
    }
    const [oldSelectedAccountCardItem, setOldSelectedAccountCardItem] = useState({
        cardId: "",
        caseId: "",
        accountCaseId: "",
        name: "",
        palCode: '',
        client: null
    });
    const [originalTaxClearance, setOriginalTaxClearance] = useState(null);
    const [openReportModal, setOpenReportModal] = useState(false);
    const extractVatByType = (data) => {


        // Sum the WithholdingTax based on PaymentAcceptanceType
        let acceptedWithholdingSum = 0;
        let paymentWithholdingSum = 0;
        let creditSum = 0;
        let debitSum = 0;
        let total = 0;
        data.forEach(doc => {

            if (doc.paymentAcceptanceType === 'תקבול') {
                acceptedWithholdingSum += doc.withholdingTax || 0;
            } else if (doc.paymentAcceptanceType === 'תשלום') {
                paymentWithholdingSum += doc.withholdingTax || 0;
            }
            total += doc.amount
            creditSum += doc.creditAccountAmount || 0;
            debitSum += doc.debitAccountAmount || 0;
        });
        let diff = debitSum - creditSum;
        setAcceptedWithholdingTax(acceptedWithholdingSum);
        setPaymentWithholdingTax(paymentWithholdingSum);
    };

    const CustomPalCode = (props) => {
        return (
            <components.Option {...props}>
                <div style={{ textAlign: 'center', width: '100%' }}>{props.label}</div>
            </components.Option>
        );
    };

    const setAccount = (field, input) => {
        //switch case on field
        switch (field) {
            case 'companyId':
                setCompanyId(input);
                setInvoiceClientCaseId(input);

                break;
            case 'accountCodeName':
                setAccountCodeName(input);

                break;
            case 'accountCodeId':
                setAccountCodeId(input);

                break;
            default:

        }
    }

    const checkIfExistingAccountCard = (accountCardOptions, cardId, accountCaseId, edit = false) => {
        const existingAccountCard = accountCardOptions.filter(card =>
            (card.accountCaseId === accountCaseId && accountCaseId) ||
            (card.cardId === cardId && cardId)
        );
        const isExist = existingAccountCard && (edit
            ? existingAccountCard.length > 1
            : existingAccountCard.length > 0);
        if (isExist) {
            toast.info(t('AccountCardExists', { accountCodeId: existingAccountCard.cardId }));
            return true;
        }
    }
    const fetchDocuments = async (selectedDate) => {
        setLoading(true);
        const newDate = new Date(Date.UTC(selectedDate.getFullYear(), selectedDate.getMonth(), 10));

        try {
            const response = await DocumentService.GetDocumentbyDate(type, client.caseId, newDate, token)
            const response1 = await ClientService.CheckIfMonthClosed(client.caseId, newDate, token);

            if (response.ok && response1.ok) {
                const data = await response.json(); // Parse the JSON in the response
                const isClosedReportingMonth = await response1.json();
                setIsClosedReportingMonth(isClosedReportingMonth);
                extractVatByType(data);
                setData(data);
                toast.success(t('fetchDocumentsSuccess'));
            } else {
                const errorText = await response.text();
            }
        } catch (error) {

            toast.error(t('errorFetchingDocuments'));
        }
        setLoading(false);
    };

    const handleCloseAddNewAccountCardDocument = () => {
        setAccountCodeId("");
        setAccountCodeName("");
        setAddNewAccountCardDocument(false);
    };
    const onRefresh = async () => {
        setLoading(true);
        await fetchUserData();
        if (!isRange)
            fetchDocuments(sortDate);
        else
            fetchDataOnDateRangeChange(startDate, endDate)
        await fetchEnums(user, token, client);
    };
    const handleSave = async () => {


        try {
            setModalVisible(false);
            setOpenInvoiceCreatorModal(false);
            setLoading(true);
            // Prepare the data to be sent in the request body
            const updatedDocument = {
                id: addEditableItem.id,
                caseId: addEditableItem.caseId,
                accountCodeName: addEditableItem.accountCodeName,
                accountCodeId: addEditableItem.accountCodeId,
                reference: addEditableItem.reference,
                amount: addEditableItem.amount,
                uploadDate: addEditableItem.uploadDate,
                paymentAcceptanceType: addEditableItem.paymentAcceptanceType,
                description: addEditableItem.description,
                withholdingTax: addEditableItem.withholdingTax,
                accountCaseId: addEditableItem.accountCaseId,
                checks: addEditableItem.checks,
                creditCards: addEditableItem.creditCards,
                cash: addEditableItem.cash,
                bankTransactions: addEditableItem.bankTransactions,
                date: addEditableItem.date ? new Date(addEditableItem.date).toISOString() : new Date().toISOString(),
                reportingMonthDate: new Date(addEditableItem.reportingMonthDate).toISOString(),
                confirmationNumber: addEditableItem.confirmationNumber,
                creditAccount: addEditableItem.creditAccount,
                creditAccountAmount: addEditableItem.creditAccountAmount,
                debitAccount: addEditableItem.debitAccount,
                debitAccountAmount: addEditableItem.debitAccountAmount,
            };


            // Call the UpdateDocument function from DocumentService
            const response = await DocumentService.UpdateDocument("PaymentAcceptances", token, updatedDocument);

            if (response.ok) {
                // Handle successful update
                if (addEditableItem.status === 'done') {
                    await handleDoneClick(addEditableItem.id);
                } else if (addEditableItem.status === 'notDone') {
                    await handleNotDoneClick(addEditableItem.id);
                }
                toast.success(t('documentUpdateSuccess'));
                await fetchAccountCardDashboard(startDate, endDate, processedFilesCheckbox);
            } else {
                // Handle any errors or display an error message
                toast.error(t('documentUpdateError'));
            }
        } catch (error) {
            console.error('documentUpdateError:', error);
            // Show an error toast message
            toast.error(t('documentUpdateError'));
        }
        await onRefresh();
        handleClose(); // Function to revert or exit the edit mode
        setLoading(false);

    };

    const handleSaveEditAccountCard = async () => {
        if (checkIfExistingAccountCard(accountCardOptions, accountCodeId, companyId, true)) {
            return;
        }
        const Item = addEditableItem;
        handleClose();
        try {
            setLoading(true)

            const response = await EnumsService.UpdateAccountCard(client.caseId, oldSelectedAccountCardItem.cardId, oldSelectedAccountCardItem.name, accountCodeId, accountCodeName, companyId, palCode, token);
            if (response.ok) {
                const newItem = {
                    ...Item,
                    accountCodeId: accountCodeId?.toString(),
                    accountCodeName: accountCodeName?.toString(),
                    accountCaseId: invoiceClientCaseId?.toString(),
                };
                setNewItem(newItem);
                let selectedAccountCard = {
                    cardId: accountCodeId,
                    caseId: client.caseId,
                    accountCaseId: companyId,
                    name: accountCodeName,
                    palCode: palCode,
                    client: null
                };
                setAccountCardOptions(prevCards => {
                    const updatedCards = prevCards.filter(card => card.cardId !== selectedAccountCard.cardId);
                    updatedCards.push(selectedAccountCard);
                    setIsUpdated(true);
                    return updatedCards;
                });
                // handleReopenEdit(editedItem);
                toast.success(t('CustomerSupplierUpdatedSuccessfully'));
                setSelectedOption(selectedAccountCard ? selectedAccountCard : null);

            } else {
                const errorText = await response.text();
                console.error('FailedToUpdateCustomerSupplier', errorText);
                toast.error(t('FailedToUpdateCustomerSupplier'));
            }
        } catch (error) {
            console.error('FailedToUpdateCustomerSupplier:', error);

            toast.error(t('FailedToUpdateCustomerSupplier'));

        }
        setLoading(false);
        setPalCode('');
        setAccountCodeId("");
        setAccountCodeName("");
        setAddNewAccountCardDocument(false);
        setShowAccountCardInputsEdit(false);
    };



    const handleItemChange = (type, index, field, value) => {
        setPaymentAcceptanceItems(prevItems => {
            let typeIndex = -1;
            const updatedItems = prevItems.map((item, idx) => {
                if (item.type === type) {
                    typeIndex += 1;
                    if (typeIndex === index) {
                        return { ...item, [field]: value };
                    }
                }
                return item;
            });

            // Update the corresponding field in addEditableItem
            setAddEditableItem(prevItem => {
                const updatedTypeItems = prevItem[type].map((item, idx) => {
                    if (idx === index) {
                        return { ...item, [field]: value };
                    }
                    return item;
                });

                // Conditionally update the fields based on payment type and field
                const paymentType = addEditableItem.paymentAcceptanceType;
                const shouldUpdateFields =
                    (paymentType === 'תקבול' && field === 'creditAccount') ||
                    (paymentType === 'תשלום' && field === 'debitAccount');

                const matchingOption = accountCardOptions.find(
                    option => option.cardId === value
                );

                const updatedFields = shouldUpdateFields && matchingOption
                    ? {
                        accountCaseId: matchingOption.accountCaseId || '',
                        accountCodeId: matchingOption.cardId || '',
                        accountCodeName: matchingOption.name || '',
                    }
                    : {};

                return { ...prevItem, ...updatedFields, [type]: updatedTypeItems };
            });

            return updatedItems;
        });
    };
    const handleChangeAccountType = (selectedOption) => {
        setAccountType(selectedOption.value);
        setAddEditableItem((prevState) => ({
            ...prevState,
            paymentAcceptanceType: selectedOption ? selectedOption.value : ''
        }));
    };


    const setPaymentDate = async (date) => {
        const response1 = await ClientService.CheckIfMonthClosed(client.caseId, date, token);
        const isClosedReportingMonth = await response1.json();
        if (isClosedReportingMonth) {
            toast.error(t('MonthReportAlreadyClosedTryAgain'));
            return
        }
        setAddEditableItem((prevItem) => ({
            ...prevItem,
            date: date.toISOString()
        }));
    }
    const handleDescriptionChange = (event) => {
        setDescription(event.target.value);
        addEditableItem.description = event.target.value;
    };
    const handleChangeOriginalTaxClearance = (event) => {
        const value = event.target.value;
        addEditableItem.withholdingTax = value;
        // setOriginalTaxClearance(value);

    };

    const handleRemoveItem = (type, index) => {
        let count = 0;

        // Update paymentAcceptanceItems
        const newItems = paymentAcceptanceItems.filter(item => {
            if (item.type === type) {
                if (count === index) {
                    count++;
                    return false;
                }
                count++;
            }
            return true;
        });
        setPaymentAcceptanceItems(newItems);

        // Update addEditableItem
        setAddEditableItem(prevItem => {
            const updatedTypeItems = prevItem[type].filter((_, idx) => idx !== index);
            return { ...prevItem, [type]: updatedTypeItems };
        });
    };

    const handleAddItem = (theType) => {
        const paymentType = addEditableItem.paymentAcceptanceType;

        const newItem = {
            type: theType,
            description: '',
            amount: '',
            paymentDate: new Date(),
            bankName: '',
            bankCode: '',
            bankBranchCode: '',
            supplierAccountNumber: '',
            bankTransactionsCode: Constants.PaymentAcceptanceConstantsAccountcards.GeneralBank,
            creditAccount: paymentType === 'תקבול' ? addEditableItem.accountCodeId || '' : '',
            debitAccount: paymentType === 'תשלום' ? addEditableItem.accountCodeId || '' : '',
        };

        // Update paymentAcceptanceItems
        setPaymentAcceptanceItems(prevItems => [...prevItems, newItem]);

        // Update addEditableItem
        setAddEditableItem(prevItem => ({
            ...prevItem,
            [theType]: [...prevItem[theType], newItem]
        }));

    };
    useEffect(() => {
        let unionArray = [];
        if (addEditableItem) {
            unionArray = [
                ...(addEditableItem.checks || []),
                ...(addEditableItem.creditCards || []),
                ...(addEditableItem.cash || []),
                ...(addEditableItem.bankTransactions || [])
            ];
        }

        if (unionArray.length > 0) {
            const allAmountsFilled = unionArray.every(item => item.amount !== undefined && item.amount !== null && item.amount !== '');
            const allFieldsFilled = allAmountsFilled &&
                (addEditableItem.accountCaseId !== undefined && addEditableItem.accountCaseId !== null && addEditableItem.accountCaseId !== '' ||
                    addEditableItem.accountCodeId !== undefined && addEditableItem.accountCodeId !== null && addEditableItem.accountCodeId !== '' ||
                    addEditableItem.accountCodeName !== undefined && addEditableItem.accountCodeName !== null && addEditableItem.accountCodeName !== '') &&
                addEditableItem.paymentAcceptanceType !== undefined && addEditableItem.paymentAcceptanceType !== null && addEditableItem.accountType !== '';



            setButtonDisabled(!allFieldsFilled);
        } else {
            setButtonDisabled(true);
        }
    }, [addEditableItem, accountCodeId, accountType]);



    const handleEdit = (transaction, index) => {
        setCurrentItem(transaction, index);
    };


    const handleMuiChange = (event, value) => {

        if (value?.value1 === 'AddItem') {
            setAccountCodeName("");
            setAccountCodeId("");
            setCompanyId("");
            setAddNewAccountCardDocument(true);
            setAddNewAccountCard(true);
        }
        else {
            setItemEditing(true);
            const updatedFields = {
                accountCaseId: value.accountCaseId,
                accountCodeId: value.cardId,
                accountCodeName: value.name,
            };

            setAccountCodeName(value.name);
            setCompanyId(value.accountCaseId);
            setAccountCodeId(value.cardId);
            setInvoiceClientCaseId(value.cardId);

            // Update addEditableItem state
            setAddEditableItem(prevState => ({
                ...prevState,
                ...updatedFields,
            }));
            setSelectedOption({ ...value });

            // Call the function to update items
            updateAllItems(value, updatedFields);
        }
        // else {
        //     setItemEditing(true);
        //     setAccountCodeName(value.name);
        //     setCompanyId(value.accountCaseId);
        //     // const accountCodeId = accountCardOptions.find(option => option.name === selectedOption.value);
        //     setAccountCodeId(value.cardId);
        //     setInvoiceClientCaseId(value.cardId);
        //     addEditableItem.accountCaseId = value.accountCaseId;
        //     addEditableItem.accountCodeId = value.cardId;
        //     addEditableItem.accountCodeName = value.name;
        //     // Conditionally set creditAccount or debitAccount based on paymentAcceptanceType
        //     if (addEditableItem.paymentAcceptanceType === 'תקבול') {
        //         addEditableItem.creditAccount = value.name;
        //         setSelectedOption({ ...value, disableCreditAccount: true, disableDebitAccount: false });
        //     } else if (addEditableItem.paymentAcceptanceType === 'תשלום') {
        //         addEditableItem.debitAccount = value.name;
        //         setSelectedOption({ ...value, disableDebitAccount: true, disableCreditAccount: false });
        //     }
        // }


    };
    const updateAllItems = (value, updatedFields) => {
        const updatedItems = ['checks', 'bankTransactions', 'creditCards', 'cash'];
        const paymentType = addEditableItem.paymentAcceptanceType;

        updatedItems.forEach(itemType => {
            addEditableItem[itemType]?.forEach((item, index) => {
                // Determine the field to update
                const fieldToUpdate = paymentType === 'תקבול' ? 'creditAccount' : 'debitAccount';

                handleItemChange(itemType, index, fieldToUpdate, value.cardId);
            });
        });

        // Update addEditableItem with new account data
        setAddEditableItem(prevState => ({
            ...prevState,
            ...updatedFields,
        }));
    };
    const handleAccountEdit = (option) => {
        setShowAccountCardInputsEdit(true);
        setAccountCodeName(option.name);
        setAccountCodeId(option.cardId);
        setCompanyId(option.caseId);
        const account = accountCardOptions.find(account => account.cardId === option.cardId);
        setPalCode(account ? account.palCode : '');
        setOldSelectedAccountCardItem({
            cardId: option.cardId,
            caseId: option.caseId,
            accountCaseId: option.accountCaseId,
            name: option.name,
            palCode: '',
            client: null

        })
        setAddNewAccountCardDocument(true);
    };

    const handleAccountDelete = (option) => {
        setActionType('deleteAccountCard');
        setAccountCardIdToDelete(option.cardId);
        setIsModalVisible(true);
        // handleAccountCardDeleteClick(option.cardId);
    };


    const handleClose = async (clearDocuments = true) => {
        setOpenInvoiceCreatorModal(false);
        setPaymentModal(false);
        setAddEditableItem({
            checks: [],
            creditCards: [],
            cash: [],
            bankTransactions: [],
            date: new Date()
        });
    }
    const [paymentModal, setPaymentModal] = useState(false);

    const setCurrentItem = async (transaction, rowIndex) => {
        try {

            let type = transaction.type1



            let myItem;

            // Check if transactionDetailsDictionary has the item with the transaction ID
            if (transactionDetailsDictionary[transaction.id]) {
                myItem = transactionDetailsDictionary[transaction.id];
            } else {
                // If not, make an API call to fetch the item
                let response;
                if (transaction.type1 === 'JournalEntries') {
                    response = await DocumentService.GetDocumentById('JournalEntries', client.caseId, transaction.id, token);
                    type = transaction.type1;
                } else {
                    response = await DocumentService.GetDocumentById(type, client.caseId, transaction.id, token);
                }

                if (response.ok) {
                    myItem = await response.json();
                    myItem.docType = type;
                    // Store the fetched item in transactionDetailsDictionary with the transaction ID as key
                    const updatedDictionary = { ...transactionDetailsDictionary };
                    updatedDictionary[transaction.id] = myItem;
                    setTransactionDetailsDictionary(updatedDictionary);
                } else {
                    console.error('Error fetching document details');
                    return; // Exit the function if the API call fails
                }
            }

            // Load the previous and next items asynchronously without await
            loadSurroundingItems(rowIndex, type, filteredAndSortedTransactions);

            if (!type.includes("PaymentAcceptances")) {
                if (transaction.type1 !== 'JournalEntries') {
                    setCurrentIndex(rowIndex);
                    setItemToDelete({ id: myItem.id, type: myItem.docType });
                    setEditedItem({ ...myItem });
                    setEditedPopupItem(true);
                    setEditedJournalItem(false);
                    setPaymentModal(false);
                } else {

                    setCurrentIndex(rowIndex);
                    setItemToDelete({ id: myItem.id, type: myItem.docType });
                    setEditedItem({ ...myItem });
                    setEditedPopupItem(true);
                    setEditedJournalItem(true);
                    setPaymentModal(false);
                }
            }
            else {
                // Extract the type and id from the transaction
                const itemType = transaction.paymentAcceptanceFinancialTransactionAccountDetailsItem.type;
                const itemId = transaction.paymentAcceptanceFinancialTransactionAccountDetailsItem.id;

                // Get the array from myItem corresponding to the type
                const itemsArray = myItem[itemType]; // e.g., myItem['checks']


                // Ensure itemsArray exists and is an array
                if (Array.isArray(itemsArray)) {
                    // Find the index of the item with the matching id
                    const indexInArray = itemsArray.findIndex(item => item.id === itemId);


                    setShowType({ type: itemType, index: indexInArray })

                } else {
                    console.error(`No array found for type "${itemType}" in myItem.`);
                    setShowType({ type: itemType, index: -1 })

                }

                setItemToDelete({ id: myItem.id, type: myItem.docType });
                setCurrentIndex(rowIndex);
                setOpenInvoiceCreatorModal(true);
                setAddEditableItem({ ...myItem });
                setPaymentModal(true);
                setEditedJournalItem(false);
                setEditedPopupItem(false);
                setItemEditing(true);
            }

            // else {
            //     

            // setCurrentIndex(rowIndex);
            // setOpenInvoiceCreatorModal(true);
            // setAddEditableItem({ ...myItem });
            // setPaymentModal(true);
            // setEditedJournalItem(false);
            // setEditedPopupItem(false);
            // }
        } catch (error) {
            console.error('An error occurred:', error);
        }
    };

    // Function to load the surrounding items (previous and next)
    const loadSurroundingItems = (rowIndex, type, transactionArray) => {
        return new Promise((resolve, reject) => {
            const updatedDictionary = { ...transactionDetailsDictionary };
            const promises = [];

            // Load the previous transaction if it exists and is not already loaded
            if (rowIndex > 0 && !transactionDetailsDictionary[transactionArray[rowIndex - 1].id]) {
                const previousPromise = DocumentService.GetDocumentById(transactionArray[rowIndex - 1].type, client.caseId, transactionArray[rowIndex - 1].id, token)
                    .then(response => response.ok ? response.json() : null)
                    .then(data => {
                        if (data) {
                            data.docType = transactionArray[rowIndex - 1].type;
                            updatedDictionary[transactionArray[rowIndex - 1].id] = data;
                            setTransactionDetailsDictionary(updatedDictionary);
                        }
                    })
                    .catch(error => {
                        console.error('Error fetching previous transaction:', error);
                        reject(error);
                    });

                promises.push(previousPromise);
            }

            // Load the next transaction if it exists and is not already loaded
            if (rowIndex < transactionArray.length - 1 && !transactionDetailsDictionary[transactionArray[rowIndex + 1].id]) {
                const nextPromise = DocumentService.GetDocumentById(transactionArray[rowIndex + 1].type, client.caseId, transactionArray[rowIndex + 1].id, token)
                    .then(response => response.ok ? response.json() : null)
                    .then(data => {
                        if (data) {
                            data.docType = transactionArray[rowIndex + 1].type;
                            updatedDictionary[transactionArray[rowIndex + 1].id] = data;
                            setTransactionDetailsDictionary(updatedDictionary);
                        }
                    })
                    .catch(error => {
                        console.error('Error fetching next transaction:', error);
                        reject(error);
                    });

                promises.push(nextPromise);
            }

            // Resolve when all promises are complete
            Promise.all(promises).then(() => resolve()).catch(error => reject(error));
        });
    };


    const getItemAtIndex = (transactions, newIndex) => {
        // Convert the object to an array of its values
        const transactionsArray = Object.values(transactions);

        // Check if the index is within the bounds of the array
        if (newIndex >= 0 && newIndex < transactionsArray.length) {
            return transactionsArray[newIndex];
        } else {
            console.error('Index out of bounds');
            return null;
        }
    };


    const [firstRowDetails, setFirstRowDetails] = useState(null);
    const [transactions, setTransactions] = useState(null);

    const handleNext = () => {
        let newIndex = currentIndex;

        while (newIndex < filteredAndSortedTransactions.length - 1) {
            newIndex += 1;
            const nextItem = getItemAtIndex(filteredAndSortedTransactions, newIndex);
            const currentItem = getItemAtIndex(filteredAndSortedTransactions, currentIndex);
            // Check if the next item has a different id
            if (nextItem.id !== currentItem.id) {
                setCurrentItem(nextItem, newIndex);
                break;  // Exit the loop once a different ID is found
            }
        }
    };

    const handlePrevious = () => {
        let newIndex = currentIndex;

        while (newIndex > 0) {
            newIndex -= 1;
            const previousItem = getItemAtIndex(filteredAndSortedTransactions, newIndex);
            const currentItem = getItemAtIndex(filteredAndSortedTransactions, currentIndex);

            // Check if the previous item has a different id
            if (previousItem.id !== currentItem.id) {
                setCurrentItem(previousItem, newIndex);
                break;  // Exit the loop once a different ID is found
            }
        }
    };

    const [dataForPdf, setDataForPdf] = useState(null);
    const [sortedDataForPdf, setSortedDataForPdf] = useState(null);

    const [transactionDetailsDictionary, setTransactionDetailsDictionary] = useState({});

    const filteredAndSortedTransactions = useMemo(() => {
        if (!transactions || transactions.length === 0) {
            return [];
        }

        // Filtering the transactions based on searchQuery
        const filteredData = transactions.filter((transaction) => {
            const query = searchQuery.toLowerCase();
            return (
                transaction?.description?.toLowerCase().includes(query) ||
                transaction?.reference1?.toLowerCase().includes(query) ||
                transaction?.reference2?.toLowerCase().includes(query) ||
                transaction?.debitAccount?.toString().includes(query) ||
                transaction?.creditAccount?.toString().includes(query) ||
                transaction?.balance?.toString().includes(query) ||
                new Date(transaction.documentDate).toLocaleDateString().includes(query) ||
                new Date(transaction.uploadDate).toLocaleDateString().includes(query)
            );
        });

        // Sorting the filtered transactions based on sortConfig
        if (sortConfig.key) {
            filteredData.sort((a, b) => {
                const aValue = a[sortConfig.key] || ""; // Default to empty string if null or undefined
                const bValue = b[sortConfig.key] || ""; // Default to empty string if null or undefined

                if (sortConfig.type === 'number') {
                    return sortConfig.direction === 'ascending' ? aValue - bValue : bValue - aValue;
                } else if (sortConfig.type === 'date') {
                    return sortConfig.direction === 'ascending'
                        ? new Date(aValue) - new Date(bValue)
                        : new Date(bValue) - new Date(aValue);
                } else { // default to string comparison
                    return sortConfig.direction === 'ascending'
                        ? aValue?.localeCompare(bValue)
                        : bValue?.localeCompare(aValue);
                }
            });
        }
        const updatedDataForPdf = {
            ...dataForPdf,
            transactions: filteredData,
        };
        setSortedDataForPdf(updatedDataForPdf);

        return filteredData;
    }, [transactions, searchQuery, sortConfig]);

    const [palCodeDetails, setPalCodeDetails] = useState('');

    const [finalBalance, setFinalBalance] = useState('');

    const fetchAccountCardDashboard = async (startDate, endDate, processedFilesCheckbox) => {

        setLoading(true);
        try {
            ;

            const response = await EnumsService.AccountCardPalCodeDetails(accountCardId, accountCardName, client.accountantId, palCodeName, client.caseId, startDate, endDate, !isMonthly, processedFilesCheckbox, token);

            if (response?.ok) {
                const result = await response.json();

                const totalBalance = result.transactions?.length
                    ? result.transactions[result.transactions.length - 1].balance.toFixed(2)
                    : "0";
                setFinalBalance(totalBalance);

                // Loop over transactions and modify the type
                // result.transactions.forEach(transaction => {
                //     if (transaction.type === 'Income') {
                //         transaction.type = 'Incomes';
                //     } else if (transaction.type === 'Expense') {
                //         transaction.type = 'Expenses';
                //     } else {
                //         transaction.type = 'PaymentAcceptances';
                //     }
                // });

                const firstRow = {
                    creditAccountAmount: result.creditAccountAmount || 0,
                    debitAccountAmount: result.debitAccountAmount || 0,
                    balance: result.balance,
                    description: result.description,
                };

                setFirstRowDetails(firstRow);
                setTransactions(result.transactions);

                //for getting surrounding items
                const newDictionary = {};
                result.transactions.forEach(transaction => {
                    newDictionary[transaction.id] = null;
                });
                setTransactionDetailsDictionary(newDictionary);

                const dataForPdf = {
                    clientName: client.name,
                    clientCase: client.caseId,
                    startDate: startDate,
                    EndDate: endDate,
                    accountCard: accountCardName,
                    ...result,
                };

                setDataForPdf(dataForPdf);

                setSortedDataForPdf(dataForPdf);
            }
        } catch (error) {
            console.error("Error in API call:", error);
            toast.error(`${t('ErrorFetchReportCardDataReturned')}`);
        }

        //Pal Details
        try {
            const response = await EnumsService.GetPalcodesDetailsByAccountCard(client.accountantId, palCodeName, token);
            if (response.ok) {
                const palCodeDetails = await response.json();
                setPalCodeDetails(palCodeDetails);

                setDataForPdf(prevData => ({
                    ...palCodeDetails,
                    ...prevData
                }));
                setSortedDataForPdf(prevData => ({
                    ...palCodeDetails,
                    ...prevData
                }));

            } else {
                console.error('Failed to fetch PalCode details:', response.statusText);
                toast.error(t('MissingInformationForAction'));
            }
        } catch (error) {
            toast.error(t('MissingInformationForAction'));
        }
        setLoading(false);
    };

    const CustomInput = React.forwardRef(({ value, onClick }, ref) => (
        <button className={`document-custom-input document-custom-input-range `} onClick={onClick} ref={ref}>
            {value}
        </button>
    ));



    const handleGoBack = () => {

        if (client) {

            handleBack();
        }
    };
    const [palCodeName, setPalCodeName] = useState(null);
    const [accountCardName, setAccountCardName] = useState(null);
    const [accountCardId, setAccountCardId] = useState(null);

    const fetchUserData = async () => {
        setLoading(true);
        try {
            const currentUser = await UserService.getCurrentUser();
            const currentToken = await UserService.getCurrentToken();
            if (currentUser && currentToken) {
                setUser(currentUser);
                setToken(currentToken);


                if (location.state && location.state.client && location.state.palCodeName && location.state.accountCardName
                    && location.state.accountCardId && location.state.startDate && location.state.endDate) {
                    setType("AccountCardDashboard");
                    setClient(location.state.client);
                    setPalCodeName(location.state.palCodeName);
                    setAccountCardName(location.state.accountCardName);
                    setAccountCardId(location.state.accountCardId);
                    setStartDate(location.state.startDate);
                    setEndDate(location.state.endDate);
                    await fetchEnums(currentUser, currentToken, location.state.client);
                }
            }
            else {
                navigate("/login");
            }

        } catch (error) {
            console.error("Error fetching user data:", error);
            // navigate("/login");
        }
        setLoading(false);
    };
    useEffect(() => {

        fetchUserData();
    }, [location]);



    useEffect(() => {
        if (isDataFetched) {
            const startDate = getStartOfYear(new Date());
            const endDate = getCurrentMonth(new Date());

            const startOfYear = new Date(Date.UTC(startDate.getFullYear(), 0, 1));
            const endOfYear = new Date(Date.UTC(endDate.getFullYear(), 11, 31));

            setStartDate(startDate);
            setEndDate(endDate);
            setStartYearDate(startOfYear);
            setEndYearDate(endOfYear);

            fetchAccountCardDashboard(isMonthly ? startDate : startOfYear, isMonthly ? endDate : endOfYear, processedFilesCheckbox);
        }
    }, [isDataFetched]);

    const handleProcessFilesCheckboxClick = async (isChecked) => {
        try {
            if (isChecked !== null) {
                if (isChecked) {
                    fetchAccountCardDashboard(startDate, endDate, true)
                } else {
                    fetchAccountCardDashboard(startDate, endDate, false)
                }
            }
        } catch (error) {
            console.error("An unexpected error occurred:", error);
        }

        setProcessedFilesCheckbox(isChecked);
    };






    // let header = getHeader();

    const refreshData = async () => {

        if (!isMonthly) {
            let startYear = new Date(startYearDate).getFullYear();
            let endYear = new Date(endYearDate).getFullYear();

            if (!startYear || !endYear) {
                console.error('Invalid dates:', startYearDate, endYearDate);
                return;
            }
            let startOfYear = new Date(startYear, 0, 1); // January is 0
            let endOfYear = new Date(endYear, 11, 31); // December is 11
            await fetchAccountCardDashboard(startOfYear, endOfYear, processedFilesCheckbox);
        }
        else {
            await fetchAccountCardDashboard(startDate, endDate, processedFilesCheckbox);
        }
    }







    const handleDeleteClick = () => {
        setIsModalVisible(true);
    };

    const handleEditDeleteClick = (itemId) => {
        setEditedPopupItem(false);
        setEditedItem({});
        setPaymentModal(false);
        setAddEditableItem({
            checks: [],
            creditCards: [],
            cash: [],
            bankTransactions: [],
            date: new Date()
        });
        handleDeleteClick();
    };

    const handleConfirmDelete = async () => {
        // Perform delete operation
        setLoading(true);
        setIsModalVisible(false);
        try {
            const response = await DocumentService.DeleteDocument(itemToDelete.type, itemToDelete.id, token);
            if (response.ok) {

                await fetchAccountCardDashboard(startDate, endDate, processedFilesCheckbox);
                // Show success toast
                toast.success(t("documentDeleteSuccess"));
            } else {
                // Handle any errors or display an error toast
                const errorResponse = await response.json();
                console.error('Error deleting document:', errorResponse);

                // Show error toast
                toast.error(t("documentDeleteError"));
            }
        } catch (error) {
            console.error('Error deleting document:', error);

            // Show error toast
            toast.error(t("documentDeleteError"));
        }
        setLoading(false);
    };


    //table functions
    const handleCustomScroll = (e) => {
        if (tableContainerRef.current) {
            tableContainerRef.current.scrollLeft = e.target.scrollLeft;
        }
    };
    const handleTableScroll = (e) => {
        if (customScrollbarRef.current) {
            customScrollbarRef.current.scrollLeft = e.target.scrollLeft;
        }
    };
    const headerStyle = {
        backgroundColor: '#f7f7f7',
        color: 'black',
        fontWeight: 'bold'
    }


    const customScrollbarRef = useRef();
    const tableContainerRef = useRef();

    const handleOpenHTMLModal = async () => {
        setOpenReportModal(true);
    };

    const handleCardMatching = async (card) => {
        navigate('/CardMatching', { state: { type: 'CardMatching', client, card } });
    };

    const handleCloseHTMLModal = () => {
        setOpenReportModal(false);
    };

    const handleSortConfigChange = (newSortConfig) => {
        handleSortChange(newSortConfig.key, newSortConfig.type);
    };

    const [denseTableHeader] = useState([
        t("BalanceClassification"),
        t("BalanceClassificationCategory"),
        t("AccountingGroup")
    ]);

    const [denseTableRows, setDenseTableRows] = useState([]);


    // Update denseTableRows whenever palCodeDetails changes
    useEffect(() => {
        setDenseTableRows([
            {
                BalanceClassification: palCodeDetails.palCode || '-',
                AccountingGroup: palCodeDetails.palCodeAccountGroup || '-',
                BalanceClassificationCategory: palCodeDetails.palCodeCategory || '-'
            }
        ]);
    }, [palCodeDetails]);



    return (
        <>
            {(addEditableItem && paymentModal) && (
                <PaymentAcceptanceCreateEditModal
                    openInvoiceCreatorModal={openInvoiceCreatorModal}
                    handleClose={handleClose}
                    t={t}
                    itemEditing={itemEditing}
                    addNewAccountCardDocument={addNewAccountCardDocument}
                    companyId={companyId}
                    accountCodeName={accountCodeName}
                    accountCodeId={accountCodeId}
                    setAccount={setAccount}
                    Constants={Constants}
                    palCode={palCode}
                    setPalCode={setPalCode}
                    isRange={isRange}
                    addEditableItem={addEditableItem}  // renamed from editedItem
                    isClosedReportingMonth={isClosedReportingMonth}
                    handleSaveEditAccountCard={handleSaveEditAccountCard}
                    handleAddAccountCard={handleAddAccountCard}
                    handleCloseAddNewAccountCardDocument={handleCloseAddNewAccountCardDocument}
                    accountCardOptions={accountCardOptions}
                    selectedOption={selectedOption}
                    handleMuiChange={handleMuiChange}
                    handleEdit={handleEdit}
                    handleAccountDelete={handleAccountDelete}  // renamed from handleDelete
                    handleChangeAccountType={handleChangeAccountType}
                    customStyles={customStyles}
                    setPaymentDate={setPaymentDate}
                    setReportingMonthDate={setReportingMonthDate}
                    handleDescriptionChange={handleDescriptionChange}
                    handleChangeOriginalTaxClearance={handleChangeOriginalTaxClearance}
                    handleAddEditableItemChange={handleAddEditableItemChange}
                    handleItemChange={handleItemChange}
                    handleRemoveItem={handleRemoveItem}
                    showAccountCardInputsEdit={showAccountCardInputsEdit}
                    statusCheck={statusCheck}
                    handleRadioChange={handleRadioChange}
                    handleSave={handleSave}
                    buttonDisabled={buttonDisabled}
                    paymentBeingEdited={paymentBeingEdited}
                    CustomDropdown={CustomDropdown}
                    type={type}
                    direction={direction}
                    setAddNewAccountCardDocument={setAddNewAccountCardDocument}
                    fetchEnums={fetchEnums}
                    isClosedReportingMonthAndIsRange={isClosedReportingMonthAndIsRange}
                    handleAddItem={handleAddItem}
                    CustomPalCode={CustomPalCode}
                    handleAccountEdit={handleAccountEdit}
                    handlePrevious={handlePrevious}
                    currentIndex={currentIndex}
                    filteredAndSortedData={filteredAndSortedTransactions}
                    handleNext={handleNext}
                    showType={showType}
                    handleDeleteClick={handleEditDeleteClick}
                />
            )}

            <div className="template-page-withoutHeight">
                <div className="template-container ">
                    <UpperPanelDetails client={client} />
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>

                        <div className='backIconBackground' style={{ alignSelf: i18n.language === 'en' ? 'flex-start' : 'flex-end' }} onClick={handleGoBack}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                                <path d="M20 21.1667L14.8267 15.9933L20 10.82C20.52 10.3 20.52 9.45999 20 8.93999C19.48 8.41999 18.64 8.41999 18.12 8.93999L12 15.06C11.48 15.58 11.48 16.42 12 16.94L18.12 23.06C18.64 23.58 19.48 23.58 20 23.06C20.5067 22.54 20.52 21.6867 20 21.1667Z" fill="black" fill-opacity="0.9" />
                            </svg>
                        </div>
                        <div className={`main-title ${direction}`}>{t("Sort Code Report") + ": " + accountCardName}</div>
                    </div>
                    <div className={`title-back-container ${direction}`} style={{ marginTop: '10px' }}>
                        <button onClick={handleOpenHTMLModal} className="create-Invoice-selector" >
                            <DescriptionIcon style={{ fontSize: '24px' }} />
                            <span>{t("GenerateLedgerCard")}</span>
                        </button>

                    </div>
                    <div className={`searchFilter-bar__container ${direction}`} >
                        <div>


                            <TextField
                                fullWidth
                                placeholder={t('searchClient')}
                                value={searchQuery}
                                onChange={(e) => setSearchQuery(e.target.value)}
                                className="search-bar"
                                style={{ height: '55px' }}
                                variant="standard"
                                InputProps={{
                                    disableUnderline: true,
                                    className: `custom-input-box-sizing ${direction === 'ltr' ? 'ltr-input' : 'rtl-input'}`,
                                    sx: {
                                        height: '55px',
                                        padding: '0 10px',
                                        borderRadius: '8px',
                                        background: '#F5F5F6',
                                        border: '1px solid transparent',
                                        '&:focus-within': {
                                            border: '2px solid var(--Foundation-Blue-Normal, #304FFF)',
                                        },
                                    },
                                    ...(direction === 'ltr'
                                        ? {
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton edge="end" style={{ marginRight: '10px' }}>
                                                        <SearchIcon />
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }
                                        : {
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <IconButton edge="start" style={{ marginLeft: '10px' }}>
                                                        <SearchIcon />
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }),
                                }}
                                inputProps={{
                                    style: {
                                        textAlign: direction === 'ltr' ? 'left' : 'right',
                                    },
                                }}
                            />


                        </div>
                        {isMonthly ? (
                            <>


                                <CustomMonthYearIndividualDatePicker
                                    year={dayjs(startDate)}
                                    month={dayjs(startDate)}
                                    onYearChange={(newYear) => handleStartDateChangeNew(newYear, 'year')}
                                    onMonthChange={(newMonth) => handleStartDateChangeNew(newMonth, 'month')}
                                    labelYear={t("StartYear")}
                                    labelMonth={t("StartMonth")}
                                    height="48px"
                                    widthOuter='270px'
                                    widthInner='120px'
                                />
                                <FormControlLabel
                                    value={isMonthly}
                                    control={<Switch
                                        className="custom-input-box-sizing-toggle"
                                        color="primary"
                                        checked={!isMonthly}
                                        onChange={handleRangeChange}
                                    />}
                                    label={t("yearly")}
                                    labelPlacement="bottom"
                                />

                                <CustomMonthYearIndividualDatePicker
                                    year={dayjs(endDate)}
                                    month={dayjs(endDate)}
                                    onYearChange={(newYear) => handleEndDateChangeNew(newYear, 'year')}
                                    onMonthChange={(newMonth) => handleEndDateChangeNew(newMonth, 'month')}
                                    labelYear={t("EndYear")}
                                    labelMonth={t("EndMonth")}
                                    height="48px"
                                    widthOuter='270px'
                                    widthInner='120px'
                                />
                            </>
                        ) : (
                            <>
                                <CustomYearDatePicker
                                    onDateChange={(date) => handleStartDateChange(date)}
                                    date={dayjs(startDate)}
                                    label={t("StartDate")}
                                />

                                <FormControlLabel
                                    value={!isMonthly}
                                    control={<Switch
                                        className="custom-input-box-sizing-toggle"
                                        color="primary"
                                        checked={!isMonthly}
                                        onChange={handleRangeChange}

                                    />}
                                    label={t("yearly")}
                                    labelPlacement="bottom"
                                />


                                <CustomYearDatePicker
                                    onDateChange={(date) => handleEndDateChange(date)}
                                    date={dayjs(endDate)}
                                    label={t("EndDate")}
                                />
                            </>
                        )}
                        <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                            <FormControlLabel
                                control={<Checkbox />}
                                label={t('ShowUnprocessedDocuments')}
                                checked={!processedFilesCheckbox}
                                onChange={() => handleProcessFilesCheckboxClick(!processedFilesCheckbox)}
                                sx={{
                                    width: '100%',  // Optional: to ensure it takes up available space
                                    display: 'flex',
                                    justifyContent: 'flex-start',  // Align checkbox and label properly
                                    '.MuiFormControlLabel-label': {
                                        width: '170px',  // Set your desired fixed width for the label
                                        textOverflow: 'ellipsis',
                                        whiteSpace: 'nowrap',
                                    }
                                }}
                            />
                        </div>
                        <button onClick={() => handleCardMatching(accountCardId)} className="create-Invoice-selector">
                            <img src={cardMatchingIcon} alt="Card Matching Icon" style={{ width: '24px', height: '24px', filter: 'brightness(0) invert(1)' }} />
                            <span>{t("CardMatching")}</span>
                        </button>

                    </div>
                    <div className='document-Dense-container'  >

                        <DenseTable headers={denseTableHeader} rows={denseTableRows} width='100%'

                            headerStyle={{
                                opacity: 0.6,
                                background: 'var(--orange, #F9AA2A)',
                                textAlign: 'center',
                                minHeight: '50px'
                            }}
                            rowStyle={{
                                borderTop: '1px solid rgba(191, 191, 193, 0.56)',
                                background: 'var(--secondary-12, rgba(254, 247, 234, 0.12))',
                                minHeight: '50px'
                            }}
                        />
                    </div>



                    <AccountCardDashboardTable
                        firstRowDetails={firstRowDetails}
                        filteredAndSortedTransactions={filteredAndSortedTransactions}
                        handleEdit={handleEdit}
                        handleSortChange={handleSortChange}
                        formatDateNew={formatDateNew}
                    />
                </div>
                <ToastContainer />
                <HamburgerMenu client={client} />
                {loading && (
                    <div className="loader-container">
                        <PuffLoader
                            size={100}        // Larger size for better visibility
                            loading={loading}
                        />
                    </div>
                )}

                <CustomDeleteConfirmationModal
                    isVisible={isModalVisible}
                    onClose={() => setIsModalVisible(false)}
                    onDelete={handleConfirmDelete}
                    confirmMessage={t('confirmDeleteMessage')}
                    titleMessage={t('confirmDeleteTitle')}
                />

                {editedItem && editedPopupItem && Object.keys(editedItem).length > 0 && (

                    <EditModal
                        editedItem={editedItem}
                        fade={fade}
                        handlePrevious={handlePrevious}
                        handleNext={handleNext}
                        currentIndex={currentIndex}
                        filteredAndSortedData={responseData}
                        NoDocumentsPdf={NoDocumentsPdf}
                        t={t}
                        handleAccountChange={handleAccountChange}
                        isRange={isRange}
                        isClosedReportingMonth={isClosedReportingMonth}
                        accountCardOptions={accountCardOptions}
                        handleChange={handleChange}
                        handleDateChange={handleDateChange}
                        CustomInput2={CustomInput2}
                        setReportingMonthDate={setReportingMonthDate}
                        handleVatRadioChange={handleVatRadioChange}
                        statusCheck={statusCheck}
                        handleRadioChange={handleRadioChange}
                        type={type}
                        editedAddAccountCard={editedAddAccountCard}
                        handleCloseEditAddNewAccountCardDocument={handleCloseEditAddNewAccountCardDocument}
                        handleEditAddAccountChange={handleEditAddAccountChange}
                        sortCodeIdOptions={sortCodeIdOptions}
                        debitAccountOptions={debitAccountOptions}
                        creditAccountOptions={creditAccountOptions}
                        handleUpdate={handleUpdate}
                        handleCancel={handleCancel}
                        handleDeleteClick={handleEditDeleteClick}
                        client={client}
                        token={token}
                        setAccountCardOptions={setAccountCardOptions}
                        setLoading={setLoading}
                        setEditedAddAccountCard={setEditedAddAccountCard}
                        setEditedItem={setEditedItem}
                        handleReopenEdit={handleReopenEdit}
                        showIframe={!editedJournalItem}
                        palCodes={formattedPalCodeOptions}
                    />
                )}
                <AccountCardReportHTML
                    open={openReportModal}
                    handleClose={handleCloseHTMLModal}
                    data={sortedDataForPdf}
                    initialData={firstRowDetails}
                    onSortConfigChange={handleSortConfigChange}
                    token={token}
                    finalBalance={finalBalance}
                />
            </div>

        </>
    );
}


export default AccountCardDashboard;
