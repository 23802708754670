import React, { useEffect, useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Constants from '../../../assests/Constants/constants';
import { styled } from '@mui/system';
import CustomDatePicker from '../../CustomDatePicker/CustomDatePicker';
import AddAccountCardModal from '../../Widgets/AddAccountCardModal/AddAccountCardModal';
import { useTranslation } from 'react-i18next';


import {
    Modal,
    TextField,
    Autocomplete,
    RadioGroup,
    FormControlLabel,
    Radio,
    Popper,
    Button,
    Tooltip,
} from '@mui/material';
import { getVatByCaseType } from '../../../Utils/GetVatValue';
import { ToastContainer, toast } from "react-toastify";


const StyledListItem = styled('li')({
    '&:hover': {
        backgroundColor: '#F5F5F6', // Hover color
        cursor: 'pointer', // Changes the cursor to pointer
    },
});

const StyledPopper = styled(Popper)(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: 'var(--Spacing-md, 8px)',
        background: 'var(--white, #FFF)',
        boxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.32)',
    },
    '& .MuiAutocomplete-listbox': {
        padding: theme.spacing(1),
    },
    '& .MuiAutocomplete-option': {
        borderRadius: 'var(--Spacing-sm, 4px)',
        '&[aria-selected="true"], &.Mui-focused, &:hover': {
            background: 'var(--orange, #F9AA2A) !important',
        },
    },
}));
const CreateDocumentModal = ({
    modalVisible,
    closeCreateModal,
    autoFilling,
    setAutoFilling,
    addNewAccountCardDocument,
    setAddNewAccountCardDocument,
    accountCardOptions,
    sortCodeIdOptions,
    creditAccountOptions,
    debitAccountOptions,
    type,
    formData,
    setFormData,
    handleSave,
    allFieldsFilled,
    documentDate,
    setDocumentDate,
    documentVat,
    setDocumentVat,
    handleDocumentUpload,
    documents,
    errorMessage,
    okMessage,
    client, // Ensure these props are passed to the component
    token,
    setLoading,
    palCodes,
    fetchEnums,
}) => {
    const { i18n, t } = useTranslation();

    const [creditDebitSearchTerm, setCreditDebitSearchTerm] = useState('');
    const [newAccountCard, setNewAccountCard] = useState({ accountCodeName: '', accountCodeId: '' });
    const [isEditAccountCard, setIsEditAccountCard] = useState(false);
    const [isAddAccountCard, setIsAddAccountCard] = useState(false);
    const [editedAddAccountCard, setEditedAddAccountCard] = useState(false);
    const [palCode, setPalCode] = useState('');
    const [newCardId, setNewCardId] = useState(null);
    const [addAccountCardType, setAddAccountCardType] = useState('main');
    const [cardIdOption, setCardIdOption] = useState(-1);

    useEffect(() => {
        if (accountCardOptions && accountCardOptions.length > 0) {
            const maxCardId = Math.max(
                ...accountCardOptions.slice(1).map((option) => parseInt(option.cardId, 10))
            );
            setCardIdOption(maxCardId + 1);
        }
    }, [accountCardOptions]);



    const formattedAccountCardOptions = accountCardOptions.map((option) => ({
        value: option.cardId,
        label: option.accountCaseId
            ? `${option.name} - ${option.accountCaseId}`
            : `${option.name}`,
        name: option.name,
        accountCaseId: option.accountCaseId || '',
        cardId: option.cardId,
        value1: option.value1,
        isEditable: option.isEditable,
        palCode: option.palCode,

    }));

    const formattedCreditAccountOptions = accountCardOptions.map((option) => ({
        value: option.cardId,
        label: option.accountCaseId
            ? `${option.name} - ${option.accountCaseId}`
            : `${option.name}`,
        name: option.name,
        accountCaseId: option.accountCaseId || '',
        cardId: option.cardId,
    }));

    const formattedDebitAccountOptions = accountCardOptions.map((option) => ({
        value: option.cardId,
        label: option.accountCaseId
            ? `${option.name} - ${option.accountCaseId}`
            : `${option.name}`,
        name: option.name,
        accountCaseId: option.accountCaseId || '',
        cardId: option.cardId,
    }));



    const formattedSortCodeOptions = sortCodeIdOptions.map((option) => ({
        value: option.sortCodeId,
        label: `${option.sortCodeId} - ${option.sortCodeName}`,
        name: option.sortCodeName
    }));
    const [direction, setDirection] = useState('rtl'); // Default to 'rtl'


    useEffect(() => {
        // Update direction based on the current language
        if (i18n.language === 'he' || i18n.language === 'ar') {
            setDirection('rtl');
        } else {
            setDirection('ltr');
        }
    }, [i18n.language]);

    const handleSortCodeChange = (value) => {
        if (!value) {
            return
        }
        const selectedOption = sortCodeIdOptions.find(option => option.sortCodeId === value);



        if (selectedOption) {
            const nameWithoutParentheses = selectedOption.sortCodeName
                .replace(/\s*\(.*?\)\s*/, '')
                .trim();

            const match = selectedOption.sortCodeName.match(/\((\d+)%?/);
            let customVat = match ? parseInt(match[1], 10) : null;

            // Set VAT based on name or conditions
            const vat = Constants.SortCodesWith66Vat.includes(nameWithoutParentheses) ? 66 :
                Constants.SortCodesWithZeroVat.includes(nameWithoutParentheses) || selectedOption.sortCodeVatType === 0 ? 0 :
                    Constants.SortCodesWith16Vat.includes(nameWithoutParentheses) ? 16 :
                        customVat !== null ? (customVat === 66 ? 66 : customVat) : 100;

            // Initialize updatedData with relevant properties
            const updatedData = {
                sortCodeId: selectedOption.sortCodeId,
                vat,
                creditAccount: selectedOption.creditAccount || '',
                debitAccount: selectedOption.debitAccount || '',
            };

            // Find the corresponding account card based on type and account options
            const accountCard = accountCardOptions.find(
                item => item.cardId === (type === 'Incomes' ? updatedData.debitAccount : updatedData.creditAccount)
            );

            // Set account details if accountCard is found
            if (accountCard) {
                updatedData.accountCodeName = accountCard.name;
                updatedData.accountCodeId = accountCard.cardId;
                updatedData.accountCaseId = accountCard.accountCaseId;
            } else {
                // Clear fields if no account card is found
                updatedData.accountCodeName = '';
                updatedData.accountCodeId = '';
                updatedData.accountCaseId = '';
            }

            // Update formData in one call


            setFormData(prev => ({ ...prev, ...updatedData }));

        }
    };




    const handleChange = (field, value) => {
        if (!value) {
            return
        }

        setFormData(prev => ({ ...prev, [field]: value }));
    };

    const EditAccountCard = (selectedItem, accountType) => {
        if (!selectedItem) {
            console.warn('No account card selected for editing.');
            return;
        }
        setPalCode(selectedItem.palCode);
        setNewAccountCard({
            accountCodeName: selectedItem.name || '',
            accountCodeId: selectedItem.cardId,
            accountCaseId: selectedItem.accountCaseId || '',
        });
        setIsEditAccountCard(true);
        setAddAccountCardType(accountType);
        setIsAddAccountCard(false);
        setEditedAddAccountCard(true);
    };

    const updateAccountFields = (newValue, accountType) => {
        if (newValue?.value1 === 'AddItem') {
            handleNoOptionCreditDebitClick('', accountType);
            return;
        }

        // Since type is always 'Incomes' in this modal
        const isIncomesDebit = accountType === 'debitAccount';

        const updatedFields = {
            accountCodeId: isIncomesDebit ? (newValue ? newValue.cardId : '') : '',
            accountCodeName: isIncomesDebit ? (newValue ? newValue.name : '') : '',
            accountCaseId: isIncomesDebit ? (newValue ? newValue.accountCaseId : '') : '',
        };

        setFormData((prev) => ({
            ...prev,
            [accountType]: newValue ? newValue.cardId : '',
            ...(isIncomesDebit ? updatedFields : {}),
        }));
    };

    const handleChangeAfterAdd = (cardId, accountType) => {
        const newCard = {
            cardId,
            type: accountType,
        };
        setNewCardId(newCard);
    };

    useEffect(() => {
        if (accountCardOptions && accountCardOptions.length > 0 && newCardId) {
            const selectedOption = accountCardOptions.find(
                (option) => option.cardId === newCardId.cardId
            );

            updateAccountFields(selectedOption, newCardId.type)
            setNewCardId(null);
        }
    }, [accountCardOptions, newCardId]);

    const handleCreditDebitForJournalEntry = (newValue, accountType) => {
        if (!newValue) {
            setFormData((prev) => ({ ...prev, [accountType]: '' }));
            return;
        }

        if (newValue.value1 === 'AddItem') {

            setIsAddAccountCard(true);
            setAddAccountCardType(accountType);
            setNewAccountCard({ accountCodeName: newValue.searchTerm || '', accountCodeId: cardIdOption });
            setEditedAddAccountCard(true);
            setIsEditAccountCard(false);
        } else {
            updateAccountFields(newValue, accountType);
        }
    };

    const handleNoOptionCreditDebitClick = (searchTerm, accountType) => {
        const newOption = {
            ...formattedAccountCardOptions[0],
            searchTerm: searchTerm,
            value1: 'AddItem',
        };
        handleCreditDebitForJournalEntry(newOption, accountType);
    };

    const inputPropsStyle = {
        disableUnderline: true,
        className: `custom-input-box-sizing-toggle ${direction === 'ltr' ? 'ltr-input' : 'rtl-input'}`,
        sx: {
            height: '48px',
            padding: '0 10px',
            borderRadius: '8px',
            background: '#F5F5F6',
            border: '1px solid transparent',
            '&:focus-within': {
                border: '2px solid var(--Foundation-Blue-Normal, #304FFF)',
            },

            '& *': {
                boxSizing: 'border-box !important',

            },
        },
    };

    const inputStyle = {
        style: { textAlign: direction === 'ltr' ? 'left' : 'right' },
    };

    const handleAccountCodeChange = (selectedOption) => {

        if (!selectedOption) {
            return
        }

        if (selectedOption?.value1 === "AddItem") {
            // Clear formData fields when adding a new item
            setFormData(prev => ({
                ...prev,
                accountCodeId: cardIdOption,
                accountCaseId: '',
                accountCodeName: '',
                creditAccount: '',
                debitAccount: '',
            }));
            setAddNewAccountCardDocument(true);
        } else {

            // Set formData fields based on selected option
            const accountCard = accountCardOptions.find(option => option.cardId === selectedOption?.value);


            setFormData(prev => ({
                ...prev,
                accountCodeId: selectedOption?.value || "",
                accountCaseId: selectedOption?.accountCaseId || "",
                accountCodeName: accountCard ? accountCard.name : '',
                creditAccount: type !== 'Incomes' ? selectedOption.value : '',
                debitAccount: type === 'Incomes' ? selectedOption.value : '',
            }));
        }
    };
    const handleCloseAddNewAccountCardDocument = () => {

        setFormData(prev => ({
            ...prev,
            accountCodeId: '',
            accountCaseId: '',
            accountCodeName: '',
            creditAccountOption: '',
            debitAccountOption: '',
        }));
        setAddNewAccountCardDocument(false);
    };

    return (
        <Modal open={modalVisible} onClose={closeCreateModal}>

            <div className="create-modal-overlay">
                <div className="modal-background">
                    <div className={`newDocument-modal-content ${direction}`}>
                        <div className={`modal-content-title ${direction}`}>

                            <h2>{t('newDocument')}</h2>

                            <div className='newDocument-container-button'>
                                <button className={`newDocument-button ${autoFilling ? 'active' : ''}`} onClick={() => setAutoFilling(true)}>
                                    {t('Automatic Filling')}
                                </button>
                                <button className={`newDocument-button ${!autoFilling ? 'active' : ''}`} onClick={() => setAutoFilling(false)}>
                                    {t('Manual Filling')}
                                </button>
                            </div>
                        </div>

                        {!autoFilling && (
                            <div className={`newDocument-container-items ${direction}`}>


                                <div className="newDocument-form-row-item">
                                    <div className="edit-form-row-item-title">{t('SortCode Id')}<span className="required-asterisk">*</span></div>
                                    <Autocomplete
                                        options={formattedSortCodeOptions}
                                        getOptionLabel={(option) => option.label}
                                        value={formattedSortCodeOptions.find(option => option.value === formData.sortCodeId) || null}
                                        onChange={(e, newValue) => handleSortCodeChange(newValue ? newValue.value : '')}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                variant="standard"
                                                InputProps={{ ...params.InputProps, ...inputPropsStyle }}
                                                inputProps={{ ...params.inputProps, ...inputStyle }}
                                                placeholder={t("SortCode Id")}
                                            />
                                        )}
                                        PopperComponent={(props) => (
                                            <StyledPopper
                                                {...props}
                                                style={{
                                                    ...props.style,
                                                    direction: direction,
                                                }}
                                            />
                                        )}
                                    />
                                </div>
                                <div className="newDocument-form-row-item">
                                    <div className="edit-form-row-item-title">
                                        {t('Debit Account')}
                                        <span className="required-asterisk">*</span>
                                    </div>
                                    <Autocomplete
                                        options={formattedAccountCardOptions}
                                        getOptionLabel={(option) =>
                                            `${option.cardId} - ${option.name}`
                                        }
                                        value={
                                            formattedAccountCardOptions.find(
                                                (option) => option.cardId === formData.debitAccount
                                            ) || null
                                        }
                                        onChange={(event, newValue) => {
                                            updateAccountFields(newValue, 'debitAccount');
                                        }}
                                        onInputChange={(event, inputValue) => {
                                            setCreditDebitSearchTerm(inputValue);
                                        }}
                                        noOptionsText={
                                            <Button
                                                onClick={() =>
                                                    handleNoOptionCreditDebitClick(
                                                        creditDebitSearchTerm,
                                                        'debitAccount'
                                                    )
                                                }
                                                variant="contained"
                                                color="primary"
                                            >
                                                {t('AddAccountCard')} "{creditDebitSearchTerm}"
                                            </Button>
                                        }
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                placeholder={t('Debit Account')}
                                                variant="standard"
                                                InputProps={{
                                                    ...params.InputProps,
                                                    ...inputPropsStyle,
                                                }}
                                                inputProps={{
                                                    ...params.inputProps,
                                                    ...inputStyle,
                                                }}
                                            />
                                        )}
                                        renderOption={(props, option) => (
                                            <Tooltip
                                                title={
                                                    option.isEditable
                                                        ? t('RightClickToEdit')
                                                        : t('NotEditable')
                                                }
                                                placement="top"
                                            >
                                                <StyledListItem
                                                    {...props}
                                                    onContextMenu={(event) => {
                                                        if (!option.isEditable) {
                                                            event.preventDefault();
                                                            return;
                                                        }
                                                        event.preventDefault();
                                                        EditAccountCard(option, 'debitAccount');
                                                    }}
                                                >
                                                    {`${option?.cardId || ''} - ${option.name}`}
                                                </StyledListItem>
                                            </Tooltip>
                                        )}
                                        PopperComponent={(props) => (
                                            <StyledPopper
                                                {...props}
                                                style={{
                                                    ...props.style,
                                                    direction: direction,
                                                }}
                                            />
                                        )}
                                    />
                                </div>
                                {/* Credit Account */}
                                <div className="newDocument-form-row-item">
                                    <div className="edit-form-row-item-title">
                                        {t('Credit Account')}
                                        <span className="required-asterisk">*</span>
                                    </div>
                                    <Autocomplete
                                        options={formattedAccountCardOptions}
                                        getOptionLabel={(option) =>
                                            `${option.cardId} - ${option.name}`
                                        }
                                        value={
                                            formattedAccountCardOptions.find(
                                                (option) => option.cardId === formData.creditAccount
                                            ) || null
                                        }
                                        onChange={(event, newValue) => {
                                            updateAccountFields(newValue, 'creditAccount');
                                        }}
                                        onInputChange={(event, inputValue) => {
                                            setCreditDebitSearchTerm(inputValue);
                                        }}
                                        noOptionsText={
                                            <Button
                                                onClick={() =>
                                                    handleNoOptionCreditDebitClick(
                                                        creditDebitSearchTerm,
                                                        'creditAccount'
                                                    )
                                                }
                                                variant="contained"
                                                color="primary"
                                            >
                                                {t('AddAccountCard')} "{creditDebitSearchTerm}"
                                            </Button>
                                        }
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                placeholder={t('Credit Account')}
                                                variant="standard"
                                                InputProps={{
                                                    ...params.InputProps,
                                                    ...inputPropsStyle,
                                                }}
                                                inputProps={{
                                                    ...params.inputProps,
                                                    ...inputStyle,
                                                }}
                                            />
                                        )}
                                        renderOption={(props, option) => (
                                            <Tooltip
                                                title={
                                                    option.isEditable
                                                        ? t('RightClickToEdit')
                                                        : t('NotEditable')
                                                }
                                                placement="top"
                                            >
                                                <StyledListItem
                                                    {...props}
                                                    onContextMenu={(event) => {
                                                        if (!option.isEditable) {
                                                            event.preventDefault();
                                                            return;
                                                        }
                                                        event.preventDefault();
                                                        EditAccountCard(option, 'creditAccount');
                                                    }}
                                                >
                                                    {`${option?.cardId || ''} - ${option.name}`}
                                                </StyledListItem>
                                            </Tooltip>
                                        )}
                                        PopperComponent={(props) => (
                                            <StyledPopper
                                                {...props}
                                                style={{
                                                    ...props.style,
                                                    direction: direction,
                                                }}
                                            />
                                        )}
                                    />
                                </div>



                                <div className="newDocument-form-row-item">
                                    <div className="edit-form-row-item-title">{t('Amount')}<span className="required-asterisk">*</span></div>
                                    <TextField
                                        fullWidth
                                        type="number"
                                        value={formData.amount || ''}
                                        onChange={(e) => handleChange('amount', e.target.value)}
                                        variant="standard"
                                        InputProps={inputPropsStyle}
                                        inputProps={inputStyle}
                                        placeholder={t('Amount')}
                                    />
                                </div>
                                <div className="newDocument-form-row-item">
                                    <div className="edit-form-row-item-title">{t('enterReference')}<span className="required-asterisk">*</span></div>
                                    <TextField
                                        fullWidth
                                        value={formData.reference || ''}
                                        onChange={(e) => handleChange('reference', e.target.value)}
                                        variant="standard"
                                        InputProps={inputPropsStyle}
                                        inputProps={inputStyle}
                                        placeholder={t('enterReference')}
                                    />
                                </div>
                                <div className="newDocument-form-row-item">
                                    <div className="edit-form-row-item-title">{t('enterDescription')}<span className="required-asterisk">*</span></div>
                                    <TextField
                                        fullWidth
                                        value={formData.description || ''}
                                        onChange={(e) => handleChange('description', e.target.value)}
                                        variant="standard"
                                        InputProps={inputPropsStyle}
                                        inputProps={inputStyle}
                                        placeholder={t('enterDescription')}
                                    />
                                </div>
                                <div className="newDocument-form-row-item">
                                    <div className="edit-form-row-item-title">
                                        {t('Document Date')}<span className="required-asterisk">*</span>
                                    </div>
                                    {/* Use CustomDatePicker here */}
                                    <CustomDatePicker
                                        date={formData.documentDate ? new Date(formData.documentDate) : null}
                                        onDateChange={(date) => {
                                            if (date) {
                                                handleChange('documentDate', date.toISOString());
                                            }
                                        }}
                                        // label={t('Select Document Date')}
                                        height="48px"
                                    />
                                </div>
                            </div>
                        )}
                        <div className="newDocument-autoContainer">
                            <div className="newDocument-form-row-item">
                                <div className="edit-form-row-item-title">{t('VAT')}<span className="required-asterisk">*</span></div>
                                <RadioGroup
                                    row
                                    aria-labelledby="demo-form-control-label-placement"
                                    name="vat"
                                    value={
                                        client?.caseType && getVatByCaseType(client.caseType) !== undefined
                                            ? getVatByCaseType(client.caseType)
                                            : formData.vat
                                    }
                                    onChange={(e) => {
                                        if (client?.caseType && getVatByCaseType(client.caseType) !== undefined) {
                                            // Prevent change and show toast
                                            toast.error(
                                                `${t('VAT')} ${client.caseType}`,
                                                {
                                                    autoClose: 3000,
                                                }
                                            );
                                        } else {
                                            handleChange('vat', parseFloat(e.target.value)); // Allow editing for non-exempt types
                                        }
                                    }}
                                >
                                    {Constants.VATPercentage.map((value) => (
                                        <FormControlLabel
                                            key={value}
                                            value={value}
                                            control={<Radio />}
                                            label={t(value === 16 ? 'AccountingTax' : `${value}`)}
                                            labelPlacement="top"
                                            checked={
                                                client?.caseType && getVatByCaseType(client.caseType) !== undefined
                                                    ? value === getVatByCaseType(client.caseType) // Select the correct value for exempt types
                                                    : value === formData.vat // Select the correct value for other cases
                                            }

                                        />
                                    ))}
                                </RadioGroup>
                            </div>


                            <div className={`newDocument-file-upload-container ${autoFilling ? "input-field_Container_required-asterisk" : ""}`}>
                                <input
                                    type="file"
                                    id="file"
                                    className="input-file"
                                    multiple
                                    onChange={handleDocumentUpload}
                                />
                                <label htmlFor="file" className="newDocument-upload-label">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="upload-icon" viewBox="0 0 58 58" fill="none">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M29 38.0625C30.001 38.0625 30.8125 37.251 30.8125 36.25V9.73298L34.874 14.4712C35.5253 15.2313 36.6696 15.3193 37.4296 14.6678C38.1896 14.0164 38.2776 12.8721 37.6261 12.1121L30.3761 3.65378C30.0319 3.25204 29.529 3.02084 29 3.02084C28.471 3.02084 27.9681 3.25204 27.624 3.65378L20.3739 12.1121C19.7224 12.8721 19.8104 14.0164 20.5705 14.6678C21.3305 15.3193 22.4747 15.2313 23.1262 14.4712L27.1875 9.73298V36.25C27.1875 37.251 27.999 38.0625 29 38.0625Z" fill="#F9AA2A" />
                                        <path d="M38.6673 21.75C36.9703 21.75 36.1218 21.75 35.5124 22.1572C35.2485 22.3336 35.0218 22.5602 34.8456 22.8241C34.4381 23.4336 34.4381 24.2822 34.4381 25.9792V36.25C34.4381 39.2529 32.0038 41.6875 29.0006 41.6875C25.9977 41.6875 23.5632 39.2529 23.5632 36.25V25.9792C23.5632 24.2822 23.5632 23.4335 23.1559 22.824C22.9796 22.5601 22.7531 22.3336 22.4893 22.1573C21.8797 21.75 21.0311 21.75 19.334 21.75C12.4986 21.75 9.08094 21.75 6.95746 23.8735C4.83398 25.9971 4.83398 29.4142 4.83398 36.2495V38.6662C4.83398 45.5015 4.83398 48.9191 6.95746 51.0427C9.08094 53.1662 12.4986 53.1662 19.334 53.1662H38.6673C45.5026 53.1662 48.9203 53.1662 51.0438 51.0427C53.1673 48.9191 53.1673 45.5015 53.1673 38.6662V36.2495C53.1673 29.4142 53.1673 25.9971 51.0438 23.8735C48.9203 21.75 45.5026 21.75 38.6673 21.75Z" fill="#F9AA2A" />
                                    </svg>
                                    <span className="upload-text">
                                        {documents?.length > 0 ? documents.map(doc => doc.name).join(', ') : t('uploadDocument')}
                                    </span>
                                </label>
                            </div>

                        </div>

                        <div className="newDocument-button-container">
                            <div
                                className={`newDocument-create-buttons ${!allFieldsFilled ? 'disabled' : ''}`}
                                onClick={allFieldsFilled ? handleSave : null}
                            >
                                {t('save')}
                            </div>
                            <div className="newDocument-cancel-buttons" onClick={closeCreateModal}>
                                {t('close')}
                            </div>
                        </div>
                    </div>
                </div>
                <AddAccountCardModal
                    accountCardOptions={accountCardOptions}
                    open={editedAddAccountCard}
                    onClose={() => setEditedAddAccountCard(false)}
                    editedItem={newAccountCard}
                    isEdit={isEditAccountCard}
                    direction={direction}
                    client={client}
                    token={token}
                    setLoading={setLoading}
                    handleChangeAfterAdd={handleChangeAfterAdd}
                    fetchEnums={fetchEnums}
                    type={addAccountCardType}
                    palCodes={palCodes}
                    propPalCode={palCode}
                    setPalCode={setPalCode}
                />
            </div>

        </Modal>
    );
};

export default CreateDocumentModal;
